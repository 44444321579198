import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogContentText, 
	DialogActions, 
	Button,
	TextField,
	Box,
	Typography
} from "@mui/material" 
import {useDispatch, useSelector} from "react-redux"
import { closeAttachmentModal, selectNoteAttachmentPreview, selectNote, selectIsModalOpen, updateNoteAttachmentPreview, selectIsLinkValid, selectIsNoteAttachmentPreviewValid, selectActionType } from "../../../reducers/noteAttachmentsSlice"
import Attachment from "./Attachments/Attachment"
import { postNoteAttachment, editNoteAttachment } from "../../../reducers/noteAttachmentsSlice"

export default function NoteAttachmentModal() {
	const dispatch = useDispatch()
	const attachmentPreview = useSelector(selectNoteAttachmentPreview)
	const isLinkValid = useSelector(selectIsLinkValid)
	const note = useSelector(selectNote)
	const open = useSelector(selectIsModalOpen)
	const isAttachmentValid = useSelector(selectIsNoteAttachmentPreviewValid)
	const actionType = useSelector(selectActionType)
	return (
		<Dialog
			open={open}
			onClose={() => dispatch(closeAttachmentModal())}
			sx={{ textAlign: "left" }}
		>
			<DialogTitle>Attach Link</DialogTitle>
			<DialogContent>  
				
				<DialogContentText variant="body1" sx={{color: "black"}} paragraph gutterBottom>
					{
						actionType === "create" && (
							`Attach a website URL to the note: ${note?.content}`
						)
					}
					{
						actionType === "edit" && (
							`Edit attachment on note: ${note?.content}`
						)
					}
				</DialogContentText>
				<form onSubmit={(e) => {
					e.preventDefault()
					if (actionType === "create") {
						dispatch(postNoteAttachment({attachment: attachmentPreview, note }))
					}
					if (actionType === "edit") {
						dispatch(editNoteAttachment({attachment: attachmentPreview, note }))
					}
				}}>
					<TextField
						variant="outlined"
						label={"Add Your Link Here"}
						className="keyTextField"
						value={attachmentPreview?.url}
						onChange={(e) => dispatch(updateNoteAttachmentPreview({...attachmentPreview, url: e.target.value}))}
						fullWidth
						error={!isLinkValid}
						required
					/>
					<TextField
						variant="outlined"
						label={"Title"}
						className="keyTextField"
						value={attachmentPreview?.title}
						onChange={(e) => dispatch(updateNoteAttachmentPreview({...attachmentPreview, title: e.target.value}))}
						sx={{
							mt: 3, // shorthand for marginTop: theme.spacing(2)
							mb: 3, // shorthand for marginBottom: theme.spacing(2)
						}}
						fullWidth
						required
					/>
					<TextField
						variant="outlined"
						label={"Description"}
						className="keyTextField"
						value={attachmentPreview?.description}
						onChange={(e) => dispatch(updateNoteAttachmentPreview({...attachmentPreview, description: e.target.value}))}
						fullWidth
						required
					/>	

					<Box sx={{
						mt: 3,
						mb: 1
					}}>
						<Typography variant="subtitle1" component="div">
							Preview
						</Typography>
						<Attachment attachment={attachmentPreview} preview={true} />
					</Box>
					<DialogActions
						sx={{
							padding: 0,
							mt: 2
						}}
					>
						<Button
							variant="contained"
							color={"primary"}
							type="submit"
							disabled={!isAttachmentValid}
						>
							{
								actionType === "create" ? "	Attach Link" : "Update Link"
							}
						</Button>
						<Button
							variant={"outlined"}
							color="primary"
							onClick={() => dispatch(closeAttachmentModal())}
						>
							Cancel
						</Button>
					</DialogActions>
				</form>
			</DialogContent>
		</Dialog>
	)
}
