import {createSlice} from "@reduxjs/toolkit"

export const DYNAMIC_PANEL_TAB_INDEX_MAP = {
	ARTICLE_SEARCH: 0,
	NOTE_ARTICLE_LIST: 1,
	TOOLS: 2,
	AUTOMATED_SEARCH: 3,
	NOTIFICATION: 4,
	NON_PUBMED_IMPORT: 5
	// ### PortalLegacy ### \\
	//PORTALS: 4,
	// ### PortalLegacy ### \\
}

const initialValue = DYNAMIC_PANEL_TAB_INDEX_MAP["ARTICLE_SEARCH"]

export const dynamicViewSlice = createSlice({
	name: "dynamicView",
	initialState: {
		value: initialValue,
	},
	reducers: {
		setDynamicView: (state, action) => {
			state.value = action.payload
		},
		resetDynamicView: (state) => {
			state.value = initialValue
		}
	},
	extraReducers: builder => {
		builder.addCase("noteArticleTabs/handleNoteArticle", (state) => {
			state.value = DYNAMIC_PANEL_TAB_INDEX_MAP.NOTE_ARTICLE_LIST
		})
		builder.addCase("workspace/setWorkspace", state => {
			state.value = initialValue
		})
	}
})

export const { setDynamicView, resetDynamicView } = dynamicViewSlice.actions

export const selectDynamicView = (state) => state.dynamicView.value

export default dynamicViewSlice.reducer
