import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"
import {changeOpenComponent} from "./accountSettingsNavSlice"

export const fetchStripeInvoices = createAsyncThunk(
	"stripeInvoices/fetchStripeInvoices",
	async (_, { rejectWithValue, getState, }) => {
		const state = getState()
		const startingValue = state.stripeInvoices.startingAfter
		const {data} = await axios
			.get(`api/subscription/list-invoices${startingValue ? `?after=${startingValue}` : ""}`)
			.catch(e => {
				console.error("Failed to fetch invoices: ", e)
				rejectWithValue(e)
			})
		console.log("fetched invoices, what's data: ", data)
		return data
	}
)

export const fetchCurrentInvoice = createAsyncThunk(
	"stripeInvoices/fetchCurrent",
	async (latestInvoiceId, {rejectWithValue}) => {
		try {
			const {data: currentInvoice} = await axios.get(`/api/subscription/invoice/${latestInvoiceId}`)
			return currentInvoice
		} catch (e) {
			console.error("error fetching current invoice: ", e)
			rejectWithValue("Failed to retrieve current invoice, please try again.")
		}
	}
)

const initial = {
	invoices: [],
	startingAfter: null,
}

export const stripeInvoicesSlice = createSlice({
	name: "stripeInvoices",
	initialState: {
		invoices: [],
		startingAfter: null,
		currentInvoice: null,
	},
	reducers: {
		resetState: () => initial,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchStripeInvoices.fulfilled, (state, action) => {
			console.log("let's unpack the data... whats action's payload: ", action.payload)
			const {invoices} = action.payload
			const {data: invoiceList} = invoices
			if(invoices.has_more) {
				// because we set a hard limit of 3 invoices per request on the server, we can assume that the most recent
				// invoice is sitting at index 2 if invoices.has_more is true.
				state.startingAfter = invoiceList[2].id
			} else {
				state.startingAfter = null
			}
			//check to make sure we don't already have invoices to avoid duplicates
			invoiceList.forEach(invoice => {
				const invoiceInState = state.invoices.findIndex(inv => inv.id === invoice.id) !== -1
				if(!invoiceInState){
					state.invoices.push(invoice)
				}
			})
		})
		builder.addCase(fetchCurrentInvoice.fulfilled, (state, action) => {
			state.currentInvoice = action.payload
		})
		//reset state when changing AccountSettings views, otherwise we will paginate next time we load MySubscription
		builder.addCase(changeOpenComponent, stripeInvoicesSlice.caseReducers.resetState)
	}
})

export const {resetState} = stripeInvoicesSlice.actions
export const selectThreeInvoices = (state) => state.stripeInvoices.invoices
export const selectCustomerHasMoreInvoices = (state) => state.stripeInvoices.startingAfter !== null
export const selectCurrentInvoiceUrl = (state) => state.stripeInvoices.currentInvoice?.hosted_invoice_url
export const selectCurrentInvoiceIsOpen = (state) => {
	const openInvoice = state.stripeInvoices.currentInvoice?.status === "open"
	const unpaidPI = state.stripeDetails.userSubscriptionData?.collection_method === "charge_automatically" && state.stripeDetails.userSubscriptionData?.status === "incomplete"

	return openInvoice || unpaidPI
}
export default stripeInvoicesSlice.reducer