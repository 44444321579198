import {useDispatch, useSelector} from "react-redux"
import {IconButton, Tab} from "@mui/material"
import {resetWorkingArticleNoteRelation} from "../../../reducers/workingArticleNoteRelationSlice"
import {Close} from "@mui/icons-material"
import {TabLabel} from "./TabLabel"
import {
	removeTabV2,
	selectNoteArticleTabsCurrentIndexV2,
	setCurrentTabIndexV2
} from "../../../reducers/noteArticleTabsV2Slice"
import { clearAttachmentsFromTab } from "../../../reducers/noteAttachmentsSlice"

export function NoteArticleTab(props) {
	const dispatch = useDispatch()
	const {tab, index} = props
	const currentIndex = useSelector(selectNoteArticleTabsCurrentIndexV2)

	const handleRemoveTabClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		dispatch(removeTabV2(index))
		dispatch(clearAttachmentsFromTab(index))
		if(index === currentIndex){
			dispatch(resetWorkingArticleNoteRelation())
		}
	}

	const setIndex = () => {
		dispatch(setCurrentTabIndexV2(index))
	}

	return (
		<Tab
			{...props}
			component={"div"}
			onClick={setIndex}
			variant={"secondaryToolBarTab"}
			sx={{
				zIndex: 10,
				border: tab.singleNote && "1px solid orange",
				borderBottom: "none",
				transition: "background-color 250ms ease-in-out",
				"&:hover": { backgroundColor: "rgba(0, 179, 197, 0.1)" },
			}}
			icon={
				<IconButton
					onClick={handleRemoveTabClick}
					variant={"tab-close"}
				>
					<Close fontSize={"inherit"} />
				</IconButton>
			}
			iconPosition="end"
			label={
				<TabLabel title={tab.title} />
			}
		></Tab>
	)
}
