import {Box, LinearProgress, Typography} from "@mui/material"
import {useSelector} from "react-redux"
import {selectImportedArticleCount} from "../../reducers/freeTierTrackerslice"
import {useNavigate} from "react-router-dom"

export default function ImportedArticleCounter() {
	const navigate = useNavigate()
	const articleCount = useSelector(selectImportedArticleCount)
	// directly set computed property for percentage and normalize percentage so it doesn't exceed 100%
	const percentage = Math.min(Math.floor((articleCount / 20) * 100), 100)

	return (
		<Box
			onClick={() => navigate("/account-settings")}
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				padding: "8px 10px",
				boxSizing: "border-box",
				zIndex: 20,
				backgroundColor: "rgba(89, 89, 89, 0.1)",
				gap: "4px",
				cursor: "pointer",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography sx={{
					fontSize: ".75rem",
					fontWeight: 400,
					color: "rgb(94, 94, 94)"
				}}>
					Workplace Citation Utilization
				</Typography>
				<Typography sx={{
					fontSize: ".75rem",
					fontWeight: 400,
					color: "rgb(94, 94, 94)"
				}}>
					{articleCount} / 20 ({articleCount > 20 ? "+" : ""}{percentage}%)
				</Typography>
			</Box>
			<LinearProgress
				variant="determinate"
				value={percentage}
				sx={{
					height: "0.375rem",
					borderRadius: "3px",
					backgroundColor: "rgb(204, 204, 204)",
					"& .MuiLinearProgress-barColorPrimary": {
						backgroundColor: "rgb(94, 94, 94)"
					}
				}}
			/>
			<Typography sx={{
				fontSize: ".625rem",
				fontWeight: 400,
				fontStyle: "italic",
				color: "rgb(94, 94, 94)",
			}}>
				This workspace has imported {articleCount} out of 20 citations. If you're the owner, upgrade now to get unlimited citations in all of your workspaces, and more!
			</Typography>
		</Box>
	)
}