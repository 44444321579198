import {createAsyncThunk, createSlice, createSelector} from "@reduxjs/toolkit"
import axios from "axios"
import { workspaceSlice }  from "./workspaceSlice"
import { deleteNote } from "./workspaceNoteSlice"

export const getUnassignedArticles = createAsyncThunk(
	"unassignedArticlesEventsSlice/getUnassignedArticles",
	async (workspaceId) => {
		const listOfUnassignedArticles = axios
			.get(`/api/workspace/${workspaceId}/article/unassigned`)
			.then(({ data }) => data)
			.catch(e => console.error("failed to get Unassigned Articles: ", e))
		return listOfUnassignedArticles
	}
)

export const unassignedArticlesEventsSlice = createSlice({
	name: "unassignedArticlesEventsSlice",
	initialState: {
		value: {
			unassignedArticles: []
		},
	},
	reducers: {
		clearUnassigned: (state) => {
			state.value.unassignedArticles = []
		},
		concatUnassignedArticles: (state, action) => {
			state.value.unassignedArticles = state.value.unassignedArticles.concat(action.payload)
		},
		removeUnassignedArticles: (state, action) => {
			const articleIdsToRemove = action.payload.map(article => article.articleId)
			state.value.unassignedArticles = state.value.unassignedArticles.filter(article => !articleIdsToRemove.includes(article.articleId))
		}
	},
	extraReducers: (builder) => {
		builder.addCase(deleteNote.fulfilled, (state, action) => {
			const { unassignedArticles } = action.payload
			state.value.unassignedArticles = state.value.unassignedArticles.concat(unassignedArticles)
		})
		builder.addCase(getUnassignedArticles.fulfilled, (state, action) => {
			state.value.unassignedArticles = action.payload
		})
		builder.addCase(
			workspaceSlice.actions.setWorkspace,
			unassignedArticlesEventsSlice.caseReducers.clearUnassigned
		)
	}
})

export const { concatUnassignedArticles, removeUnassignedArticles } = unassignedArticlesEventsSlice.actions

export const selectUnassignedArticles = (state) => state.unassignedArticlesEvent.value.unassignedArticles

// Memoized selector to get unassigned article IDs
export const selectUnassignedArticleIds = createSelector(
	[selectUnassignedArticles],
	(unassignedArticles) => unassignedArticles.map(article => article.articleId) 

)
export default unassignedArticlesEventsSlice.reducer
