import {
	Box,
	Tooltip,
	Typography,
} from "@mui/material"
import FlagIcon from "@mui/icons-material/Flag"
import { useSelector } from "react-redux"
import { selectToolPanelView } from "../../../reducers/toolPanelViewSlice"
import { TOOL_PANEL_VIEW } from "../../dynamic/ToolPanel"
import { DYNAMIC_PANEL_TAB_INDEX_MAP, selectDynamicView } from "../../../reducers/dynamicViewSlice"

export default function ArticleCitation ({id, targetArticle, article, isUnassigned, getYearFromPublicationDate}) {
	const view = useSelector(selectToolPanelView)
	const openTab = useSelector(selectDynamicView)
	

	return (
		<Box
			sx={{ padding: "10px 5px 0px 0px" }}
		>
			<Typography
				variant={"articleTitle"}
				component={"div"}
				sx={{
					marginRight: id === targetArticle
						? "80px"
						: "32px",
					whiteSpace: "pre-wrap"
				}}
			>
				{
					(id !== targetArticle) &&
					isUnassigned &&
					((view !== TOOL_PANEL_VIEW.UNASSIGNED &&
						openTab !== DYNAMIC_PANEL_TAB_INDEX_MAP.TOOLS) ||
						view === TOOL_PANEL_VIEW.RECENTLY_VIEWED) &&
					(
						<Tooltip
							title={"This article is imported but un-attached"}
							placement={"left"}
						>
							<FlagIcon
								fontSize={"1rem"}
								sx={{
									color: "unassignedArticle.darker"
								}}
							/>
						</Tooltip>
					)
				}
				{article?.title}
			</Typography>
			<Typography variant={"authorsList"}>
				{article?.authors}
			</Typography>
			<Typography
				sx={{
					width: "90%",
					color: "RBOneFont.otherInfo",
					display: "inline",
				}}
				variant={"caption"}
				component={"div"}
			>
				{` ${article?.publication}`} {getYearFromPublicationDate(article?.date)};
				{article?.issue}:{article?.pages}
			</Typography>
		</Box>
	)
}