import {useSnackbar} from "notistack"
import axios from "axios"
import {Drawer, List, ListItem, ListItemText, ListSubheader, Typography} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {
	changeOpenComponent,
	selectAvailableAccountSettingsMenuItems,
	selectOpenAccountSettingsComponent
} from "../../reducers/accountSettingsNavSlice"

export default function AccountSettingsDrawer(props) {
	const {drawerOpen, toolbarHeight,} = props
	const {enqueueSnackbar} = useSnackbar()
	const dispatch = useDispatch()
	const availableAccountSettingsMenuItems = useSelector(selectAvailableAccountSettingsMenuItems)
	const openComponentName = useSelector(selectOpenAccountSettingsComponent)

	const handleChangeComponent = (accountSettingView) => {
		if(accountSettingView.slug === "reset-password") {
			resetPassword()
		} else {
			dispatch(changeOpenComponent(accountSettingView.key))
		}
	}

	const resetPassword = () => {
		axios
			.put("/api/user/password")
			.then(() => {
				enqueueSnackbar(
					"A password reset link has been sent to your email",
					{
						variant: "success",
						autoHideDuration: 3000,
					}
				)
			}).catch(() => {
				enqueueSnackbar(
					"Something went wrong re-setting your password",
					{
						variant: "error",
						autoHideDuration: 3000,
					}
				)
			})
	}

	return (
		<Drawer
			open={drawerOpen}
			variant={"persistent"}
			anchor={"left"}
			PaperProps={{
				sx: {padding: `${toolbarHeight} 24px 0 24px`, marginTop: "16px"}
			}}
		>
			{
				availableAccountSettingsMenuItems.map(accountSettingGroup => (
					<List
						key={accountSettingGroup.groupTitle}
						dense
						sx={{ paddingBottom: "1rem"}}
						subheader={
							<ListSubheader disableGutters sx={{
								fontSize: "0.75rem",
								lineHeight: "1.5",
								fontWeight: "bold",
								color: "rgb(41, 38, 49)"
							}}>
								{accountSettingGroup.groupTitle}
							</ListSubheader>
						}
					>
						{
							accountSettingGroup.items.map(accountSettingView => (
								<ListItem
									key={accountSettingView.slug}
									disablePadding
									onClick={() => {
										handleChangeComponent(accountSettingView)
									}}
								>
									<ListItemText
										sx={{
											textDecoration: "none",
											cursor: "pointer",
											color: openComponentName === accountSettingView.key ? "rgb(2, 179, 197)" : "rgb(85,85,85)",
											lineHeight: "0.5 rem",
											margin: 0
										}}
									>
										<Typography>{ accountSettingView.title }</Typography>
									</ListItemText>
								</ListItem>
							))
						}
					</List>
				))
			}
		</Drawer>
	)
}