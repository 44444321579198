import { AppBar, Box, Paper, styled, Tab, Tabs } from "@mui/material"

export const NavigationWrapper = styled(Box)(() => ({
	boxShadow: "0px 1px 12px -3px",
	position: "relative",
	zIndex: "100",
}))

export const NavigationTabWrapper = styled(AppBar)(() => ({
	flexDirection: "row",
	display: "flex",
	position: "sticky",
	alignItems: "flex-end",
	minHeight: "40px",
	maxHeight: "40px",
	boxShadow: "none",
}))

export const NavigationTabs = styled(Tabs)(({ theme }) => ({
	background: theme.palette.primary.main,
	color: "white",
	minHeight: "30px",
	width: "auto",
	"& .MuiTabs-indicator": {
		backgroundColor: "white",
	},
}))

export const NavigationTab = styled(Tab)(({ theme }) => ({
	color: "white",
	borderRadius: "5px 5px 0px 0px",
	margin: "5px 10px 0px 10px",
	padding: "2px 16px 2px 16px",
	minWidth: "40px",
	minHeight: "30px",
	overflow: "visible",

	"&.Mui-selected": {
		color: theme.palette.primary.main,
		background: "white",
	},
}))

export const NavigationSecondaryFnWrapper = styled(Paper)(() => ({
	margin: "0 4px",
	display: "flex",
	alignItems: "center",
	minHeight: "40px",
	position: "relative",
	boxShadow: "none",
}))
