import { Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material"
import {UpdateBillingDetailsForm} from "../PaymentDetails/BillingDetailsForm"

export function ChangePaymentDetailsModal({ open, setIsOpen }) {
	return (
		<Dialog
			open={open}
			onClose={() => setIsOpen(false)}
			sx={{ textAlign: "center" }}
		>
			<DialogTitle>Set a billing method</DialogTitle>
			<DialogContent>
				<DialogContentText variant="body1" paragraph gutterBottom>
					Before you're able to purchase or modify your current subscription, you need to set or update your payment method using the form below.
				</DialogContentText>
				<UpdateBillingDetailsForm />
			</DialogContent>
		</Dialog>
	)
}