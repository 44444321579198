import {useEffect, useState} from "react"
import useHover from "../../../hooks/useOnHover"
import {IconButton} from "@mui/material"
import { fetchChildren, selectLoadingChildren } from "../../../reducers/workspaceNoteSlice"
import { useDispatch, useSelector } from "react-redux"
import {AddRounded, RemoveRounded} from "@mui/icons-material"
import {NOTE_ACTIONS} from "../../../reducers/noteActionSlice"

//TODO: user option to turn off hover-to-open
const HOVER_TO_OPEN = true

export function ToggleChildrenIcon({
	childCount,
	note,
	noteAction
}) {
	const [hoverRef, isHovered] = useHover()
	const [showLoadingAnimation, setShowLoadingAnimation] = useState(false)
	const dispatch = useDispatch()
	const loadingChildren = useSelector(selectLoadingChildren)
	const isLoading = loadingChildren.includes(note?.id)

	useEffect(() => {
		let timer
		if (!isLoading && isHovered) {
			timer = setTimeout( () => {
				setShowLoadingAnimation(true)
				dispatch(fetchChildren(note))
				setShowLoadingAnimation(false)
			}, 250)
		}
		if(!isLoading && !isHovered) {
			timer = setTimeout( () => {
				setShowLoadingAnimation(false)
			}, 250)
		}
		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [isHovered])

	const handleToggleClick = (e) => {
		e.stopPropagation()
		dispatch(fetchChildren(note))
	}

	if (childCount > 0) {
		if (note?.showChildren) {
			return (
				<IconButton
					sx={{
						py: "17px",
						alignSelf: noteAction === NOTE_ACTIONS.addSibling ? "end" : "inherit",
						"&:hover": {
							backgroundColor: "rgba(0,0,0, 0.1)"
						}
					}}
					onClick={handleToggleClick}
					variant={"toggle-children"}>
					<RemoveRounded />
				</IconButton>
			)
		} else {
			if (HOVER_TO_OPEN) {
				//laying the groundwork for global user preferences
				return (
					<IconButton
						sx={{
							py: "17px",
							alignSelf: noteAction === NOTE_ACTIONS.addSibling ? "end" : "inherit",
						}}
						ref={hoverRef}
						onClick={handleToggleClick}
						variant={"toggle-children"}
					>
						<AddRounded
							className={
								showLoadingAnimation || isLoading ? "loading-article-children" : null
							}
						/>
					</IconButton>
				)
			} else {
				return (
					<IconButton
						onClick={handleToggleClick}
						variant={"toggle-children"}
						sx={{alignSelf: noteAction === NOTE_ACTIONS.addSibling ? "end" : "inherit",}}
					>
						<AddRounded
							className={
								showLoadingAnimation || isLoading ? "loading-article-children" : null
							}
						/>
					</IconButton>
				)
			}
		}
	} else {
		return (
			<span
				style={{
					maxWidth: "25px",
					minWidth: "25px",
					maxHeight: "30px",
				}}
			/>
		)
	}
}