import {
	Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
	List,
	ListItem,
	ListItemAvatar, ListItemIcon,
	ListItemText, Tooltip,
	Typography,
} from "@mui/material"
import {
	DeleteOutline,
	Download,
	FileUpload,
} from "@mui/icons-material"
import axios from "axios"
import {useState} from "react"
import {useSnackbar} from "notistack"

export function ArticleFileList({files, articleWorkspace, fullTextId, setArticleFiles, handleUploadButtonClick}) {
	const {enqueueSnackbar} = useSnackbar()
	const [isRemovingAttachment, setIsRemovingAttachment] = useState(false)
	const [fileToDetach, setFileToDetach] = useState(null)
	const nonFullTextFiles = files.filter(file => file.id !== fullTextId)

	const openRemoveFileModal = (file) => {
		setFileToDetach(file)
		setIsRemovingAttachment(true)
	}

	const detachFile = () => {
		axios.delete(`/api/workspace/${articleWorkspace.workspaceId}/article/${articleWorkspace.articleId}/attachment/${fileToDetach.id}`)
			.then(({data}) => {
				setArticleFiles(data.files)
			})
			.catch(e => {
				console.error("FAILED TO DETACH FILE: ", e)
				enqueueSnackbar("Unable to detach file, please try again", {variant: "error"})
			})
			.finally(closeModalAndCleanup)
	}

	const closeModalAndCleanup = () => {
		setFileToDetach(null)
		setIsRemovingAttachment(false)
	}

	return (
		<Box sx={{padding: "0 5px",}}>
			<Box sx={{
				width: "100%",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between"
			}}>
				<Typography variant={"subtitle2"} sx={{fontWeight: 600, fontSize: ".850rem"}}>
					Attached Files:
				</Typography>
				{ nonFullTextFiles.length >= 1 &&
					<Button
						onClick={handleUploadButtonClick}
						variant={"outlined"}
						size={"small"}
					>
						Attach File
					</Button>
				}
			</Box>
			<List>
				{nonFullTextFiles.length >= 1 ?
					files.map(file => {
						if(file.id !== fullTextId){
							return (
								<FileItem
									key={file.id}
									file={file}
									articleWorkspace={articleWorkspace}
									alert={enqueueSnackbar}
									openRemoveFileModal={openRemoveFileModal}
								/>
							)
						}
					})
					:
					<ListItem
						onClick={handleUploadButtonClick}
						sx={{
							margin: "1px 0",
							pointerEvents: "auto",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0 10px",
							height: "25px",
							zIndex: "2",
							transitionProperty: "box-shadow, z-index",
							transitionDuration: "200ms",
							transitionTimingFunction: "ease-out",
							textShadow: "rgba(0, 0, 0, 0.1) 0px 0px 1px",
							color: "RBOneFont.main",
							"&:hover": {
								boxShadow: "0 0px 8px rgba(0, 0, 0, 0.7)",
								zIndex: "3",
								cursor: "pointer"
							},
							cursor: "default",
							backgroundColor: "notes.crumbsBackground",
							borderLeft: "5px solid",
							borderColor: "notes.dark",
							borderRadius: "4px"
						}}
					>
						<ListItemAvatar sx={{
							minWidth: 0,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}>
							<FileUpload />
						</ListItemAvatar>
						<ListItemText
							primary={"Attach a file"}
							sx={{ maxWidth: "30vw" }}
							primaryTypographyProps={{ variant: "subtitle2" }}
						/>
					</ListItem>
				}
			</List>
			<Dialog
				open={ isRemovingAttachment	}
				onClose={closeModalAndCleanup}
			>
				<DialogTitle>
					Are you sure you want to remove this file from this article?
				</DialogTitle>
				<DialogContent>
					This action cannot be undone.
				</DialogContent>
				<DialogActions>
					<Button
						onClick={detachFile}
						variant={"outlined"}
					>Remove Article From Workspace</Button>
					<Button
						onClick={closeModalAndCleanup}
						variant={"outlined"}
						color={"error"}
					>Cancel</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}

function FileItem({file, articleWorkspace, alert, openRemoveFileModal}){
	const [isDownloading, setIsDownloading] = useState(false)

	const downloadAttachment = async () => {
		const {data: fileUrl} = await axios.get(`/api/workspace/${articleWorkspace.workspaceId}/article/${articleWorkspace.articleId}/attachment/${file.id}`)
		setIsDownloading(true)

		axios
			.get(fileUrl, {
				// in App.js we set the Axios' default Headers to always include
				// JWT auth from auth0, and we need to remove those before
				// making an S3 req.
				transformRequest: (data, headers) => {
					delete headers.common["Authorization"]
					return data
				},
				onDownloadProgress: (progressEvt) => {
					let percentCompleted = Math.round((progressEvt.loaded * 100) / progressEvt.total)
					if(percentCompleted === 100) {
						setIsDownloading(false)
					}
				},
				responseType: "blob",
			})
			.then(({data}) => {
				const fakeHref = URL.createObjectURL(data)
				const fakeEl = document.createElement("a")
				fakeEl.setAttribute("download", file.fileName)
				fakeEl.href = fakeHref
				fakeEl.setAttribute("target", "_blank")
				fakeEl.click()
				URL.revokeObjectURL(fakeHref)
			})
			.catch((err) => {
				console.error("DOWNLOADING ATTACHMENT FAILED: ", err)
				alert("Couldn't download attachment", {variant: "error"})
			})
			.finally(() => {
				setIsDownloading(false)
			})

	}
	const setDetachingFile = (e) => {
		e.stopPropagation()
		openRemoveFileModal(file)
	}
	return (
		<ListItem
			onClick={downloadAttachment}
			sx={{
				margin: "1px 0",
				pointerEvents: "auto",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "0 10px",
				height: "25px",
				zIndex: "2",
				transitionProperty: "box-shadow, z-index",
				transitionDuration: "200ms",
				transitionTimingFunction: "ease-out",
				textShadow: "rgba(0, 0, 0, 0.1) 0px 0px 1px",
				color: "RBOneFont.main",
				"&:hover": {
					boxShadow: "0 0px 8px rgba(0, 0, 0, 0.7)",
					zIndex: "3",
					cursor: "pointer"
				},
				cursor: "default",
				backgroundColor: isDownloading ? "notes.dark" : "notes.crumbsBackground",
				borderLeft: "5px solid",
				borderColor: "notes.dark",
				borderRadius: "4px"
			}}
		>
			<ListItemAvatar sx={{
				minWidth: 0,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}>
				{ isDownloading ?
					<CircularProgress
						variant={"indeterminate"}
						size={20}
					/>
					:
					<Download fontSize={"medium"}/>
				}
			</ListItemAvatar>
			<ListItemText
				primary={file.fileName}
				sx={{
					maxWidth: "30vw"
				}}
				primaryTypographyProps={{
					variant: "subtitle2",
					sx: {
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}
				}}
			>
			</ListItemText>
			<ListItemIcon sx={{minWidth: "20px"}}>
				<Tooltip title={"Delete file on Article"} placement={"left"}>
					<IconButton
						onClick={(e) => setDetachingFile(e)}
						edge="end"
						aria-label="delete"
						sx={{
							color: "alertRed.main",
							padding: 0,
							zIndex: 3,
							maxWidth: "25px",
							"&:hover": {
								backgroundColor: "alertRed.highlight",
							},
						}}
					>
						<DeleteOutline fontSize={"small"} />
					</IconButton>
				</Tooltip>
			</ListItemIcon>
		</ListItem>
	)
}