
import { Box, Container, Grid, Stack} from "@mui/material"
import Typography from "@mui/material/Typography"
import HomeNav from "../components/home/HomeNav"
import FeaturesSection from "../components/home/FeaturesSection"
import Testimonials from "../components/home/Testimonials"
import Pricing from "../components/home/Pricing"
import Footer from "../components/home/Footer"
import Hero from "../components/home/Hero"
import OUDContact from "../components/home/OUDContact"

export default function Home() {

	return (

		<Box>

			<HomeNav />
			<Container maxWidth="full" sx={{
				marginTop: 0,
				padding: "1rem",
				background: "#fff",
			}}
			>
				<Container
					maxWidth="xl"
					sx={{
					}}
				>

					{/* HERO */}

					<Hero />
				</Container>
			</Container>

			{/* FEATURES */}

			<Container maxWidth="full" id="features" sx={{
				marginTop: 0,
				padding: "1rem",
				background: "#fff",
			}}
			>
				<Container maxWidth="xl">

					<Grid container spacing={4}
						sx={{
							marginTop: "2rem",
						}}
					>
						<Grid item md={7}>
							<Typography
								variant="h2"
								sx={{
									fontFamily: "Inter Tight",
									letterSpacing: "-1.5",
									fontWeight: "700",
									fontSize: "2rem",
									lineHeight: "1.25",
									marginBottom: "1rem",
									color: "#263031"
								}}
							>
								Tools designed specifically around your need to keep up with new medical journal articles related to your field.
							</Typography>

							{/* <Typography
								variant="h3"
								sx={{
									fontFamily: "Inter",
									fontSize: "1.25rem",
									lineHeight: "1.25",
									marginBottom: "1.5rem",
								}}
							>
									In semper volutpat ex eget auctor. Fusce sodales luctus libero, nec viverra magna tristique et. Proin accumsan ipsum a nulla placerat, ut tincidunt elit consequat. Quisque eu dui sit amet magna fermentum porta. Duis vitae efficitur enim.
							</Typography> */}



						</Grid>

						<Grid item md={5}>
							<Stack direction="column" spacing={4}>
							</Stack>
						</Grid>
					</Grid>

					<Stack
						direction="row"
						spacing={0}
						sx={{
							marginTop: "2rem",
							marginBottom: "6rem",
						}}
					>
						<FeaturesSection />
					</Stack>
				</Container>
			</Container>

			<Container maxWidth="full" sx={{
				marginTop: 0,
				padding: "1rem",
				background: "#fff",
			}}
			>
				<OUDContact />
				<Container maxWidth="xl">

					{/* TESTIMONIALS */}

					<Typography
						variant="h1"
						align="center"
						sx={{
							fontFamily: "Inter Tight",
							fontWeight: "700",
							fontSize: "2rem",
							letterSpacing: "-1.5",
							lineHeight: "1.2",
							marginLeft: "auto",
							marginRight: "auto",
							marginBottom: "2rem",
							marginTop: "8rem",
							maxWidth: "75%",
						}}
					>
						Don't take our word for it
					</Typography>

					<Testimonials />
				</Container>
			</Container>
			<Container maxWidth="full" sx={{
				marginTop: 0,
				padding: "1rem",
				background: "#fff",
			}}
			>
				<Container maxWidth="xl">

					{/* PRICING */}
					<Box id="pricing">
						<Typography
							variant="h1"
							align="center"
							sx={{
								fontFamily: "Inter Tight",
								fontSize: "2rem",
								fontWeight: "700",
								lineHeight: "1.2",
								letterSpacing: "-1.5",
								marginLeft: "auto",
								marginRight: "auto",
								marginBottom: "2rem",
								maxWidth: "75%",
							}}
						>
							Pricing
						</Typography>
						{/* <Typography
							align="center"
							variant="h2"
							sx={{
								fontFamily: "Cabin",
								fontSize: "1.25rem",
								lineHeight: "1.5",
								marginBottom: 6,
								maxWidth: "75%",
								mx: "auto",
							}}
						>
								In semper volutpat ex eget auctor. Fusce sodales luctus libero, nec viverra magna tristique et. Proin accumsan ipsum a nulla placerat, ut tincidunt elit consequat. Quisque eu dui sit amet magna fermentum porta. Duis vitae efficitur enim.
						</Typography> */}

						<Pricing />
					</Box>

				</Container>
			</Container>


			{/* FOOTER */}
			<Box id="contact">
				<Footer />
			</Box>

		</Box>
	)
}
