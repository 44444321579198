import { Typography } from "@mui/material"

export function TabLabel({ title }) {
	const label = title.length < 15 ? title : title.substring(0, 15) + "..."

	return (
		<Typography variant={"caption"} noWrap>
			{label}
		</Typography>
	)
}