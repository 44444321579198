import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	Tooltip,
} from "@mui/material"
import { useSnackbar } from "notistack"

export default function OwnedLicenses({ licenses, handleButtonClick }) {
	const tableHeaders = ["License Key", "In Use", "User Name", "User E-mail", ""]
	const { enqueueSnackbar } = useSnackbar()

	const copyUuidToClipboard = (uuid) => {
		navigator.clipboard
			.writeText(uuid)
			.then(() => {
				enqueueSnackbar("License Key copied to clipboard!", {
					variant: "success",
					autoHideDuration: 2000,
				})
			})
			.catch((err) => {
				enqueueSnackbar("Could not copy License Ke to clipboard", {
					variant: "error",
					autoHideDuration: 2000,
				})
				console.error(`couldn't copy UUID to clipboard because: ${err}`)
			})
	}

	if (!licenses) {
		return null
	}

	return (
		<TableContainer sx={{ maxHeight: "50vh" }}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						{tableHeaders.map((label) => (
							<TableCell align="center" key={label}>
								{label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{licenses.map((license) => (
						<OwnedLicenseTableRow
							key={license.UUID}
							license={license}
							copyLicenseKey={copyUuidToClipboard}
							clickHandler={handleButtonClick}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

function OwnedLicenseTableRow({ license, copyLicenseKey, clickHandler }) {
	const { used: inUse, UUID } = license
	const licenseKeyUser = license.licenseKeyUser || {
		firstName: "-",
		lastName: "",
		email: "-",
	}
	return (
		<Tooltip title={"Copy license key to clipboard"} followCursor>
			<TableRow
				selected={inUse}
				onClick={inUse ? null : () => copyLicenseKey(license.UUID)}
				hover
				sx={{ cursor: inUse ? "default" : "pointer" }}
			>
				<TableCell align="center">{UUID}</TableCell>
				<TableCell align="center">{`${inUse}`}</TableCell>
				<TableCell align="center">{`${licenseKeyUser.firstName} ${licenseKeyUser.lastName}`}</TableCell>
				<TableCell align="center">
					{" "}
					{inUse ? (
						<a href={`mailto:${licenseKeyUser.email}`}>
							{licenseKeyUser.email}
						</a>
					) : (
						licenseKeyUser.email
					)}{" "}
				</TableCell>
				<TableCell align="center">
					<Button
						onClick={(e) => {
							e.stopPropagation()
							clickHandler(license)
						}}
					>
						{" "}
						{inUse ? "Revoke" : "Delete"} License{" "}
					</Button>
				</TableCell>
			</TableRow>
		</Tooltip>
	)
}
