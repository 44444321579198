import {useDispatch} from "react-redux"
import {useState} from "react"
import axios from "axios"
import {AUTOMATED_SEARCH_PANEL_VIEW, setAutomatedSearchPanelView, setSelectedSearch} from "../../../reducers/automatedSearchViewSlice"
import {Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from "@mui/material"
import {Close, Edit, MoreHoriz} from "@mui/icons-material"

export function AutomatedSearchOptions({
	workspaceId,
	search,
	getSearches,
	setSearchToUpdate,
}) {
	const dispatch = useDispatch()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
		dispatch(setSelectedSearch(search))
	}

	const deleteSearch = (event) => {
		event.stopPropagation()
		axios
			.delete(`/api/workspace/${workspaceId}/automatedSearch/${search.id}`)
			.then(() => {
				getSearches()
			})
		dispatch(setSelectedSearch(search))
	}

	const updateSearch = (event) => {
		event.stopPropagation()
		setSearchToUpdate(search)
		dispatch(setSelectedSearch(search))
		dispatch(
			setAutomatedSearchPanelView(
				AUTOMATED_SEARCH_PANEL_VIEW.AUTOMATED_SEARCH_FORM
			)
		)
	}

	return (
		<>
			<Tooltip title="Search Options">
				<IconButton onClick={handleClick} variant={"curved-square"}>
					<MoreHoriz />
				</IconButton>
			</Tooltip>
			<Menu
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<MenuItem onClick={updateSearch}>
					<ListItemIcon>
						<Edit
							fontSize={"small"}
							sx={{ color: "#c78f33", paddingRight: "5px" }}
						/>
					</ListItemIcon>
					<ListItemText> Edit Search </ListItemText>
				</MenuItem>
				<Divider />
				<MenuItem onClick={deleteSearch}>
					<ListItemIcon>
						<Close
							fontSize={"small"}
							sx={{ color: "#C72D30", paddingRight: "5px" }}
						/>
					</ListItemIcon>
					<ListItemText> Delete Search </ListItemText>
				</MenuItem>
			</Menu>
		</>
	)
}
