import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { Box, Button } from "@mui/material"
import Loading from "../Loading"
import { useSnackbar } from "notistack"
import {useLocation, useNavigate} from "react-router-dom"

import axios from "axios"
import InvoiceReceiptRecipientList from "./InvoiceReceiptRecipientList"

export default function CheckoutForm({secret}) {

	const location = useLocation()
	const navigate = useNavigate()

	const cancelSubscriptionAndGoBack = () => {
		axios
			.put(`/api/subscription/cancel-pending/${location.state.subscriptionId}`)
			.then(() => {
				const cleanLocationPayload = {
					priceId: location.state.priceId,
					quantity: location.state.quantity,
				}
				navigate("/subscribe", {state: cleanLocationPayload})
			})
			.catch(() => {
				console.error("well this shouldn't happen!")
			})
	}

	const stripe = useStripe(),
		elements = useElements(),
		{ enqueueSnackbar } = useSnackbar()

	const submitElements = async () => {
		const { error } = await elements.submit()
		if(error){
			throw error
		}
	}

	const confirmPayment = async () => {
		const { error } = await stripe.confirmPayment({
			clientSecret: secret,
			elements,
			confirmParams: {
				return_url: window.location.origin + "/dashboard",
			},
		})
		if(error){
			throw error
		}
	}

	const handleSubmit = async (event) => {
		event.preventDefault()

		if (!stripe || !elements) {
			return
		}

		try {
			submitElements()
				.then(async () => {
					await confirmPayment()
				})
		} catch (err) {
			console.error("error on elements submission: ", err)
			enqueueSnackbar("Something went wrong trying to submit your payment details", {variant: "error"})
		}
	}

	if (!stripe) {
		return <Loading />
	}

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement/>
			<br />
			<InvoiceReceiptRecipientList />
			<Box sx={{ width: "100%", display: "flex", gap: 2, marginTop: 2 }}>
				<Button
					variant="outlined"
					onClick={cancelSubscriptionAndGoBack}
					sx={{ flex: 1 }}
				>
					Back
				</Button>
				<Button
					disabled={!stripe}
					sx={{ flex: 1 }}
					variant="contained"
					onClick={handleSubmit}
				>
					Submit Payment
				</Button>
			</Box>
		</form>
	)
}
