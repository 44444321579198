import {Box, Typography, Button, List, ListItem} from "@mui/material"
import { useEffect, useState } from "react"
import NoteRestrictionBreadcrumb from "./NoteRestrictionBreadcrumb"
import { selectWorkspaceId } from "../../../reducers/workspaceSlice"
import { useSelector } from "react-redux"
import axios from "axios"
import { useSnackbar } from "notistack"


export default function ConfigureNotePermissions({selectedNotes, setSelectedNotes, isAddingNewMember, setNotePermission, submitCallback, member}) {
	const [topLevelNotes, setTopLevelNotes] = useState([])
	const workspaceId = useSelector(selectWorkspaceId)
	const { enqueueSnackbar } = useSnackbar()
	const fetchTopLevelNotes = async () => {
		if (isAddingNewMember) {
			axios
				.get(`/api/workspace/${workspaceId}/note/0/children`)
				.then(({ data: notes }) => {
					setTopLevelNotes(notes)
					setSelectedNotes(notes)
				})
				.catch((err) => {
					console.error("Workspace notes request failed: ", err)
					enqueueSnackbar("Could not retrieve Workspace Notes", {
						variant: "error",
					})
				})
		} else {
			// get current member's permissions
			// request to "/:id/user/:userId/restrictions"
			// set selected notes to the ones returned
			try {
				const {data: topLevelNotes} = await axios.get(`/api/workspace/${workspaceId}/note/0/children`)
				const {data: existingRestrictions} = await axios.get(`/api/workspace/${workspaceId}/user/${member.userId}/restrictions`)
				setTopLevelNotes(topLevelNotes)
				setSelectedNotes(existingRestrictions)
			} catch(e) {
				enqueueSnackbar("Could not retrieve existing note restrictions", {
					variant: "error",
				})
			}
		}
	}

	useEffect(() => {
		fetchTopLevelNotes()
	}, [])

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				gap: "2rem"
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "flex-start",
					gap: "1rem"
				}}
			>
				<Typography id="modal-modal-title" variant="tunedH1">
					Visible Notes
				</Typography>
				<Typography id="modal-modal-subtitle" variant="tunedSubtitle2" sx={{textAlign: "left"}}>
					Select top level notes that you want visible to the user. New notes created in this workspace will note be visible.
				</Typography>
				<Box sx={{display: "flex", gap: "2rem", justifyContent: "space-between", width: "100%"}}>
					<Button
						sx={{ width: "100%", fontSize: "1rem", lineHeight: "1.18rem", paddingY: "12px", minWidth: "fit-content" }}
						variant={"outlined"}
						onClick={() => setSelectedNotes([])}
					>
						Select None
					</Button>
					<Button
						sx={{ width: "100%", fontSize: "1rem", lineHeight: "1.18rem", paddingY: "12px", minWidth: "fit-content" }}
						variant={"outlined"}
						onClick={() => setSelectedNotes(topLevelNotes)}
					>
						Select All
					</Button>
				</Box>
			</Box>
			{/* Top Level Note List */}
			<List
				sx={{
					maxHeight: "25vh",
					overflow: "auto",
					width: "100%",
					paddingRight: "0.5rem"
				}}
			>

				{
					topLevelNotes.map((note, i) => {
						return (
							
							<ListItem
								key={i}
								sx={{
									padding: "0px",
									paddingBottom: "0.75rem",
									display: "block",
									pointerEvents: "auto",
								}}>
								<NoteRestrictionBreadcrumb note={note} selectedNotes={selectedNotes} setSelectedNotes={setSelectedNotes}/>
							</ListItem>
						)
					})
				}
			</List>
			{/* Buttons */}
			<Box sx={{display: "flex", gap: "2rem", justifyContent: "flex-end", width: "100%"}}>
				<Button
					onClick={() => {
						submitCallback && submitCallback(selectedNotes)
					}}
					variant={"contained"}
					color={"primary"}
					disabled={selectedNotes.length === 0}
				>
					{isAddingNewMember ? "Add Member" : "Save Changes"}
				</Button>
				<Button
					color={"error"}
					onClick={() => {
						setSelectedNotes([])
						setNotePermission(false)}
					}
				>
					Cancel
				</Button>
			</Box>
		</Box>
	)
}