import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogContentText, 
	DialogActions, 
	Stack, 
	Button 
} from "@mui/material" 
import {useDispatch} from "react-redux"
import { deleteNote } from "../../../reducers/workspaceNoteSlice"

export default function DeleteNoteModal({ open, setIsOpen, noteId }) {

	const dispatch = useDispatch()


	return (
		<Dialog
			open={open}
			onClose={() => setIsOpen(false)}
			sx={{ textAlign: "center" }}
		>
			<DialogTitle>Are you sure you want to delete?</DialogTitle>
			<DialogContent>  
				
				<DialogContentText variant="body1" sx={{color: "black"}} paragraph gutterBottom>
					Deleting this note will delete all of its children, and citations from your workspace.
				</DialogContentText>
					
				<DialogActions 
					sx={{ 
						flexDirection: "row", 
						display: "flex",
						justifyContent: "center"
					}} 
					disableSpacing>
					<Stack width="75%" spacing={4} direction="row">

						<Button
							fullWidth
							variant={"outlined"}
							color="primary"
							onClick={() => setIsOpen(false)}
						>
							Cancel
						</Button>

						<Button
							fullWidth
							variant="contained"
							color="error"
							onClick={() => {
								dispatch(deleteNote(noteId))
								setIsOpen(false)
							}}>
						Delete
						</Button>

					</Stack>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}
