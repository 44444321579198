import {
	createAsyncThunk, createSlice, createSelector
	//current
} from "@reduxjs/toolkit"
import axios from "axios"
import { workspaceSlice } from "./workspaceSlice"

export const newSearch = createAsyncThunk(
	"workspaceSearch/makeNewSearch",
	async ({ workspaceId, query }) => {
		const { data } = await axios.get(`/api/workspace/${workspaceId}/note?search=${query}`)
			.catch(e => console.error("NEW WORKSPACE SEARCH FAILED: ", e))
		return {
			query,
			results: data
		}
	}
)

export const updateSearches = createAsyncThunk(
	"workspaceSearch/updateSearches",
	async (workspaceId, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
		const { workspaceSearch } = getState()

		if (workspaceSearch.searches.length >= 1) {
			for (let i = 0; i < workspaceSearch.searches.length; i++) {
				let search = workspaceSearch.searches[i]
				const { data } = await axios.get(`/api/workspace/${workspaceId}/note?search=${search.query}`)
					.catch(e => console.error("FETCHING WORKSPACE SEARCH UPDATES FAILED: ", e))
				try {
					// This is where I made my mistake
					// I tried dispatching "workspaceSearchSlice.caseReducers.updateSearch" because
					// I forgot to export updateSearch() as a value from workspaceSearchSlice.actions
					// this meant that Redux was skipping the Immer invocation when creating this slice
					// making state mutation extremely complex.
					dispatch(updateSearch({
						searchIndex: i,
						updatedResults: data
					}))
				} catch (e) {
					console.error("UPDATING WORKSPACE SEARCHES FAILED: ", e)
				}
			}
			return fulfillWithValue("searches updated")
		}
		return rejectWithValue("no need to update searches")
	}
)

export const workspaceSearchSlice = createSlice({
	name: "workspaceSearch",
	initialState: {
		searches: [],
		isSearching: false,
		currentSearch: -1,
		isLoading: false,
	},
	reducers: {
		hideSearches: (state) => {
			state.isSearching = false
		},
		showSearches: (state) => {
			state.isSearching = true
		},
		openExistingSearch: (state, action) => {
			state.isSearching = true
			state.currentSearch = action.payload
		},
		resetWorkspaceSearches: (state) => {
			state.searches = []
			state.isSearching = false
			state.currentSearch = -1
		},
		removeWorkspaceSearch: (state, action) => {
			if (state.searches.length === 1) {
				workspaceSearchSlice.caseReducers.resetWorkspaceSearches(state)
			} else {
				const deletingIndex = action.payload

				if (
					state.currentSearch >= deletingIndex &&
					state.currentSearch > 0
				) {
					state.currentSearch = state.currentSearch - 1
				}
				state.searches.splice(deletingIndex, 1)
			}
		},
		updateSearch: (state, action) => {
			const { searchIndex, updatedResults } = action.payload
			state.searches[searchIndex].results = updatedResults
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(newSearch.fulfilled, (state, action) => {
				const newIndex = (state.searches.push(action.payload) - 1)
				state.currentSearch = newIndex//push returns length not new index
				state.isSearching = true
				state.isLoading = false
			})
			.addCase(
				workspaceSlice.actions.setWorkspace,
				workspaceSearchSlice.caseReducers.resetWorkspaceSearches
			)
			.addCase(newSearch.pending, (state) => {
				state.isLoading = true
			})
			.addCase(newSearch.rejected, (state) => {
				state.isLoading = false
			})
	}
})

export const { hideSearches, showSearches, openExistingSearch, resetWorkspaceSearches, removeWorkspaceSearch, updateSearch } = workspaceSearchSlice.actions
export const selectSearches = (state) => state.workspaceSearch.searches
export const selectIsSearching = (state) => state.workspaceSearch.isSearching
export const selectCurrentWorkspaceSearchIndex = (state) => state.workspaceSearch.currentSearch
export const selectCurrentWorkspaceSearchResults = createSelector(
	[selectCurrentWorkspaceSearchIndex, selectSearches], 
	(currentSearchIndex, searches) => {
		if (currentSearchIndex === -1) {
			return []
		}
		return searches[currentSearchIndex]?.results || []
	}
)
export const selectCurrentWorkspaceSearch = createSelector(
	[selectCurrentWorkspaceSearchIndex, selectSearches], // Input selectors
	(currentSearchIndex, searches) => {
		if (currentSearchIndex === -1) {
			return []
		}
		return searches[currentSearchIndex] || []
	}
)

export const selectIsWorkspaceSearchLoading = (state) => state.workspaceSearch.isLoading

export default workspaceSearchSlice.reducer