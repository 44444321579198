import {IconButton, Tab, Tabs, Tooltip, Typography} from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import {useDispatch} from "react-redux"
import {openExistingSearch, removeWorkspaceSearch} from "../../../reducers/workspaceSearchSlice"
import {Close} from "@mui/icons-material"

export function WorkspaceSearchTabs({currentSearches, currentSearchIndex, inSearchResultsView, toggleShowSearch}) {
	return (
		<Tabs
			isSecondaryToolBarTabs
			value={inSearchResultsView ? currentSearchIndex + 1 : 0}
			TabIndicatorProps={{
				style: {
					opacity: currentSearches.length >= 1 ? 1 : 0
				},
			}}
		>
			<Tooltip title={"Return to your notes tree"}>
				<Tab
					value={0}
					onClick={toggleShowSearch}
					component={"div"}
					disabled={inSearchResultsView === false}
					variant={"secondaryToolBarTab"}
					sx={{
						backgroundColor: inSearchResultsView ? "notes.moveNoteIndicator" : "transparent",
						width: "110px",
						opacity: currentSearches.length >= 1 ? 1 : 0,
					}}
					icon={ <ArrowBackIosIcon fontSize={"small"} sx={{marginRight: "4px"}}/> }
					iconPosition={"start"}
					label={
						<Typography variant={"caption"}>
							Workspace
						</Typography>
					}
				/>
			</Tooltip>
			{
				currentSearches.map((search, index) => {
					if(search){
						return (
							<WorkspaceTab
								key={search.query}
								index={index+1}
								search={search}
								inSearchResultsView={inSearchResultsView}
								currentSearchIndex={currentSearchIndex}
							/>
						)
					}
				})
			}
		</Tabs>
	)
}

function WorkspaceTab({index, search, inSearchResultsView, currentSearchIndex}) {
	const dispatch = useDispatch()

	const handleClose = (e, index) => {
		e.preventDefault()
		e.stopPropagation()
		dispatch(removeWorkspaceSearch(index - 1))
	}

	return (
		<Tooltip title={`All notes in this workspace containing the term ${search.query}`}>
			<Tab
				value={index}
				component={"div"}
				onClick={() => dispatch(openExistingSearch(index - 1))}
				variant={"secondaryToolBarTab"}
				sx={{
					transition: "background-color 250ms ease-in-out",
					...(inSearchResultsView) && {
						backgroundColor: "transparent"
					},
					...(inSearchResultsView === false && (currentSearchIndex === index - 1)) && {
						backgroundColor: "notes.moveNoteIndicator"
					},
				}}
				icon={
					<Tooltip title={"Close search"} placement={"right"}>
						<IconButton
							onClick={(e) => handleClose(e, index)}
							variant={"tab-close"}
						>
							<Close
								fontSize={"inherit"}
							/>
						</IconButton>
					</Tooltip>
				}
				iconPosition={"end"}
				label={
					<Typography variant={"caption"} noWrap>
						{search.query}
					</Typography>
				}
			/>
		</Tooltip>
	)
}