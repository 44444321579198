import { useTheme, Box, Typography, TextField, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { CITATION_IMPORT_VIEW_MAP, selectIsLoading, selectQuery, selectResults, selectView, setQuery, setCitationAsImported, fetchData } from "../../../reducers/citationImportSlice"
import PanelLoading from "../../PanelLoading"
import { selectWorkspaceExclusions, selectWorkspaceId } from "../../../reducers/workspaceSlice"
import RawArticle from "../../articles/RawArticle"
import { useState } from "react"
import ImportedArticle from "../../articles/ImportedArticle"
import { selectUnassignedArticleIds } from "../../../reducers/unassignedArticlesEventsSlice"
import { checkExclusion } from "../../../utils/articleUtilities"
import { selectWorkspaceDeletedArticles } from "../../../reducers/deletedArticlesSlice"
import { useSnackbar } from "notistack"
import EmptyResults from "../EmptyResults"

export default function CitationImportPanel() {
	const theme = useTheme()
	const query = useSelector(selectQuery)
	const dispatch = useDispatch()
	const view = useSelector(selectView)
	const isLoading = useSelector(selectIsLoading)
	const results = useSelector(selectResults)
	const workspaceId = useSelector(selectWorkspaceId)
	const [targetArticle, setTargetArticle] = useState(null)
	const unassignedArticleIds = useSelector(selectUnassignedArticleIds)
	const workspaceExcludedArticles = useSelector(selectWorkspaceExclusions)
	const deletedArticles = useSelector(selectWorkspaceDeletedArticles)
	const { enqueueSnackbar } = useSnackbar()
	
	const handleOnClick = () => {
		if (query.trim() === "") {
			enqueueSnackbar(
				"Enter a valid list of citation IDs before submitting",
				{
					variant: "error",
					autoHideDuration: 3000,
				}
			)
		} else {
			dispatch(fetchData({workspaceId, query}))
		}
	}
	const setArticleAsImported = (article) => dispatch(setCitationAsImported(article))
	return (
		<div style={{overflow: "scroll", height: view === CITATION_IMPORT_VIEW_MAP.RESULTS ? "calc(100vh - 80px)" : "auto"}}>
			{
				view === CITATION_IMPORT_VIEW_MAP.SEARCH && (
					<Box
						sx={{
							height: "100vh",
							background: theme.palette.primary.light
						}}
					>
						<Box
							sx={{
								margin: "0 8px",
								padding: "20px",
								borderRadius: "5px",
							}}
						>
							<Box
								display={"flex"}
								flexDirection={"column"}
								padding={"20px 10px"}
								gap={"6px"}
								sx={{ background: "white", borderRadius: "5px" }}
							>
								<Typography variant="h6">Import from Article / Manuscript ID</Typography>
								<Typography variant="body1">If you are searching for a specific article ID (such as ISBN, DOI, ArXiv, etc.), you can expand your search outside of PubMed and include other publications for citation data.</Typography>
								<Typography variant="body1" sx={{ fontStyle: "italic" }}>You may enter multiple comma separated IDs.</Typography>
								<TextField
									id="outlined-textarea"
									label="DOI, ISBN, PMID, arXiv ID"
									InputLabelProps={{ required: true }}
									onChange={(e) => {
										dispatch(setQuery(e.target.value))
									}}
									multiline
									value={query}
									required
								/>
								<Box display="flex" justifyContent={"start"}>
									<Button onClick={handleOnClick} sx={{ width: "33%" }} variant={"contained"}>Submit</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				)
			}
			{
				view === CITATION_IMPORT_VIEW_MAP.RESULTS && (
					<>
						{
							isLoading && (
								<PanelLoading />
							)
						}
						{
							!isLoading && query && results.length === 0 && (
								<EmptyResults title="No Articles Found." body="Please adjust your search criteria and try again."/>
							)
						}
						{
							!isLoading && results && (
								results.map( (citationData, index) => {
									if (citationData.articleId && citationData.deletedAt === null) {
										return (
											<ImportedArticle
												key={citationData.articleId}
												articleWorkspace={citationData}
												targetArticle={targetArticle}
												setTargetArticle={setTargetArticle}
												isUnassigned={unassignedArticleIds.includes(citationData.articleId)}
												index={index}
											/>
										)
									} else {
										let rawArticle = citationData.articleId ? citationData.article : citationData
										return (
											<RawArticle
												key={rawArticle.key}
												workspaceId={workspaceId}
												article={rawArticle}
												targetArticle={targetArticle}
												setTargetArticle={setTargetArticle}
												setArticleAsImported={setArticleAsImported}
												isExcluded={checkExclusion(rawArticle.PMID, workspaceExcludedArticles)}
												index={index}
												deletedAt={deletedArticles.find(deletedArticle => deletedArticle.articleId === rawArticle.id)?.deletedAt || rawArticle.deletedAt}
											/>
										)
									}
								})
							)
						}
					</>

				)
			}
		</div>
	)
}