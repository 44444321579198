/**
 * Formats a Date object into a "Mon dd" format.
 *
 * @param {Date} date - The Date object to be formatted.
 * @returns {string} - The formatted date string.
 *
 * @example
 * const now = new Date();
 * console.log(formatDate(now)); // "Aug 30"
 */
export const formatDate = (date) => {
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
	const month = months[date.getMonth()]
	const day = date.getDate()
	return `${month} ${day}`
}
/**
 * Formats a Date object into a "Mon dd yyyy" format.
 *
 * @param {Date} date - The Date object to be formatted.
 * @returns {string} - The formatted date string.
 *
 * @example
 * const now = new Date();
 * console.log(formatDate(now)); // "Aug 30, 2023"
 */
export const formatDateWithYear = (date) => {
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
	const month = months[date.getMonth()]
	const day = date.getDate()
	const year = date.getFullYear()
	return `${month} ${day}, ${year}`
}

export const formatDateToISO = (dateString) => {
	const date = new Date(dateString)
	const year = date.getFullYear() // Gets the year (4 digits)
	let month = date.getMonth() + 1 // Gets month, January is 0!
	let day = date.getDate() // Gets day of the month
  
	// Ensuring month and day are in two-digit format
	month = month < 10 ? "0" + month : month
	day = day < 10 ? "0" + day : day
  
	return `${year}-${month}-${day}`
}
  


export const getDateFromDateString = (dateString) => {
	const pubDate = new Date(dateString)
	return pubDate.toLocaleDateString("en-US", { dateStyle: "short" })
}

export const getDateHourMinuteFromDateString = (dateString) => {
	const pubDate = new Date(dateString)
	const options = {
		dateStyle: "short",
		timeStyle: "short",
	}
	return new Intl.DateTimeFormat("en-US", options).format(pubDate)
}

