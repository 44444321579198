import { useSelector, useDispatch} from "react-redux"
import PanelLoading from "../../PanelLoading"
import PanelInfiniteScroll from "../PanelInfiniteScroll"
import ImportedArticle from "../../articles/ImportedArticle"
import {
	selectNoteArticleTabHasMore,
	selectCurrentNoteArticleTab,
	selectNoteArticleTabPage,
	selectNoteArticleTabsArticles,
	selectNoteArticleTabsCurrentIndexV2,
	selectNoteArticleTabsIsLoading,
	updateNoteArticleAsImported,
	incrementPageAndFetchArticles
} from "../../../reducers/noteArticleTabsV2Slice"
import RawArticle from "../../articles/RawArticle"
import { selectUnassignedArticleIds } from "../../../reducers/unassignedArticlesEventsSlice"
import { selectNoteAttachmentMap } from "../../../reducers/noteAttachmentsSlice"
import Attachment from "../../workspace/note_tree/Attachments/Attachment"
import { Box, Collapse, Typography } from "@mui/material"
import { ArrowRight } from "@mui/icons-material"

import { useState } from "react"
export function NoteArticleList({
	workspaceId
}) {
	
	const dispatch = useDispatch()
	const isLoading = useSelector(selectNoteArticleTabsIsLoading)
	const articles = useSelector(selectNoteArticleTabsArticles)
	const unassignedArticleIds = useSelector(selectUnassignedArticleIds)
	const tab = useSelector(selectCurrentNoteArticleTab)
	const setArticleAsImported = (article) => dispatch(updateNoteArticleAsImported(article))
	const tabPage = useSelector(selectNoteArticleTabPage)
	const noteAttachmentsMap = useSelector(selectNoteAttachmentMap)
	const currentNoteId = useSelector(selectNoteArticleTabsCurrentIndexV2)
	const hasMore = useSelector(selectNoteArticleTabHasMore)
	const [open, setOpen] = useState(true)
	
	const handleClick = () => {
		setOpen(!open)
	}

	const rotateStyle = {
		transform: open ? "rotate(90deg)" : "rotate(0deg)", 
		transition: "transform 150ms ease",
	}

	const paginate = () => {
		if (hasMore) {
			const note = tab?.meta?.note 
			dispatch(incrementPageAndFetchArticles({
				tab, note, page: tabPage + 1, singleNote: tab.singleNote
			}))
		}
	}

	return (
		<div role="tabpanel" style={{maxHeight: "100%", overflow: "auto"}}>
			{isLoading && articles.length === 0 ? (
				<PanelLoading />
			) : (
				<>
					{
						noteAttachmentsMap[currentNoteId]?.length > 0 && (
							<Box sx={{padding: "8px"}} >
								<Box sx={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={handleClick}>
									<ArrowRight  sx={{
										...rotateStyle,
										fontSize: "2rem"
									}}/>
									<Typography
										variant={"body1"}
										sx={{
											marginBottom: 0,
											wordBreak: "break-word"
										}}
										paragraph
									>
										Attached URLs
									</Typography>
								</Box>


								<Collapse in={open} timeout="auto" unmountOnExit>
									<PanelInfiniteScroll
										dataLength={noteAttachmentsMap[currentNoteId]?.length}
										hasMore={false}
										loader={<PanelLoading />}
										fitContent={true}
									>
										{
											noteAttachmentsMap[currentNoteId]?.map((attachment) => {
												return (
													<Box key={attachment.id} sx={{marginY: "8px"}} >
														<Attachment attachment={attachment}/>
													</Box>
												)
											})
										}
									</PanelInfiniteScroll>
								</Collapse>
							</Box>
						)
					}
					<PanelInfiniteScroll
						dataLength={articles.length}
						hasMore={hasMore}
						next={paginate}
						loader={<PanelLoading />}
					>
						{articles.map((article, index) => {
							if (article.articleId && article.deletedAt === null) {
								return (
									<ImportedArticle
										key={article.articleId}
										articleWorkspace={article}
										isUnassigned={unassignedArticleIds.includes(article.articleId)}
										index={index}
									/>
								)
							} else {
								let rawArticle = article.articleId ? article.article : article
								return (
									<RawArticle
										key={rawArticle.DOI || rawArticle.PMID}
										workspaceId={workspaceId}
										article={rawArticle}
										setArticleAsImported={setArticleAsImported}
										index={index}
										deletedAt={article.deletedAt}
									/>
								)
							}
						})}
					</PanelInfiniteScroll>
				
				</>
			)}
		</div>
	)
}