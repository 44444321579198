import { Box, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectView, CITATION_IMPORT_VIEW_MAP, setView } from "../../../reducers/citationImportSlice"
export default function CitationImportNav() {
	const view = useSelector(selectView)
	const dispatch = useDispatch()

	const handleClick = () => {
		dispatch(setView(CITATION_IMPORT_VIEW_MAP.SEARCH))
	}

	return(
		<Box sx={{display: "flex", justifyContent: "left", alignItems: "center", gap: "8px"}}>
			{
				view === CITATION_IMPORT_VIEW_MAP.RESULTS && (
					<Button
						variant={"outlined"}
						onClick={() =>
							handleClick()
						}
					>
						Back
					</Button>
				)
			}

			<b>
				<i>Import Citations</i>
			</b>
		</Box>
	)
}