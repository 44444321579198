import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material"

export function CancellationModal({ cancelling, setCancelling, cancelSub }) {
	const close = () => setCancelling(false)

	return (
		<Dialog open={cancelling}>
			<DialogTitle>
				Are you sure you want to cancel your subscription?
			</DialogTitle>
			<DialogContent>
				Canceling your subscription will result in you immediately being unable
				to access the app and any data you've left behind. Cancelling{" "}
				<em>WILL NOT</em> delete your workspaces or the data inside of them.
			</DialogContent>
			<DialogContent>
				If you have purchased any license keys for other users, they will also
				be unable to access the app until they purchase a paid account.
			</DialogContent>
			<DialogContent>
				Upon cancellation, any remaining time in the current payment period will
				be prorated. A final invoice will be generated and sent to you. Do you
				wish to continue?
			</DialogContent>
			<DialogActions>
				<Stack spacing={1} sx={{ width: "100%" }}>
					<Button
						color={"primary"}
						variant={"outlined"}
						onClick={close}
						fullWidth
					>
						No, don't cancel my subscription.
					</Button>
					<Button
						color={"warning"}
						variant={"contained"}
						onClick={cancelSub}
						fullWidth
					>
						Yes, cancel my subscription.
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}