import { useSelector } from "react-redux"
import { getDateHourMinuteFromDateString } from "../../../utils/dates"
import { Box, Typography, Skeleton } from "@mui/material"
import { selectSearchCounts } from "../../../reducers/automatedSearchViewSlice"

const AutomatedSearchCounts = ({ search }) => {
	const searchCounts = useSelector(selectSearchCounts)
	return (
		<>
			{!searchCounts[search.id] || searchCounts[search?.id]?.isLoading ? (
				<div style={{display: "flex", flexDirection: "column", gap: 3}}>
					<div style={{display: "flex", alignItems: "center", gap: 2}}>
						<Skeleton variant="rounded" width={41} height={21} />
						<Skeleton variant="rounded" width={100} height={14} />
					</div>
					<div style={{display: "flex", alignItems: "center", gap: 2}}>
						<Skeleton variant="rounded" width={41} height={21} />
						<Skeleton variant="rounded" width={75} height={14} />
					</div>				
				</div>
			) : (
				<div>
					<NewCountContainer search={search} count={searchCounts[search.id]?.newCount}/>
					<TotalCountContainer count={searchCounts[search.id]?.totalCount}/>
				</div>
			)}
			
		</>
	)
}

const TotalCountContainer = ({count}) => {
	return (
		<Typography
			variant={"subtitle2"}
			sx={{ color: "RBOneFont.otherInfo", width: "100%", marginY: "2px", marginX: "2px" }}>
			{count + " Total "} {count > 1 ? "articles" : "article"}
		</Typography>
	)
}

const NewCountContainer = ({search, count}) => {
	return (
		<Typography
			variant={"subtitle2"}
			sx={{ color: "RBOneFont.otherInfo", width: "100%", marginY: "2px" }}>
			<Box
				component="span"
				sx={{
					backgroundColor: count > 0 ? "#03c58d" : "none",
					color: count > 0 ? "white" : "RBOneFont.otherInfo",
					borderRadius: "3px",
					padding: count > 0 ? "2px 4px" : "2px 2px",
					margin: count > 0 ? "2px 4px 2px 0" : "2px 0"
				}}>
				{count}
			</Box> {"New "} {count > 1 ? "articles" : "article"} as of {getDateHourMinuteFromDateString(search.updatedAt)}
		</Typography>
	)
}

export default AutomatedSearchCounts
