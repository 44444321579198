import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	Box,
	Button,
	Card,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material"
import { CheckBox, ContentCopy, HighlightOff, Reply } from "@mui/icons-material"
import { openModal } from "../../reducers/copyCitationsModalSlice"
import {
	clearSelectedArticles, selectAllArticles,
	selectNumberOfSelectedArticles,
} from "../../reducers/selectedArticlesSlice"
import ShareArticleModal from "./ShareArticleModal"
import {selectDynamicView, DYNAMIC_PANEL_TAB_INDEX_MAP} from "../../reducers/dynamicViewSlice"

export default function MultiSelect({
	open,
	selectedArticles,
}) {
	useTheme()
	const dispatch = useDispatch()
	const articleCount = useSelector(selectNumberOfSelectedArticles)
	const dynamicView = useSelector(selectDynamicView)
	const disableSelectAll = dynamicView === DYNAMIC_PANEL_TAB_INDEX_MAP.AUTOMATED_SEARCH || dynamicView === DYNAMIC_PANEL_TAB_INDEX_MAP.ARTICLE_SEARCH
	const [isArticleShareModalOpen, setIsArticleShareModalOpen] = useState(false)

	return (
		<Card
			raised
			sx={{
				backgroundColor: "panels.multiSelect",
				color: "#FFF",
				maxWidth: "95%",
				display: "flex",
				marginBottom: "10px",
				gap: "5px",
				paddingRight: "10px",
				zIndex: 100,
				transform: open ? "none" : "translateY(10px)",
				opacity: open ? 1 : 0,
				//offsetting the timing on these animations feels nicer
				transition: "transform 300ms cubic-bezier(0, 0, 0.2, 1), opacity 200ms cubic-bezier(0, 0, 0.2, 1)",
				position: "absolute",
				bottom: 0,
				alignSelf: "center"
			}}
		>
			<Box
				sx={{
					justifySelf: "start",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minWidth: "60px",
					backgroundColor: "primary.mainROne",
					flexDirection: "column",
				}}
			>
				<Typography
					variant={"h3"}
					sx={{ margin: "0 5px", lineHeight: "0.9", fontWeight: 600 }}
				>
					{" "}
					{articleCount}{" "}
				</Typography>
				<Typography variant={"caption"}>selected</Typography>
			</Box>
			<Stack
				direction={"row"}
				spacing={"15px"}
				sx={{
					alignSelf: "center",
					justifyContent: "space-around",
					alignItems: "center",
					paddingLeft: "10px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						alignItems: "start",
					}}
				>
					<Button
						variant={"contained"}
						size={"small"}
						sx={{ width: "100%", maxWidth: "130px" }}
						startIcon={<CheckBox />}
						onClick={() => dispatch(selectAllArticles())}
						disabled={disableSelectAll}
					>
						Select All
					</Button>
					<Button
						variant={"contained"}
						size={"small"}
						sx={{ width: "100%", maxWidth: "130px" }}
						startIcon={<HighlightOff />}
						onClick={() => dispatch(clearSelectedArticles(selectedArticles))
						}
					>
						Select None
					</Button>
				</Box>
				<IconButton
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						maxHeight: "100%",
						padding: "0 5px",
						color: "#FFF",
					}}
					onClick={() => {
						setIsArticleShareModalOpen(true)
					}}
				>
					<Reply fontSize={"large"} sx={{ transform: "scaleX(-1)" }} />
					<Typography variant={"caption"}>Share</Typography>
				</IconButton>
				<IconButton
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						maxHeight: "100%",
						padding: "0 5px",
						color: "#FFF",
					}}
					onClick={() => dispatch(openModal())}
				>
					<ContentCopy fontSize={"large"} />
					<Typography variant={"caption"}>Copy</Typography>
				</IconButton>
			</Stack>
			<ShareArticleModal setIsArticleShareModalOpen={setIsArticleShareModalOpen} isArticleShareModalOpen={isArticleShareModalOpen} />
		</Card>
	)
}
