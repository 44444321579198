import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material"
import ChangeSubscriptionForm from "./ChangeSubscriptionForm"

export function ChangeSubscription() {
	return (
		<Box>
			{/*I wish I knew why we have to set overflow to visible since nothing is overflowing, but removing this reverts the Card component to "overflow:hidden" and that lops off the right border of the subscription prices cards*/}
			<Card elevation={0} sx={{overflow: "visible"}}>
				<CardHeader
					title={
						<Typography variant={"tunedH1"}>Change Subscription</Typography>
					}
					subheader={
						<Typography variant={"tunedSubtitle2"} sx={{paddingTop: "0.625em", color: "RBOneFont.main"}} paragraph>
							Change your billing cycle or add and remove additional subscription seats.
						</Typography>
					}
					sx={{
						padding: 0
					}}
				/>
				<CardContent sx={{padding: 0}}>
					<ChangeSubscriptionForm />
				</CardContent>
			</Card>
		</Box>
	)
}