import InfiniteScroll from "react-infinite-scroll-component"
import PanelLoading from "../PanelLoading"
export default function PanelInfiniteScroll(props) {
	return (
		<InfiniteScroll
			height={props.fitContent ? "" : "calc(100vh - 80px)"}
			{...props}
			loader={<PanelLoading />}
		>
			{props.children}
		</InfiniteScroll>
	)
}
