import RegistrationLayout from "./RegistrationLayout"
import {useLocation, useNavigate} from "react-router-dom"
import {Box, Button, Card, CardActionArea, Typography} from "@mui/material"
import {Download} from "@mui/icons-material"
import {createAndClickDownloadLink} from "../../utils/domUtilities"
import {useState} from "react"
import axios from "axios"
import InvoiceReceiptRecipientList from "../../components/stripe/InvoiceReceiptRecipientList"

export default function GenerateInvoice() {
	const location = useLocation()
	const navigate = useNavigate()
	const {invoice_pdf} = location.state.finalizedInvoice
	const [invoiceDownloadStarted, setInvoiceDownloadStarted] = useState(false)

	const downloadInvoice = () => {
		createAndClickDownloadLink(invoice_pdf)
		setInvoiceDownloadStarted(true)
	}

	const cancelSubscriptionAndGoBack = () => {
		axios
			.put(`/api/subscription/cancel-pending/${location.state.subscriptionId}`)
			.then(() => {
				const cleanLocationPayload = {
					priceId: location.state.priceId,
					quantity: location.state.quantity,
				}
				navigate("/subscribe", {state: cleanLocationPayload})
			})
			.catch(() => {
				console.error("well this shouldn't happen!")
			})

	}

	return (
		<RegistrationLayout title={"Download Invoice"}>
			<Card
				sx={{
					flexBasis: "100%",
					width: "100%",
					border: "1px solid #02B3C5",
					boxShadow: "none",
					padding: "0px",
					cursor: "pointer",
				}}
			>
				<CardActionArea
					onClick={downloadInvoice}
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "1em",
						padding: "1em"
					}}
				>
					<Download
						sx={{
							fontSize: "3rem",
							border: "3px dashed",
							borderColor: "primary.main",
							borderRadius: "8px"
						}}
					/>
					<Typography variant={"tunedSubtitle2"}>
						Download Invoice PDF
					</Typography>
				</CardActionArea>
			</Card>
			<InvoiceReceiptRecipientList />
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					gap: "0.5em"
				}}
			>
				<Button
					variant={"outlined"}
					fullWidth
					onClick={cancelSubscriptionAndGoBack}
				>Back</Button>
				<Button
					variant={"contained"}
					fullWidth
					onClick={() => navigate("/dashboard")}
					disabled={!invoiceDownloadStarted}
					color={"primary"}
				>Continue</Button>
			</Box>
		</RegistrationLayout>
	)
}