import {useAuth0} from "@auth0/auth0-react"
import {useEffect} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import axios from "axios"
import {useSnackbar} from "notistack"

export const InvitedRoute = () => {
	const navigate = useNavigate()
	const {enqueueSnackbar} = useSnackbar()
	const { search } = useLocation()
	const queryParams = new URLSearchParams(search)
	const keyId = queryParams.get("key")
	const isNew = queryParams.get("new") === "new"
	const { loginWithRedirect } = useAuth0()
	useEffect(() => {
		console.log("Hit the reroute component, whats the deal with params: ", isNew)
		axios.get(`/api/license/validate/${keyId}`)
			.then(() => {
				if(isNew){ //route to signup
					loginWithRedirect({
						screen_hint: "signup",
						appState: {
							returnTo: `/dashboard?pricing=key&key=${keyId}`,
						},
					})
						.catch((e) => { //loginWithRedirect is async, so we add a "catch" to resolve it
							console.error("Error from signup redirect: ", e)
						})
				} else { //route to login
					loginWithRedirect({
						screen_hint: "login",
						appState: {
							returnTo: `/dashboard?pricing=key-upgrade&key=${keyId}`,
						},
					})
						.catch((e) => {
							console.error("Error from signup redirect: ", e)
						})
				}

			})
			.catch(() => {
				enqueueSnackbar("Invalid invite link, please contact support.", {variant: "error", autoHideDuration: 5000})
				navigate("/")
			})
	}, [])
	/**
	 * This is an intentionally empty route.
	 * The idea is that users who are invited to join RefZoom via e-mail will hit this endpoint for a second or less for verification
	 *
	 * In the future, this might be modified to have placeholder elements if the verification request hangs on deployment
	 */
	return null
}
