import Logo from "../../refzoom.png"
import { Box, Grid, Typography, Container} from "@mui/material"


export default function Footer() {

	return (
		<Box
			sx={{
				width: "100%",
				height: "auto",
				backgroundColor: "#1C2121",
				paddingTop: "1rem",
				paddingBottom: "1rem",
			}}
		>
			<Container maxWidth="xl">
				<Grid container direction="column" alignItems="center">
					<Grid item xs={12}>
						<Box sx={{ display: { xs: "none", md: "flex" }, m:2, }}>
							<img
								src={Logo}
								alt={"RefZoom Logo"}
								style={{ width: "96px" }}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sx={{mb:2}}>
						<Typography color="white" variant="h5">
							RefZoom
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color="grey" variant="subtitle1">
							76 Rivervale Rd, Shelburne, VT 05482
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color="grey" variant="subtitle1">
							hello@refzoom.com
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color="grey" variant="subtitle1">
							{`Copyright ${new Date().getFullYear()} RefZoom`}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}
