import {
	Box,
	Typography,
	Collapse,
	Button,
	Fade,
	IconButton,
	Tooltip,
	Menu,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Stack
} from "@mui/material"
import {
	AddLink,
	Remove,
	Settings
} from "@mui/icons-material"
import { useState, useRef } from "react"
import { deleteNoteAttachment, initializeLinkAttachmentModal, selectNoteAttachmentBreadcrumbs } from "../../../../reducers/noteAttachmentsSlice"
import {useDispatch, useSelector} from "react-redux"
import NoteAttachmentBreadcrumbs from "./AttachmentBreadcrumbs"
import { clearState, selectTargetArticle, setTargetObject } from "../../../../reducers/targetArticleSlice"
import { resetWorkingArticleNoteRelation } from "../../../../reducers/workingArticleNoteRelationSlice"
import { setDragItem } from "../../../../reducers/articleDragSlice"
import { dragType } from "../../../../reducers/articleDragSlice"
export default function Attachment({attachment, preview}) {
	const [isHovered, setIsHovered] = useState(false)
	const [isDragHover, setIsDragHover] = useState(false)
	const [isDragging, setIsDragging] = useState(false)
	const [openSubMenu, setOpenSubMenu] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const anchorElRef = useRef(null)
	const dispatch = useDispatch()
	const noteAttachmentBreadcrumbs = useSelector(selectNoteAttachmentBreadcrumbs)

	const targetObjectId = useSelector(selectTargetArticle)
	const open = "attachment/" + attachment.id === targetObjectId

	const handleClick = () => {
		if (open) {
			// reset target article state
			dispatch(clearState())
		} else {
			// set target article state
			dispatch(setTargetObject({attachment}))
		}
	}
	
	const handleDragStart = (event) => {
		setIsDragging(true)

		// dispatch anywhere in this drag handler causes a visual lag in firefox
		// need to request animation frame to perform dispatch to avoid visual lag
		requestAnimationFrame(() => {
			dispatch(setDragItem({item: attachment, type: dragType.ATTACHMENT }))
		})

		event.dataTransfer.effectAllowed = "move"
		event.dataTransfer.setData("text/plain", `note-attachment-${attachment.id}`)
	
		// Create a new element to be used as the drag image
		const dragImage = document.getElementById("custom-drag-container")
		const dragText = document.getElementById("custom-drag-text")
		
		if (dragImage) {
			dragText.textContent = "Dragging 1 attachment" // Set the text content to the article ID
			event.dataTransfer.setDragImage(dragImage, 0, 0) // Center the drag image under the cursor
	
		} else {
			console.error("Drag UI Not Located")
		}

		event.currentTarget.addEventListener("dragend", () => {
			setIsDragging(false)
		})
	}

	return (
		<Box 
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<Box
				variant="note"
				sx={{
					position: "relative",
					display: "flex",
					width: "100%",
					color: "RBOneFont.main",
					backgroundColor: "notes.background",
					boxShadow: "0 0px 4px rgba(0, 0, 0, 0.2)",
					alignItems: "center",
					borderRadius: "4px",
					overflow: "hidden"
				}}
			>
				<Fade 
					in={open || preview} 				
					mountOnEnter
					unmountOnExit>
					<Box
						sx={{
							zIndex: "100",
							position: "absolute",
							top:-1,
							right: 0,
							height: "24px",
							padding: "0px",
							borderBottomLeftRadius: "10px",
							borderLeft: "3px solid rgba(0, 179, 197, 0.5)",
							borderBottom:  "3px solid rgba(0, 179, 197, 0.5)",
							transition: "border-radius 0.06s ease-in-out",
							overflow: "hidden",
						}}
						ref={anchorElRef}
					>
						<Tooltip
							title={"Minimize Attachment"}
						>
							<IconButton
								disabled={preview}
								sx={{
									height: "24px",
									minWidth: "24px",
								}}
								onClick={() => {
									dispatch(clearState())
									dispatch(resetWorkingArticleNoteRelation())
								}}
							>
								<Remove fontSize={"small"}/>
							</IconButton>
						</Tooltip>
						<Tooltip
							title={"Manage Note Attachment"}
							placement={"top"}
						>
							<IconButton
								disabled={preview}
								sx={{
									minWidth: "24px",
								}}
								onClick={() => setOpenSubMenu(true)}
							>
								<Settings fontSize={"small"}/>
							</IconButton>
						</Tooltip>
						<Menu
							open={openSubMenu}
							onClose={() => setOpenSubMenu(false)}
							elevation={0}
							anchorEl={anchorElRef.current}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
							MenuListProps={{
								dense: true,
								sx: {
									backgroundColor: "primary.transparency50",
									paddingTop: 0,
									paddingBottom: 0,
								}
							}}
							PaperProps={{
								sx: {
									borderRadius: "2px 0px 0px 10px"
								}
							}}
							TransitionProps={{
								style: {
									transformOrigin: "-1px 0 0"
								}
							}}
						>
							<MenuItem
								onClick={() => {
									dispatch(initializeLinkAttachmentModal({note: attachment.note, actionType: "edit", attachment}))
									setOpenSubMenu(false)
								}}
								dense
								divider
								sx={{
									justifyContent: "end"
								}}
							>Edit Note Attachment</MenuItem>
							<MenuItem
								dense
								onClick={() => {
									setOpenDeleteModal(true)
									setOpenSubMenu(false)
								}}
								sx={{
									justifyContent: "end"
								}}
							>Delete Note Attachment</MenuItem>
						</Menu>
					</Box>
				</Fade>
				<Tooltip
					title={isDragging ? "" : "Drag to attach to note"}
					followCursor
					placement={"left"}
					enterDelay={1000}
					sx={{ display: isDragging ? "none" : "initial" }} // this doesn't work after replacing DnD Kit drag values with  local state
				>
					<div
						// rb 724 node ref
						draggable="true"
						onDragStart={handleDragStart}
						onMouseEnter={() => setIsDragHover(true)}
						onMouseLeave={() => setIsDragHover(false)}
						onClick={() => null}
						style={{
							minWidth: "32px",
							width: "32px",
							alignSelf: "stretch", 
							backgroundColor: (isDragging && !preview) ? "#B9E7EC" : ( isDragHover ?  "#1095A2" : "#02B3C5"),
							cursor: "pointer"
						}}>
							&nbsp;
					</div>

				</Tooltip>


				<div style={{
					display: "flex",
					padding: "8px",
					overflow: "hidden",
					gap: "8px",
					alignItems: (open || preview) ? "flex-start" : "center",
					width: "100%"
				}}>
					<AddLink sx={{
						color: "#02B3C5",
						fontSize: "1.2rem"
					}}  />
					<Box sx={{display: (open || preview) ? "box" : "flex", alignItems: "center", gap: 1, width: "100%", paddingRight: "8px", overflow: "hidden"}}>
						<Typography
							variant={"body1"}
							sx={{
								marginBottom: 0,
								wordBreak: "break-word",
								fontWeight: "bold",
							}}
							paragraph
						>{attachment.title}</Typography>
						{
							(!open && !preview) && (
								<Typography
									variant={"body1"}
									sx={{
										marginBottom: 0,
										flex: "1",
										overflow: "hidden",
										whiteSpace: "nowrap", // Keeps text on a single line
										textOverflow: "clip", // Clips overflow without an ellipsis
									}}
									paragraph
								>{attachment.description}</Typography>
							)
						}
						{

							!open && !preview && (
								<Fade
									in={isHovered}>
									<div style={{
										position: "absolute",
										right: "4px",
										paddingLeft: "25px",
										background: "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 10%)"

									}}>
										<Button
											onClick={handleClick}
											variant={"text"}
											color={"otherInfo"}
											sx={{
												textTransform: "none",
												padding: "4px 16px",
												"&:hover": {
													backgroundColor: "primary.transparency20",
													//color: "primary.main"
												}
											}}
										>
											Details
										</Button>
									</div>
								</Fade>
							)

						}
						<Collapse in={open || preview} timeout="auto" mountOnEnter unmountOnExit>
							{													
								(open || preview) && (<>
									<Typography
										variant={"body1"}
										sx={{
											marginBottom: 0,
											wordBreak: "break-word"
										}}
										paragraph
									>{attachment.description}</Typography>

									<a
										href={attachment?.url?.startsWith("http") ? attachment?.url : `http://${attachment.url}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{attachment.url}
									</a>
									{
										noteAttachmentBreadcrumbs[attachment.id]?.map(note => {
											return (
												<Box sx={{marginTop: "8px"}} key={note.id}>
													<NoteAttachmentBreadcrumbs 
														note={note}
														isParent={true}
														attachment={attachment}
														preview={preview}
													/>
												</Box>

											)

										})
									}
								</>)
							}

						</Collapse>
					</Box>
				</div>

				<Dialog
					open={openDeleteModal}
					onClose={() => setOpenDeleteModal(false)}
					sx={{ textAlign: "center" }}
				>
					<DialogTitle>Are you sure you want to delete this note attachment?</DialogTitle>
					<DialogContent>  
						
						<DialogContentText variant="body1" sx={{color: "black"}} paragraph gutterBottom>
							Deleting this attachment cannot be undone.
						</DialogContentText>
							
						<DialogActions 
							sx={{ 
								flexDirection: "row", 
								display: "flex",
								justifyContent: "center"
							}} 
							disableSpacing>
							<Stack width="75%" spacing={4} direction="row">

								<Button
									fullWidth
									variant={"outlined"}
									color="primary"
									onClick={() => setOpenDeleteModal(false)}
								>
									Cancel
								</Button>

								<Button
									fullWidth
									variant="contained"
									color="error"
									onClick={() => {
										dispatch(deleteNoteAttachment({attachment}))
										setOpenDeleteModal(false)
									}}>
								Delete
								</Button>

							</Stack>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</Box>
		</Box>
	)
}