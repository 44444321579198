import RegistrationLayout from "./RegistrationLayout"
import Subscribe from "../Subscribe"

export default function PickPayment() {
	return (
		<RegistrationLayout
			title={"Payment Method"}
		>
			<Subscribe />
		</RegistrationLayout>
	)
}