import { 
	Card, 
	CardContent, 
	Typography, 
	Button, 
	List, 
	ListItem, 
	ListItemText, 
	Skeleton,
	ListItemSecondaryAction,
	IconButton,
} from "@mui/material"
import { useSelector } from "react-redux"
import { selectUser } from "../../reducers/userSlice"
import { useEffect, useState } from "react"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import axios from "axios"
import InvoiceReceiptRecipientModal from "./InvoiceReceiptRecipientModal"
import { useSnackbar } from "notistack"

export default function InvoiceReceiptRecipientList () {
	const { enqueueSnackbar } = useSnackbar()
	const user = useSelector(selectUser)
	const [open, setOpen] = useState(false)

	const [recipientListData, setRecipientListData] = useState({
		recipients: [],
		isLoading: false,
	})

	useEffect(() => {
		const fetchRecipients = async () => {
			try {
				const { data } = await axios.get("/api/user/recipients")
				setRecipientListData({
					recipients: data,
					isLoading: false
				})
			} catch (error) {
				enqueueSnackbar("Failed to fetch recipients", {
					variant: "error",
					autoHideDuration: 2000,
				})
				setRecipientListData({
					recipients: [],
					isLoading: false
				})
			}
		}

		setRecipientListData((prevState) => ({
			...prevState,
			isLoading: true
		}))

		fetchRecipients()
	}, [user])

	const deleteRecipient = async (recipientId) => {
		try {
			const response = await axios.delete(`/api/user/recipients/${recipientId}`)
			if (response.status === 204) {
				setRecipientListData((prevState) => ({
					...prevState,
					recipients: prevState.recipients.filter(({ id }) => id !== recipientId),
				}))
				enqueueSnackbar("Recipient successfully deleted", {
					variant: "success",
					autoHideDuration: 2000,
				})
			}
		} catch (error) {
			enqueueSnackbar("Failed to delete recipient", {
				variant: "error",
				autoHideDuration: 2000,
			})
			console.error("Error deleting recipient:", error.response ? error.response.data : error.message)
		}
	}

	return (
		<Card variant="outlined" sx={{width: "100%"}}>
			<CardContent>
				<Typography variant="h5" component="div">
					Additional Receipt Recipients
				</Typography>
				<Typography sx={{ mb: 1.5 }} color="text.secondary">
					Recipients will receive an email with a link to the receipt after each invoice is paid.
				</Typography>
				<List dense={true}>
					{recipientListData.isLoading ? (
						[1, 2].map((_, index) => (
							<ListItem key={index}>
								<Skeleton variant="text" width="100%" />
							</ListItem>
						))
					) : (
						recipientListData.recipients.map((recipient, index) => (
							<ListItem key={index} sx={{paddingLeft: 0}}>
								<ListItemText
									primary={recipient.email}
								/>
								<ListItemSecondaryAction>
									<IconButton edge="end" aria-label="delete" onClick={() => deleteRecipient(recipient.id)}>
										<DeleteOutlineIcon sx={{ color: "alertRed.main" }} />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
				<InvoiceReceiptRecipientModal open={open} handleClose={setOpen} setRecipientListData={setRecipientListData} />
				<Button
					variant={"outlined"}
					fullWidth
					onClick={() => setOpen(true)}
				>Add Additional Recipients</Button>
			</CardContent>
		</Card>
	)
}