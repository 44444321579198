import { Box, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { setNotificationPanelView, selectArticleCount } from "../../../reducers/notificationPanelSlice"
export default function NotificationPanelNav() {
	const dispatch = useDispatch()
	const articleCount = useSelector(selectArticleCount) 
	return(
		<Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
			<b>
				<i>Notifications</i>
			</b>
			{
				articleCount > 0 && (
					<Button
						variant={"outlined"}
						onClick={() =>
							dispatch(setNotificationPanelView())
						}
					>
						Back
					</Button>
				)
			}
		</Box>
	)
}