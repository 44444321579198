import { addAttachment, detachAttachment, fetchNoteAttachmentBreadcrumbs, removeAttachment, updatedAttachment } from "../../reducers/noteAttachmentsSlice"
import { fetchUniqueCounts, fetchUniqueCountsForEachPath } from "../../reducers/workspaceNoteSlice"
import { setWorkspace } from "../../reducers/workspaceSlice"
import { getCurrentUserId } from "./notesSocketMiddleware"

export const noteAttachmentMiddleware = (socket) => {
	return (storeAPI) => {
		socket.on("NEW_NOTE_ATTACHMENT", (payload) => {
			const {userId, note, attachment} = payload
			const state = storeAPI.getState()
			const currentUserId = getCurrentUserId(storeAPI)
			if (currentUserId != userId) {
				// first, update unique count for the incoming attachment note
				storeAPI.dispatch(fetchUniqueCounts(note))
				// next, check if their is a tab open to push data into
				if (state.noteAttachmentsSlice.noteAttachmentMap[note.id]) {
					storeAPI.dispatch(addAttachment(attachment))
				}
				// check if breadcrumbs exist, if they do, update them
				if (state.noteAttachmentsSlice.breadcrumbs[attachment.id]) {
					storeAPI.dispatch(fetchNoteAttachmentBreadcrumbs({attachment}))
				}
			}
		})

		socket.on("UPDATE_NOTE_ATTACHMENT", (payload) => {
			const {userId, attachment} = payload
			const currentUserId = getCurrentUserId(storeAPI)
			if (currentUserId != userId) {
				// find all instances of the attachment and update
				storeAPI.dispatch(updatedAttachment(attachment))
			}

		})
		
		socket.on("DELETE_NOTE_ATTACHMENT", (payload) => {
			const {paths, userId, deletedAttachment} = payload
			const currentUserId = getCurrentUserId(storeAPI)
			if (currentUserId != userId) {
				storeAPI.dispatch(fetchUniqueCountsForEachPath(paths))
				storeAPI.dispatch(removeAttachment({deletedAttachment}))
			}
		})
		socket.on("DETACH_NOTE_ATTACHMENT", (payload) => {
			const {note, userId, attachment} = payload
			// dispatch(fetchUniqueCounts(note))
			// dispatch(fetchNoteAttachmentBreadcrumbs({attachment, force: true}))
			// return {note, attachment}
			const currentUserId = getCurrentUserId(storeAPI)
			if (currentUserId != userId) {
				storeAPI.dispatch(fetchUniqueCounts(note))
				storeAPI.dispatch(fetchNoteAttachmentBreadcrumbs({attachment, force: true}))
				storeAPI.dispatch(detachAttachment({note, attachment}))
			}
		})
		return next => async action => {
			if (action.type === setWorkspace.type) {
				const workspaceId = action.payload.id
				socket.emit("SUBSCRIBE_TO_NOTE_ATTACHMENTS", {workspaceId})
			}
			return next(action)
		}
	}
}