import { fetchArticleBreadcrumbs } from "../../reducers/breadcrumbSlice" 
import { concatUnassignedArticles } from "../../reducers/unassignedArticlesEventsSlice" 
import { addNoteToTree, deleteNoteFromTree, fetchUniqueCounts, getClosestParentNoteByPath, getNoteByPath, moveNoteInTree, openToPath, updateNoteContent } from "../../reducers/workspaceNoteSlice"
import { setWorkspace } from "../../reducers/workspaceSlice"
import axios from "axios"

export const getCurrentUserId = (storeAPI) => {
	const state = storeAPI.getState()
	const currentUserId = state.user?.value?.id
	return currentUserId
}

const updateBreadcrumbs = (storeAPI,note) => {
	const state = storeAPI.getState()
	const trackedBreadcrumb = state.breadcrumbSlice.trackedBreadcrumb
	if (trackedBreadcrumb) {
		const isNoteIdInTrackedBreadcrumbs = trackedBreadcrumb.breadcrumbTopLevelNoteIds
		const isIncomingNoteInBreadcrumbPath = note.path
			.split(".")
			.map(Number)
			.some(number => isNoteIdInTrackedBreadcrumbs.includes(number))
		if (isIncomingNoteInBreadcrumbPath) {
			console.log("rb 773 update breadcrumb", note)
			storeAPI.dispatch(fetchArticleBreadcrumbs({article: {id: trackedBreadcrumb.articleId}}))
		}
	}
}

export const notesSocketMiddleware = (socket) => {
	return storeAPI => {

		socket.on("UPDATE_NOTE", ({note}) => {
			storeAPI.dispatch(updateNoteContent(note))
			updateBreadcrumbs(storeAPI, note)
		})

		socket.on("ADD_NOTE", ({note, userId}) => {
			const state = storeAPI.getState()
			const currentUserId = state.user?.value?.id
			if (userId != currentUserId) {
				storeAPI.dispatch(addNoteToTree(note))
			}
		})

		socket.on("DELETE_NOTE", ({ note, articleIds, unassignedArticles, userId }) => {
			const state = storeAPI.getState()
			const currentUserId = state.user?.value?.id
			if (userId != currentUserId) {
				const relatedArticleIds = articleIds
				const closestParent = getClosestParentNoteByPath(state.workspaceNotes.notes, note.path, true)
				storeAPI.dispatch(fetchUniqueCounts(closestParent))
				const breadcrumbs = state.breadcrumbSlice.articleMap
				for (const [key] of Object.entries(breadcrumbs)) {
					if(relatedArticleIds.includes(+key)) {
						// update breadcrumb for article
						// passing in a fake article object that only includes id
						storeAPI.dispatch(fetchArticleBreadcrumbs({article: {id: +key}}))
					}
				}
				storeAPI.dispatch(deleteNoteFromTree(note))
				storeAPI.dispatch(concatUnassignedArticles(unassignedArticles))
			}
		})

		socket.on("ARTICLE_NOTE_RELATIONS_UPDATE", ({note, articleNotes, userId}) => {
			const state = storeAPI.getState()
			const currentUserId = getCurrentUserId(storeAPI)
			const articleNoteRelationsList = articleNotes
			if (currentUserId != userId) {
				articleNoteRelationsList.forEach(({articleId}) => {
					// reload breadcrumbs if articleMap[articleId] !== null
					if (state.breadcrumbSlice.articleMap[articleId]) {
						storeAPI.dispatch(fetchArticleBreadcrumbs({article: {id: articleId}, note}))
					} else {
						// don't update crumb but update unique count
						storeAPI.dispatch(fetchUniqueCounts(note))
					}
				})

			}
		})

		socket.on("DELETE_ARTICLE_NOTE_RELATION", ({userId, note, articleId}) => {
			const state = storeAPI.getState()
			const currentUserId = getCurrentUserId(storeAPI)
			if (currentUserId != userId) {
				// reload breadcrumbs if articleMap[articleId] !== null
				if (state.breadcrumbSlice.articleMap[articleId]) {
					storeAPI.dispatch(fetchArticleBreadcrumbs({article: {id: articleId}, note}))
				} else {
					storeAPI.dispatch(fetchUniqueCounts(note))
				}
			}
		})

		socket.on("UNDO_DELETE_NOTE", async ({userId, note}) => {
			const currentUserId = getCurrentUserId(storeAPI)
			if (currentUserId != userId) {
				storeAPI.dispatch(openToPath({path: note.path, note, insertTarget: true, fromWebsocket: true}))
			}
			updateBreadcrumbs(storeAPI, note)
		})

		socket.on("MOVE_NOTE", async ({receivingNote, movingNote, newNote, userId, movingNotePreviousLocation}) => {
			//receivingNote: the new parent of newNote. Does not change depending on if "paste as sibling" or "paste as child" is selected.
			const state = storeAPI.getState()
			const currentUserId = getCurrentUserId(storeAPI)
			if (currentUserId != userId) {
				const parentNote = getNoteByPath(state.workspaceNotes.notes, receivingNote ? receivingNote.path : "")
				// is receiving note parent in the current state? 
				// is moving note in memory?
				// if receiving note parent is in memory but the moving note is nte 
				let incomingChildNotes = []
				if (parentNote && !parentNote.showChildren && parentNote.childCount > Object.keys(parentNote.noteChildren || {}).length) {
					const {data: children } = await axios.get(`/api/workspace/${receivingNote.workspaceId}/note/${parentNote.id || 0}/children`)
					incomingChildNotes = children
				}

				// update unique counts for all parent notes in new location
				if (parentNote) {
					// update receiving parent note hierarchy
					storeAPI.dispatch(fetchUniqueCounts(parentNote))
				} else if(receivingNote) {
					// get closest parent and update count
					const closestParent = getClosestParentNoteByPath(state.workspaceNotes.notes, receivingNote.path, true)

					storeAPI.dispatch(fetchUniqueCounts(closestParent))
				}
				// update original parent note hierarchy
				const parentPath = movingNote.path.split(".").slice(0, -1).join(".")
				const originalParent = getNoteByPath(state.workspaceNotes.notes, parentPath)
				storeAPI.dispatch(fetchUniqueCounts(originalParent))
				// {newNote, originalNote, incomingChildNotes, receivingPath, originalNoteLocation}
				storeAPI.dispatch(moveNoteInTree( {newNote, originalNote: movingNote, incomingChildNotes, receivingPath: receivingNote?.path ? receivingNote.path : "", originalNoteLocation: movingNotePreviousLocation}))
			} 
			updateBreadcrumbs(storeAPI, movingNote)
		})

		return next => action => {
			if (action.type === "WS_CONNECT") {
				socket.connect()
			}
	
			if (action.type === "WS_DISCONNECT") {
				socket.disconnect()
			}
	
			if (action.type === "WS_EMIT") {
				const { event, payload } = action
				socket.emit(event, payload)
			}

			if (action.type === setWorkspace.type) {
				const workspaceId = action.payload.id
				socket.emit("SUBSCRIBE_TO_NOTES", workspaceId)
			}
	
			return next(action)
		}
	}
}