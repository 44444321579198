import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { completeMovingNote } from "./moveNoteSlice"
import { addNote, clearAddNoteError, deleteNote, fetchTopLevelNotes, openToPath } from "./workspaceNoteSlice"

const initialValue = {
	noteId: null,
	action: null,
	showLoading: false
}

export const NOTE_ACTIONS = {
	scrollToNote: "scroll-to-note",
	addChild: "add-child",
	addRoot: "add-root",
	addSibling: "add-sibling",
	editNote: "edit",
	cancelNote: "cancel-note"
}

export const scrollToNote = createAsyncThunk(
	"noteAction/scrollToNote",
	async ({action, noteId, path, note, showLoading}, {rejectWithValue, dispatch}) => {
		try {
			// dispatch note expansion action
			// await and unwrap to ensure fulfilled state is complete
			await dispatch(openToPath({path, note})).unwrap()
			return {action, noteId, path, showLoading}
		} catch (error) {
			return rejectWithValue(error?.response?.message || "Error restoring note")
		}
	}
)

export const noteActionSlice = createSlice({
	name: "noteAction",
	initialState: {
		value: initialValue,
	},
	reducers: {
		setNoteAction: (state, action) => {
			state.value = {...action.payload, showLoading: false}
		},
		clearNoteAction: (state) => {
			state.value = initialValue
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				scrollToNote.fulfilled, 
				noteActionSlice.caseReducers.setNoteAction
			)
			.addCase(
				scrollToNote.pending,
				(state, action) => {
					const {showLoading} = action.meta.arg
					if (showLoading) {
						state.value.showLoading = showLoading
					}
				}
			)
			.addCase(completeMovingNote, (state, action) => {
				const payload = action.payload
				state.value = {
					action: "scroll-to-note",
					...payload
				}
			})
			.addCase(fetchTopLevelNotes.fulfilled, (state, action) => {
				if (Object.keys(action.payload).length === 0) {
					state.value = { noteId: null, action: NOTE_ACTIONS.addRoot }
				}
			})
			.addCase(deleteNote.fulfilled, (state, action) => {
				const { isTopLevelEmpty } = action.payload
				if (isTopLevelEmpty) {
					state.value = { noteId: null, action: NOTE_ACTIONS.addRoot }
				}
			})
			.addCase(addNote.fulfilled, noteActionSlice.caseReducers.clearNoteAction)
			.addCase(clearAddNoteError, noteActionSlice.caseReducers.clearNoteAction)
	}
})

export const { setNoteAction, clearNoteAction } = noteActionSlice.actions

export const selectNoteAction = (state) => state.noteAction.value

export const selectShowNoteActionLoading = (state) => !!state.noteAction.value?.showLoading
export default noteActionSlice.reducer
