import { Box, CardContent, Paper, Typography } from "@mui/material"

const FeatureBlock = ({ key, featureContent, active, callback}) => {

	const activeStyles = {
		backgroundColor: "#fff",
		borderLeft: "12px solid #02B3C5",
		transition: "all ease-in .2s",
	}

	const defaultStyles = {
		backgroundColor: "#fff",
		borderLeft: "12px solid #CEEAD4",
		"&:hover": {
			backgroundColor: "#f2f2f2",
			borderLeft: "12px solid #CEEAD4",
		},
	}

	return (
		<Paper
			key={key}
			elevation={active == featureContent.title ? "7" : "2"}
			sx= {{
				borderRadius: ".25rem",
				transition: "all ease-in .2s",
				...(active == featureContent.title ? activeStyles : defaultStyles)
			}}>
			<Box>
				<CardContent
					sx={{
						cursor: "pointer"
					}}
					onClick={() => callback(featureContent.imageLink, featureContent.title) }>
					<Typography sx={{ fontSize: 16, }} color="text.secondary" gutterBottom>

						{featureContent.icon}
					</Typography>
					<Typography variant="h3" component="div"
						sx={{
							fontFamily: "Inter",
							fontSize: "1rem",
							fontWeight: "600",
							marginBottom: ".5rem",
						}}
					>
						{featureContent.title}
					</Typography>
					<Typography variant="body2" sx={{
						fontFamily: "Inter",
						fontSize: "1rem",
						fontWeight: "400",
						marginBottom: ".5rem",
					}}>
						{featureContent.content}
					</Typography>
				</CardContent>
			</Box>
		</Paper>
	)
}

export default FeatureBlock