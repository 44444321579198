import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getImportedArticleCountForWorkspace = createAsyncThunk(
	"freeTierTracker/getImportedArticleCount",
	async (payload, {getState, rejectWithValue}) => {
		const state = getState()
		const workspace = payload?.updatedWorkspace ? payload?.updatedWorkspace : state.workspace.value
		if(workspace.ownerIsFreeTier){
			const {data} = await axios.get(`/api/workspace/${workspace.id}/imports-count`)
			return data.count
		} else {
			rejectWithValue("OWNER IS PAID, DONT GET COUNT")
		}
	}
)

export const freeTierTrackerSlice = createSlice({
	name: "freeTierTracker",
	initialState: {
		importedArticleCount: 0,
		workspaceOverLimit: false,

	},
	reducers: {
		setImportedArticleCount: (state, action) => {
			state.importedArticleCount = action.payload
		},
		setWorkspaceOverLimit: (state, action) => {
			state.workspaceOverLimit = action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(
			getImportedArticleCountForWorkspace.fulfilled,
			(state, action) => {
				state.importedArticleCount = action.payload
			}
		)
	}
})

export const {
	setImportedArticleCount,
	setWorkspaceOverLimit,
} = freeTierTrackerSlice.actions

export const selectImportedArticleCount = (state) => state.freeTierTracker.importedArticleCount
export const selectWorkspaceOverLimit = (state) => state.freeTierTracker.workspaceOverLimit

export default freeTierTrackerSlice.reducer