import {Box, Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material"
import {formatDateWithYear} from "../../../../utils/dates"
import {useDispatch, useSelector} from "react-redux"
import {changeOpenComponent} from "../../../../reducers/accountSettingsNavSlice"
import {fetchCurrentInvoice, selectCurrentInvoiceIsOpen} from "../../../../reducers/stripeInvoicesSlice"
import OpenInvoiceAlert from "./OpenInvoiceAlert"
import {useEffect} from "react"

export function CurrentSubscriptionCard({userSubscription}) {
	const dispatch = useDispatch()
	const hasOpenInvoice = useSelector(selectCurrentInvoiceIsOpen)
	useEffect(() => {
		console.log("CurrentSubCard useEffect: ", userSubscription)
		if(userSubscription && userSubscription.collection_method === "send_invoice") {
			dispatch(fetchCurrentInvoice(userSubscription.latest_invoice))
		}
	}, [userSubscription.collection_method])

	const setHeaderText = (userSubscriptionDataId) => {
		console.log("set header text fired, value passed in: ", userSubscriptionDataId)
		switch(userSubscriptionDataId) {
			case ("None" || "INVALID"):
				return "Free Tier Plan"
			case "KeySub":
				return `Subscription provided by ${userSubscription.keyOwner.firstName} ${userSubscription.keyOwner.lastName}`
			default:
				return `${userSubscription.plan?.nickname} Subscription${userSubscription.quantity > 1 ? ` with ${userSubscription.quantity - 1} users` : ""}`

		}
	}
	return (
		<>
			<Typography variant={"tunedH2"}>
				Current Subscription
			</Typography>
			{ hasOpenInvoice && (
				<Box mt={"1em"}>
					<OpenInvoiceAlert />
				</Box>
			)}
			<Card sx={{margin: "1em 0", border: "1px solid rgb(207, 207, 207)"}} elevation={0}>
				<CardHeader
					title={setHeaderText(userSubscription.id)}
					titleTypographyProps={{
						variant: "tunedH3",
						color: "RBOneFont.main",
					}}
					sx={{
						padding: "1em 1em 0.5em 1em",
					}}
				/>
				<CardContent sx={{padding: "0 16px"}}>
					<CurrentDescriptionText userSubscription={userSubscription}/>
				</CardContent>
				<CardActions sx={{padding: "0 1em .75em 1em"}}>
					<Button
						variant={"text"}
						color={"primary"}
						size={"small"}
						onClick={()=> dispatch(changeOpenComponent("mySubscription"))}>
						Manage Subscription
					</Button>
				</CardActions>
			</Card>
		</>
	)
}

function CurrentDescriptionText({userSubscription}) {
	if(userSubscription["plan"]){
		return (
			<Typography variant={"tunedSubtitle3"} paragraph sx={{color: "RBOneFont.main"}}>
				You are currently using a Refzoom {userSubscription.plan.nickname} Subscription. Your subscription will renew on {formatDateWithYear(new Date(userSubscription.current_period_end * 1000))}.
			</Typography>
		)
	} else {
		if(userSubscription.id === "KeySub"){
			return (
				<>
					<Typography variant={"tunedSubtitle3"} paragraph sx={{color: "RBOneFont.main"}}>E-mail: <a href={`mailto:${userSubscription.keyOwner.email}`}>{userSubscription.keyOwner.email}</a></Typography>
					<Typography variant={"tunedSubtitle3"} paragraph sx={{color: "RBOneFont.main"}}>Subscription valid until: {formatDateWithYear(new Date(userSubscription.keyOwner.subscriptionValidThrough))}</Typography>
					<Typography variant={"tunedSubtitle3"} paragraph sx={{color: "RBOneFont.main"}}>To purchase your own subscription, click "Manage Subscription"</Typography>
				</>
			)
		} else {
			return (
				<Typography variant={"tunedSubtitle3"} paragraph sx={{color: "RBOneFont.main"}}>
					You currently are not on a paid subscription. Your account is limited to 20 citation imports and read only access in workspaces you do not own. To purchase or re-start a paid subscription, click "Manage Subscription".
				</Typography>
			)
		}
	}
}