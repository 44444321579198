import {
	useSelector} from "react-redux"
import {Tabs} from "@mui/material"
import {NoteArticleTab} from "./NoteArticleTab"
import {
	selectNoteArticleTabsCurrentIndexV2, selectNoteArticleTabsInsertionOrder,
	selectNoteArticleTabsListV2
} from "../../../reducers/noteArticleTabsV2Slice"

export function NoteArticleListPanelNav() {
	const tabs = useSelector(selectNoteArticleTabsListV2)
	const currentIndex = useSelector(selectNoteArticleTabsCurrentIndexV2)
	const insertionOrder = useSelector(selectNoteArticleTabsInsertionOrder)

	return tabs ?
		(
			<Tabs
				value={currentIndex}
				scrollButtons
				allowScrollButtonsMobile
				TabIndicatorProps={{
					style: {
						opacity: Object.values(tabs).length >= 1 ? 1 : 0
					},
				}}
				variant={"scrollable"}
				isSecondaryToolBarTabs
			>
				{insertionOrder.map((tabId) =>
					(
						<NoteArticleTab
							key={tabId}
							value={tabId}
							tab={tabs[tabId]}
							index={tabId}
						/>
					))}
			</Tabs>
		)
		:
		(
			<span></span>
		)
}