import {useDispatch, useSelector} from "react-redux"
import {
	cancelUserSubscription,
	getProductsFromStripe,
	modifySubscription,
	selectAdditionalLicenseKeyQuantity,
	selectCurrentlySelectedSubscription,
	selectLoadingProductList,
	selectProductListAccountSettings,
	selectSubscriptionData,
	selectUserAllowedToUpdateSubscription,
	selectUserNeedsPaymentDetails,
} from "../../../../reducers/stripeDetailsSlice"
import {useEffect, useState} from "react"
import {Alert, AlertTitle, Box, Button, Skeleton, Stack} from "@mui/material"
import ProductPriceCard from "./ProductPriceCard"
import {fetchCurrentInvoice, selectCurrentInvoiceIsOpen} from "../../../../reducers/stripeInvoicesSlice"
import {CancellationModal} from "./CancellationModal"
import {ChangePaymentDetailsModal} from "../shared-components/ChangePaymentDetailsModal"
import {formatDateWithYear} from "../../../../utils/dates"
import OpenInvoiceAlert from "../shared-components/OpenInvoiceAlert"

export default function ChangeSubscriptionForm() {
	const dispatch = useDispatch()
	const prices = useSelector(selectProductListAccountSettings)
	const loadingPrices = useSelector(selectLoadingProductList)
	const currentSelectedSubscriptionPlan = useSelector(selectCurrentlySelectedSubscription)
	const currentSubscriptionData = useSelector(selectSubscriptionData)
	const updateNotAllowed = !useSelector(selectUserAllowedToUpdateSubscription)
	const hasOpenInvoice = useSelector(selectCurrentInvoiceIsOpen)
	const quantity = useSelector(selectAdditionalLicenseKeyQuantity)
	const userNeedsPaymentDetails = useSelector(selectUserNeedsPaymentDetails)

	const [userIsCancelling, setUserIsCancelling] = useState(false)
	const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)

	useEffect(() => {
		dispatch(getProductsFromStripe())
		if(currentSubscriptionData && currentSubscriptionData.collection_method === "send_invoice") {
			dispatch(fetchCurrentInvoice(currentSubscriptionData.latest_invoice))
		}
	}, [])

	const cancelSubscription = async () => {
		dispatch(cancelUserSubscription())
			.finally(() => setUserIsCancelling(false))
	}

	const handleSubmit = async () => {
		if(userNeedsPaymentDetails){
			setShowPaymentMethodModal(true)
		} else if(currentSelectedSubscriptionPlan.id === "FreeTier" && currentSubscriptionData.plan.id !== "FreeTier"){
			setUserIsCancelling(true)
		} else {
			await dispatch(modifySubscription()).catch((e) => console.error("failed to change subscription because: ", e))
		}
	}

	const isCurrentPlan = (price) => {
		const priceIsFreeTier = price.id === "FreeTier"
		if(currentSubscriptionData.plan?.id) {
			return price.id === currentSubscriptionData.plan?.id
		} else {
			return priceIsFreeTier && currentSubscriptionData.id !== "KeySub"
		}
	}

	return (
		<>
			{ loadingPrices ?
				(
					<Stack spacing={"16px"} sx={{width: "100%"}} >
						<Skeleton
							sx={{
								borderRadius: "4px"
							}}
							variant="rectangular"
							height={72}
							width={"100%"}
							animation="wave"
						/>
						<Skeleton
							sx={{
								borderRadius: "4px"
							}}
							variant="rectangular"
							height={72}
							width={"100%"}
							animation="wave"
						/>
						<Skeleton
							sx={{
								borderRadius: "4px"
							}}
							variant="rectangular"
							height={72}
							width={"100%"}
							animation="wave"
						/>
					</Stack>
				)	: (
					<>
						<Box sx={{
							display: "flex",
							flexDirection: "column",
							gap: "16px",
							width: "100%"
						}}>
							{ hasOpenInvoice && ( <OpenInvoiceAlert />) }
							{ currentSubscriptionData.id === "KeySub" && (
								<Alert severity={"success"}>
									<AlertTitle>Your Refzoom subscription is provided by: {currentSubscriptionData.keyOwner.firstName} {currentSubscriptionData.keyOwner.lastName}</AlertTitle>
									You are currently using a seat on a subscription purchased by {currentSubscriptionData.keyOwner.firstName} {currentSubscriptionData.keyOwner.lastName} (<a href={`mailto:${currentSubscriptionData.keyOwner.email}`}>{currentSubscriptionData.keyOwner.email}</a>). Their subscription is valid through {formatDateWithYear(new Date(currentSubscriptionData.keyOwner.subscriptionValidThrough))}. You can change to a different subscription at any time using the form below; your data will not be affected.
								</Alert>
							)}
							{prices.map((price) => (
								<ProductPriceCard
									key={price.id}
									price={price}
									isCurrentPlan={isCurrentPlan(price)}
									quantity={quantity}
								/>
							))}
						</Box>
						{/*
							TODO: change copy of Cancellation Modal to not mention "cancellation" and instead explain the ramification of moving to free
						*/}
						<CancellationModal
							cancelling={userIsCancelling}
							setCancelling={setUserIsCancelling}
							cancelSub={cancelSubscription}
						/>
						<ChangePaymentDetailsModal
							open={showPaymentMethodModal}
							setIsOpen={setShowPaymentMethodModal}
						/>
					</>
				)

			}
			<Button
				variant={"contained"}
				fullWidth
				onClick={handleSubmit}
				disabled={hasOpenInvoice || updateNotAllowed}
				sx={{marginTop: "1rem"}}
			>Change Subscription</Button>
		</>
	)
}