import {useDispatch, useSelector} from "react-redux"
import {useEffect, useState} from "react"
import {selectArticleSearchQuery, setArticleSearchQuery} from "../../../reducers/articleSearchQuery"
import {Box, FormControl, IconButton, OutlinedInput, Stack, styled, Typography} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

const SearchSourceButton = styled(Box)(({ theme, isSelectedSource }) => ({
	fontSize: "small",
	color: "grey",
	cursor: "pointer",
	"&:hover": {
		color: theme.palette.primary.main,
	},
	...(isSelectedSource && {
		fontSize: "medium",
		fontWeight: "bold",
		color: theme.palette.primary.main,
	}),
}))

export function SearchPanelNav({workspace}) {
	const dispatch = useDispatch()
	const [source, setSource] = useState("pubmed")
	const [query, setQuery] = useState("")
	const [count, setCount] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	const articlesSearchQuery = useSelector(selectArticleSearchQuery)

	useEffect(() => {
		setQuery("")
		setSource("pubmed")
	}, [workspace])

	useEffect(() => {
		setCount(articlesSearchQuery.resultsCount)
		setPageCount(articlesSearchQuery.resultsPageCount)
	}, [articlesSearchQuery])

	const handleSubmit = (e) => {
		e.preventDefault()
		dispatch(setArticleSearchQuery({ query, source }))
	}

	const changeSourceAndSubmit = (source) => {
		setSource(source)
		dispatch(setArticleSearchQuery({ query, source }))
	}

	const handleChange = (e) => {
		setQuery(e.target.value)
	}

	return (
		<FormControl
			as={"form"}
			fullWidth
			variant="outlined"
			onSubmit={handleSubmit}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					minHeight: "40px"
				}}
			>
				<OutlinedInput
					sx={{ height: "30px", flexGrow: 1 }}
					placeholder={
						source === "pubmed"
							? "Search PubMed once"
							: "Search this workspace once"
					}
					inputProps={{
						style: {
							padding: "0px 8px 0px 8px"
						}
					}}
					value={query}
					onChange={handleChange}
					endAdornment={
						<IconButton aria-label="search articles" type={"submit"} edge="end">
							<SearchIcon />
						</IconButton>
					}
				/>
				<Stack
					sx={{
						alignItems: "flex-start",
						padding: "0px 10px",
						minWidth: "100px",
					}}
				>
					<SearchSourceButton
						onClick={() => changeSourceAndSubmit("pubmed")}
						isSelectedSource={source === "pubmed"}
					>
						PubMed
					</SearchSourceButton>
					<SearchSourceButton
						onClick={() => changeSourceAndSubmit("library")}
						isSelectedSource={source === "library"}
					>
						My Workspace
					</SearchSourceButton>
				</Stack>
			</Box>
			{ count > 0 &&
				(
					<Typography variant={"subtitle2"}>Viewing {pageCount} of {count} results</Typography>
				)
			}
		</FormControl>
	)
}