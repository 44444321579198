import "../styles/Loading.css"
import logo from "../logo.png"

export default function Loading() {
	return (
		<div className={"loading-container"}>
			<div>
				<img src={logo} alt={"refbin-logo"} />
			</div>
			<br />
			<div>Loading...</div>
		</div>
	)
}
