import { createSlice } from "@reduxjs/toolkit"
import { moveNote } from "./workspaceNoteSlice"

export const moveNoteSlice = createSlice({
	name: "moveNote",
	initialState: { value: {id: -1} },
	reducers: {
		initializeMovingNote: (state, action) => {
			state.value = action.payload
		},
		cancelMovingNote: (state) => {
			state.value = {id: -1}
		},
		completeMovingNote: (state) => {
			state.value = {id: -1}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(moveNote.fulfilled, (state) => {
				state.value = {id: -1}
			})
			.addCase(moveNote.rejected, (state) => {
				state.value = {id: -1}
			})
	}
})

export const { initializeMovingNote, cancelMovingNote, completeMovingNote } = moveNoteSlice.actions
export const selectMovingNote = (state) => state.moveNote
export default moveNoteSlice.reducer