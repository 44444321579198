import PageWrapper from "../components/PageWrapper"
import {
	Button,
	Card,
	CardContent,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Stack,
	Typography,
} from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import OwnedLicenses from "../components/stripe/OwnedLicenses"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import Loading from "../components/Loading"

export default function ManageLicenses() {
	const { enqueueSnackbar } = useSnackbar(),
		navigate = useNavigate()

	const [loadingAllKeys, setLoadingAllKeys] = useState(true),
		[loadingNewKey, setLoadingNewKey] = useState(false),
		[ownedLicenses, setOwnedLicenses] = useState(null),
		[totalCost, setTotalCost] = useState(-1),
		[keyToRevoke, setKeyToRevoke] = useState(null),
		[revokeModalOpen, setRevokeModalOpen] = useState(false)

	const updateLicensesAndCost = (data) => {
		setOwnedLicenses(data.keys)
		setTotalCost(data.total)
	}

	useEffect(() => {
		axios
			.get("/api/license/")
			.then(({ data }) => updateLicensesAndCost(data))
			.catch(() => {
				enqueueSnackbar("Failed to load your owned keys", {
					variant: "error",
					autoHideDuration: 3000,
				})
				navigate("/account-settings")
			})
			.finally(() => {
				setLoadingAllKeys(false)
			})
	}, [])

	const revokeLicenseKey = () => {
		axios
			.put(`/api/license/${keyToRevoke.id}/reset`)
			.then(() => {
				axios
					.get("/api/license/")
					.then(({ data }) => updateLicensesAndCost(data))
			})
			.catch((error) => {
				enqueueSnackbar(error.response.data.msg, {
					variant: "error",
					autoHideDuration: 3000,
				})
			})
			.finally(() => setKeyToRevoke(null))
	}

	const deleteLicenseKey = () => {
		axios
			.delete(`/api/license/${keyToRevoke.id}`)
			.then(() => {
				axios
					.get("/api/license/")
					.then(({ data }) => updateLicensesAndCost(data))
			})
			.catch((error) => {
				enqueueSnackbar(error.response.data.msg, {
					variant: "error",
					autoHideDuration: 3000,
				})
			})
			.finally(() => setKeyToRevoke(null))
	}

	const handleLicenseKeyButtonClick = (licenseKey) => {
		setKeyToRevoke(licenseKey)
		setRevokeModalOpen(true)
	}

	const createLicenseKey = async () => {
		setLoadingNewKey(true)
		axios
			.post("/api/license/")
			.then(() => {
				axios
					.get("/api/license/")
					.then(({ data }) => updateLicensesAndCost(data))
			})
			.catch(() => {
				enqueueSnackbar("Failed to create a new License Key", {
					variant: "error",
					autoHideDuration: 3000,
				})
			})
			.finally(() => {
				setLoadingNewKey(false)
			})
	}

	if (loadingAllKeys) {
		return <Loading />
	}

	return (
		<>
			<PageWrapper title={"Manage Licenses"}>
				<Card variant={"outlined"} sx={{ width: "100%" }}>
					<CardContent>
						<Typography gutterBottom varient="h1">
							Total Monthly Cost
						</Typography>
						<Typography varient="subtitle2">
							{`$${totalCost / 100} USD per month`}
						</Typography>
						<Typography varient="subtitle2">
							{`Number of additional keys: ${ownedLicenses.length}`}
						</Typography>
					</CardContent>
				</Card>
				<OwnedLicenses
					licenses={ownedLicenses}
					handleButtonClick={handleLicenseKeyButtonClick}
				/>
				{loadingNewKey ? (
					<CircularProgress variant={"indeterminate"} />
				) : (
					<Button
						onClick={createLicenseKey}
						variant={"outlined"}
						sx={{ width: "50%" }}
					>
						{" "}
						Purchase New License{" "}
					</Button>
				)}
				<Button
					onClick={() => {
						navigate("/account-settings/subscription")
					}}
					variant={"contained"}
					sx={{ width: "50%" }}
				>
					Back to Manage Subscription
				</Button>
			</PageWrapper>
			<RevokeKeyDialogModal
				open={revokeModalOpen}
				setIsOpen={setRevokeModalOpen}
				revokeKey={revokeLicenseKey}
				deleteKey={deleteLicenseKey}
			/>
		</>
	)
}

function RevokeKeyDialogModal({ open, setIsOpen, revokeKey, deleteKey }) {
	return (
		<Dialog
			open={open}
			onClose={() => setIsOpen(false)}
			sx={{ textAlign: "center" }}
		>
			<DialogTitle>Do you want to delete this subscription?</DialogTitle>
			<DialogContent>
				<DialogContentText variant="body1" paragraph gutterBottom>
					Deleting this key will remove the associated user's license key AND
					will delete the subscription cost from your monthly bill.
				</DialogContentText>
				<DialogContentText variant="body1" paragraph gutterBottom>
					Revoking this key will remove the associated user's license key and
					generate a new key to be used. Your monthly bill will not change.
				</DialogContentText>
				<DialogContentText variant="body1" paragraph gutterBottom>
					Do you want to DELETE or REVOKE this subscription?
				</DialogContentText>
				<DialogActions sx={{ flexDirection: "column" }} disableSpacing>
					<Stack spacing={2}>
						<Button
							fullWidth
							variant="contained"
							color="warning"
							onClick={() => {
								deleteKey()
								setIsOpen(false)
							}}
						>
							Delete this key and remove the subscription from my bill.
						</Button>
						<Button
							fullWidth
							variant="contained"
							color="warning"
							onClick={() => {
								revokeKey()
								setIsOpen(false)
							}}
						>
							Revoke this key and generate a new key.
						</Button>
						<Button
							fullWidth
							variant={"outlined"}
							color="primary"
							onClick={() => setIsOpen(false)}
						>
							Cancel
						</Button>
					</Stack>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}
