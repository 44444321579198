import {Box, Button, Card, CardContent, CardHeader, CircularProgress, TextField, Typography} from "@mui/material"
import { clearUserUpdateSuccess, selectIsUpdating, selectUser, selectUserUpdateSuccess, updateUserNameAsync } from "../../../../reducers/userSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import {useSnackbar} from "notistack"

export function AccountDetails() {
	const user = useSelector(selectUser)
	const isUpdating = useSelector(selectIsUpdating)
	const userUpdateSuccess = useSelector(selectUserUpdateSuccess)
	const dispatch = useDispatch()
	const {enqueueSnackbar} = useSnackbar()

	const [name, setName] = useState({
		firstName: user?.firstName || "",
		lastName: user?.lastName || ""
	})

	useEffect(() => {
		setName({
			firstName: user?.firstName || "",
			lastName: user?.lastName || ""
		})
	}, [user])

	useEffect(() => {
		if(userUpdateSuccess) {
			enqueueSnackbar("User updated successfully", {
				variant: "success",
				autoHideDuration: 3000,
				onClose: () => dispatch(clearUserUpdateSuccess()),
			})
		}
	}, [userUpdateSuccess, enqueueSnackbar])

	const handleNameChange = (event) => {
		const { name, value } = event.target
		setName(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		dispatch(updateUserNameAsync({ firstName: name.firstName, lastName: name.lastName }))
	}

	return (
		<Box>
			<Card elevation={0} sx={{ overflow: "visible" }}>
				<CardHeader
					title={
						<Typography variant={"tunedH1"}>Account Details</Typography>
					}
					subheader={
						<Typography variant={"tunedSubtitle2"} sx={{ paddingTop: "0.625em", color: "RBOneFont.main" }} paragraph>
							Update your first and last name.
						</Typography>
					}
					sx={{
						padding: 0
					}}
				/>
				<CardContent sx={{ padding: 0, display: "flex", flexDirection: "column", gap: 2 }}>
					<TextField
						required
						id="outlined-required"
						label="First Name"
						name="firstName"
						value={name.firstName}
						onChange={handleNameChange}
					/>
					<TextField
						required
						id="outlined-required"
						label="Last Name"
						name="lastName"
						value={name.lastName}
						onChange={handleNameChange}
					/>
					<Button
						variant={"outlined"}
						type="submit"
						onClick={handleSubmit}
						disabled={isUpdating || !name.firstName || !name.lastName}
						startIcon={isUpdating ? <CircularProgress size={20} /> : null}
					>
						{isUpdating ? "Updating..." : "Submit"}
					</Button>
				</CardContent>
			</Card>
		</Box>
	)
}