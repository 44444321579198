import {
	Card,
	Typography,
	CardContent, Box
} from "@mui/material"
import { useEffect, useState } from "react"
import axios from "axios"

export default function ProductPrice({
	price,
	quantity,
	selectPrice,
	isSelected,
}) {
	const MAX_NUMBER_OF_KEYS = 10
	const [validPrice, setValidPrice] = useState(null)
	const [loadingPricePreview, setLoadingPricePreview] = useState(false)

	const setInitialPrice = () => {
		if (price.billing_scheme === "tiered") {
			const baseTier = price.tiers.find((tier) => tier.up_to === 1 || 5)
			setValidPrice(baseTier.unit_amount)
		} else if (price.billing_scheme === "per_unit") {
			setValidPrice(price.unit_amount)
		}
	}
	useEffect(() => {
		setInitialPrice()
	}, [])

	useEffect(() => {
		handleSetQuantity()
	}, [quantity])

	const handleProductPriceSelection = () => {
		selectPrice(price.id)
	}

	const handleSetQuantity = () => {
		if (!isNaN(quantity) && quantity <= MAX_NUMBER_OF_KEYS) {
			setLoadingPricePreview(true)
			axios
				.post("/api/subscription/preview", {
					price,
					quantity,
				})
				.then(({ data }) => {
					setValidPrice(data.total)
				})
				.finally(() => setLoadingPricePreview(false))
		}
	}

	if (!validPrice) {
		return null
	}

	return (
		<Card
			key={price.id}
			sx={{
				flexGrow: 1,
				width: "100%",
				border: isSelected ? "1px solid #02B3C5" : "1px solid #CFCFCF",
				boxShadow: isSelected ? "0 2px 4px rgba(0,0,0,0.2)" : "none",
				padding: "0px",
				cursor: "pointer"
			}}
			onClick={handleProductPriceSelection}
		>
			<CardContent sx={{"&:last-child": {paddingBottom: "1em"}}}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					<Typography variant={"tunedH2"}>
						{price.nickname + " Subscription"}
					</Typography>
					{ loadingPricePreview ?
						(
							<Box
								className={"dots-loader"}
								sx={{
									marginRight: "1em",
								}}
							/>

						) : (
							<Typography variant={"tunedSubtitle2"}>
								{
									`$${validPrice / 100}`
								}
							</Typography>
						)

					}
				</Box>
			</CardContent>
		</Card>
	)
}
