import cloneDeep from "lodash/cloneDeep"


/**
 * Sorts the given automated searches based on the provided sorting criteria.
 * @param {Array} searches - Array of searches to be sorted.
 * @param {string} sortBy - Criteria to sort by. Can be either "alpha" or "newResults".
 * @returns {Array} Sorted array of searches.
 */
export const sortAutomatedSearches = (searches, sortBy) => {
	let sortedSearches = []
	if(sortBy == "alpha") {
		sortedSearches = sortSearchesAlpha(searches)
	} else if (sortBy == "createdAt") {
		sortedSearches = sortSearchesByCreatedAt(searches)
	}
	return sortedSearches
}

/**
 * Sorts the searches alphabetically based on the title.
 * @param {Array} searches - Array of searches to be sorted.
 * @returns {Array} Alphabetically sorted array of searches.
 */
const sortSearchesAlpha = (searches) => {
	const shallowClone = [...searches]
	const sorted = shallowClone.sort(function (x, y) {
		const xTitle = x.title
		const yTitle = y.title

		if(xTitle.toLowerCase() < yTitle.toLowerCase()) {
			return -1
		} else if (xTitle.toLowerCase() > yTitle.toLowerCase() ) {
			return 1
		} else {
			return 0
		}
	})
	return sorted
}

const sortSearchesByCreatedAt = (searches) => {
	const shallowClone = [...searches]
	return shallowClone.sort((a, b) => {
		// Convert createdAt strings to Date objects
		const dateA = new Date(a.createdAt)
		const dateB = new Date(b.createdAt)

		// Return comparison result for sort function
		// Subtracting dates gives the difference in milliseconds between them
		return dateA - dateB // For ascending order
		// Use `return dateB - dateA;` for descending order
	})
}

// Exa
/**
 * Calculates the new article count by subtracting the old count from the new count.
 * @param {number} oldCount - Previous article count.
 * @param {number} newCount - Current article count.
 * @returns {number} New article count.
 */
export const newArticleCount = (oldCount, newCount) => {
	return Math.max(0, newCount - oldCount)
}

/**
 * Sets unassigned articles as imported based on the provided search parameters.
 * @param {Object} searchParams - Search parameters containing articles.
 * @param {Array} unassignedArticles - Array of unassigned articles.
 * @param {Function} setSearchParams - Setter function for search parameters.
 */
export const setUnassignedArticlesAsImported = (unassignedArticles, setSearchParams) => {
	setSearchParams(prevParams => {
		if (prevParams.articles.length === 0) {
			return prevParams 
		}

		const updatedArticles = cloneDeep(prevParams.articles)

		for (let article of updatedArticles) {
			if (!article?.articleId) {
				let unassignedIndex = unassignedArticles.findIndex(unassignedArticle => unassignedArticle?.article?.PMID === article?.PMID)
				if (unassignedIndex > -1) {
					const updatedArticle = unassignedArticles[unassignedIndex]

					let index = updatedArticles.findIndex(
						a => a.DOI === updatedArticle?.article?.DOI || updatedArticle?.article?.PMID === a?.PMID
					)

					if (index > -1) {
						updatedArticles.splice(index, 1, updatedArticle)
					}
				}
			}
		}

		return {
			...prevParams,
			articles: updatedArticles
		}
	})
}
/**
 * Sets excluded articles as excluded based on the provided search object.
 * @param {Object} search - Search object containing articles.
 * @param {Function} setSearch - Setter function for the search object.
 */
export const setExcludedArticlesAsExcluded = (search, setSearch) => {
	if (search.articles.length !== 0) {
		//force an update of the search results, inspired by setArticleAsImported below		
		setSearch(prevParams => {
			let _articles = cloneDeep(prevParams.articles)
			return ({
				...prevParams,
				articles: _articles
			})
		})
	}
}

/**
 * Sets imported articles as deleted based on the provided search object and an array of articles that have been deleted.
 * @param {Array} deletedArticles - Array from the deletedArticlesSlice that contains the deleted articles for this workspace.
 * @param {Function} setSearch - Setter function for the search object.
 */
export const setDeletedArticlesAsDeleted = (deletedArticles, setSearch) => {
	setSearch(prevParams => {
		if(prevParams?.articles?.length === 0){
			return prevParams
		}

		const updatedArticles = cloneDeep(prevParams.articles)
		for (let [index, article] of updatedArticles.entries()) {
			if(article?.articleId && article?.deletedAt === null){
				const deleted = deletedArticles.find(deletedArticle => deletedArticle?.articleId === article?.articleId)
				if(deleted){
					updatedArticles[index].deletedAt = deleted?.deletedAt
				}
			}
		}
		return {
			...prevParams,
			articles: updatedArticles
		}
	})
}
