import { Box, Button, TextField, Typography } from "@mui/material"
import { DashboardModal } from "./DashboardModal"
import { useSelector } from "react-redux"
import { selectUser } from "../../reducers/userSlice"
import { useState } from "react"
import axios from "axios"
import { useSnackbar } from "notistack"

export default function ContactSupport({
	openSupportModal,
	setOpenSupportModal,
}) {
	return (
		<DashboardModal
			open={openSupportModal}
			setOpen={setOpenSupportModal}
			title={"Contact Support"}
		>
			<Typography variant={"body1"}>
				Please describe the problem you're experiencing below. If you know them,
				please include your OS and browser information. A member of the support
				staff will respond to you as soon as possible.
			</Typography>
			<EmailForm
				subject={"support"}
				placeholder={"What's the issue?"}
				setIsOpen={setOpenSupportModal}
			/>
		</DashboardModal>
	)
}

export function EmailForm({ subject, placeholder, setIsOpen }) {
	const user = useSelector(selectUser)
	const [message, setMessage] = useState("")
	const { enqueueSnackbar } = useSnackbar()

	const handleChange = (evt) => {
		setMessage(evt.target.value)
	}

	const handleSend = () => {
		const messageToSend = {
			subject: `[${subject}] request from ${user.email} - ${user.firstName} ${user.lastName}`,
			body: message,
		}
			
		axios
			.post("/api/email/contact-support", messageToSend)
			.then(response => {
				console.log(response.data)
				setMessage("ok")
				enqueueSnackbar("Message sent!", { variant: "info" })
			})
			.catch(error => {
				console.error(error)
				enqueueSnackbar("Something went wrong. Please try again.", { variant: "error" })
			})
			.finally(() => {
				setIsOpen(false)
			})
	}
	

	return (
		<Box>
			<TextField
				multiline
				fullWidth
				rows={10}
				label={placeholder}
				sx={{ margin: "16px 0" }}
				value={message}
				onChange={handleChange}
			/>
			<Button onClick={handleSend} variant={"contained"} color={"primary"}>
				Send
			</Button>
		</Box>
	)
}
