const EmptyResults = ({ title, body }) => {
	const containerStyle = {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "100vh",
		textAlign: "center",
		position: "relative",
		top: "-20%", // Adjust this value to position slightly above vertical center
		padding: "0 100px", // Add horizontal padding for gutter space
	}

	const titleStyle = {
		fontSize: "2em",
		fontWeight: "normal",
		lineHeight: 1,
		overflowWrap: "break-word", 
		wordBreak: "break-word"
	}

	const bodyStyle = {
		fontSize: "1em",
		fontWeight: "normal",
		marginTop: 0
	}

	return (
		<div style={containerStyle}>
			<h1 style={titleStyle}>{title}</h1>
			<p style={bodyStyle}>{body}</p>
		</div>
	)
}
  
export default EmptyResults