import { createSlice } from "@reduxjs/toolkit"

export const portalPanelViewSlice = createSlice({
	name: "portalPanelView",
	initialState: {
		value: "MY_PORTALS",
	},
	reducers: {
		setPortalPanelView: (state, action) => {
			state.value = action.payload
		},
	},
})

export const { setPortalPanelView } = portalPanelViewSlice.actions

export const selectPortalPanelView = (state) => state.portalPanelView.value

export default portalPanelViewSlice.reducer
