import { createSlice } from "@reduxjs/toolkit"

export const toolPanelViewSlice = createSlice({
	name: "toolPanelView",
	initialState: {
		value: null,
		clearRecentlyReviewedTrigger: 0
	},
	reducers: {
		setToolPanelView: (state, action) => {
			state.value = action.payload
		},
		setTriggerClearRecenltyReviewed: (state) => {
			state.clearRecentlyReviewedTrigger += 1
		}
	},
})

export const { setToolPanelView, setTriggerClearRecenltyReviewed } = toolPanelViewSlice.actions

export const selectToolPanelView = (state) => state.toolPanelView.value
export const selectClearRecentlyReviewedTrigger= (state) => state.toolPanelView.clearRecentlyReviewedTrigger

export default toolPanelViewSlice.reducer
