import { useEffect, useState} from "react"
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	Tooltip,
	Typography
} from "@mui/material"
import {AdminPanelSettings, Book, DeleteOutline, Edit, ManageAccounts, MoreHoriz, TransferWithinAStation, Key} from "@mui/icons-material"
import FormattedToolTip from "../../../utils/FormattedToolTip"
import MenuItem from "@mui/material/MenuItem"
import {useDispatch, useSelector} from "react-redux"
import {selectWorkspace} from "../../../reducers/workspaceSlice"
import {selectUser} from "../../../reducers/userSlice"
import { setManageWorkspaceView, setTargetUser, VIEW } from "../../../reducers/manageWorkspaceSlice"

export function ListOfMembers({members, setMemberToModify, fetchMembers, removeMember, userWorkspacePermissions}) {
	const [anchorEl, setAnchorEl] = useState(null)
	const [menuMember, setMenuMember] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const workspace = useSelector(selectWorkspace)

	useEffect(() => {
		fetchMembers()
		// eslint-disable-next-line
	}, [])

	const handleMenuOpen = (e, member) => {
		setAnchorEl(e.currentTarget)
		setMenuMember(member)
		setIsOpen(true)
	}

	const handleMenuClose = () => {
		setIsOpen(false)
		setMenuMember(null)
		setAnchorEl(null)
	}

	return (
		<Box>
			<Typography id="modal-modal-title" variant="h4">
				Workspace Members
			</Typography>
			<br />
			<List
				sx={{
					maxHeight: "40vh",
					overflowY: "scroll"
				}}
			>
				{members !== null && members.map((member, i) => (
					<ListItem
						key={i}
						sx={{
							border: "1px solid black",
							borderRadius: "4px",
							paddingRight: "8px",
						}}
					>
						<Typography>
							{member.user.firstName} {member.user.lastName}
						</Typography>
						<span style={{ flexGrow: 1 }} />
						<Box>
							{
								workspace?.ownerUserId === member.userId && 
								<Tooltip
									title={<FormattedToolTip
										title={"Is Owner"}
										description={"This user is the owner of the workspace"}
									/>}
									arrow
									placement={"bottom"}
								>
									<Key/>
								</Tooltip>
							}
							{member.admin &&
								<Tooltip
									title={<FormattedToolTip
										title={"Is Admin"}
										description={"This user can Read, Edit, and manage user access to the Workspace"}
									/>}
									arrow
									placement={"bottom"}
								>
									<AdminPanelSettings/>
								</Tooltip>
							}

							{ member.write ?
								<Tooltip
									title={<FormattedToolTip
										title={"Can Edit"}
										description={"This user can edit notes, articles, and searches in the Workspace"}
									/>}
									arrow
									placement={"bottom"}
								>
									<Edit />
								</Tooltip>
								:
								<Edit color={"disabled"}/>
							}
							{member.read ?
								<Tooltip
									title={<FormattedToolTip
										title={"Can Read"}
										description={"This user can read notes and articles in the Workspace"}
									/>}
									arrow
									placement={"bottom"}
								>
									<Book/>
								</Tooltip>
								:
								<Book color={"disabled"}/>
							}
						</Box>
						<Tooltip title={"Change this user's permissions in this Workspace"} placement={"right"}>
							<IconButton
								variant={"curved-square"}
								onClick={(e) => handleMenuOpen(e, member)}
								disabled={!userWorkspacePermissions.admin}
							>
								<MoreHoriz />
							</IconButton>
						</Tooltip>
					</ListItem>
				))}
				{ menuMember !== null &&
					<UserManagementContextMenu
						member={menuMember}
						isOpen={isOpen}
						close={handleMenuClose}
						setMemberToModify={setMemberToModify}
						anchorEl={anchorEl}
						removeMember={removeMember}
					/>
				}
			</List>
		</Box>
	)
}

function UserManagementContextMenu({member, isOpen, close, setMemberToModify, anchorEl, removeMember}) {
	// select workspace
	const workspace = useSelector(selectWorkspace)
	const user = useSelector(selectUser)
	const currentUserIsWorkspaceOwner = workspace?.ownerUserId === user?.id
	const targetMemberIsNotCurrentUser = member.userId !== user?.id
	const dispatch = useDispatch()

	const handleModifyPermissions = () => {
		setMemberToModify(member)
		close()
	}

	const handleRemoveMember = (member) => {
		removeMember(member)
		close()
	}
	return (
		<Menu
			open={isOpen}
			onClose={close}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "center",
				horizontal: "left",
			}}
		>
			<MenuItem
				onClick={handleModifyPermissions}
				dense
				divider
			>
				<ListItemIcon>
					<ManageAccounts />
				</ListItemIcon>
				<ListItemText>
					Modify permissions in this Workspace
				</ListItemText>
			</MenuItem>
			<MenuItem
				onClick={() => handleRemoveMember(member)}
				disabled={member.admin}
				dense
			>
				<ListItemIcon>
					<DeleteOutline />
				</ListItemIcon>
				<ListItemText>
					Remove user from this workspace
				</ListItemText>
			</MenuItem>
			{
				currentUserIsWorkspaceOwner &&
				targetMemberIsNotCurrentUser && (
					<MenuItem
						onClick={() =>{
							dispatch(setTargetUser(member))
							dispatch(setManageWorkspaceView(VIEW.TRANSFER_OWNERSHIP))
						}}
						dense
					>
						<ListItemIcon>
							<TransferWithinAStation />
						</ListItemIcon>
						<ListItemText>
							Transfer Ownership
						</ListItemText>
					</MenuItem>
				)
			}
		</Menu>
	)
}