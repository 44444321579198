import { useAuth0 } from "@auth0/auth0-react"
import { Box, Card, CardContent, Grid, Stack,List, ListItem,ListItemIcon, ListItemText, Button, Typography } from "@mui/material"
import SchoolIcon from "@mui/icons-material/School"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"


export default function Pricing() {

	const { loginWithRedirect } = useAuth0()

	const tiers = [
		{
			icon: () => <SchoolIcon />,
			name: "Free Access",
			priceMonthly: "Free",
			details: [
				"Unlimited workspaces",
				"Manage up to 20 citations in each workspace",
				"Unlimited read access to workspaces you're invited to",
			],
			button: {
				icon: () => <AccountCircleOutlinedIcon />,
				text: "text",
				callback: () =>
					loginWithRedirect({
						screen_hint: "signup",
						appState: { returnTo: "/dashboard?pricing=free" }
					}),
			}
		},
		{
			icon:  () => <AccountBalanceIcon />,
			name: "Unlimited Access",
			priceMonthly: "$13.00/month",
			priceYearly: "or $140.00/year",
			details: [
				"Unlimited descriptive headings",
				"Cloud document storage and annotation",
				"Access your library from any computer",
				"Customized support",
				"10% annual discount",
			],
			button: {
				icon: () => <AccountCircleOutlinedIcon />,
				text: "text",
				callback: () =>
					loginWithRedirect({
						screen_hint: "signup",
						appState: { returnTo: "/dashboard?pricing=paid" }
					}),
			}
		},
	]

	return(
		<Stack
			direction="row"
			justifyContent="space-evenly"
			alignItems="flex-start"
			spacing={4}
			sx={{
				mb: 12,
			}}
		>

			<Grid id={"pricing"} container spacing={4}>
				{tiers.map((tier) => (
					<Grid key={tier.icon} item md={6} xs={12}>
						<Card elevation="3" grow="true">
							<CardContent>
								<Box >
									<List sx={{
										mx: 1,
										padding: "0px",
										margin: "0px",
									}}>
										<ListItem sx={{
											mx: 1,
											padding: "0px",
											margin: "0px",
										}}>
											<ListItemIcon sx={{
												mx: 1,
												padding: "0px",
												margin: "0px",
												minWidth: "32px",
												fill: "black",
											}}>
												{tier.icon()}
											</ListItemIcon>
											<ListItemText sx={{
												m: 0,
											}}>
												<Typography sx={{
													fontFamily: "Inter",
													fontWeight: "700",
												}}
												>
													{tier.name}
												</Typography>
											</ListItemText>
										</ListItem>
									</List>
								</Box>
								<Typography variant="p" component="div" sx={{
									mt:2,
									fontSize: "1.5rem",
									fontFamily: "Inter",
									fontWeight: "700",
								}}>
									{tier.priceMonthly} {tier.priceYearly}
								</Typography>
								<Box>
									<List>
										{tier.details.map((detail) => (
											<ListItem key={tier.details} sx={{
												mx: 1,
												padding: "0px",
												margin: "0px",
												my: .75
											}}>
												<ListItemText>
													<Typography variant="body" sx={{
														fontFamily: "Inter",
														fontWeight: "500",
														lineHeight: "1rem",
													}}>
														{detail}
													</Typography>
												</ListItemText>
											</ListItem>
										))}
									</List>
								</Box>
								<Button
									startIcon={tier.button.icon()}
									onClick={tier.button.callback}
									variant={"contained"}
									size="large"
									sx={{
										color: "white",
										borderRadius: "48px",
										mt: 0,
										width: "100%",
									}}
								>
									Register Now
								</Button>
							</CardContent>
						</Card>
					</Grid>
				))}

			</Grid>
		</Stack>
	)
}
