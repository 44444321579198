import Split from "react-split"
import "../styles/Resizer.css"
import {
	//Alert, AlertTitle,
	Box, Dialog, useTheme} from "@mui/material"
import Manager from "../components/workspace/Manager"
import {useDispatch, useSelector} from "react-redux"
import {useEffect, useState} from "react"
import { selectWorkspace } from "../reducers/workspaceSlice"
import DynamicPanel from "../components/dynamic/DynamicPanel"
import WorkspacePanel from "../components/workspace/WorkspacePanel"
import ContactSupport from "../components/dashboard/ContactSupport"
import CopyCitations from "../components/dashboard/CopyCitations"
import ManageWorkspaceMembers from "../components/workspace/ManageWorkspaceMembers"
import LimitReachedModal from "../components/dashboard/LimitReachedModal"
import { selectAttachError, clearAttachError } from "../reducers/workspaceNoteSlice"
import { useSnackbar } from "notistack"
import { clearNoteAttachmentError } from "../reducers/noteAttachmentsSlice"
import { selectNoteAttachmentError } from "../reducers/noteAttachmentsSlice"
import UpgradeWithKeyModal from "../components/dashboard/UpgradeWithKeyModal"
import {useLocation, useSearchParams} from "react-router-dom"
import {selectLoadingUser} from "../reducers/userSlice"
import {getCustomerData, selectCustomerId} from "../reducers/stripeDetailsSlice"
import {clearMessage, selectError, selectMessage} from "../reducers/manageWorkspaceSlice"

export default function Dashboard() {
	console.log("Link Attach - Dashboard")
	useTheme()
	const dispatch = useDispatch()
	const workspace = useSelector(selectWorkspace)
	const userIsLoading = useSelector(selectLoadingUser)
	const userCustomerId = useSelector(selectCustomerId)
	const { search } = useLocation()
	const queryParams = new URLSearchParams(search)
	const keyId = queryParams.get("key")
	const pricing = queryParams.get("pricing")
	const [openSupportModal, setOpenSupportModal] = useState(false)
	const [openWorkspaceManager, setOpenWorkspaceManager] = useState(workspace === null )
	const [openWorkspacePermissions, setOpenWorkspacePermissions] = useState(false)
	const attachArticleError = useSelector(selectAttachError)
	const noteAttachmentError = useSelector(selectNoteAttachmentError)
	const workspaceTransferMessage = useSelector(selectMessage)
	const workspaceTransferError = useSelector(selectError)
	const { enqueueSnackbar } = useSnackbar()
	const [openKeyUpgradeModal, setOpenKeyUpgradeModal] = useState((!!pricing && pricing === "key-upgrade") && !!keyId)
	const changeUrlParams = useSearchParams()[1]
	const closeKeyUpgrade = () => {
		queryParams.delete("key")
		queryParams.delete("pricing")
		changeUrlParams(queryParams)
		setOpenKeyUpgradeModal(false)
	}

	useEffect(() => {
		if(workspaceTransferMessage) {
			enqueueSnackbar(workspaceTransferMessage, {
				variant: "success",
				autoCloseDuration: 3000,
				onClose: () => {dispatch(clearMessage())}
			})
		}
		if(workspaceTransferError) {
			enqueueSnackbar(workspaceTransferError, {
				variant: "error",
				autoCloseDuration: 3000,
				onClose: () => {dispatch(clearMessage())}
			})
		}

	}, [workspaceTransferMessage, workspaceTransferError])

	useEffect(() => {
		if(workspace === null && openWorkspaceManager === false){
			setOpenWorkspaceManager(true)
		}

		if(userCustomerId === -1) {
			dispatch(getCustomerData())
		}
	}, [workspace])

	useEffect(() => {
		let timer
		if (attachArticleError) {
			enqueueSnackbar(attachArticleError, { variant: "info" })
			timer = setTimeout(() => {
				dispatch(clearAttachError())
			}, 3000)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [attachArticleError])

	useEffect(() => {
		let timer
		if (noteAttachmentError) {
			enqueueSnackbar(noteAttachmentError, { variant: "info" })
			timer = setTimeout(() => {
				dispatch(clearNoteAttachmentError())
			}, 3000)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [noteAttachmentError])

	useEffect(() => {
		window.ondragover = function(e) {
			e.stopPropagation()
			e.preventDefault()
			return false 
		}
		window.ondrop = function(e) { 
			e.stopPropagation()
			e.preventDefault()
			return false 
		}
	})

	return workspace === null ?
		(
			<Box sx={{ height: "100vh", backgroundColor: "primary.main" }}>
				<Dialog
					sx={{
						zIndex: 1302, //2 higher than the MUI default for "Dialog" which is used in Manager as well
					}}
					open={!userIsLoading && openKeyUpgradeModal}
					disableEscapeKeyDown
					fullWidth
				>
					<UpgradeWithKeyModal
						close={closeKeyUpgrade}
						keyId={keyId}
						enqueueSnackbar={enqueueSnackbar}
					/>
				</Dialog>
				<Manager
					setOpen={setOpenWorkspaceManager}
					open={openWorkspaceManager}
				/>
			</Box>
		)
		:
		(
			<Box sx={{
				backgroundColor: "panels.main",
				//NOTE: cursor style can be set here when dragging note between panels
			}}
			>
				{/*<Alert
					severity={"error"}
				>
					<AlertTitle>PubMed is currently experiencing issues.</AlertTitle>
					Searches, including Automated Searches, may experience issues until PubMed services are restored. Your data is safe, and you can continue use RefZoom to manage your imported citations.
				</Alert>*/}
				<Split
					className="wrap"
					sizes={[50, 50]}
					minSize={100}
					expandToMin={false}
					gutterSize={5}
					gutterAlign="center"
					snapOffset={30}
					dragInterval={1}
					direction="horizontal"
					cursor="col-resize"
				>
					<WorkspacePanel workspace={workspace} />
					<DynamicPanel
						workspace={workspace}
						setOpenWorkspaceManager={setOpenWorkspaceManager}
						setOpenSupportModal={setOpenSupportModal}
						setOpenWorkspacePermissions={setOpenWorkspacePermissions}
					/>
				</Split>
				<Manager
					setOpen={setOpenWorkspaceManager}
					open={openWorkspaceManager}
				/>
				<ContactSupport
					openSupportModal={openSupportModal}
					setOpenSupportModal={setOpenSupportModal}
				/>
				<ManageWorkspaceMembers
					workspace={workspace}
					isOpen={openWorkspacePermissions}
					setIsOpen={setOpenWorkspacePermissions}
				/>
				<LimitReachedModal />
				<CopyCitations workspace={workspace} />
			</Box>
		)
}
