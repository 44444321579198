import {
	Box, Button, FormControl, InputLabel, MenuItem, Select,
	Skeleton,
	Stack,
} from "@mui/material"
import {useEffect, useState} from "react"

import ProductPrice from "../../components/stripe/ProductPrice"
import { useNavigate } from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import RegistrationLayout from "./RegistrationLayout"
import {getProductsFromStripe, selectLoadingProductList, selectProductList} from "../../reducers/stripeDetailsSlice"

const MAX_NUMBER_OF_KEYS = 10

export default function PricesForm() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const prices = useSelector(selectProductList)
	const loadingPrices = useSelector(selectLoadingProductList)

	const [selectedCycle, setSelectedCycle] = useState(null)
	const [quantity, setQuantity] = useState(1)

	useEffect(() => {
		dispatch(getProductsFromStripe())
	}, [])

	const handleSetQuantity = (e) => {
		const value = parseInt(e.target.value)

		if (isNaN(value) || value < 0 || value % 1 > 0) {
			setQuantity(null)
		} else if (value > MAX_NUMBER_OF_KEYS) {
			setQuantity(null)
		} else if (!isNaN(value) && value <= MAX_NUMBER_OF_KEYS) {
			setQuantity(value)
		}
	}

	const handleSubmit = async () => {
		navigate("/subscribe", {
			state: {priceId: selectedCycle, quantity}
		})
	}

	return (
		<RegistrationLayout
			title={"Choose your subscription"}
			subheading={"You can change your subscription and users at any time in your account settings."}
		>
			{ loadingPrices ?
				(
					<Stack spacing={"16px"} sx={{width: "100%"}} >
						<Skeleton
							sx={{
								borderRadius: "4px"
							}}
							variant="rectangular"
							height={72}
							width={"100%"}
							animation="wave"
						/>
						<Skeleton
							sx={{
								borderRadius: "4px"
							}}
							variant="rectangular"
							height={72}
							width={"100%"}
							animation="wave"
						/>
						<Skeleton
							sx={{
								borderRadius: "4px"
							}}
							variant="rectangular"
							height={72}
							width={"100%"}
							animation="wave"
						/>
						<Skeleton
							sx={{
								borderRadius: "4px"
							}}
							variant="rectangular"
							height={72}
							width={"100%"}
							animation="wave"
						/>
					</Stack>
				)	: (
					<>
						<Box sx={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
							<FormControl fullWidth>
								<InputLabel>How many users?</InputLabel>
								<Select
									autoFocus
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={quantity}
									label="Number of additional license keys"
									onChange={handleSetQuantity}
								>
									{Array.from({ length: MAX_NUMBER_OF_KEYS + 1 }, (_, index) => (
										//short circuit to avoid displaying quantity of 0
										index && <MenuItem key={index} value={index}>{index === 1 ? `${index} user` : `${index} users`}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						<Box sx={{display: "flex", flexDirection: "column", gap: "16px", width: "100%"}}>
							{prices.filter(priceObject => (!!priceObject.product.active && priceObject.id !== "FreeTier")).map((price) => (
								<ProductPrice
									key={price.id}
									price={price}
									isSelected={price.id === selectedCycle}
									selectPrice={setSelectedCycle}
									quantity={quantity}
								/>
							))}
						</Box>
					</>
				)

			}
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					gap: "0.5em"
				}}
			>
				<Button
					variant={"outlined"}
					fullWidth
					onClick={() => navigate("/register?pricing=paid&registered=true")}
				>Back</Button>
				<Button
					variant={"contained"}
					fullWidth
					onClick={handleSubmit}
					disabled={selectedCycle === null}
				>Next</Button>
			</Box>
		</RegistrationLayout>
	)
}