/**
 * Stripe measures all amounts in USD cents. This take USD cents and returns a USD dollar string
 * @param amountInCents<number>
 * @returns {string}
 */

export const centsToDollars = (amountInCents) =>
	parseFloat(amountInCents / 100).toFixed(2)

/**
 * Stripe measures all dates in milliseconds, so this takes a Stripe date value and returns a human readable string
 * @param milliseconds<number>
 * @returns {string}
 */
export const msToDate = (milliseconds) =>
	new Date(milliseconds * 1000).toDateString()

/**
 * generates a price string from a Stripe Plan entity
 * @param unit_amount<number> - plan.tiers[0].unit_amount
 * @param interval<string> - plan.recurring.interval
 * @param quantity<number> - plan.quantity
 * @returns {string} - ex "$5 /month"
 */
export const generatePriceString = (unit_amount, interval, quantity) => {
	if(unit_amount === "N/A"){
		return unit_amount
	} else if(quantity) {
		return `$${centsToDollars(unit_amount * quantity)} /${interval}`
	} else {
		return `$${centsToDollars(unit_amount)} /${interval}`
	}
}