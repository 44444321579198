import {Box, Card, List, ListItem, useTheme} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {NOTE_ACTIONS, scrollToNote} from "../../reducers/noteActionSlice"
import {hideSearches} from "../../reducers/workspaceSearchSlice"
import {selectTargetNote} from "../../reducers/targetNoteSlice"
import {useEffect, useRef} from "react"

export default function SearchNote({ note, search }) {
	const dispatch = useDispatch()
	const scrollRef = useRef()
	const targetNote = useSelector(selectTargetNote)
	const theme = useTheme()

	const getHighlightedText = (text, highlight) => {
		const parts = text.split(new RegExp(`(${highlight})`, "gi"))
		return (
			<span>
				{parts.map((part, i) => (
					<span
						key={i}
						style={
							part.toLowerCase() === highlight.toLowerCase()
								? { backgroundColor: "#FFFF00" }
								: {}
						}
					>
						{part}
					</span>
				))}
			</span>
		)
	}

	useEffect(() => {
		if(targetNote.id === note.id){
			scrollRef.current.scrollIntoView()
		}
	}, [])

	const handleClick = (e) => {
		e.stopPropagation()
		dispatch(hideSearches())
		dispatch(
			scrollToNote({
				note: note,
				action: NOTE_ACTIONS.scrollToNote,
				noteId: note.id,
				path: note.path,
				showLoading: true
			})
		)
	}

	return (
		<List
			variant={"notetree"}
			toplevel={note?.parentId === null ? "true" : "false" }
			dense
		>
			<ListItem
				ref={scrollRef}
				variant={"notetree"}
				toplevel={note?.parentId === null ? "true" : "false" }
				onClick={handleClick}
			>
				<Box sx={{ display: "flex", paddingTop: "1px",}}>
					<span style={{ maxWidth: "25px", minWidth: "25px" }} />
					<Card
						variant={"note"}
						elevation={2}
						sx={{
							width: "100%",
							padding: "5px",
							minHeight: "30px",
							border: targetNote.id === note.id ? `2px solid ${theme.palette.primary.main}` : "none",
							backgroundColor: targetNote.id === note.id ? "notes.selected" : "white",
							wordBreak: "break-word",
							display: "flex",
							alignItems: "center"
						}}
					>
						{getHighlightedText(note.content, search.query)}
					</Card>
				</Box>
				{note.children.map((child) => (
					<SearchNote
						key={child.id}
						note={child}
						search={search}
					/>
				))}
			</ListItem>
		</List>
	)
}
