import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { getImportedArticleCountForWorkspace } from "./freeTierTrackerslice"

// Initial state
const initialState = {
	view: null,
	targetUser: null,
	message: null,
	error: null
}

export const VIEW = {
	TRANSFER_OWNERSHIP: 0,
}

export const confirmWorkspaceTransfer = createAsyncThunk(
	"manageWorkspaceSlice/confirmWorkspaceTransfer",
	async ({userId, workspaceId}, {dispatch, rejectWithValue}) => {
		try {
			const url = `/api/workspace/${workspaceId}/transferOwnership/user/${userId}`
			const res = await axios.put(url)
			const {data: updatedWorkspace} = res
			dispatch(getImportedArticleCountForWorkspace({updatedWorkspace}))
			return updatedWorkspace
		} catch (error) {
			if (error.response) {
				return rejectWithValue(error.response.statusText)
			}
			return rejectWithValue(error.message)
		}
	}
)

// Create slice
const manageWorkspaceSlice = createSlice({
	name: "manageWorkspaceSlice",
	initialState,
	reducers: {
		setManageWorkspaceView: (state, action) => {
			state.view = action.payload
		},
		setTargetUser: (state, action) => {
			state.targetUser = action.payload
		},
		clearManageWorkspaceView: (state) => {
			state.view = null
			state.targetUser = null
		},
		clearMessage: (state) => {
			state.message = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				confirmWorkspaceTransfer.fulfilled,
				(state) => {
					state.view = null
					state.targetUser = null
					state.message = "Ownership transfer was successful"
					state.error = null
				}
			)
			.addCase(
				confirmWorkspaceTransfer.rejected,
				(state, action) => {
					state.error = action.payload || "Ownership transfer failed"
				}
			)
	}
})

// Export actions generated from createSlice
export const {
	setManageWorkspaceView,
	clearManageWorkspaceView,
	setTargetUser,
	clearMessage
} = manageWorkspaceSlice.actions

export const selectManageWorkspaceView = (state) => state.manageWorkspaceSlice.view
export const selectTargetUser = (state) => state.manageWorkspaceSlice.targetUser

export const selectMessage= (state) => state.manageWorkspaceSlice.message
export const selectError= (state) => state.manageWorkspaceSlice.error


// Export the reducer
export default manageWorkspaceSlice.reducer
