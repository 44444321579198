import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@mui/material"
import { Close } from "@mui/icons-material"

export function DashboardModal({ open, setOpen, title, children }) {
	const handleClose = () => setOpen(false)

	return (
		<Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
			<IconButton
				onClick={handleClose}
				variant={"curved-square"}
				sx={{ position: "absolute", top: 0, right: 0 }}
			>
				<Close />
			</IconButton>
			<DialogTitle
				sx={{ display: "flex", justifyContent: "center", fontWeight: 600 }}
				variant={"h5"}
			>
				{title}
			</DialogTitle>
			<DialogContent>{children}</DialogContent>
		</Dialog>
	)
}
