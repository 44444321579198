import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const initialValue = {
	count: 0,
	articles: [],
	isLoading: false,
	searchId: null,
	page: 1,
	scrollY: 0,
}

export const automatedSearchSlice = createSlice({
	name: "automateSearch",
	initialState: {
		value: initialValue,
	},
	reducers: {
		setSearchResults: (state, action) => {
			console.log(
				state.value.searchId === action.payload.searchId,
				state.value.searchId,
				action.payload.searchId
			)
			state.value.count = action.payload.count
			state.value.articles = action.payload.articles
			state.value.page = action.payload.page
			state.value.scrollY =
				state.value.searchId === action.payload.searchId
					? state.value.scrollY
					: 0
			state.value.searchId = action.payload.searchId
		},
		addArticles: (state, action) => {
			state.value.articles = state.value.articles.concat(action.payload)
		},
		incrementPage: (state) => {
			state.value.page += 1
		},
		resetSearch: (state) => {
			state.value = initialValue
		},
		setSearch: (state, action) => {
			state.value = action.payload
		},
		setLoading: (state, action) => {
			state.value.isLoading = action.payload
		},
		setArticleAsImported: (state, action) => {
			const index = state.value.articles.findIndex(
				(article) => article.DOI === action.payload.article.DOI
			)
			state.value.articles.splice(index, 1, action.payload)
		},
		setScrollY: (state, action) => {
			state.value.scrollY = action.payload
		},
	},
})

export const {
	setSearchResults,
	addArticles,
	incrementPage,
	resetSearch,
	setLoading,
	setArticleAsImported,
	setScrollY,
} = automatedSearchSlice.actions

export const selectAutomatedSearch = (state) => state.automatedSearch.value

export const submitSearch = (workspaceId, searchId) => async (dispatch) => {
	dispatch(resetSearch())
	dispatch(setLoading(true))

	const uri = `/api/workspace/${workspaceId}/auto-search/${searchId}?page=1`
	await axios
		.get(uri)
		.then(({ data: results }) => {
			dispatch(setSearchResults({ ...results, page: 1, searchId }))
			dispatch(incrementPage())
		})
		.finally(() => {
			dispatch(setLoading(false))
		})
}

export const nextPage = (workspaceId, searchId, page) => async (dispatch) => {
	dispatch(setLoading(true))

	const uri = `/api/workspace/${workspaceId}/auto-search/${searchId}?page=${page}`
	await axios
		.get(uri)
		.then(({ data: results }) => {
			dispatch(addArticles(results.articles))
			dispatch(incrementPage())
		})
		.finally(() => {
			dispatch(setLoading(false))
		})
}

export default automatedSearchSlice.reducer
