import {
	Box,
	Card,
	List,
	ListItem,
	Typography,
	Tooltip,
	IconButton,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { NOTE_ACTIONS } from "../../../../reducers/noteActionSlice"
import { selectTargetNote } from "../../../../reducers/targetNoteSlice"
import { scrollToNote } from "../../../../reducers/noteActionSlice"
import { Close } from "@mui/icons-material"
import { detachNoteFromAttachment, selectAttachmentRelations } from "../../../../reducers/noteAttachmentsSlice"


export default function NoteAttachmentBreadcrumbs({
	note,
	attachment,
	isParent,
	preview
}) {

	const isRelationPresent = (noteId, attachmentId, relations) => {
		// Access the array of notes for the given attachment ID
		const noteArray = relations[attachmentId]
	
		// Check if noteArray exists and contains the given note ID
		if (noteArray && noteArray.some(relation => relation.noteId === noteId)) {
			return true // The note ID is found in the array
		} else {
			return false // The note ID is not found in the array
		}
	}

	const dispatch = useDispatch()
	const targetNote = useSelector(selectTargetNote)
	const relations = useSelector(selectAttachmentRelations)
	const isDetachable = isRelationPresent(note.id, attachment.id, relations)

	


	const handleClick = (e) => {
		e.stopPropagation()
		dispatch(
			scrollToNote({
				note: note,
				action: NOTE_ACTIONS.scrollToNote,
				noteId: note.id,
				path: note.path,
				showLoading: true
			})
		)
	}

	return (
		<List
			sx={{
				paddingLeft: isParent ? "0px" : "25px",
				paddingTop: "1px",
				paddingBottom: 0,
				pointerEvents: "none",
			}}
			dense
		>
			<ListItem
				sx={{
					display: "block",
					borderLeft: "1px solid #dfdfdfa1",
					padding: 0,
					pointerEvents: "auto",
				}}
			>
				<Box sx={{ display: "flex" }}>
					<Card
						onClick={handleClick}
						variant={"note"}
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							paddingRight: "25px",
							minHeight: "30px",
							color: "RBOneFont.main",
							backgroundColor: "notes.crumbsBackground",
							borderLeft: "5px solid",
							borderColor: "notes.dark",
							// ...(isAttached && {
							// 	backgroundColor: "notes.crumbsBackground",
							// }),
							...(targetNote.path.indexOf(note.id) !== -1 && {
								borderColor: "primary.main",
							}),
							...(targetNote.id === note.id && {
								backgroundColor: "notes.selected",
								"&:hover": { backgroundColor: "notes.selectedHighlightBg" },
							}),
						}}
					>
						<Typography
							variant={"body1"}
							sx={{
								padding: "5px 10px",
								marginBottom: 0,
								wordBreak: "break-word"
							}}
							paragraph
						>{note.content}</Typography>
					</Card>
					{
						isDetachable && (
							<Tooltip title={"Detach from note"} placement={"left"}>
								<IconButton
									disabled={preview}
									onClick={() => dispatch(detachNoteFromAttachment({note, attachment}))}
									sx={{
										color: "alertRed.main",
										padding: 0,
										position: "absolute",
										zIndex: 3,
										right: "5px",
										maxHeight: "30px",
										"&:hover": {
											backgroundColor: "alertRed.highlight",
										},
									}}
								>
									<Close />
								</IconButton>
							</Tooltip>
						)
					}	
				</Box>
				{note.children.map((child) => (
					<NoteAttachmentBreadcrumbs
						key={child.id}
						note={child}
						attachment={attachment}
					/>
				))}
			</ListItem>
		</List>
	)
}
