import {useSnackbar} from "notistack"
import {Box, IconButton, Link, Tooltip, Typography} from "@mui/material"
import {ContentCopy} from "@mui/icons-material"

export default function ArticleLink({ label, value, link, selected }) {
	const { enqueueSnackbar } = useSnackbar()

	const copyToClipboard = async (text) => {
		try {
			await navigator.clipboard.writeText(text)
			enqueueSnackbar(`Copied ${label} to clipboard.`, {
				variant: "info",
				autoHideDuration: 3000,
			})
		} catch (e) {
			enqueueSnackbar(`Failed to copy ${label} to clipboard`, {
				variant: "warning",
				autoHideDuration: 3000,
			})
		}
	}

	return (
		<Box
			sx={{
				display: "inline-flex",
				paddingRight: "8px",
				alignItems: "center",
			}}
		>
			{selected ? (
				<>
					<Tooltip title={`Visit article on ${label}`}>
						<>
							{label}:{" "}
							<Link href={link} target={"_blank"} rel="noreferrer noopener">
								{value}
							</Link>
						</>
					</Tooltip>
					<Tooltip title={`Copy ${label} to Clipboard`}>
						<IconButton
							onClick={() => copyToClipboard(value)}
							variant={"curved-square"}
							size={"small"}
						>
							<ContentCopy fontSize={"small"} />
						</IconButton>
					</Tooltip>
				</>
			) : (
				<>
					<Typography variant={"caption"} sx={{ color: "RBOneFont.otherInfo" }}>
						{label}: {value}
					</Typography>
				</>
			)}
		</Box>
	)
}