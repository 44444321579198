import { createSlice} from "@reduxjs/toolkit"

export const sessionTimestampSlice = createSlice({
	name: "sessionTimestamp",
	initialState: {
		value: null
	},
	reducers: {
		setSessionTimestamp: (state) => {
			state.value = new Date().toISOString()
		},
	},
})

export const {setSessionTimestamp} = sessionTimestampSlice.actions

export const selectSessionTimestamp = (state) => state.sessionTimestamp.value

export default sessionTimestampSlice.reducer