import {NavigationSecondaryFnWrapper} from "../../panels/Navigation"
import {
	Box,
} from "@mui/material"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {
	hideSearches,
	newSearch,
	selectIsSearching,
	showSearches
} from "../../../reducers/workspaceSearchSlice"
import {useSnackbar} from "notistack"
import {WorkspaceSearchInputForm} from "./WorkspaceSearchInputForm"
import {WorkspaceSearchTabs} from "./WorkspaceSearchBarTabs"

const MAX_NUMBER_OF_WORKSPACE_SEARCHES = 3

export default function WorkspaceSearchBar({workspace, currentSearches, currentSearchIndex }) {
	const {enqueueSnackbar} = useSnackbar()
	const dispatch = useDispatch()
	const inSearchResultsView = useSelector(selectIsSearching)
	const [query, setQuery] = useState("")
	const [canAddSearch, setCanAddSearch] = useState(true)
	const [showSearchInput, setShowSearchInput] = useState(false)

	useEffect(() => {
		if(currentSearches.length >= MAX_NUMBER_OF_WORKSPACE_SEARCHES) {
			setCanAddSearch(false)
		} else {
			setCanAddSearch(true)
		}
	}, [currentSearches])

	const handleChange = (e) => {
		setQuery(e.target.value)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (query) {
			try {
				await dispatch(newSearch({workspaceId: workspace.id, query})).unwrap()
			} catch {
				enqueueSnackbar(
					"Your search did not return any results. Check your query and try again!",
					{
						variant: "error",
						autoHideDuration: 3000,
					}
				)
			} finally {
				setQuery("")
				setShowSearchInput(false)
			}
		}
	}
	
	const toggleShowSearch = (e) => {
		e.preventDefault()
		if(inSearchResultsView){
			dispatch(hideSearches())
		} else {
			dispatch(showSearches())
		}
	}

	return (
		<Box sx={{ background: "white" }}>
			<NavigationSecondaryFnWrapper sx={{alignItems: "end", justifyContent: "space-between"}}>
				<WorkspaceSearchTabs
					currentSearchIndex={currentSearchIndex}
					currentSearches={currentSearches}
					inSearchResultsView={inSearchResultsView}
					toggleShowSearch={toggleShowSearch}
				/>
				<WorkspaceSearchInputForm
					showSearchInput={showSearchInput}
					setShowSearchInput={setShowSearchInput}
					handleSubmit={handleSubmit}
					handleChange={handleChange}
					query={query}
					canAddSearch={canAddSearch}
				/>
			</NavigationSecondaryFnWrapper>
		</Box>
	)
}