import { styled } from "@mui/material/styles"

const ArticleSideBar = styled("div", {
	shouldForwardProp: (prop) =>
		prop !== "color" && prop !== "variant" && prop !== "isExcluded",
	name: "ArticleSideBar",
	slot: "Root",
})(() => ({
	height: "100%",
	minWidth: "40px",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	color: "#FFF",
	opacity: 1,
	cursor: "pointer"
}))

export default ArticleSideBar
