import {useNavigate} from "react-router-dom"
import {AppBar, Divider, IconButton, Stack, Toolbar, Tooltip, Typography} from "@mui/material"
import {Close, MenuOpen} from "@mui/icons-material"
import {DashboardMenu} from "../DashboardMenu"
import {useState} from "react"
import ContactSupport from "../dashboard/ContactSupport"

export default function AccountSettingsNav({title, toolbarHeight, toggleDrawer}) {
	const navigate = useNavigate()
	const [openSupportModal, setOpenSupportModal] = useState(false)

	const toolBarStackStyling = {
		height: toolbarHeight,
		justifyContent: "center",
		alignItems: "center"
	}

	return (
		<AppBar position={"fixed"} sx={{height: toolbarHeight, justifyContent: "center", zIndex: 1201}}>
			<Toolbar
				variant={"dense"}
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between"
				}}
			>
				<Stack
					direction={"row"}
					gap={3}
					sx={toolBarStackStyling}
				>
					<Typography sx={{
						cursor: "default",
						fontSize: "1rem",
						fontWeight: "500"
					}}>{title}</Typography>
					<Tooltip title={`Toggle ${title} menu`} placement={"bottom"}>
						<IconButton
							size={"large"}
							sx={{ p: "10px", flexGrow: 0 }}
							onClick={() => toggleDrawer()}
						>
							<MenuOpen />
						</IconButton>
					</Tooltip>
				</Stack>
				<Stack
					direction={"row"}
					sx={toolBarStackStyling}
				>
					<DashboardMenu
						setOpenSupportModal={setOpenSupportModal}
					/>
					<Divider orientation={"vertical"}/>
					<Tooltip title={`Close ${title}`} placement={"bottom"}>
						<IconButton
							size={"large"}
							onClick={() => navigate("/dashboard")}
							sx={{ p: "10px", flexGrow: 0 }}
						>
							<Close />
						</IconButton>
					</Tooltip>
				</Stack>
				<ContactSupport
					openSupportModal={openSupportModal}
					setOpenSupportModal={setOpenSupportModal}
				/>
			</Toolbar>
		</AppBar>
	)
}