import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import CheckoutForm from "./CheckoutForm"
import { StripeConfig } from "../../config/stripe.config"

export default function Checkout({ clientSecret, subscriptionId, amount }) {
	const stripePromise = loadStripe(StripeConfig.perishable_key)
	const stripeElementsOptions = {
		mode: "subscription",
		setupFutureUsage: "off_session",
		amount: amount,
		currency: "usd"
	}

	return (
		<Elements stripe={stripePromise} options={stripeElementsOptions}>
			<CheckoutForm subscriptionId={subscriptionId} secret={clientSecret}/>
		</Elements>
	)
}
