import {
	Button, Table, TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {
	fetchStripeInvoices,
	selectCustomerHasMoreInvoices,
	selectThreeInvoices
} from "../../../../reducers/stripeInvoicesSlice"
import {useEffect} from "react"
import {centsToDollars} from "../../../../utils/stripeUtilityFns"
import {formatDateWithYear} from "../../../../utils/dates"

export function BillingHistoryTable() {
	const dispatch = useDispatch()
	const invoices = useSelector(selectThreeInvoices)
	const hasMore = useSelector(selectCustomerHasMoreInvoices)

	useEffect(() => {
		dispatch(fetchStripeInvoices())
	}, [])

	console.log("billing history... what be invoices: ", invoices)
	return invoices?.length > 0 ? (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align={"left"} sx={{fontWeight: "bold"}}> Date </TableCell>
						<TableCell align={"left"} sx={{fontWeight: "bold"}}> Amount </TableCell>
						<TableCell align={"left"} sx={{fontWeight: "bold"}}> Status </TableCell>
						<TableCell align={"left"}> </TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ invoices.map(invoice => (
						<TableRow key={invoice.id}>
							<TableCell> {formatDateWithYear(new Date(invoice.created * 1000))} </TableCell>
							<TableCell> {centsToDollars(invoice.amount_paid)} </TableCell>
							<TableCell> {invoice.status.toUpperCase()} </TableCell>
							<TableCell>
								<Button
									onClick={() => window.open(invoice.hosted_invoice_url, "_blank", "noopener,noreferrer")}
								> View </Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{ hasMore &&
				<Button
					fullWidth
					variant={"contained"}
					color={"primary"}
					onClick={() => dispatch(fetchStripeInvoices())}
				> Get Older Invoices </Button>
			}
		</>
	) : null
}