import { fetchNoteRestrictions, updateAllowedNoteIds } from "../../reducers/noteRestrictionsSlice"
import { fetchNewTopLevelNotesById } from "../../reducers/workspaceNoteSlice"
import { setWorkspace } from "../../reducers/workspaceSlice"

export const noteRestrictionsMiddleware = (socket) => {
	return (storeAPI) => {
		socket.on("UPDATE_NOTE_RESTRICTIONS", (noteRestrictions) => {
			storeAPI.dispatch(fetchNewTopLevelNotesById(noteRestrictions))
			storeAPI.dispatch(updateAllowedNoteIds(noteRestrictions))
		})
		return next => async action => {
			if (action.type === setWorkspace.type) {
				const state = storeAPI.getState()
				const currentUserId = state.user?.value?.id
				const workspaceId = action.payload.id
				const userId = currentUserId
				socket.emit("SUBSCRIBE_TO_NOTE_RESTRICTIONS", {workspaceId, userId})
				storeAPI.dispatch(fetchNoteRestrictions(workspaceId))

			}
			return next(action)
		}
	}
}