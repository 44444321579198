import {Box, Button, Dialog, DialogContentText, Typography} from "@mui/material"

export default function ConfirmLeaveModal({openModal, setOpenModal, workspace, leaveWorkspace}) {
	return (
		<Dialog
			open={openModal}
			onClose={() => setOpenModal(false)}
			PaperProps={{
				sx: {
					alignItems: "center",
					padding: "24px 32px",
					gap: "1rem",
					maxWidth: "26rem"
				}
			}}
		>
			<Typography
				sx={{
					fontWeight: 400,
					fontSize: "1.25rem",
					padding: "0",
					textAlign: "center"
				}}
			>
				Are you sure you want to leave the workspace {workspace.title}?
			</Typography>
			<DialogContentText
				sx={{
					fontSize: "1rem",
					color: "#555555",
					lineHeight: "1.375rem"
				}}
			>
				If you leave this workspace, you will be unable to join it again unless you receive a new invite.
			</DialogContentText>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "end",
					gap: "1em",
				}}
			>
				<Button
					onClick={leaveWorkspace}
					variant={"contained"}
					color={"error"}
				>
					Leave Workspace
				</Button>
				<Button
					onClick={() => setOpenModal(false)}
				>
					Cancel
				</Button>
			</Box>
		</Dialog>
	)
}