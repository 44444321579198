import {Box, Button, FormControl, FormLabel, MenuItem, Select, Stack, Tooltip, Typography, useTheme} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {
	AUTOMATED_SEARCH_PANEL_VIEW,
	resetImportedExcludedList,
	selectAutomatedSearchPanelView,
	setAutomatedSearchPanelView,
	setFetchInitialPage,
	updateSearchCounts
} from "../../../reducers/automatedSearchViewSlice"
import {selectAutomatedSearchPanelNav} from "../../../reducers/automatedSearchNavSlice"
import {selectAutomatedSearchSortBy, setAutomatedSearchSortBy} from "../../../reducers/automatedSearchSortBySlice"
import AutomatedSearchTotalCount from "./AutomatedSearchTotalCount"

export function AutomatedSearchPanelNav() {
	useTheme()
	const dispatch = useDispatch()
	const view = useSelector(selectAutomatedSearchPanelView)
	const search = useSelector(selectAutomatedSearchPanelNav)
	const sortBy = useSelector(selectAutomatedSearchSortBy)
	
	const handleSortChange = (event) => {
		dispatch(
			setAutomatedSearchSortBy(
				event.target.value
			)
		)
	}

	if (view === AUTOMATED_SEARCH_PANEL_VIEW.ARTICLE_LIST) {
		// show title, article count data and back button

		return (
			<Stack
				direction="row"
				spacing={1}
				sx={{
					alignItems: "center",
					justifyContent: "space-between",
					maxHeight: "40px",
					width: "100%",
					overflow: "hidden",
				}}
			>
				<Button
					variant={"outlined"}
					onClick={() => {
						dispatch(setFetchInitialPage({selectedSearch: search}))
						dispatch(updateSearchCounts({ searchId: search.id }))
						dispatch(
							setAutomatedSearchPanelView(
								AUTOMATED_SEARCH_PANEL_VIEW.SEARCH_LIST
							)
						)
						dispatch(resetImportedExcludedList())
					}}
				>
				Back
				</Button>
				<Typography
					display={"inline"}
					variant={"articleTitle"}
					noWrap
					sx={{
						width: "100%",
						color: "RBOneFont.main",
						textAlign: "left",
						flexShrink: 1,
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					{search.title}
				</Typography>
				<Box sx={{ flexShrink: 0, whiteSpace: "nowrap" }}>
					<AutomatedSearchTotalCount search={search} />
				</Box>
			</Stack>
		)
	} else if (view === AUTOMATED_SEARCH_PANEL_VIEW.AUTOMATED_SEARCH_FORM) {
		return (
			/* Remove button */
			<></>
		)
	} else {
		// options to create a new search, filter fn() ??
		return (
			<>
				<Tooltip title="Create a new PubMed automated search">
					<Button
						variant={"outlined"}
						onClick={() =>
							dispatch(
								setAutomatedSearchPanelView(
									AUTOMATED_SEARCH_PANEL_VIEW.AUTOMATED_SEARCH_FORM
								)
							)
						}
					>
						New Automated Search
					</Button>
				</Tooltip>
				<Box sx={{display: "flex", alignItems: "center", gap: 1}}>
					<FormLabel>Sort:</FormLabel>
					<FormControl variant="standard" sx={{ minWidth: 120 }} size="small" margin="dense">
						<Select
							value={sortBy}
							onChange={handleSortChange}
							inputProps={{ "aria-label": "Without label" }}
						>
							<MenuItem value={"alpha"}>Alphabetically</MenuItem>
							<MenuItem value={"createdAt"}>Created At</MenuItem>
						</Select>
					</FormControl>
				</Box>

			</>
		)
	}
}