import { createSlice } from "@reduxjs/toolkit"

export const automatedSearchSortBySlice = createSlice({
	name: "automatedSearchSortBy",
	initialState: {
		value: "alpha",
	},
	reducers: {
		setAutomatedSearchSortBy: (state, action) => {
			state.value = action.payload
		},
	},
})

export const { setAutomatedSearchSortBy } = automatedSearchSortBySlice.actions

export const selectAutomatedSearchSortBy = (state) => state.automatedSearchSortBy.value

export default automatedSearchSortBySlice.reducer
