import logo from "../logo.png"
import { Box, Stack, Typography, useTheme } from "@mui/material"

export default function PageWrapper({ title, children }) {
	const theme = useTheme()

	return (
		<Box
			sx={{
				background: theme.palette.primary.main,
				width: "100vw",
				maxWidth: "100%",
				minHeight: "100vh",
				paddingBottom: "10px",
				paddingTop: "10px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					background: "white",
					borderRadius: "5px",
					padding: "10px",
					width: "50vw",
				}}
			>
				<Stack spacing={1} sx={{ alignItems: "center" }}>
					<img width="50px" src={logo} alt={"refbin-logo"} />
					<Typography variant={"h4"}>{title}</Typography>
					{children}
				</Stack>
			</Box>
		</Box>
	)
}
