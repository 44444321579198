import {useState} from "react"
import axios from "axios"
import {Box, Button, List, ListItem, Stack, TextField, Typography} from "@mui/material"
import { debounce } from "lodash"

export function AddMember({ setIsAddingNewMember, setNewMember, workspaceId, snackbar }) {
	const [searchResults, setSearchResults] = useState([])

	const handleClick = (newMember) => {
		setNewMember({
			user: newMember,
			read: true,
			write: false,
			admin: false,
			workspaceId,
			userId: newMember.id
		})
		//setIsAddingNewMember(false)
	}

	const handleChange = (e) => {
		const query = e.target.value

		if (query.length >= 3) {
			axios.get(`/api/user?search=${query}`)
				.then(({ data }) => {
					setSearchResults(data)
				})
				.catch((e) => {
					console.error("SEARCHING FOR MEMBERS TO ADD: \n", e)
					snackbar("Failed while searching for member to add. Please try again.")
				})
		}
	}

	// debounce the user search
	const debouncedSearch = debounce(handleChange, 300)

	return (
		<Box>
			<Typography id="modal-modal-title" variant="h4">
				Add Member
			</Typography>
			<br />
			<Box sx={{ margin: "10px", minHeight: "200px" }}>
				<Stack>
					<TextField
						onKeyDown={(e) => e.stopPropagation()}
						fullWidth
						label="Search Users"
						variant="standard"
						onChange={debouncedSearch}
					/>
					<List sx={{ maxHeight: "40vh", overflowY: "scroll" }}>
						{searchResults.map((user) => (
							<ListItem
								key={user.id}
								sx={{
									display: "flex",
									justifyContent: "center",
									border: "1px solid black",
									borderRadius: "5px",
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "action.focus"
									},
								}}
							>
								<Typography onClick={() => handleClick(user)}>
									{user.firstName} {user.lastName} - {user.email}
								</Typography>
							</ListItem>
						))}
					</List>
				</Stack>
			</Box>
			<br />
			<Box sx={{ display: "flex" }}>
				<Button
					variant={"outlined"}
					color={"error"}
					onClick={() => setIsAddingNewMember(false)}
				>
					Cancel
				</Button>
			</Box>
		</Box>
	)
}