import PropTypes from "prop-types"
import { Box, CardMedia, Paper } from "@mui/material"

const YoutubeEmbed = ({ embedId }) => (
	<Box component="div" className="video-responsive">
		<Paper elevation="5" sx={{
			overflow: "hidden",
			paddingBottom: "56.25%",
			position: "relative",
			borderRadius: "16px"
		}}
		>
			<CardMedia component="iframe" sx={{
				width: "100%",
				height: "100%",
				position: "absolute"
			}}
			width="853"
			height="480"
			src={`https://www.youtube.com/embed/${embedId}?rel=0`}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			title="Embedded youtube"
			/>
		</Paper>
	</Box>
)

YoutubeEmbed.propTypes = {
	embedId: PropTypes.string.isRequired
}

export default YoutubeEmbed
