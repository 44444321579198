import { useEffect, useState } from "react"

const useIsMacOS = () => {
	const [isMacOS, setIsMacOS] = useState(false)

	useEffect(() => {
		const detectMacOS = () => {
			if (navigator.userAgentData) {
				navigator.userAgentData.getHighEntropyValues(["platform"])
					.then(ua => {
						setIsMacOS(ua.platform === "macOS")
					})
			} else {
				// Fallback for browsers without support for navigator.userAgentData
				setIsMacOS(navigator.userAgent.includes("Mac"))
			}
		}

		detectMacOS()
	}, [])

	return isMacOS
}

export default useIsMacOS
