import {Box, Button, Card, CardContent, CircularProgress, Divider, Typography} from "@mui/material"
import {
	useLocation,
	Navigate, useNavigate} from "react-router-dom"
import { useSnackbar } from "notistack"
import { useState} from "react"
import axios from "axios"
import {useDispatch} from "react-redux"
import { setUser } from "../reducers/userSlice"
import {useAuth0} from "@auth0/auth0-react"

export default function Subscribe() {
	const dispatch = useDispatch()
	const location = useLocation()
	const { enqueueSnackbar } = useSnackbar()
	const navigate = useNavigate()
	const [paymentMethod, setPaymentMethod] = useState("none")
	const [loading, setLoading] = useState(false)
	const auth = useAuth0()

	if(auth.isAuthenticated && !location.state){
		return <Navigate to="/prices" />
	}


	const handleSubmit = () => {
		if(paymentMethod === "pay_now") {
			createPayNowSubscription()
		}

		if(paymentMethod === "invoice") {
			generateInvoice()
		}
	}

	const generateInvoice = () => {
		setLoading(true)
		axios.post("/api/subscription/pay-invoice", {
			priceId: location.state.priceId,
			quantity: location.state.quantity
		}).then(({data}) => {
			let nextPagePayload = {...data, ...location.state}
			dispatch(setUser(data.user))
			navigate("/subscribe/generate-invoice", {
				state: nextPagePayload
			})
		}).catch((e) => {
			enqueueSnackbar("Error occurred during subscription: ", e)
		}).finally(() => {
			setLoading(false)
		})
	}

	const createPayNowSubscription = () => {
		setLoading(true)
		axios.post("/api/subscription/pay-now", {
			priceId: location.state.priceId,
			quantity: location.state.quantity
		}).then(({data}) => {
			let nextPagePayload = {...data, ...location.state}
			dispatch(setUser(data.user))
			navigate("/subscribe/pay-now", {
				state: nextPagePayload
			})
		}).catch((e) => {
			enqueueSnackbar("Error occurred during subscription: ", e)
		}).finally(() => {
			setLoading(false)
		})

	}

	return loading ? (
		<CircularProgress />
	) : (
		<Box sx={{
			width: "100%",
			marginTop: "1.5em",
			display: "flex",
			flexDirection: "column",
			gap: "1.5em",
		}}>
			<Card
				sx={{
					flexBasis: "100%",
					width: "100%",
					border: paymentMethod === "pay_now" ? "1px solid #02B3C5" : "1px solid #CFCFCF",
					boxShadow: "none",
					padding: "0px",
					cursor: paymentMethod !== "pay_now" ? "pointer" : "default"
				}}
				onClick={paymentMethod !== "pay_now" ? () => setPaymentMethod("pay_now") : undefined}
			>
				<CardContent sx={{textAlign: "center", display: "flex", flexDirection: "column", gap: "12px", paddingY: "24px !important", paddingX: "16px"}}>
					<Typography variant={"tunedH2"}>
						Credit or Debit card
					</Typography>
				</CardContent>
			</Card>
			<Divider variant={"middle"} flexItem>
				OR
			</Divider>
			<Card
				sx={{
					flexBasis: "100%",
					width: "100%",
					border: paymentMethod === "invoice" ? "1px solid #02B3C5" : "1px solid #CFCFCF",
					boxShadow: "none",
					padding: "0px",
					cursor: paymentMethod !== "invoice" ? "pointer" : "default"
				}}
				onClick={paymentMethod !== "invoice" ? () => setPaymentMethod("invoice") : undefined}
			>
				<CardContent sx={{textAlign: "center", display: "flex", flexDirection: "column", gap: "12px", paddingY: "24px !important", paddingX: "16px"}}>
					<Typography variant={"tunedH2"}>
						Generate an invoice
					</Typography>
				</CardContent>
			</Card>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					gap: "0.5em"
				}}
			>
				<Button
					variant={"outlined"}
					fullWidth
					onClick={() => navigate("/prices")}
				>Back</Button>
				<Button
					variant={"contained"}
					fullWidth
					onClick={handleSubmit}
					disabled={paymentMethod === "none"}
				>Next</Button>
			</Box>
		</Box>
	)
}
