import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { attachArticlesToNote } from "./breadcrumbSlice"
import { attachNoteToAttachment } from "./noteAttachmentsSlice"

// Initial state
const initialState = {
	dragItem: null,
}

export const dragType = {
	ATTACHMENT: 0,
	ARTICLE: 1
}

export const setOnDrop = createAsyncThunk(
	"articleDragSlice/setOnDrop",
	async ({note}, {getState, dispatch}) => {
		const state = getState()
		const { selectedArticles, articleDragSlice } = state
		const payloadType = articleDragSlice.dragItem.type
		
		if (payloadType === dragType.ARTICLE) {
			const itemPayload = Object.keys(selectedArticles?.value).length ? Object.values(selectedArticles.value).map((article) => article) : [articleDragSlice.dragItem.item]
			dispatch(attachArticlesToNote({
				articles: itemPayload,
				note
			}))
		}

		if (payloadType === dragType.ATTACHMENT) {
			const itemPayload = articleDragSlice.dragItem.item
			dispatch(attachNoteToAttachment({
				attachment: itemPayload,
				note
			}))
		}

	}
)

// Create slice
const articleDragSlice = createSlice({
	name: "articleDragSlice",
	initialState,
	reducers: {
		setDragItem: (state, action) => {
			state.dragItem = action.payload
		},
		clearDragItem: (state) => {
			state.dragItem = null
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setOnDrop.fulfilled, (state) => {
			state.dragItem = null
		})
	}
})

// Export actions generated from createSlice
export const { setDragItem, clearDragItem } = articleDragSlice.actions

export const selectDragItem = (state) => state.articleDragSlice.dragItem

// Export the reducer
export default articleDragSlice.reducer
