import { TextField } from "@mui/material"
import { useRef, useState } from "react"
import axios from "axios"
import {clearNoteAction} from "../../../reducers/noteActionSlice"
import { useDispatch } from "react-redux"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import {useSnackbar} from "notistack"
import { updateNoteContent } from "../../../reducers/workspaceNoteSlice"

export default function EditNote({ note }) {
	const dispatch = useDispatch()
	const ref = useRef()
	const {enqueueSnackbar} = useSnackbar()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [content, setContent] = useState(note.content)

	useOnClickOutside(ref, () => handleSubmit())

	const handleSubmit = () => {
		const noteContentsChanged = content && content !== note.content

		if (noteContentsChanged) {
			setIsSubmitting(true)
			axios
				.put(`/api/workspace/${note.workspaceId}/note/${note.id}`, {
					...note,
					content,
				})
				.then(({ data }) => {
					dispatch(updateNoteContent(data))
				})
				.catch((e) => {
					console.error("FAILED TO EDIT NOTE: ", e)
					enqueueSnackbar("Failed to update the note, please try again", {variant: "error"})
				})
				.finally(() => {
					dispatch(clearNoteAction())
				})
		} else {
			dispatch(clearNoteAction())
		}
	}

	const handleChange = (e) => {
		setContent(e.target.value)
	}

	const keyPress = (e) => {
		if (e.key === "Enter" || e.keyCode === 13) {
			handleSubmit()
		}

		if(e.key === "Escape" || e.keyCode === 27){
			dispatch(clearNoteAction())
		}
	}

	const handleClick = (e) => {
		e.stopPropagation()
		e.preventDefault()
	}

	return (
		<TextField
			InputProps={{
				sx: { padding: "0px" },
				inputProps: {
					sx: { padding: "5px 10px" },
				},
			}}
			ref={ref}
			onClick={(e) => handleClick(e)}
			fullWidth
			autoFocus
			multiline
			onKeyDown={keyPress}
			onChange={(e) => handleChange(e)}
			value={content}
			disabled={isSubmitting}
			sx={{ backgroundColor: "white", borderRadius: "4px" }}
		/>
	)
}
