import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./reducers/userSlice"
import workspaceReducer from "./reducers/workspaceSlice"
import noteActionReducer from "./reducers/noteActionSlice"
import refreshRelayReducer from "./reducers/refreshRelaySlice"
import targetNoteReducer from "./reducers/targetNoteSlice"
import articleSearchReducer from "./reducers/articleSearchSlice"
import dynamicViewReducer from "./reducers/dynamicViewSlice"
import noteArticlesReducer from "./reducers/noteArticlesSlice"
import automatedSearchReducer from "./reducers/automatedSearchSlice"
import targetArticleReducer from "./reducers/targetArticleSlice"
import workingArticleNoteRelationReducer from "./reducers/workingArticleNoteRelationSlice"
import selectedArticlesReducer from "./reducers/selectedArticlesSlice"
import noteArticleTabReducer from "./reducers/noteArticleTabsSlice"
import noteArticleTabV2Reducer from "./reducers/noteArticleTabsV2Slice"
import articleSearchQueryReducer from "./reducers/articleSearchQuery"
import toolPanelViewReducer from "./reducers/toolPanelViewSlice"
import notificationPanelReducer from "./reducers/notificationPanelSlice"
import portalPanelViewReducer from "./reducers/portalPanelSlice"
import automatedSearchPanelViewReducer from "./reducers/automatedSearchViewSlice"
import automatedSearchPanelNavReducer from "./reducers/automatedSearchNavSlice"
import automatedSearchSortByReducer from "./reducers/automatedSearchSortBySlice"
import copyCitationsModalReducer from "./reducers/copyCitationsModalSlice"
import unassignedArticlesEventSlice from "./reducers/unassignedArticlesEventsSlice"
import moveNoteSlice from "./reducers/moveNoteSlice"
import workspaceSearchReducer from "./reducers/workspaceSearchSlice"
import sessionTimestampReducer from "./reducers/sessionTimestampSlice"
import notificationsReducer from "./reducers/notificationsSlice"
import citationImportSlice from "./reducers/citationImportSlice"
import freeTierTrackerSlice from "./reducers/freeTierTrackerslice"
import recentlyReviewedSlice from "./reducers/recentlyReviewedSlice"
import workspaceNoteSlice from "./reducers/workspaceNoteSlice"
import breadcrumbSlice from "./reducers/breadcrumbSlice"
import undoDeleteNoteSlice from "./reducers/undoDeleteNoteSlice"
import noteRestrictionsSlice from "./reducers/noteRestrictionsSlice"
import noteAttachmentsSlice from "./reducers/noteAttachmentsSlice"
import stripeDetails from "./reducers/stripeDetailsSlice"
import articleDragSlice from "./reducers/articleDragSlice"
import noteOrderSlice from "./reducers/noteOrderSlice"
import manageWorkspaceSlice from "./reducers/manageWorkspaceSlice"
import accountSettingsNavSlice from "./reducers/accountSettingsNavSlice"
import stripeInvoicesSlice from "./reducers/stripeInvoicesSlice"

import { defaultSocketMiddleware } from "./utils/reduxMiddleware/defaultSocketMiddleware"
import { notificationsSocketMiddleware } from "./utils/reduxMiddleware/notificationsSocketMiddleware"
import { defaultSocketService, notificationsSocketService, articlesSocketService, notesSocketService, noteRestrictionsService, noteAttachmentService } from "./context/socket.context"
import deletedArticlesReducer from "./reducers/deletedArticlesSlice"
import { createLogger } from "redux-logger"
import { articlesSocketMiddleware } from "./utils/reduxMiddleware/articlesSocketMiddleware"
import fetchUnassignedArticlesMiddleware from "./utils/reduxMiddleware/fetchUnassignedArticlesMiddleware"
import { notesSocketMiddleware } from "./utils/reduxMiddleware/notesSocketMiddleware"
import { noteRestrictionsMiddleware } from "./utils/reduxMiddleware/noteRestrictionsMiddleware"
import { noteAttachmentMiddleware } from "./utils/reduxMiddleware/noteAttachmentSocketMiddleware"

const logger = createLogger()

export default configureStore({
	reducer: {
		user: userReducer,
		workspace: workspaceReducer,
		noteAction: noteActionReducer,
		refreshRelay: refreshRelayReducer,
		targetNote: targetNoteReducer,
		articleSearch: articleSearchReducer,
		noteArticles: noteArticlesReducer,
		dynamicView: dynamicViewReducer,
		automatedSearch: automatedSearchReducer,
		targetArticle: targetArticleReducer,
		workingArticleNoteRelation: workingArticleNoteRelationReducer,
		selectedArticles: selectedArticlesReducer,
		noteArticleTabs: noteArticleTabReducer,
		noteArticleTabsV2: noteArticleTabV2Reducer,
		articleSearchQuery: articleSearchQueryReducer,
		toolPanelView: toolPanelViewReducer,
		portalPanelView: portalPanelViewReducer,
		automatedSearchPanelView: automatedSearchPanelViewReducer,
		automatedSearchPanelNav: automatedSearchPanelNavReducer,
		automatedSearchSortBy: automatedSearchSortByReducer,
		copyCitationsModal: copyCitationsModalReducer,
		unassignedArticlesEvent: unassignedArticlesEventSlice,
		moveNote: moveNoteSlice,
		workspaceSearch: workspaceSearchReducer,
		sessionTimestamp: sessionTimestampReducer,
		notificationPanelView: notificationPanelReducer,
		notifications: notificationsReducer,
		deletedArticles: deletedArticlesReducer,
		citationImport: citationImportSlice,
		freeTierTracker: freeTierTrackerSlice,
		recentlyReviewed: recentlyReviewedSlice,
		workspaceNotes: workspaceNoteSlice,
		breadcrumbSlice,
		undoDeleteNoteSlice,
		noteRestrictionsSlice,
		noteAttachmentsSlice,
		stripeDetails,
		articleDragSlice,
		manageWorkspaceSlice,
		accountSettingsNav: accountSettingsNavSlice,
		stripeInvoices: stripeInvoicesSlice,
		noteOrder: noteOrderSlice,
	},
	middleware: (getDefaultMiddleware) => {
		// Get the default middlewares
		let middlewares = getDefaultMiddleware()
	
		// Conditionally add the logger middleware
		if (process.env.REACT_APP_REDUX_LOGS === "on") {
			middlewares = middlewares.concat(logger)
		}
	
		// Add other custom middlewares as needed
		middlewares = middlewares.concat(
			defaultSocketMiddleware(defaultSocketService),
			notificationsSocketMiddleware(notificationsSocketService),
			articlesSocketMiddleware(articlesSocketService),
			notesSocketMiddleware(notesSocketService),
			noteRestrictionsMiddleware(noteRestrictionsService),
			fetchUnassignedArticlesMiddleware,
			noteAttachmentMiddleware(noteAttachmentService)
		)
	
		return middlewares
	},
})
