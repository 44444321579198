import FeatureBlock from "./FeatureBlock"
import FeaturesImage from "./FeaturesImage"
import { Grid, Stack } from "@mui/material"
import { useState } from "react"
import ChatIcon from "@mui/icons-material/Chat"
import CloudIcon from "@mui/icons-material/Cloud"
import MemoryIcon from "@mui/icons-material/Memory"
import SearchIcon from "@mui/icons-material/Search"
import ShareIcon from "@mui/icons-material/Share"

const features = [
	{
		title: "Describe",
		content: "Use our unique freeform hierarchy to describe the content of your articles and keep them organized in a way that is meaningful to you by adding articles to multiple descriptive topics that relate to their content. Instantly view all articles related to specific or broad subject matters.",
		icon: <ChatIcon fontSize="medium" />,
		imageLink: "/refzoom-headings.gif",
		active: true
	},
	{
		title: "Automate",
		content: "Automated PubMed searches allow you to quickly filter through new articles related to your subject matter and easily pull them into your library. Similarly, when articles appear that aren’t relevant to your research, exclude them and you’ll never have to see them again.",
		icon: <MemoryIcon fontSize="medium" />,
		imageLink: "/refzoom-automate.jpeg",
		active: false
	},
	{
		title: "Quick Search",
		content: "Quickly find specific articles from PubMed and import them directly into your library.",
		icon: <SearchIcon fontSize="medium" />,
		imageLink: "/refzoom-quicksearch.jpeg",
		active: false
	},
	{
		title: "Cloud Storage & Annotation",
		content: "Upload your PDF’s, with the ability to highlight and annotate from anywhere.",
		icon: <CloudIcon fontSize="medium" />,
		imageLink: "/refzoom-cloudstorage.jpeg",
		active: false
	},
	{
		title: "Share",
		content: "Connect with your colleagues and easily share citations, full-text PDF's, and notes.",
		icon: <ShareIcon fontSize="medium" />,
		imageLink: "/refzoom-sharing.jpeg",
		active: false
	}

]

const FeaturesSection = () => {
	const [ selectedFeature, setSelectedFeature ] = useState("/refzoom-headings.gif")
	const [ active, setActive ] = useState("Describe")

	const callback = (link, title) => {
		setSelectedFeature(link)
		setActive(title)
	}

	return (
		<Grid container spacing={4}>
			<Grid item md={6}>
				<FeaturesImage selectedFeature={selectedFeature}></FeaturesImage>
			</Grid>

			<Grid item md={6}>
				<Stack
					direction="column"
					spacing={2}
				>
					{features.map((feature) => {
						return (
							<FeatureBlock 
								key={feature.title}
								featureContent={feature} 
								active={active} 
								callback={callback}></FeatureBlock>
						)
					})}
				</Stack>
			</Grid>
		</Grid>
	)
}

export default FeaturesSection