import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import axios from "axios"
import { useSnackbar } from "notistack"

export default function InvoiceReceiptRecipientModal({ handleClose, open, setRecipientListData }) {
	const { enqueueSnackbar } = useSnackbar()

	const addRecipient = async (email) => {
		try {
			const { data } = await axios.post("/api/user/recipients", { email })
			setRecipientListData((prevState) => ({
				recipients: [...prevState.recipients, data],
				isLoading: false
			}))
			enqueueSnackbar("Recipient added successfully", {
				variant: "success",
				autoHideDuration: 2000,
			})
			return data
		} catch (error) {
			enqueueSnackbar("Failed to add recipient", {
				variant: "error",
				autoHideDuration: 2000,
			})
			console.error("Error adding recipient:", error.response ? error.response.data : error.message)
			setRecipientListData((prevState) => ({
				...prevState,
				isLoading: false
			}))
		}
	}

	return (
		<Dialog
			open={open}
			onClose={() => handleClose(false)}
			PaperProps={{
				component: "form",
				onSubmit: async (event) => {
					event.preventDefault()
					event.stopPropagation() // Prevent the event from bubbling up to the parent form
					event.preventDefault()
					const formData = new FormData(event.currentTarget)
					const formJson = Object.fromEntries((formData).entries())
					const email = formJson.email
					console.log(email)
					await addRecipient(email)
					handleClose()
				},
			}}
		>
			<DialogTitle>Additional Receipt Recipients</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Recipients will receive an email with a link to the receipt after each invoice is paid.
				</DialogContentText>
				<TextField
					autoFocus
					required
					margin="dense"
					id="name"
					name="email"
					label="Email Address"
					type="email"
					fullWidth
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose(false)}>Cancel</Button>
				<Button type="submit">Submit</Button>
			</DialogActions>
		</Dialog>
	)
}