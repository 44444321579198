import Article from "./article/Article"
import {
	CircularProgress, IconButton,
	Tooltip,
} from "@mui/material"
import {useRef, useState, memo} from "react"
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import {useSnackbar} from "notistack"
import { useDispatch, useSelector } from "react-redux"
import { setDragItem } from "../../reducers/articleDragSlice"
import { selectSelectedArticles } from "../../reducers/selectedArticlesSlice"
import { dragType } from "../../reducers/articleDragSlice"

const ImportedArticle = ({
	articleWorkspace,
	isUnassigned,
	index,
	fromAutoSearch
}) => {
	const dispatch = useDispatch()
	const { article } = articleWorkspace
	const { getAccessTokenSilently } = useAuth0()
	const {enqueueSnackbar} = useSnackbar()
	const attachmentInputRef = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	const [fullTextId, setFullTextId] = useState(articleWorkspace.fullTextFileId)
	const [articleFiles, setArticleFiles] = useState(articleWorkspace.files || [])
	const [isDraggingFile, setIsDraggingFile] = useState(false)
	const selectedArticles = useSelector(selectSelectedArticles)
	const draggingArticleCount = Object.keys(selectedArticles).length ?  Object.keys(selectedArticles).length : 1
	const [isDragging, setIsDragging] = useState(false)
	const handleDragStart = (event) => {
		setIsDragging(true)
		// dispatch anywhere in this drag handler causes a visual lag in firefox
		// need to request animation frame to perform dispatch to avoid visual lag
		requestAnimationFrame(() => {
			dispatch(setDragItem({item: article, type: dragType.ARTICLE }))
		})

		event.dataTransfer.effectAllowed = "move"
		event.dataTransfer.setData("text/plain", `note-attachment-${article.id}`)
	
		// Create a new element to be used as the drag image
		const dragImage = document.getElementById("custom-drag-container")
		const dragText = document.getElementById("custom-drag-text")
		
		if (dragImage) {
			dragText.textContent = `Dragging ${draggingArticleCount} ${draggingArticleCount > 1 ? "articles" : "article"}` // Set the text content to the article ID
			event.dataTransfer.setDragImage(dragImage, 0, 0) // Center the drag image under the cursor
	
		} else {
			console.error("Drag UI Not Located")
		}

		event.currentTarget.addEventListener("dragend", () => {
			setIsDragging(false)
		})
	}

	const openPDF = () => {
		getAccessTokenSilently().then((token) => {
			window.open(
				`/viewer?workspaceId=${articleWorkspace.workspaceId}&articleId=${articleWorkspace.articleId}&token=${token}`
			)
		})
	}

	const variant = "imported"

	// Define base styles
	const baseStyle = {
		height: "100%",
		minWidth: "40px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		color: "#FFF",
		opacity: 1,
		cursor: "pointer",
		position: "relative"
	}

	const overlayStyle = {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		zIndex: 2, 
		backgroundColor: "rgba(255, 255, 255, 0)", 
	}
	
	// Apply variant-specific styles
	let variantStyle = {}

	if (variant === "imported") {
		variantStyle = {
			backgroundColor: "rgba(80, 183, 113, 0.3)",
			cursor: "pointer",
		}
	}

	// Merge styles
	const style = { ...baseStyle, ...variantStyle }

	const uploadFulltextFile = (fileData) => {
		setIsLoading(true)
		axios
			.put(
				`/api/workspace/${articleWorkspace.workspaceId}/article/${articleWorkspace.articleId}/fulltext`,
				fileData
			)
			.then(({ data }) => {
				setFullTextId(data.fullTextFileId)
				setArticleFiles(data.files)
				enqueueSnackbar("Successfully uploaded a full-text manuscript citation", {variant: "success", autoHideDuration: 5000})
			})
			.catch((error) => {
				console.error("FULL-TEXT PDF UPLOAD FAILURE: ", error)
				enqueueSnackbar("Failed to upload full-text manuscript to citation", {variant: "error", autoHideDuration: 5000})
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const uploadAttachmentFile = (fileData) => {
		setIsLoading(true)
		axios
			.put(
				`/api/workspace/${articleWorkspace.workspaceId}/article/${articleWorkspace.articleId}/attachment`,
				fileData
			)
			.then(({ data }) => {
				setArticleFiles(data.files)
				enqueueSnackbar("Successfully uploaded file attachment to citation", {variant: "success", autoHideDuration: 5000})
			})
			.catch((error) => {
				console.error("ATTACHMENT UPLOAD FAILURE: ", error)
				enqueueSnackbar("Failed to upload file attachment to citation", {variant: "error", autoHideDuration: 5000})
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	/**
	 * This handles attaching full-text PDFs to the article when
	 * a PDF is dragged from the client"s OS desktop and dropped
	 * on the article.
	 *
	 *  This is NOT handling attaching articles to notes in the
	 *  note tree by grabbing the ArticleSideBar and dropping it
	 *  on a note. That is handled by:
	 *  ../workspace/NoteTreeV2.handleDrop()
	 **/

	const onDragOver = (event) => {
		event.stopPropagation()
		event.preventDefault()
		setIsDraggingFile(true)
	}

	const onDragLeave = (event) => {
		event.stopPropagation()
		event.preventDefault()
		setIsDraggingFile(false)
	}

	const onDrop = (event) => {
		event.stopPropagation()
		event.preventDefault()
		const isMoz =  !!event.dataTransfer.mozCursor
		setIsDraggingFile(false)
		if ((!isMoz && event.dataTransfer?.items?.length > 1) ||  (isMoz && event.dataTransfer?.items?.length > 2)  ) {
			enqueueSnackbar("You can only upload one attachment or full-text manuscript at a time", {variant: "error", autoHideDuration: 5000})
		} else {
			//TODO: check to see if dropped file is PDF by looking at dataTransfer.items[0].type === "application/pdf"
			const fileIsPDF = event.dataTransfer.items[0].type === "application/pdf"
			if(fileIsPDF) {
				const file = event.dataTransfer.items[0].getAsFile()
				const formData = new FormData()
				formData.append("userfile", file)
				uploadFulltextFile(formData)
			} else {
				// Retrieve the data set during the drag event
				const data = event.dataTransfer.getData("text/plain")
				// Check if the data contains the prefix 'note-attachment-'
				if (data.startsWith("note-attachment-")) {
					// this is a note attachment drop, do nothing!
					return
				}
				// all else fails, display an error
				enqueueSnackbar("You can only set .PDF files as a citation's full-text manuscript. Please check your file and try again", {variant: "error", autoHideDuration: 5000})
			}
		}
	}

	const handleUploadButtonClick = () => {
		//simulates click event on invisible file input, which opens browser file explorer
		//uploading through the browser modifies the value of the input, which triggers onChange event and onAttachmentFileInputChange()
		attachmentInputRef.current.click()
	}

	const onAttachmentFileInputChange = () => {
		const files = attachmentInputRef.current.files
		if(files && files.length > 1) {
			enqueueSnackbar("You can only upload one attachment or full-text manuscript at a time", {variant: "error", autoHideDuration: 5000})
		} else if (files && files.length === 1) {
			const formData = new FormData()
			formData.append("userfile", files[0])
			uploadAttachmentFile(formData)
		} else {
			enqueueSnackbar("Something went wrong with dropping file", {variant: "error", autoHideDuration: 5000})
		}
		//reset invisible form
		attachmentInputRef.current.value = ""
	}

	return (
		<div
			onDragOver={onDragOver}
			onDrop={onDrop}
			onDragLeave={onDragLeave}
			className="DropZone"
		>
			<Article
				fromAutoSearch={fromAutoSearch}
				article={article}
				workspaceId={articleWorkspace.workspaceId}
				isImported={true}
				isUnassigned={isUnassigned}
				articleWorkspace={articleWorkspace}
				index={index}
				fullText={fullTextId}
				openFullText={()=> openPDF()}
				files={articleFiles}
				isDragFilesActive={isDraggingFile}
				setFullTextId={setFullTextId}
				setArticleFiles={setArticleFiles}
				handleUploadButtonClick={handleUploadButtonClick}
			>
				<Tooltip
					title={isDragging ? "" : "Drag to attach to note"}
					followCursor
					placement={"left"}
					enterDelay={1000}
					sx={{ display: isDragging ? "none" : "initial" }} // this doesn't work after replacing DnD Kit drag values with  local state
				>
					<div
						style={style}
					>

						<div 
							draggable="true"
							onDragStart={handleDragStart}
							style={overlayStyle}
						/>

						<IconButton
							onClick={() => null}
							variant={"grab-bar-imported"}
						>
							{isLoading && <CircularProgress size={"20px"} />}
						</IconButton>
					</div>
				</Tooltip>

			</Article>
			<form style={{display: "none"}}>
				<input
					type="file"
					ref={attachmentInputRef}
					onChange={onAttachmentFileInputChange}
				/>
			</form>
		</div>
	)
}

export default memo(ImportedArticle)