import {Box, Switch, Card, Typography, 	useTheme} from "@mui/material"

export default function NoteRestrictionBreadcrumb({note, selectedNotes, setSelectedNotes}) {
	const isSelected = selectedNotes.filter(selectedNote => selectedNote.id === note.id).length > 0
	const theme = useTheme()
	const toggleSelected = () => {
		setSelectedNotes(prevNotes => {
			return isSelected ? prevNotes.filter(n => n.id !== note.id) : [...prevNotes, note]
		})
	}
	return (
		<Box sx={{display: "flex", gap: "1rem", alignItems: "center", width: "100%", justifyContent: "flex-start"}}>
			<Switch
				checked={isSelected}
				onChange={toggleSelected}
				name={note}
			/>
			<Box sx={{display: "flex", width: "100%"}}>
				<Card
					onClick={toggleSelected}
					variant={"note"}
					sx={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						padding: "0.5rem",
						color: "RBOneFont.main",
						backgroundColor: "notes.crumbsBackground",
						borderLeft: "5px solid",
						borderColor: "notes.dark",
						lineHeight: "1rem",
						fontSize: "1rem",
						...(isSelected && {
							borderColor: `${theme.palette.primary.main}`,
						}),
					}}
				>
					<Typography
						variant={"body1"}
						sx={{
							marginBottom: 0,
							wordBreak: "break-word",
							fontSize: "1rem",
						}}
						paragraph
					>{note.content}</Typography>
				</Card>
			</Box>
		</Box>
	)
}