import { setWorkspace } from "../../reducers/workspaceSlice"
import { concatUnassignedArticles, removeUnassignedArticles } from "../../reducers/unassignedArticlesEventsSlice"
import { getImportedArticleCountForWorkspace } from "../../reducers/freeTierTrackerslice"
import { pushAttachedArticlesToNoteTab } from "../../reducers/noteArticleTabsV2Slice" 
export const articlesSocketMiddleware = (socket) => {
	// eslint-disable-next-line no-unused-vars
	return storeAPI => {
		socket.on("NEW_UNASSIGNED_ARTICLES", async (unassignedArticles) => {
			storeAPI.dispatch(concatUnassignedArticles(unassignedArticles))
			await storeAPI.dispatch(getImportedArticleCountForWorkspace())
		})
		// "REMOVE_UNASSIGNED_ARTICLE"
		socket.on("REMOVE_UNASSIGNED_ARTICLES", (unassignedArticles) => {
			storeAPI.dispatch(removeUnassignedArticles(unassignedArticles))

		})
		// "DELETE_ARTICLES"
		socket.on("DELETE_ARTICLES", () => {
			storeAPI.dispatch(getImportedArticleCountForWorkspace())

		})
		// "ATTACH_NOTE_TO_ARTICLE"
		socket.on("ATTACH_NOTE_TO_ARTICLE", (payload) => {
			storeAPI.dispatch(pushAttachedArticlesToNoteTab(payload))

		})
		return next => action => {
			if (action.type === "WS_CONNECT") {
				socket.connect()
	
			}
	
			if (action.type === "WS_DISCONNECT") {
				socket.disconnect()
			}
	
			if (action.type === "WS_EMIT") {
				const { event, payload } = action
				socket.emit(event, payload)
			}
	
			if (action.type === setWorkspace.type) {
				const workspaceId = action.payload.id
				socket.emit("SUBSCRIBE_TO_ARTICLES", workspaceId)
			}
	
	
			return next(action)
		}
	}
}