import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { workspaceSlice } from "./workspaceSlice"
import { deletedArticlesSlice } from "./deletedArticlesSlice"
import axios from "axios"

export const fetchArticleWorkspaces = createAsyncThunk(
	"recentlyReviewed/fetchArticleWorkspaces",
	async (_, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const articleList = state.recentlyReviewed.articles[workspaceId] || []
		const articleWorkspaceIds = articleList.map(x => x.articleId)
		const queryString = articleWorkspaceIds.map(id => `articleIds=${id}`).join("&")
		if (queryString.length <= 0) {
			return []
		}
		try {
			const url = `/api/workspace/${workspaceId}/articleWorkspaces?${queryString}`
			const response = await axios.get(url)
			return response.data
		} catch (error) {
			rejectWithValue(error?.response?.message)
		}
	}
)


export const recentlyReviewedSlice = createSlice({
	name: "recentlyReviewed",
	initialState: {
		articles: {},
		currentWorkspaceId: -1,
	},
	reducers: {
		setArticles: (state, action) => {
			state.articles = action.payload
		},
		addArticle: (state, action) => {
			const recentlyViewedList = state.articles[state.currentWorkspaceId] || []
			const recentlyViewedListWithoutNewArticle = recentlyViewedList.filter(article => article.articleId !== action.payload.articleId)
			const updatedRecentlyViewedList = [action.payload, ...recentlyViewedListWithoutNewArticle]
			state.articles[state.currentWorkspaceId] = updatedRecentlyViewedList
		},
		clearArticles: (state) => {
			state.articles[state.currentWorkspaceId] = []
		},
		updateArticle: (state, action) => {
			const importedArticle = action.payload
			const recentlyViewedList = state.articles[state.currentWorkspaceId] || []
			const newList = recentlyViewedList.map(oldArticle => {
				if (oldArticle.articleId === importedArticle.articleId) {
					return importedArticle
				} else {
					return oldArticle
				}
			})
			state.articles[state.currentWorkspaceId] = newList
		}
	},
	extraReducers: (builder) => {
		builder.addCase(workspaceSlice.actions.setWorkspace, (state, action) => {
			const workspaceId = action.payload.id
			state.currentWorkspaceId = workspaceId
		})
		builder.addCase(
			fetchArticleWorkspaces.fulfilled,
			(state, action) => {
				const incomingWorkspaceArticles = action.payload
				const currentArticles = state.articles[state.currentWorkspaceId] || []
				
				const newArticles = currentArticles.map(article => {
					const match = incomingWorkspaceArticles.find(incomingArticle => incomingArticle.articleId === article.articleId)
					return match || article
				})
				state.articles[state.currentWorkspaceId] = newArticles
			}
		)
		builder.addCase(deletedArticlesSlice.actions.addDeletedArticle, (state, action) => {
			const deletedArticle = action.payload
			const recentlyViewedList = state.articles[state.currentWorkspaceId] || []
			const newList = recentlyViewedList.map(oldArticle => {
				if (oldArticle.articleId === deletedArticle.articleId) {
					return {
						article: oldArticle.article,
						...deletedArticle
					}
				} else {
					return oldArticle
				}
			})

			state.articles[state.currentWorkspaceId] = newList
			// const articleList = cloneDeep(recentlyViewedList)
			// const index = lookup(action.payload, articleList)
			// const articleToUpdate = articleList[index]
			// if (articleToUpdate) {
			// 	const rawArticle = articleToUpdate.article
			// 	const newArticle = action.payload
			// 	newArticle.article = rawArticle
			// 	articleList[index] = newArticle
			// 	state.articles[state.currentWorkspaceId]= articleList
			// }
		})
	}
})

export const { addArticle, setArticles, clearArticles, updateArticle } =
	recentlyReviewedSlice.actions

const recentlyReviewedState = state => state.recentlyReviewed

export const selectRecentlyViewedArticles = (state) => {
	return recentlyReviewedState(state).articles[recentlyReviewedState(state).currentWorkspaceId] || []
}

export default recentlyReviewedSlice.reducer
