import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogContentText, 
	DialogActions,
	Button 
} from "@mui/material"

export default function AdjustSeatsModal({ onCancelClick, open, onActionClick, isDeleting, isPurchasing }) {
	return (
		<Dialog
			open={open}
			sx={{ textAlign: "left" }}
		>
			<DialogTitle>
				{isPurchasing && "Add Additional Seat?"}
				{isDeleting && "Are you sure you want to remove a seat?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText variant="body1" paragraph gutterBottom>
					{isPurchasing && "Adding an additional seat will increase your invoice. This charge will be prorated and reflected on your next invoice."}
					{isDeleting && "You can re-add seats at any time. The cost of this seat will be removed from your bill, and your next invoice will prorate the charge."}
				</DialogContentText>
					
				<DialogActions sx={{ flexDirection: "row", gap: "1em" }} disableSpacing>
					<Button
						variant="contained"
						color={isPurchasing ? "primary" : "error"}
						onClick={onActionClick}
						sx={{
							width: "15em", //arbitrary width to get size difference in mock-ups
						}}
					>
						{isPurchasing && "Add Seat"}
						{isDeleting && "Remove Seat"}
					</Button>
					<Button
						variant={"outlined"}
						color={isPurchasing ? "error" : "primary"}
						onClick={onCancelClick}
					>
						Cancel
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}
