import { useEffect } from "react"

const useDragContainer = () => {
	useEffect(() => {
		// Function to create and style the drag container
		const createDragContainer = () => {
			const dragContainer = document.createElement("div")
			dragContainer.style.position = "absolute"
			dragContainer.style.top = "-100px" // Position it off-screen
			dragContainer.style.left = "0" // Keep it at the start of the body content
			dragContainer.style.display = "flex"
			dragContainer.style.alignItems = "center"
			dragContainer.style.borderRadius = "4px"
			dragContainer.style.backgroundColor = "rgba(255,255,255, 1.0)"
			dragContainer.style.opacity = "1"
			dragContainer.style.border = "1px solid rgb(204, 204, 204)"
			dragContainer.style.lineHeight = "1.5em"
			dragContainer.style.padding = "2px"
			dragContainer.id = "custom-drag-container"
			return dragContainer
		}

		// Function to create and style the SVG element
		const createSVG = () => {
			const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg")
			svgElement.setAttribute("class", "MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-b9sjjk-MuiSvgIcon-root")
			svgElement.setAttribute("focusable", "false")
			svgElement.setAttribute("aria-hidden", "true")
			svgElement.setAttribute("viewBox", "0 0 24 24")
			svgElement.setAttribute("data-testid", "DescriptionRoundedIcon")
			svgElement.style.width = "32px"
			svgElement.style.marginRight = "8px"
			svgElement.style.fill = "rgba(80, 183, 113, 0.6)"

			// Create SVG path element
			const pathElement = document.createElementNS("http://www.w3.org/2000/svg", "path")
			pathElement.setAttribute("d", "M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z")
			svgElement.appendChild(pathElement)

			return svgElement
		}

		// Function to create and style the text element
		const createTextElement = () => {
			const textElement = document.createElement("div")
			textElement.style.fontSize = "14px"
			textElement.style.width = "fit-content"
			textElement.style.paddingTop = "0"
			textElement.style.marginTop = "0"
			textElement.style.paddingBottom = "0"
			textElement.style.marginBottom = "0"
			textElement.textContent = "Your text content goes here..."
			textElement.id = "custom-drag-text"
			textElement.style.marginRight = "8px"
			return textElement
		}

		// Create drag container and elements
		const dragContainer = createDragContainer()
		const svgElement = createSVG()
		const textElement = createTextElement()

		// Append SVG and text elements to the drag container
		dragContainer.appendChild(svgElement)
		dragContainer.appendChild(textElement)

		// Append drag container to the body
		document.body.appendChild(dragContainer)

		return () => {
			// Remove drag container from the body when component unmounts
			document.body.removeChild(dragContainer)

		}
	})
}

export default useDragContainer
