import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { openToPath } from "./workspaceNoteSlice"

export const fetchLastDeletedNote = createAsyncThunk(
	"undoDeleteNoteSlice/fetchLastDeletedNote",
	async (_, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) rejectWithValue("No valid workspace id.")

		try {
			const { data: lastDeletedNote } = await axios.get(`/api/workspace/${workspaceId}/note-deleted`)
			return lastDeletedNote
		} catch (error) {
			return rejectWithValue(error?.response?.message || "Error fetching last deleted note")
		}
	}
)

export const undoDeleteNote = createAsyncThunk(
	"undoDeleteNoteSlice/undoDeleteNote",
	async (deletedNote, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) rejectWithValue("No valid workspace id.")
		try {
			const {data: relayPath} = await axios.put(`/api/workspace/${workspaceId}/note-deleted/${deletedNote.id}`, {
				...deletedNote,
			})
			// if parent is in tree and no children, fetch children
			dispatch(openToPath({path: deletedNote.path, note: deletedNote, insertTarget: true}))
			
			return {relayPath, deletedNote}
		} catch(error) {
			return rejectWithValue(error?.response?.message || "Error undoing the last deleted note. Please try again later.")

		}
	}
)

export const undoDeleteNoteSlice = createSlice({
	name: "undoDeleteNoteSlice",
	initialState: {
		deletedNote: null,
		isModalOpen: false,
		isLoading: false,
		fetchUndoNoteError: null,
		undoNoteError: null,
		successMessage: null
	},
	reducers: {
		setDeletedNote: (state, action) => {
			state.deletedNote = action.payload
		},
		setIsModalOpen: (state, action) => {
			const isOpen = action.payload
			if (!isOpen) {
				state.deletedNote = null
			}
			state.isModalOpen = action.payload
		},
		clearFetchNoteError: (state) => {
			state.fetchUndoNoteError = null
		},
		clearUndoNoteError: (state) => {
			state.undoNoteError = null
		},
		clearSuccessMessage: (state) => {
			state.successMessage = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLastDeletedNote.fulfilled, (state, action) => {
				state.deletedNote = action.payload
				state.isLoading = false
				state.isModalOpen = true
			})
			.addCase(fetchLastDeletedNote.rejected, (state, action) => {
				state.fetchUndoNoteError = action.payload
			})
			.addCase(undoDeleteNote.fulfilled, (state) => {
				state.deletedNote = null
				state.successMessage = "Your note has been restored"
				state.isModalOpen = false
			})
	}
}) 
export const { setDeletedNote, setIsModalOpen, clearFetchNoteError, clearUndoNoteError, clearSuccessMessage } = undoDeleteNoteSlice.actions

// Selector to get the notes object from the state
export const selectDeletedNote = (state) => state.undoDeleteNoteSlice.deletedNote
export const selectIsModalOpen = (state) => state.undoDeleteNoteSlice.isModalOpen
export const selectIsLoading = (state) => state.undoDeleteNoteSlice.isLoading
export const selectFetchUndoNoteError = (state) => state.undoDeleteNoteSlice.fetchUndoNoteError
export const selectUndoNoteError = (state) => state.undoDeleteNoteSlice.undoDeleteNote
export const selectSuccessMessage = (state) => state.undoDeleteNoteSlice.successMessage

export default undoDeleteNoteSlice.reducer