import {
	Box,
	CircularProgress,
	Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {
	AUTOMATED_SEARCH_PANEL_VIEW,
	getAutomatedSearchList,
	selectAutomatedSearchPanelView,
	selectAutomatedSearchSelectedSearch,
	selectIsLoading,
	selectSearches,
	setAutomatedSearchPanelView,
} from "../../../reducers/automatedSearchViewSlice"
import { resetAutomateSearchPanelNav, } from "../../../reducers/automatedSearchNavSlice"
import { useEffect, useState } from "react"
import {AutomatedSearchForm} from "./AutomatedSearchForm"
import {AutomatedSearchArticles} from "./AutomatedSearchArticles"
import AutomatedSearchList from "./AutomatedSearchList"

export function AutomatedSearchPanel({ workspace, unassignedArticleIds }) {
	console.log("Attach Link - Automated Search Panel")
	const view = useSelector(selectAutomatedSearchPanelView)
	const selectedSearch = useSelector(selectAutomatedSearchSelectedSearch)
	const dispatch = useDispatch()
	const [searchToUpdate, setSearchToUpdate] = useState(null)
	const searches = useSelector(selectSearches)
	const isLoading = useSelector(selectIsLoading)

	useEffect(() => {
		dispatch(setAutomatedSearchPanelView(AUTOMATED_SEARCH_PANEL_VIEW.SEARCH_LIST))
		getSearches()
	}, [workspace.id])

	useEffect(() => {
		if (view !== AUTOMATED_SEARCH_PANEL_VIEW.ARTICLE_LIST && selectedSearch) {
			dispatch(resetAutomateSearchPanelNav())
		}

		if (view === AUTOMATED_SEARCH_PANEL_VIEW.SEARCH_LIST) {
			setSearchToUpdate(null)
		}
	}, [view])

	const getSearches = () => {
		dispatch(getAutomatedSearchList())
	}

	if (isLoading) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "200px",
				}}
			>
				<CircularProgress />
				<Typography>
						One moment, we are loading your automated searches!
				</Typography>
			</Box>
		)
	} else if (view === AUTOMATED_SEARCH_PANEL_VIEW.SEARCH_LIST) {
		return (
			<AutomatedSearchList
				searches={searches}
				getSearches={getSearches}
				workspaceId={workspace.id}
				setSearchToUpdate={setSearchToUpdate}
			/>
		)
	} else if (view === AUTOMATED_SEARCH_PANEL_VIEW.AUTOMATED_SEARCH_FORM) {
		return (
			<AutomatedSearchForm
				workspaceId={workspace.id}
				searchToUpdate={searchToUpdate}
				setSearchToUpdate={setSearchToUpdate}
				getSearches={getSearches}
			/>
		)
	} else if (
		view === AUTOMATED_SEARCH_PANEL_VIEW.ARTICLE_LIST &&
		selectedSearch
	) {
		return (
			<AutomatedSearchArticles
				workspaceId={workspace.id}
				selectedSearch={selectedSearch}
				unassignedArticleIds={unassignedArticleIds}
			/>
		)
	}
}