import { DYNAMIC_PANEL_TAB_INDEX_MAP} from "../../reducers/dynamicViewSlice"

export function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tab-panel"
			style={{ minWidth: "100%", maxHeight: "100%", overflowY: "hidden" }}
			hidden={value !== index}
			id={`dynamic-tab-panel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{children}
		</div>
	)
}

export function NavPanel(props) {
	const { children, value, index } = props

	const isAutomatedSearchPanel = index == DYNAMIC_PANEL_TAB_INDEX_MAP.AUTOMATED_SEARCH
	const isOneTimeSearchPanel = index == DYNAMIC_PANEL_TAB_INDEX_MAP.ARTICLE_SEARCH

	const flexStyles = isAutomatedSearchPanel && (value === index) ? {
		display: "flex" ,
		justifyContent: "space-between",
		alignItems: "center",
	} : {}

	const flexHeightStyles = isOneTimeSearchPanel && (value === index) ? {
		maxHeight: "unset",
		minHeight: "40px",
		overflowY: "unset"
	} : {}

	return (
		<div
			role="tab-panel"
			style={{
				minWidth: "100%", 
				maxHeight: "40px",
				overflowY: "hidden",
				...flexStyles,
				...flexHeightStyles
			}}
			hidden={value !== index}
			id={`dynamic-tab-panel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{children}
		</div>
	)
}
