import { Button, TextField, Box } from "@mui/material"
import { useState } from "react"
import axios from "axios"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setUser } from "../reducers/userSlice"
import { useSnackbar } from "notistack"

export default function RegistrationForm() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const { enqueueSnackbar } = useSnackbar()
	const { search } = useLocation()
	const queryParams = new URLSearchParams(search)
	const pricing = queryParams.get("pricing")
	const keyId = queryParams.get("key")
	const registered = queryParams.get("registered")
	const handleSubmit = () => {
		if (!firstName && !lastName) {
			enqueueSnackbar("First name and last name are required", {
				variant: "error",
				autoHideDuration: 2000,
			})
			return
		}
		if (!firstName) {
			enqueueSnackbar("First name is required", {
				variant: "error",
				autoHideDuration: 2000,
			})
			return
		}
		if (!lastName) {
			enqueueSnackbar("Last name is required", {
				variant: "error",
				autoHideDuration: 2000,
			})
			return
		}
		if((pricing === "key" || pricing === "key-upgrade") && keyId) {
			axios
				.post("/api/user/create-invited", {
					firstName,
					lastName,
					keyUUID: keyId
				})
				.then(({data: user}) => {
					dispatch(setUser(user))
					navigate("/dashboard")
				})
		} else {
			axios
				.put("/api/user/register", {
					firstName,
					lastName,
					createNewCustomer: registered !== "true",
				})
				.then(({ data: user }) => {
					if(pricing === "free") {
						//manually set isSubscribing
						axios.patch("/api/user/issubscribing/false")
							.then(({data: updatedFreeUser}) => {
								dispatch(setUser(updatedFreeUser)).then(() => navigate("/dashboard"))
							})
					} else if (pricing === "key") {
						//isSubscribing gets set to "false" in server-side service method
						dispatch(setUser(user)).then(() => navigate("/dashboard"))
					} else {
						//isSubscribing will be updated further along in the subscription flow
						dispatch(setUser(user))
						navigate("/prices" + (pricing ? `?pricing=${pricing}` : ""))
					}
				})
		}
	}

	return (
		<Box sx={{display: "flex", flexDirection: "column", gap: "24px", width: "100%"}}>
			<TextField
				fullWidth
				required
				className="tunedTextField"
				id="firstname"
				label="First Name"
				onChange={(e) => setFirstName(e.target.value)}
				value={firstName}
			/>
			<TextField
				fullWidth
				required
				className="tunedTextField"
				id="lastname"
				label="Last Name"
				onChange={(e) => setLastName(e.target.value)}
				value={lastName}
			/>
			<Button
				sx={{ width: "100%", fontSize: "1rem", lineHeight: "1.18rem", paddingY: "12px" }}
				variant={"contained"}
				onClick={handleSubmit}
			>
				Next
			</Button>
		</Box>
	)
}
