import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchNoteAttachmentBreadcrumbs } from "./noteAttachmentsSlice"
import { setWorkingAttachmentRelation } from "./workingArticleNoteRelationSlice"

const initialValue = null

export const setTargetObject = createAsyncThunk(
	"targetArticle/setTargetObject",
	async ({articleId, attachment}, { dispatch, rejectWithValue }) => {
		try {
			if (articleId) {
				dispatch(setTargetArticle(articleId)) // Directly dispatching the action to set target article
			} else if (attachment){
				await dispatch(fetchNoteAttachmentBreadcrumbs({attachment}))
				dispatch(setTargetArticle("attachment/" + attachment.id))
				dispatch(setWorkingAttachmentRelation({attachmentId: attachment.id}))
			}
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)


export const targetArticleSlice = createSlice({
	name: "targetArticle",
	initialState: initialValue,
	reducers: {
		setTargetArticle: (state, action) => {
			return action.payload
		},
		clearState: () => {
			return null
		}
	},
})

export const { setTargetArticle, clearState } = targetArticleSlice.actions

export const selectTargetArticle = (state) => state.targetArticle

export default targetArticleSlice.reducer
