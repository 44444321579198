import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const initialValue = {
	count: 0,
	articles: [],
	isLoading: false,
	query: "",
	page: 1,
	scrollY: 0,
}

export const noteArticleSlice = createSlice({
	name: "noteArticles",
	initialState: {
		value: initialValue,
	},
	reducers: {
		setSearchResults: (state, action) => {
			state.value.count = action.payload.count
			state.value.articles = action.payload.articles
			state.value.query = action.payload.query
			state.value.scrollY = 0
		},
		addArticles: (state, action) => {
			state.value.articles = state.value.articles.concat(action.payload)
		},
		incrementPage: (state) => {
			state.value.page += 1
		},
		resetSearch: (state) => {
			state.value = initialValue
		},
		setSearch: (state, action) => {
			state.value = action.payload
		},
		setLoading: (state, action) => {
			state.value.isLoading = action.payload
		},
		setScrollY: (state, action) => {
			state.value.scrollY = action.payload
		},
	},
})

export const {
	setSearchResults,
	addArticles,
	incrementPage,
	resetSearch,
	setLoading,
	setScrollY,
} = noteArticleSlice.actions

export const selectNoteArticles = (state) => state.noteArticles.value

export const setNoteArticlesAsync =
	(workspaceId, noteId) => async (dispatch) => {
		dispatch(resetSearch())
		dispatch(setLoading(true))

		const uri = `/api/workspace/${workspaceId}/note/${noteId}/article`
		await axios
			.get(uri)
			.then(({ data: results }) => {
				dispatch(
					setSearchResults({
						count: results.length,
						articles: results,
						query: "",
					})
				)
			})
			.finally(() => {
				dispatch(setLoading(false))
			})
	}

export const setImportedArticlesNonSearch = (articles) => async (dispatch) => {
	dispatch(resetSearch())
	dispatch(
		setSearchResults({ count: articles.length, articles: articles, query: "" })
	)
}

export const submitImportedArticleSearch =
	(workspaceId, query) => async (dispatch) => {
		dispatch(resetSearch())
		dispatch(setLoading(true))

		const uri = `/api/workspace/${workspaceId}/article?search=${query}&page=1&imported=1&pubmed=0`
		await axios
			.get(uri)
			.then(({ data: results }) => {
				dispatch(
					setSearchResults({ count: results.length, articles: results, query })
				)
				dispatch(incrementPage())
			})
			.finally(() => {
				dispatch(setLoading(false))
			})
	}

export const nextPage = (workspaceId, query, page) => async (dispatch) => {
	dispatch(setLoading(true))

	const uri = `/api/workspace/${workspaceId}/article?search=${query}&page=${page}&imported=1&pubmed=0`
	await axios
		.get(uri)
		.then(({ data: results }) => {
			dispatch(addArticles(results.articles))
			dispatch(incrementPage())
		})
		.finally(() => {
			dispatch(setLoading(false))
		})
}

export default noteArticleSlice.reducer
