import {
	AppBar,
	Box, Stack, Typography
} from "@mui/material"
import {TOOLBAR_HEIGHT} from "../AccountSettings"
import Container from "@mui/material/Container"
import logo from "../../refzoom.png"

export default function RegistrationLayout({children, title, subheading}) {
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "center",
				justifyContent: "center",
				//alignItems: "center"
			}}
		>
			<AppBar position={"fixed"} sx={{height: TOOLBAR_HEIGHT, zIndex: 1201}} />
			<Box sx={{
				maxWidth: "42em",
				width: "100%",
				paddingTop: "4.5em"
			}}>
				<Stack
					spacing={3}
					sx={{
						alignItems: "center",
					}}
				>
					<img
						src={logo}
						alt={"RefZoom Logos"}
						style={{maxWidth: "4.5em"}}
					/>
					{title && <Typography variant={"tunedH1"} sx={{ color: "rgb(41,38,49)" }}>{title}</Typography>}
					{subheading && <Typography variant={"tunedSubtitle2"} sx={{ color: "rgb(85,85,85)" }}>{subheading}</Typography>}
					{children}
				</Stack>
			</Box>
		</Container>
	)
}