import {
	Box,
	Card,
	IconButton,
	List,
	ListItem,
	Tooltip,
	Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {NOTE_ACTIONS} from "../../../reducers/noteActionSlice"
import { Close } from "@mui/icons-material"
import { selectTargetNote, setTargetNote } from "../../../reducers/targetNoteSlice"
import { deleteArticleNoteRelation } from "../../../reducers/breadcrumbSlice"
import { scrollToNote } from "../../../reducers/noteActionSlice"

export default function ArticleBreadcrumbs({
	note,
	articleRelations,
	workspaceId,
	articleId,
	copyTextMode,
	handleContextMenu,
	hide,
}) {
	const dispatch = useDispatch()
	const targetNote = useSelector(selectTargetNote)
	const isAttached = articleRelations?.attached?.indexOf(note.id) !== -1

	const handleClick = (e) => {
		e.stopPropagation()
		if (copyTextMode) {
			dispatch(setTargetNote(note))
		} else {
			dispatch(
				scrollToNote({
					note: note,
					action: NOTE_ACTIONS.scrollToNote,
					noteId: note.id,
					path: note.path,
					showLoading: true
				})
			)
		}
		hide()
	}

	const detachNote = (e) => {
		e.stopPropagation()
		e.preventDefault()
		dispatch(deleteArticleNoteRelation({note, articleId}))
	}

	return (
		<List
			sx={{
				paddingLeft: "25px",
				paddingTop: "1px",
				paddingBottom: 0,
				pointerEvents: "none",
			}}
			dense
			onContextMenu={handleContextMenu}
		>
			<ListItem
				sx={{
					display: "block",
					borderLeft: "1px solid #dfdfdfa1",
					padding: 0,
					pointerEvents: "auto",
				}}
			>
				<Box sx={{ display: "flex" }}>
					<Card
						onClick={handleClick}
						variant={"note"}
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							paddingRight: "25px",
							minHeight: "30px",
							color: "RBOneFont.main",
							cursor: copyTextMode ? "text" : "pointer",
							backgroundColor: "notes.crumbsBackground",
							borderLeft: "5px solid",
							borderColor: "notes.dark",
							...(isAttached && {
								backgroundColor: "notes.crumbsBackground",
							}),
							...(targetNote.path.indexOf(note.id) !== -1 && {
								borderColor: "primary.main",
							}),
							...(targetNote.id === note.id && {
								backgroundColor: "notes.selected",
								"&:hover": { backgroundColor: "notes.selectedHighlightBg" },
							}),
						}}
					>
						<Typography
							variant={"body1"}
							sx={{
								padding: "5px 10px",
								marginBottom: 0,
								wordBreak: "break-word"
							}}
							paragraph
						>{note.content}</Typography>
					</Card>
					{isAttached && (
						<Tooltip title={"Detach from note"} placement={"left"}>
							<IconButton
								onClick={(e) => detachNote(e)}
								sx={{
									color: "alertRed.main",
									padding: 0,
									position: "absolute",
									zIndex: 3,
									right: "5px",
									maxHeight: "30px",
									"&:hover": {
										backgroundColor: "alertRed.highlight",
									},
								}}
							>
								<Close />
							</IconButton>
						</Tooltip>
					)}
				</Box>
				{note.children.map((child) => (
					<ArticleBreadcrumbs
						key={child.id}
						note={child}
						articleRelations={articleRelations}
						workspaceId={workspaceId}
						articleId={articleId}
						copyTextMode={copyTextMode}
						handleContextMenu={handleContextMenu}
						hide={hide}
					/>
				))}
			</ListItem>
		</List>
	)
}
