import { Badge, Box } from "@mui/material"

export default function ExcludedBadge({ children, isDeleted }) {
	return (
		<Badge
			color={"error"}
			variant={"standard"}
			badgeContent={isDeleted ? "Deleted" : "Excluded"}
			sx={{
				width: "100%",
				"& .MuiBadge-badge": {
					//MUI style override one-off: https://mui.com/material-ui/customization/how-to-customize/#1-one-off-customization
					borderRadius: "0 0 8px 8px",
					top: "15px",
					right: "8%",
					height: "30px",
					opacity: "0.5",
					zIndex: 3,
				},
			}}
		>
			<Box sx={{ filter: "greyscale(100%)", width: "100%" }}>{children}</Box>
		</Badge>
	)
}
