import {
	Box, Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton, Menu,
	Tooltip,
} from "@mui/material"
import {addSelectedArticle, selectIsSelectingArticles} from "../../../reducers/selectedArticlesSlice"
import {useDispatch, useSelector} from "react-redux"
import {useEffect, useRef, useState} from "react"
import {
	CheckBoxOutlineBlankOutlined,
	CheckBoxOutlined,
	Remove,
	Settings,
} from "@mui/icons-material"
import axios from "axios"
import {useSnackbar} from "notistack"
import MenuItem from "@mui/material/MenuItem"
import {clearDeleteArticleError, selectDeleteArticleError, deleteArticle} from "../../../reducers/deletedArticlesSlice"
import { clearState } from "../../../reducers/targetArticleSlice"
import { resetWorkingArticleNoteRelation } from "../../../reducers/workingArticleNoteRelationSlice"
import useIsMacOS from "../../../hooks/useIsMacOS"
export function ArticleSubmenu(
	{
		isHovered,
		articleWorkspace,
		isImported,
		isOpen,
		id,
		selectedArticles,
		fullText,
		setFullTextId,
		setArticleFiles,
		fromAutoSearch
	}
){
	const dispatch = useDispatch()
	const isSelecting = useSelector(selectIsSelectingArticles)
	const deleteArticleError = useSelector(selectDeleteArticleError)
	const anchorElRef = useRef(null)
	const [isSelected, setIsSelected] = useState(false)
	const [isAttemptingToRemoveArticle, setIsAttemptingToRemoveArticle] = useState(false)
	const [isAttemptingToRemoveFullText, setIsAttemptingToRemoveFullText] = useState(false)
	const [openSubMenu, setOpenSubMenu] = useState(false)
	const {enqueueSnackbar} = useSnackbar()
	const isMacOS = useIsMacOS()

	useEffect(() => {
		if (isImported) {
			setIsSelected(selectedArticles[articleWorkspace.articleId])
		} else {
			setIsSelected(selectedArticles[id])
		}
	}, [selectedArticles, articleWorkspace])

	useEffect(() => {
		let timer
		if (deleteArticleError) {
			enqueueSnackbar("Failed to delete this article from all notes in workspace", {variant: "error"})
			timer = setTimeout(() => {
				dispatch(clearDeleteArticleError())
			}, 3000)
		}
		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [deleteArticleError])

	const detachArticle = () => {
		// pass params fromAutoSearch and articlePMID to remove article from auto list to handle paging logic in automated search panel
		dispatch(deleteArticle({articleId: id, fromAutoSearch, articlePMID: articleWorkspace?.article?.PMID}))
	}

	const removeFullTextFromArticle = () => {
		axios
			.delete(
				`/api/workspace/${articleWorkspace.workspaceId}/article/${id}/fulltext`
			)
			.then(({data}) => {
				setFullTextId(null)
				setArticleFiles(data.files)
			})
			.catch(e => {
				console.error("ERROR ATTEMPTING TO REMOVE FULL-TEXT PDF FROM ARTICLE", e)
				enqueueSnackbar("Failed to remove the full-text PDF from this article", {variant: "error"})
			})
	}

	return (
		<Box
			sx={{
				position: "absolute",
				top:0,
				right: 0,
				borderBottomLeftRadius: openSubMenu ? "0px" : "10px",
				borderLeft: isOpen ? "3px solid rgba(0, 179, 197, 0.5)" : "none",
				borderBottom: isOpen ? "3px solid rgba(0, 179, 197, 0.5)" : "none",
				transition: "border-radius 0.06s ease-in-out",
				overflow: "hidden"
			}}
		>
			<Fade
				in={isHovered || isOpen || isSelecting}
				mountOnEnter
				unmountOnExit
				sx={{
					height: "24px"
				}}
			>
				<Box
					ref={anchorElRef}
					sx={{
						height: "100%",
						color: "white",
						display: "inline-flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						gap: "2px",
						padding: "0 4px 0 0px",
					}}
				>
					<Fade
						in={isOpen}
						appear
						mountOnEnter
						unmountOnExit
					>
						<Tooltip
							title={"Minimize Citation"}
							placement={"top"}
						>
							<IconButton
								sx={{
									minWidth: "24px",
								}}
								onClick={() => {
									dispatch(clearState())
									dispatch(resetWorkingArticleNoteRelation())
								}}
							>
								<Remove fontSize={"small"}/>
							</IconButton>
						</Tooltip>
					</Fade>
					<Fade
						in={((isOpen) && isImported && !isSelecting)}
						appear
						mountOnEnter
						unmountOnExit
					>
						<Tooltip
							title={"Remove article from workspace"}
							placement={"top"}
						>
							<IconButton
								sx={{
									minWidth: "24px",
								}}
								onClick={() => setOpenSubMenu(true)}
							>
								<Settings fontSize={"small"}/>
							</IconButton>
						</Tooltip>
					</Fade>
					<Box
						display={(((isHovered || isOpen) && isImported) || (isImported && isSelecting)) ? "initial" : "none"}
					>
						<Tooltip
							title={"Select article"}
							placement={"top"}
						>
							<IconButton
								sx={{
									minWidth: isMacOS ? "38px" : "24px",
								}}
								onClick={() => dispatch(addSelectedArticle(articleWorkspace.article))}
							>
								{ isSelected ?
									<CheckBoxOutlined fontSize={"small"}/>
									:
									<CheckBoxOutlineBlankOutlined fontSize={"small"} />
								}
							</IconButton>
						</Tooltip>
					</Box>
					<Menu
						open={openSubMenu}
						onClose={() => setOpenSubMenu(false)}
						elevation={0}
						anchorEl={anchorElRef.current}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						MenuListProps={{
							dense: true,
							sx: {
								backgroundColor: "primary.transparency50",
								paddingTop: 0,
								paddingBottom: 0,
							}
						}}
						PaperProps={{
							sx: {
								borderRadius: "2px 0px 0px 10px"
							}
						}}
						TransitionProps={{
							style: {
								transformOrigin: "-1px 0 0"
							}
						}}
					>
						{fullText &&
							<MenuItem
								onClick={() => setIsAttemptingToRemoveFullText(true)}
								dense
								divider
								sx={{
									justifyContent: "end"
								}}
							>Remove full-text PDF</MenuItem>
						}
						<MenuItem
							dense
							onClick={() => setIsAttemptingToRemoveArticle(true)}
							sx={{
								justifyContent: "end"
							}}
						>Delete article from workspace</MenuItem>
					</Menu>
				</Box>
			</Fade>
			<ConfirmationModal
				isAttemptingToRemoveArticle={isAttemptingToRemoveArticle}
				setIsAttemptingToRemoveArticle={setIsAttemptingToRemoveArticle}
				isAttemptingToRemoveFullText={isAttemptingToRemoveFullText}
				setIsAttemptingToRemoveFullText={setIsAttemptingToRemoveFullText}
				detachArticle={detachArticle}
				removeFullText={removeFullTextFromArticle}
			/>
		</Box>
	)
}

function ConfirmationModal(
	{
		isAttemptingToRemoveArticle,
		setIsAttemptingToRemoveArticle,
		isAttemptingToRemoveFullText,
		setIsAttemptingToRemoveFullText,
		detachArticle,
		removeFullText
	}
){
	const closeModal = () => {
		if(isAttemptingToRemoveArticle){
			setIsAttemptingToRemoveArticle(false)
		}

		if(isAttemptingToRemoveFullText){
			setIsAttemptingToRemoveFullText(false)
		}
	}

	const removeArticleFromWorkspace = () => {
		detachArticle()
		closeModal()
	}

	const removeFullTextFromArticle = () => {
		removeFullText()
		closeModal()
	}
	return (
		<Dialog
			open={
				isAttemptingToRemoveArticle === true
				|| isAttemptingToRemoveFullText === true
			}
			onClose={closeModal}
		>
			{ isAttemptingToRemoveArticle &&
				<RemoveFromWorkspaceDialog
					removeArticleFromWorkspace={removeArticleFromWorkspace}
					closeModal={closeModal}
				/>
			}
			{ isAttemptingToRemoveFullText &&
				<RemoveFullTextDialog
					removeFullTextFromArticle={removeFullTextFromArticle}
					closeModal={closeModal}
				/>
			}
		</Dialog>
	)
}

function RemoveFromWorkspaceDialog({removeArticleFromWorkspace, closeModal}) {
	return (
		<>
			<DialogTitle>
				Are you sure you want to remove this article from the Workspace?
			</DialogTitle>
			<DialogContent>
				Removing this article from the Workspace will detach it from ALL notes in workspace. The article can be re-imported into the workspace, but the current relations to Workspace notes will be lost.
			</DialogContent>
			<DialogActions>
				<Button
					onClick={removeArticleFromWorkspace}
					variant={"outlined"}
				>Remove Article From Workspace</Button>
				<Button
					onClick={closeModal}
					variant={"outlined"}
					color={"error"}
				>Cancel</Button>
			</DialogActions>
		</>
	)
}

function RemoveFullTextDialog({removeFullTextFromArticle, closeModal}) {
	return (
		<>
			<DialogTitle>
				Are you sure you want to remove the full-text PDF from this Article?
			</DialogTitle>
			<DialogContent>
				Removing the full-text PDF from this article will prevent you from reading and annotating the PDF in this workspace.
				Instead, it will convert the current full-text PDF into an attachment, which you can then choose to delete from RefZoom. The attachment can still be downloaded, but cannot be read or annotated on inside of RefZoom.
				You can set a new full-text PDF by uploading a new file.
			</DialogContent>
			<DialogActions>
				<Button
					onClick={removeFullTextFromArticle}
					variant={"outlined"}
				>Remove the full-text PDF from this Article</Button>
				<Button
					onClick={closeModal}
					variant={"outlined"}
					color={"error"}
				>Cancel</Button>
			</DialogActions>
		</>
	)
}