import { receivedNewNotification } from "../../reducers/notificationsSlice" 
export const notificationsSocketMiddleware = (socket) => {
	return storeAPI => {
		socket.on("NEW_NOTIFICATION", (notification) => {
			storeAPI.dispatch(receivedNewNotification(notification))
		})
		return next => action => {
			if (action.type === "WS_CONNECT") {
				socket.connect()
	
			}
	
			if (action.type === "WS_DISCONNECT") {
				socket.disconnect()
			}
	
			if (action.type === "WS_EMIT") {
				const { event, payload } = action
				socket.emit(event, payload)
			}
	
			if (action.type === "WS_SUBSCRIBE_TO_NOTIFICATIONS") {
				const { userId } = action.payload
				socket.emit("SUBSCRIBE_TO_NOTIFICATIONS", userId)
			}
	
	
	
			return next(action)
		}
	}
}