import Article from "./article/Article"
import { CircularProgress, IconButton, Tooltip } from "@mui/material"
import { Download } from "@mui/icons-material"
import {useState} from "react"
import axios from "axios"
import { useSnackbar } from "notistack"
import ArticleSideBar from "./ArticleSideBar"
import ExcludedBadge from "./ExcludedArticleBadge"
import {useDispatch} from "react-redux"
import {removeDeletedArticle} from "../../reducers/deletedArticlesSlice"
import {setWorkspaceOverLimit} from "../../reducers/freeTierTrackerslice"

export default function RawArticle({
	workspaceId,
	article,
	setArticleAsImported,
	isExcluded,
	index,
	deletedAt,
	isSharedFromOtherWorkspace
}) {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const [isLoading, setIsLoading] = useState(false)
	const isDeleted = !!deletedAt

	const importArticle = () => {
		setIsLoading(true)
		const shared = isSharedFromOtherWorkspace ? isSharedFromOtherWorkspace : 0
		axios
			.post(`/api/workspace/${workspaceId}/article?sharedWorkspaceId=${shared}`, article)
			.then(({ data }) => {
				setArticleAsImported(data)
				if(deletedAt){
					dispatch(removeDeletedArticle(data.articleId))
				}
			})
			.catch(({response}) => {
				console.error("FAILED TO IMPORT: ", response)
				if(response.status === 516){
					dispatch(setWorkspaceOverLimit(true))
				} else {
					enqueueSnackbar(response.data, {
						variant: "error",
						autoHideDuration: 500,
					})
				}
			})
			.finally(() => setIsLoading(false))
	}

	if (isExcluded || isDeleted) {
		return (
			<ExcludedBadge isDeleted={isDeleted}>
				<Article
					article={article}
					isExcluded={isExcluded || isDeleted}
					index={index}
				>
					<ArticleSideBar isExcluded={true}>
						{ isLoading && <CircularProgress size={"20px"} /> }
						{ setArticleAsImported &&
							<Tooltip
								title="Import Article into Workspace"
								placement={"left-end"}
							>
								<IconButton
									onClick={importArticle}
									variant={"grab-bar-excluded"}
								>
									<Download />
								</IconButton>
							</Tooltip>
						}
					</ArticleSideBar>
				</Article>
			</ExcludedBadge>
		)
	}

	return (
		<Article
			article={article}
			index={index}
		>
			<ArticleSideBar variant={"import"}>
				{isLoading ? (
					<CircularProgress size={"20px"} />
				) : (
					<Tooltip title={"Import Article into Workspace"}>
						<IconButton
							variant={"grab-bar-import"}
							sx={{ maxHeight: "100%" }}
							onClick={() => importArticle(article)}
						>
							<Download />
						</IconButton>
					</Tooltip>
				)}
			</ArticleSideBar>
		</Article>
	)
}