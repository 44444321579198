import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material"
import RevokeKeyModal from "./RevokeKeyModal"
import {useState} from "react"

export default function KeysInUse({usedKeys, enqueueSnackbar, refreshLicenses}) {
	const [revokeModalOpen, setRevokeModalOpen] = useState(false)
	const [keyToModify, setKeyToModify] = useState(null)
	const [keyAction, setKeyAction] = useState("revoke")

	const handleLicenseKeyClick = (license) => {
		setKeyToModify(license)
		setKeyAction("revoke")
		setRevokeModalOpen(true)
	}

	const handleModalClose = () => {
		setKeyToModify(null)
		setRevokeModalOpen(false)
	}

	const humanReadableDate = (unixDate) => {
		const newDate = new Date(unixDate)
		return newDate.toLocaleString()
	}
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell align={"left"} sx={{fontWeight: "bold"}}> Name / Email </TableCell>
					<TableCell align={"left"} sx={{fontWeight: "bold"}}> Accepted </TableCell>
					<TableCell align={"left"} sx={{fontWeight: "bold"}}> Status </TableCell>
					<TableCell align={"left"}> </TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{usedKeys.map((license) => (
					<TableRow key={license.UUID}>
						{ (license.status === "USED" || license.used === true) &&
							<TableCell> {license.licenseKeyUser?.firstName + " " + license.licenseKeyUser?.lastName} </TableCell>
						}
						{ license.status === "SENT" &&
							<TableCell> {license.inviteEmail} </TableCell>
						}
						<TableCell>
							{ license.status === "USED" ? humanReadableDate(license.licenseKeyUser?.createdAt) : "-"}
						</TableCell>
						<TableCell> {(license.status === "USED" || license.used === true) ? "Active" : "Invite Sent"} </TableCell>
						<TableCell>
							<Button
								variant={"outlined"}
								color={"error"}
								onClick={() => handleLicenseKeyClick(license)}
							>
								Revoke Access
							</Button>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
			<RevokeKeyModal
				open={revokeModalOpen}
				setIsOpen={setRevokeModalOpen}
				keyToModify={keyToModify}
				refreshLicenses={refreshLicenses}
				enqueueSnackbar={enqueueSnackbar}
				keyAction={keyAction}
				handleModalClose={handleModalClose}
			/>
		</Table>
	)
}