import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"
import {getCustomerData} from "./stripeDetailsSlice"

export const setUserAsync = createAsyncThunk(
	"user/setUserAsync",
	async (authUser, {dispatch}) => {
		dispatch(setLoadingState(true))
		return axios.get("/api/user").then(({data: profile}) => {
			if (profile) {
				dispatch(setUser(profile))
				dispatch({
					type: "WS_SUBSCRIBE_TO_NOTIFICATIONS",
					payload: { userId: profile.id }
				})
			} else {
				// If user is not registered & does not exist, create the user
				axios.post("/api/user/create", authUser).then(({data: profile}) => {
					dispatch(setUser(profile))
					dispatch({
						type: "WS_SUBSCRIBE_TO_NOTIFICATIONS",
						payload: { userId: profile.id }
					})
				})
			}
		}).catch(error => {
			console.log(error)
		}).finally(() => {
			dispatch(getCustomerData())
		})
	}
)

export const updateUserNameAsync = createAsyncThunk(
	"user/updateUserNameAsync",
	async ({ firstName, lastName }, { dispatch, rejectWithValue }) => {
		try {
			// Make the PATCH request to update the user's name
			const response = await axios.patch("/api/user/name", { firstName, lastName })

			// Dispatch the setUser action to update the user in the state
			dispatch(setUser(response.data))

			return response.data
		} catch (error) {
			console.error("Failed to update user name:", error)
			return rejectWithValue(error.response.data || error.message)
		}
	}
)

export const userSlice = createSlice({
	name: "user",
	initialState: {
		value: null,
		loading: true,
		isUpdating: false,
		error: null,
		userUpdateSuccess: null
	},
	reducers: {
		setUser: (state, action) => {
			state.value = action.payload
		},
		setLoadingState: (state, action) => {
			state.loading = action.payload
		},
		completeLoading: (state) => {
			state.loading = false
		},
		clearUserUpdateSuccess: (state) => {
			state.userUpdateSuccess = false
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				setUserAsync.fulfilled,
				userSlice.caseReducers.completeLoading
			)
			.addCase(
				setUserAsync.rejected,
				userSlice.caseReducers.completeLoading
			)
			// Handle the pending state of updateUserNameAsync
			.addCase(updateUserNameAsync.pending, (state) => {
				state.isUpdating = true
				state.error = null
			})
			// Handle the fulfilled state of updateUserNameAsync
			.addCase(updateUserNameAsync.fulfilled, (state, action) => {
				state.isUpdating = false
				state.value = action.payload
				state.error = null
				state.userUpdateSuccess = true
			})
			// Handle the rejected state of updateUserNameAsync
			.addCase(updateUserNameAsync.rejected, (state, action) => {
				state.isUpdating = false
				state.error = action.payload || "Failed to update user name"
			})
	}
})

export const { setUser, setLoadingState, clearUserUpdateSuccess } = userSlice.actions

export const selectUser = (state) => state.user.value
export const selectLoadingUser = (state) => state.user.loading
export const selectIsUpdating = (state) => state.user.isUpdating
export const selectUserError = (state) => state.user.error
export const selectUserUpdateSuccess = (state) => state.user.userUpdateSuccess

export default userSlice.reducer
