import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"
import { defaultSocketService } from "../context/socket.context" 
import { confirmWorkspaceTransfer } from "./manageWorkspaceSlice"

export const setWorkspaceThunk = createAsyncThunk(
	"workspace/setWorkspaceThunk",
	async (payload, {dispatch}) => {
		dispatch(setWorkspace(payload))
	}
)

export const workspaceSlice = createSlice({
	name: "workspace",
	initialState: {
		value: null,
	},
	reducers: {
		setWorkspace: (state, action) => {
			state.value = action.payload
		},
		resetWorkspace: (state) => {
			state.value = null
		},
		setWorkspaceExclusions: (state, action) => {
			state.value["excludedArticles"] = {}
			action.payload.map((PMID) => (state.value.excludedArticles[PMID] = PMID))
		},
		setWorkspaceExclusionsUpdate: (state, action) => {
			action.payload.map((PMID) => {
				if (state.value.excludedArticles[PMID] !== undefined) {
					delete state.value.excludedArticles[PMID]
				} else {
					state.value.excludedArticles[PMID] = PMID
				}
			})
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				confirmWorkspaceTransfer.fulfilled,
				(state, action) => {
					const {
						ownerIsFreeTier,
						ownerUserId
					} = action.payload
					state.value.ownerIsFreeTier = ownerIsFreeTier
					state.value.ownerUserId = parseInt(ownerUserId)
				}
			)
	}
})

export const {
	setWorkspace,
	setWorkspaceExclusions,
	setWorkspaceExclusionsUpdate,
	resetWorkspace
} = workspaceSlice.actions

export const selectWorkspace = (state) => state.workspace.value
export const selectWorkspaceId = (state) => state.workspace?.value?.id
export const selectWorkspaceExclusions = (state) =>
	state.workspace.value.excludedArticles

export const updateWorkspaceExclusions =
	(workspaceId, emit) => async (dispatch) => {
		//loosely modeled after the 'refresh relay' pattern in refreshRelaySlice.js
		if (emit === "emit") {
			defaultSocketService.emit("UPDATE_WORKSPACE_EXCLUSIONS", workspaceId)
		}
		const { data } = await axios.get(
			`/api/workspace/${workspaceId}/auto-search/exclusions`
		)
		dispatch(setWorkspaceExclusions(data))
	}

export default workspaceSlice.reducer
