import {
	Button,
	Card,
	CardContent, TextField,
	//Typography,
} from "@mui/material"
//import { ContentCopy } from "@mui/icons-material"
import axios from "axios"
import {useState} from "react"
import { isEmail } from "validator"
import {debounce} from "lodash"

export default function AvailableLicenses({license, enqueueSnackbar, total, index, refreshLicenses}) {
	const [recipientEmail, setRecipientEmail] = useState("")
	const [displayValidEmailError, setDisplayValidEmailError] = useState(false)
	const inviteUser = () => {
		axios
			.post("/api/license/invite-user", {
				key: license,
				email: recipientEmail
			})
			.then(() => {
				console.log("e-mail sent!")
				refreshLicenses()
			})
			.catch((e) => {
				console.error("FAILED TO SEND INVITE EMAIL: ", e)
				if(e.response.status === 513){
					enqueueSnackbar(e.response.statusText, {variant: "error", autoHideDuration: 3000})
				} else {
					enqueueSnackbar("Unable to send invite, please try again or contact support.", {variant: "error", autoHideDuration: 3000})
				}
			})
	}

	const handleEmailFormInput = (e) => {
		const {value} = e.target
		if(value.length === 0){
			setDisplayValidEmailError(false)
		} else {
			setDisplayValidEmailError(isEmail(value) === false)
		}
	}

	const debouncedInputHandler = debounce(handleEmailFormInput, 300)

	return (
		<Card elevation={0}>
			<CardContent sx={{
				padding: "1em 0 !important",
				display: "flex",
				flexDirection: "row",
				gap: "1em"
			}}>
				<TextField
					sx={{
						flex: "1 0 auto"
					}}
					label={`Invite User ${index + 1} of ${total}`}
					variant={"outlined"}
					placeholder={"Enter e-mail address"}
					value={recipientEmail}
					error={displayValidEmailError}
					onChange={(e) => {
						setRecipientEmail(e.target.value)
						debouncedInputHandler(e)
					}}
				/>
				<Button
					onClick={inviteUser}
					variant={"contained"}
					color={"primary"}
					//recipientEmail.length < 3 prevents button from being enabled when user starts typing but before debounce runs validation
					disabled={recipientEmail.length < 3 || displayValidEmailError}
				>Send Invite</Button>
			</CardContent>
		</Card>
	)
}