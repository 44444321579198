import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const fetchNoteRestrictions = createAsyncThunk(
	"noteRestrictionsSlice/fetchNoteRestrictions",
	async (workspaceId, {getState, rejectWithValue}) => {
		const state = getState()
		const userId = state.user?.value?.id

		if (!workspaceId) rejectWithValue("No valid workspace id.")
		try {
			const {data: existingRestrictions} = await axios.get(`/api/workspace/${workspaceId}/user/${userId}/restrictions`)
			return existingRestrictions
		} catch (error) {
			return rejectWithValue("Failed to load note restrictions")

		}

	}
)

export const noteRestrictionsSlice = createSlice({
	name: "noteRestrictionsSlice",
	initialState: {
		allowedNoteIds: [],
		isLoading: false,
		error: null
	},
	reducers: {
		updateAllowedNoteIds: (state, action) => {
			state.allowedNoteIds = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNoteRestrictions.pending, (state) => {
				state.isLoading = true
				state.error = null
			})
			.addCase(fetchNoteRestrictions.fulfilled, (state, action) => {
				state.isLoading = false
				state.allowedNoteIds = action.payload?.map(x => x.id)
			})
			.addCase(fetchNoteRestrictions.rejected, (state, action) => {
				state.isLoading = false
				state.error = action.payload
			})
	}
})

export const { updateAllowedNoteIds } = noteRestrictionsSlice.actions


export const selectAllowedNoteIds = state => state.noteRestrictionsSlice.allowedNoteIds
export const selectIsLoading = state => state.noteRestrictionsSlice.isLoading


export default noteRestrictionsSlice.reducer