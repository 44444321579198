import {Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {selectCurrentlySelectedSubscription, setSelectedSubscriptionId} from "../../../../reducers/stripeDetailsSlice"

export default function ProductPriceCard({ price, quantity, isCurrentPlan }) {
	const currentSelectedSubscriptionPlan = useSelector(selectCurrentlySelectedSubscription)
	const isSelected = currentSelectedSubscriptionPlan.id === price.id
	let validPrice
	if(price.billing_scheme === "tiered") {
		validPrice = `- $${price.tiers[0].unit_amount / 100} / user`
	} else if (price.unit_amount) {
		validPrice = `- $${price.unit_amount / 100} / User`
	}
	const dispatch = useDispatch()

	const handleProductPriceSelection = () => {
		dispatch(setSelectedSubscriptionId(price.id))
	}

	if (!validPrice && price.id !== "FreeTier") {
		return null
	}
	return (
		<Card
			key={price.id}
			sx={{
				flexGrow: 1,
				width: "100%",
				border: isSelected ? "1px solid #02B3C5" : "1px solid #CFCFCF",
				boxShadow: isSelected ? "0 2px 4px rgba(0,0,0,0.2)" : "none",
				padding: "0px",
			}}
		>
			<CardHeader
				title={`${price.name || price.nickname} `}
				titleTypographyProps={{variant: "tunedH3Bold", display: "inline-block"}}
				subheader={validPrice}
				subheaderTypographyProps={{display: "inline-block", pl: "0.25rem"}}
				sx={{ padding: "1rem 1rem 0.5rem 1rem"}}
			/>
			<CardContent sx={{
				padding: "0 1rem"
			}}>
				<Typography variant={"tunedSubtitle3"} color={"RBOneFont.main"}>
					{price.description || price.product.description} {quantity > 1 ? `You currently have ${quantity} users.` : ""}
				</Typography>
			</CardContent>
			<CardActions>
				{isCurrentPlan ? (
					<Typography sx={{
						fontSize: "0.85rem",
						fontWeight: "bold",
						marginLeft: "0.5rem"
					}}>
						YOUR CURRENT PLAN
					</Typography>
				) : (
					<Button
						disabled={ isSelected }
						onClick={handleProductPriceSelection}
					>
						{isSelected ? "Currently Selected Plan" : `switch to ${price.name || price.nickname}`}
					</Button>

				)}
			</CardActions>
		</Card>
	)
}