import { Item, Menu, Separator } from "react-contexify"

const MENU_ID = "breadcrumb_menu"

export default function BreadCrumbMenu({ setCopyTextMode, copyTextMode }) {
	const toggleCopyTextMode = () => {
		setCopyTextMode(!copyTextMode)
	}

	const handleCopySelectedText = async () => {
		await navigator.clipboard.writeText(window.getSelection().toString())
	}

	return (
		<Menu id={MENU_ID} animation={false}>
			{copyTextMode ? (
				<>
					<Item onClick={handleCopySelectedText}>Copy Selected Text</Item>
					<Separator />
					<Item onClick={toggleCopyTextMode}>Scroll to Note Mode</Item>
				</>
			) : (
				<Item onClick={toggleCopyTextMode}>Copy Text Mode</Item>
			)}
		</Menu>
	)
}
