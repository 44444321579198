import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material"
import {UpdateBillingDetailsForm} from "./BillingDetailsForm"
import InvoiceReceiptRecipientList from "../../../stripe/InvoiceReceiptRecipientList"
import {PaymentDetailsCard} from "../shared-components/PaymentDetailsCard"
import {useSelector} from "react-redux"
import {selectUserNeedsPaymentDetails} from "../../../../reducers/stripeDetailsSlice"

export function PaymentDetails() {
	const needsPaymentDetails = useSelector(selectUserNeedsPaymentDetails)
	return (
		<Box>
			<Card elevation={0}>
				<CardHeader
					title={
						<Typography variant={"tunedH1"}>Payment Details</Typography>
					}
					subheader={
						<Typography variant={"tunedSubtitle2"} sx={{paddingTop: "0.625em", color: "RBOneFont.main"}} paragraph>
							Check your current payment method or change the way you pay for for Refzoom.
						</Typography>
					}
					sx={{
						padding: 0
					}}
				/>
				<CardContent sx={{padding: 0}}>
					{ !needsPaymentDetails && <PaymentDetailsCard /> }
					<UpdateBillingDetailsForm />
					<InvoiceReceiptRecipientList />
				</CardContent>
			</Card>
		</Box>
	)
}