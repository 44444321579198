import cloneDeep from "lodash/cloneDeep"
import { TOOL_PANEL_VIEW } from "../components/dynamic/ToolPanel"
import { DYNAMIC_PANEL_TAB_INDEX_MAP } from "../reducers/dynamicViewSlice"

/**
 * Updates an article as imported. The article is identified either by its DOI or PMID.
 * This function is curried. First, it takes a setter function, and then returns a new function 
 * that accepts the article to update.
 * @param {Function} setFunction - The setter function used to update the state or context.
 * @returns {Function} A function that takes an article to be updated.
 */
export const updateArticleAsImported = (setFunction) => (article) => {
	setFunction(prevParams => {
		let _articles = cloneDeep(prevParams.articles)
		let index = _articles.findIndex((a) => {
			const articleIsDeleted = a?.articleId && a?.deletedAt !== null
			if (articleIsDeleted){
				return a?.article?.DOI === article?.article?.DOI || article?.article?.PMID === a?.article?.PMID
			}
			return a?.DOI === article?.article?.DOI || article?.article?.PMID === a?.PMID
		})

		if (index > -1) {
			_articles.splice(index, 1, article)
		}

		return {
			...prevParams,
			articles: _articles
		}
	})
}

/**
 * Checks if an article, identified by its PMID, is excluded within a workspace.
 * @param {string|number} PMID - The PMID of the article.
 * @param {Object} workspaceExcludedArticles - An object where keys are PMIDs and values denote their exclusion status.
 * @returns {boolean} True if the article is excluded, otherwise false.
 */
export const checkExclusion = (PMID, workspaceExcludedArticles) => {
	if (workspaceExcludedArticles) {
		return workspaceExcludedArticles[PMID] !== undefined
	}
	return false
}

/**
 * Checks if an article was deleted during the current session using the value from sessionTimestampSlice
 * @param{string} sessionTimeStamp - the ISO string of when the user opened the refBin app
 * @param{string | null} articleDeletedAtTimestamp - the value of the deletedAt column from ArticleWorkspace
 * @returns{boolean} was the deleted article deleted during the current session
 */
export const articleDeletedDuringSession = (sessionTimeStamp, articleDeletedAtTimestamp) => {
	if(articleDeletedAtTimestamp){
		let sessionStartTimeString = new Date(sessionTimeStamp)
		let deletedAtTimeString = new Date(articleDeletedAtTimestamp)

		return sessionStartTimeString < deletedAtTimeString
	}

	return false
}

/**
 * Determines the URL structure for 'select All' based on the Dynamic Panel View.
 * 
 * @param {string|number} openTab - The current open tab.
 * @param {string} toolPanelView - The view of the tool panel.
 * @returns {string|null} The type of URL ("unassigned", "noteTabs") or null if not matched.
 */
export const calculateUrlType = (openTab, toolPanelView) => {
	if (openTab === DYNAMIC_PANEL_TAB_INDEX_MAP.TOOLS && toolPanelView === TOOL_PANEL_VIEW.UNASSIGNED) {
		return "unassigned"
	} else if  (openTab === DYNAMIC_PANEL_TAB_INDEX_MAP.NOTE_ARTICLE_LIST) {
		return "noteTabs"
	} else {
		return null
	}
}