import { createSlice } from "@reduxjs/toolkit"
import { defaultSocketService } from "../context/socket.context" 

const initialValue = []

export const refreshRelaySlice = createSlice({
	name: "refreshRelay",
	initialState: {
		value: initialValue,
	},
	reducers: {
		setRefreshRelayInternal: (state, action) => {
			state.value = action.payload
				.toString()
				.split(".")
				.map((id) => parseInt(id))
		},
		clearRefreshRelay: (state) => {
			state.value = initialValue
		},
	},
})

export const { setRefreshRelayInternal, clearRefreshRelay } =
	refreshRelaySlice.actions

export const setRefreshRelay =
	(refreshRelay, workspaceId) => async (dispatch) => {
		if (workspaceId) {
			defaultSocketService.emit("SEND_WORKSPACE_UPDATE", refreshRelay, workspaceId)
		}

		dispatch(setRefreshRelayInternal(refreshRelay))
	}

export const selectRefreshRelay = (state) => state.refreshRelay.value

export default refreshRelaySlice.reducer
