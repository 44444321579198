import {Box, Button} from "@mui/material"
import axios from "axios"
import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {getCustomerData} from "../../../../reducers/stripeDetailsSlice"
import AdjustSeatsModal from "./AdjustSeatsModal"
import {selectCurrentInvoiceIsOpen} from "../../../../reducers/stripeInvoicesSlice"

export default function AdjustSeatsButtons({refreshLicenses, enqueueSnackbar, inactiveKeys}) {
	const [isPurchasing, setIsPurchasing] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)
	const [openAdjustSeatsModal, setOpenAdjustSeatsModal] = useState(false)
	const dispatch = useDispatch()
	const hasOpenInvoice = useSelector(selectCurrentInvoiceIsOpen)

	const openModalToPurchase = () => {
		setIsPurchasing(true)
		setOpenAdjustSeatsModal(true)
	}

	const openModalToDelete = () => {
		setIsDeleting(true)
		setOpenAdjustSeatsModal(true)
	}

	const closeModalAndPerformAction = () => {
		setOpenAdjustSeatsModal(false)
		if(isPurchasing) purchaseOneKey()
		else if(isDeleting) removeOneUnusedKey()
	}

	const resetAdjustmentButtonsState = () => {
		setIsDeleting(false)
		setIsPurchasing(false)
		setOpenAdjustSeatsModal(false)
	}

	const purchaseOneKey = () => {
		axios.post("/api/license/")
			.then(() => {
				refreshLicenses()
			})
			.catch((e) => {
				console.error("FAILED TO BUY ONE KEY: ", e)
				enqueueSnackbar("Something went wrong", {variant: "error"})
			})
			.finally(() => {
				setIsPurchasing(false)
				dispatch(getCustomerData())
			})
	}

	const removeOneUnusedKey = () => {
		const inactiveKeysArrayLength = inactiveKeys.length
		const keyToRemove = inactiveKeys.pop()
		axios.delete(`/api/license/${keyToRemove.id}`)
			.then(() => {
				refreshLicenses()
				enqueueSnackbar("Removed key #" + inactiveKeysArrayLength, {variant: "success"})
			})
			.catch((e) => {
				console.error("FAILED TO BUY ONE KEY: ", e)
				enqueueSnackbar("Something went wrong", {variant: "error"})
			})
			.finally(() => {
				setIsDeleting(false)
				dispatch(getCustomerData())
			})
	}

	return (
		<Box sx={{
			mb: "1.5rem",
			display: "flex",
			flexDirection:"column",
			gap: "1.5em",
			justifyContent: "center",
			alignItems: "center",
		}}>
			<Button
				variant={"contained"}
				color={"primary"}
				size={"large"}
				fullWidth
				onClick={openModalToPurchase}
				disabled={isPurchasing || hasOpenInvoice}
			>{isPurchasing ? "Please wait..." : "Add another seat"}</Button>
			<Button
				variant={"outlined"}
				color={"error"}
				onClick={openModalToDelete}
				disabled={inactiveKeys.length === 0 || isDeleting || hasOpenInvoice}
				fullWidth
			>{isDeleting ? "Please wait... " : "Remove an unused seat"}</Button>
			<AdjustSeatsModal
				open={openAdjustSeatsModal}
				onCancelClick={resetAdjustmentButtonsState}
				onActionClick={closeModalAndPerformAction}
				isDeleting={isDeleting}
				isPurchasing={isPurchasing}
			/>
		</Box>
	)
}