import RegistrationForm from "../../components/RegistrationForm"
import RegistrationLayout from "./RegistrationLayout"

export default function RegisterForm() {

	return (
		<RegistrationLayout
			title={"Welcome to RefZoom!"}
			subheading={"First thing's first, tell us a bit about yourself: "}
		>
			<RegistrationForm />
		</RegistrationLayout>
	)
}
