import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { cloneDeep } from "lodash"
import { deletedArticlesSlice } from "./deletedArticlesSlice"
import { unassignedArticlesEventsSlice } from "./unassignedArticlesEventsSlice" 
import { produce } from "immer"

// Define the initial state of the search UI
export const CITATION_IMPORT_VIEW_MAP = {
	SEARCH: 1,
	RESULTS: 2
}

const initialState = {
	query: "",          // The search query entered by the user
	results: [],        // Search results
	isLoading: false,   // Flag to indicate if the search is in progress
	error: null,         // Error information, if any
	view: CITATION_IMPORT_VIEW_MAP.SEARCH
}

// Helper function to check if two articles match based on their IDs
function isMatchingArticle(articleA, articleB) {
	const idsToCheck = ["DOI", "PMID", "ISBN", "PMCID", "ARXIVID"]
	return idsToCheck.some(id => {
		if (!articleA[id]) {
			return false
		}
		return articleA[id]?.toUpperCase() === articleB[id]?.toUpperCase()
	}	
	)
}

// Define the thunk action for fetching data
export const fetchData = createAsyncThunk(
	"citationImport/fetchData",
	async ({ workspaceId, query }, thunkAPI) => {
		thunkAPI.dispatch(startSearch())
		try {
			const response = await axios.get(`/api/workspace/${workspaceId}/zotero/${encodeURIComponent(query.toUpperCase())}`)
			return response.data
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data)
		}
	}
)

export const citationImportSlice = createSlice({
	name: "citationImport",
	initialState,
	reducers: {

		// Action to set view and reset state
		setView(state, action) {
			state.view = action.payload
			state.isLoading = false
			state.error = null
			state.results = []
			state.query = ""
		},

		// Action to update the search query
		setQuery(state, action) {
			state.query = action.payload
		},

		setCitationAsImported(state, action) {
			let _articles = cloneDeep(state.results)
			const article = action.payload
			let index = _articles.findIndex((a) => {
				const articleIsDeleted = a.articleId && a.deletedAt !== null
				if (articleIsDeleted) {
					return isMatchingArticle(a.article, article.article)
				}
				return isMatchingArticle(a, article.article)
			})
	
			if (index > -1) {
				_articles.splice(index, 1, article)
			}
			state.results = _articles
		},
		// Action to start the search
		startSearch(state) {
			state.view = CITATION_IMPORT_VIEW_MAP.RESULTS
		},
	
		// Action to set the search results
		setSearchResults(state, action) {
			state.results = action.payload
			state.isLoading = false
		},
	
		// Action to handle search failure
		searchFailed(state, action) {
			state.error = action.payload
			state.isLoading = false
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchData.pending, (state) => {
				state.isLoading = true
				state.error = null
			})
			.addCase(fetchData.fulfilled, (state, action) => {
				state.results = action.payload
				state.isLoading = false
			})
			.addCase(fetchData.rejected, (state, action) => {
				state.error = action.payload
				state.isLoading = false
			})
			.addCase(deletedArticlesSlice.actions.addDeletedArticle, (state, action) => {
				const newlyDeletedArticle = action.payload
				const resultsList = cloneDeep(state.results)
				const index = resultsList.findIndex(article => article.articleId == newlyDeletedArticle.articleId)
				const articleToUpdate = resultsList[index]
				if (articleToUpdate) {
					articleToUpdate.deletedAt = newlyDeletedArticle.deletedAt
					resultsList[index] = articleToUpdate
					state.results = resultsList
				}
			})
			.addCase(deletedArticlesSlice.actions.removeDeletedArticle, (state, action) => {
				const {payload: articleId} = action
				const resultsList = cloneDeep(state.results)
				const index = resultsList.findIndex(article => article.articleId === articleId)
				const articleNolongerDeleted = resultsList[index]
				if (articleNolongerDeleted) {
					articleNolongerDeleted.deletedAt = null
					resultsList[index] = articleNolongerDeleted
					state.results = resultsList
				}
			})
			.addCase(unassignedArticlesEventsSlice.actions.concatUnassignedArticles, (state, action) => {
				const incomingUnassignedArticles = action.payload				
				// Use Immer's produce function to create a draft of the state
				state.results = produce(state.results, draftResults => {
					draftResults.forEach((resultslistArticle, index) => {
						const isImportedResult = resultslistArticle.article
						if (!isImportedResult) {
							const matchingIndex = incomingUnassignedArticles.findIndex((a) => {
								return isMatchingArticle(resultslistArticle, a)
							})
							if (matchingIndex > -1) {
								// Update the draftResults array in an immutable way
								draftResults[index] = incomingUnassignedArticles[matchingIndex]
							}
						}
					})
				})
			})
	},
})

export const { setView, setQuery, startSearch, setSearchResults, searchFailed, setCitationAsImported } = citationImportSlice.actions

// Assuming your root reducer has a property 'citationImport' for this slice
const selectCitationImportState = state => state.citationImport

// Selector for the search query
export const selectQuery = state => {
	return selectCitationImportState(state).query
}

// Selector for the search results
export const selectResults = state => {
	return selectCitationImportState(state).results
}

// Selector for the loading state
export const selectIsLoading = state => {
	return selectCitationImportState(state).isLoading
}

// Selector for the error state
export const selectError = state => {
	return selectCitationImportState(state).error
}

// Selector for the view state
export const selectView = state => {
	return selectCitationImportState(state).view
}

export default citationImportSlice.reducer
