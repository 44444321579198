import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Logo from "../../refzoom.png"
import { Link } from "react-router-dom"
import { useTheme } from "@mui/material"

const pages = [
	{
		name: "Features",
		link: "#features"
	},
	{
		name: "Pricing",
		link: "#pricing"
	},
	{
		name: "Contact",
		link: "#contact"
	},
]

const HomeNav = () => {
	const theme = useTheme()
	const [anchorElNav, setAnchorElNav] = React.useState(null)

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget)
	}

	const handleCloseNavMenu = () => {
		setAnchorElNav(null)
	}

	return (
		<AppBar
			disableElevation
			position="static"
			sx= {{
				background: "#ffffff",
				color: "263130",
			}}>
			<head>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
				<link href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&family=Playfair+Display:wght@700&display=swap" rel="stylesheet" />
			</head>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Box sx={{ display: { xs: "none", md: "flex" }, mr: 4 }}>
						<img
							src={Logo}
							alt={"RefZoom Logo"}
							style={{ width: "72px" }}
						/>
					</Box>
					<Box sx={{ flexGrow: 0, mr:2, display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="red"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{pages.map((page) => (
								<MenuItem key={page.name} onClick={handleCloseNavMenu}>
									<Typography textAlign="center" href={page.link}>{page.name}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>

					<Box
						component={"img"}
						src={Logo}
						sx={{
							display: { xs: "flex", md: "none" },
							mr: 1,
							width: "50px",
						}}
					/>
					<Typography
						variant="h5"
						noWrap
						component="a"
						href=""
						sx={{
							mr: 1,
							display: { xs: "flex", md: "none" },
							flexGrow: 1,
							fontSize: "1rem",
							[theme.breakpoints.down("sm")]: {
								fontSize: "0.5rem",
							},
							fontWeight: 300,
							letterSpacing: ".3rem",
							color: "white",
							textDecoration: "none",
						}}
					>
						Research Better
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
						{pages.map((page) => (
							<Button
								key={page.link}
								onClick={handleCloseNavMenu}
								sx={{
									my: 2,
									color: "#263130",
									display: "block",
									fontFamily: "Cabin",
									fontWeight: "500",
								}}
								href={page.link}
							>
								{page.name}
							</Button>
						))}
					</Box>

					<Box sx={{ flexGrow: 0 }}>
						<Button
							component={Link}
							to={"/dashboard"}
							variant={"contained"}
							size={"large"}
							sx={{
								color: "Primary",
								borderRadius: "48px",
							}}
						>
							Login
						</Button>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	)
}
export default HomeNav
