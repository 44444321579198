import {createSelector, createSlice} from "@reduxjs/toolkit"
import GiftedSubscriptions from "../components/account_settings/subviews/GiftedSubscriptions/GiftedSubscriptions"
import {BillingHistory} from "../components/account_settings/subviews/BillingHistory/BillingHistory"
import {PaymentDetails} from "../components/account_settings/subviews/PaymentDetails/PaymentDetails"
import {ChangeSubscription} from "../components/account_settings/subviews/ChangeSubscription/ChangeSubscription"
import { AccountDetails } from "../components/account_settings/subviews/AccountDetails/AccountDetails"

/**
 * A map of the data required to render an account-settings sub-page
 * @type AccountSettingsComponent {
 *     title: String representing the name displayed in the toolbar,
 *     slug: String of the sub-page url
 *     component: ReactComponent
 *     requiresOwnership: Boolean. If true, the link to the component will render for users with paid accounts.
 * }
 */
export const accountSettingsComponents = {
	changeSubscription: {
		title: "Change Subscription",
		slug: "change-sub",
		component: ChangeSubscription,
		requiresOwnership: false,
		group: "subscriptions"
	},
	giftedSubscriptions: {
		title: "Invite / Manage Users",
		slug: "manage-seats",
		component: GiftedSubscriptions,
		requiresOwnership: true,
		group: "subscriptions"
	},
	billingDetails:{
		title:"Payment Details",
		slug: "billing-details",
		component: PaymentDetails,
		requiresOwnership: false,
		group: "subscriptions",
	},
	billingHistory:{
		title:"Billing History",
		slug: "billing-history",
		component: BillingHistory,
		requiresOwnership: true,
		group: "subscriptions",
	},
	accountDetails: {
		title: "Modify Account Details",
		slug: "account-details",
		component: AccountDetails,
		requiresOwnership: false,
		group: "accounts"
	},
	resetPassword: {
		title: "Reset Password",
		slug: "reset-password",
		component: null,
		requiresOwnership: false,
		group: "password"
	},
}

const accountSettingsNavSlice = createSlice({
	name: "accountSettingsNav",
	initialState: {
		openComponent: "changeSubscription",
		menuGroups: [
			{
				groupTitle: "My Profile",
				slug: "accounts",
			},
			{
				groupTitle: "Reset or Change Password",
				slug: "password"
			},
			{
				groupTitle: "My Subscription",
				slug: "subscriptions"
			},
		],
		availableComponentKeys: Object.keys(accountSettingsComponents)
	},
	reducers: {
		changeOpenComponent: (state, action) => {
			state.openComponent = state.availableComponentKeys.find(componentKey => componentKey === action.payload)
		}
	},
})

export const {changeOpenComponent} = accountSettingsNavSlice.actions

const selectMenuGroups = (state) => state.accountSettingsNav.menuGroups // this is only used in selectAvailableAccountSettingsMenuItems, so no need to export
export const selectAvailableComponentKeys = (state) => state.accountSettingsNav.availableComponentKeys
export const selectOpenAccountSettingsComponent = (state) => state.accountSettingsNav.openComponent
export const selectAvailableAccountSettingsMenuItems = createSelector(
	// attempting to use the Redux selector created in the stripeDetailsSlice for ownsSubscription causes a circular dependency error when the app loads in
	// for an alternative way to deal with this issue in the future: https://schneide.blog/2021/09/06/redux-toolkit-solving-referenceerror-access-lexical-declaration-before-initialization/
	[selectAvailableComponentKeys, (state) => state.stripeDetails.ownsSubscription, selectMenuGroups],
	(componentKeys, ownsSubscription, menuGroups) => {
		return menuGroups.map(groupObj => {
			const items = []
			for(const [accountSettingsKey, accountSettingsObj] of Object.entries(accountSettingsComponents)) {
				const inGroup = accountSettingsObj.group === groupObj.slug
				const userHasAccess = ((ownsSubscription && accountSettingsObj.requiresOwnership) || !accountSettingsObj.requiresOwnership)

				if(inGroup && userHasAccess){
					items.push({ ...accountSettingsObj, key: accountSettingsKey})
				}
			}
			return {
				groupTitle: groupObj.groupTitle,
				items
			}
		})
	}
)

export default accountSettingsNavSlice.reducer
