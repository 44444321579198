import RegistrationLayout from "./RegistrationLayout"
import Checkout from "../../components/stripe/Checkout"
import {Navigate, useLocation} from "react-router-dom"

export default function PayNow() {
	const location = useLocation()
	const payNowData = location.state


	return location.state === null
		? ( <Navigate to={"/prices"} /> )
		: (
			<RegistrationLayout title={"Pay Now"}>
				<Checkout
					clientSecret={payNowData.clientSecret}
					subscriptionId={payNowData.subscriptionId}
					amount={payNowData.amountDue}
				/>
			</RegistrationLayout>
		)
}