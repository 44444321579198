import { DashboardModal } from "./DashboardModal"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	clearModalAndClose,
	modalIsOpen,
} from "../../reducers/copyCitationsModalSlice"
import { selectSelectedArticles } from "../../reducers/selectedArticlesSlice"
import axios from "axios"
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	Select,
	TextField,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { ContentPaste } from "@mui/icons-material"
import MenuItem from "@mui/material/MenuItem"

const CITATION_FORMAT_MAP = {
	JAMA: "JAMA",
	PMIDS: "PMIDS",
}

export default function CopyCitations({ workspace }) {
	// const copyCitations = useSelector(copyCitationsModal)
	const selectedArticles = useSelector(selectSelectedArticles)
	const open = useSelector(modalIsOpen)
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()

	const [citationText, setCitationText] = useState("")
	const [loading, setLoading] = useState(false)
	const [citationsFormat, setCitationFormat] = useState(
		CITATION_FORMAT_MAP.JAMA
	)

	useEffect(() => {
		if (Object.keys(selectedArticles).length > 0) {
			setLoading(true)
			//TODO: eventually move away from PMIDs as UUIDs?
			let articleIds = Object.values(selectedArticles).map(
				(articleInfo) => articleInfo.PMID
			)
			switch (citationsFormat) {
				case CITATION_FORMAT_MAP.JAMA:
					formatCitationJAMAfromTranslation(articleIds)
					break
				case CITATION_FORMAT_MAP.PMIDS:
					setCitationText(articleIds.join(" "))
					setLoading(false)
					break
			}
		}
	}, [open, citationsFormat])

	const formatCitationJAMAfromTranslation = (articleIds) => {
		//TODO: this is strictly dealing with JAMA style citations, there might be want / need for other formats
		//to learn more about JAMA formatting: https://guides.med.ucf.edu/jama
		let citationString = ""
		axios
			.post(`/api/workspace/${workspace.id}/citations/translate/`, {
				articleIds,
			})
			.then(({ data }) => {
				data.forEach((articleInfo) => {
					const article = articleInfo[0]
					let authors =
						articleInfo[0].creators.length <= 1
							? `${article.creators[0].lastName} ${article.creators[0].firstName[0]}`
							: article.creators
								.reduce((authorString, authorObject) => {
									if (authorObject.creatorType === "author" && authorObject.lastName && authorObject.firstName) {
										//TODO: test and see if PubMed articles ever include other creatorTypes:
										// https://www.zotero.org/support/kb/item_types_and_fields#item_creators
										authorString += `${authorObject.lastName} ${authorObject.firstName[0]}, `
									}
									return authorString
								}, "")
								.replace(/,\s*$/, "") //removing trailing comma from list of authors
					//date requirements vary journal to journal: some require just a year, others year-month-day.
					//JAMA only cares about year OR abbreviated month and year, preferring the latter.
					let rawDate = article.date.split("-")
					let date =
						rawDate.length > 1
							? new Date(`${rawDate[0]}-${rawDate[1] - 1}`).toLocaleDateString(
								"en-US",
								{ month: "short", year: "numeric" }
							)
							: rawDate[0]
					let dateVolIssuePages = ""
					//formatting depends on available info >:(
					if (article.volume && article.issue) {
						dateVolIssuePages = `${date}; ${article.volume}(${article.issue}):${article.pages}`
					} else if (article.issue) {
						dateVolIssuePages = `${date}; (${article.issue}):${article.pages}`
					} else {
						dateVolIssuePages = `${date}:${article.pages}`
					}
					citationString += `\n${authors}. ${article.title}. ${article.journalAbbreviation}. ${dateVolIssuePages}.\n`
				})
				setCitationText(citationString)
			})
			.catch((err) => console.error("translationservice req went bad: \n", err))
			.finally(() => setLoading(false))
	}

	const handleClose = () => {
		dispatch(clearModalAndClose())
	}

	const handleChange = (evt) => {
		setCitationText(evt.target.value)
	}

	const handleCopy = () => {
		navigator.clipboard
			.writeText(citationText)
			.then(() => {
				enqueueSnackbar("Citations copied to clipboard!", {
					variant: "success",
					autoHideDuration: 2000,
				})
			})
			.catch((err) => {
				enqueueSnackbar("Failed to copy citations to clipboard", {
					variant: "error",
					autoHideDuration: 2000,
				})
				console.error(`couldn't copy citations to clipboard because: ${err}`)
			})
	}

	const handleSelectChange = (evt) => {
		setCitationFormat(CITATION_FORMAT_MAP[evt.target.value])
	}

	return (
		<DashboardModal open={open} setOpen={handleClose} title={"Copy Citations"}>
			<FormControl sx={{ marginTop: "5px" }}>
				<InputLabel id={"citations-format-selection"}>
					Citations Format
				</InputLabel>
				<Select
					labelId={"citations-format-selection"}
					value={citationsFormat}
					label={"Citations Format"}
					onChange={handleSelectChange}
				>
					<MenuItem value={CITATION_FORMAT_MAP.JAMA}>
						JAMA - American Medical Association Journal format
					</MenuItem>
					<MenuItem value={CITATION_FORMAT_MAP.PMIDS}>
						PMIDs - PubMed IDs only
					</MenuItem>
				</Select>
			</FormControl>
			<Box sx={{ width: "50vw", display: "flex", flexDirection: "column" }}>
				{loading ? (
					<Box
						sx={{
							width: "600px",
							height: "270px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							alignSelf: "center",
						}}
					>
						<CircularProgress size={110} />
					</Box>
				) : (
					<TextField
						multiline
						fullWidth
						rows={10}
						label={citationsFormat}
						sx={{ margin: "16px 0" }}
						value={citationText.trim()}
						onChange={handleChange}
					/>
				)}
				<Button
					onClick={handleCopy}
					variant={"contained"}
					color={"primary"}
					startIcon={<ContentPaste />}
				>
					Copy Citations to Clipboard
				</Button>
			</Box>
		</DashboardModal>
	)
}
