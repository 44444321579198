import { createSlice} from "@reduxjs/toolkit"
import { workspaceSlice } from "./workspaceSlice"

const initialState = {
	query: "",
	source: "pubmed",
	resultsCount: 0,
	resultsPageCount: 0,
	page: 1,
}

const articleSearchQuery = createSlice({
	name: "searchedArticles",
	initialState,
	reducers: {
		setArticleSearchQuery: (state, action) => {
			state.query = action.payload.query
			state.source = action.payload.source
			state.page = 1
			state.resultsCount = 0
			state.resultsPageCount = 0
		},
		resetArticleSearchQuery: () => initialState,
		setResultsCount: (state, action) => {
			const {total, currentPage} = action.payload
			const calculatedPageTotal = (state.page * 20)
			const isLastPage = state.page === currentPage || calculatedPageTotal > total
			state.resultsCount = total
			state.resultsPageCount = isLastPage ? total : calculatedPageTotal
			state.page += isLastPage ? 0 : 1
		},
	},
	extraReducers: (builder) => (
		builder.addCase (
			workspaceSlice.actions.setWorkspace,
			articleSearchQuery.caseReducers.resetArticleSearchQuery
		)
	)
})

export const { setArticleSearchQuery, setResultsCount } = articleSearchQuery.actions

export const selectArticleSearchQuery = (state) => state.articleSearchQuery
export default articleSearchQuery.reducer
