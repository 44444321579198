import {useSelector } from "react-redux"
import { NoteArticleList } from "./NoteArticleList"
import { selectNoteArticleTabsArticles } from "../../../reducers/noteArticleTabsV2Slice"
export function NoteArticleListPanel({ workspace }) {
	const articleNoteListV2 = useSelector(selectNoteArticleTabsArticles)
	// const [targetArticle, setTargetArticle] = useState(null)
	// target article id


	return <NoteArticleList
		currentIndex={articleNoteListV2.currentIndex}
		workspaceId={workspace.id}
	/>
}
