export function PubMedIcon() {
	return (
		<g
			id="layer1"
			transform="matrix(0.61516202,0,0,0.54931219,-31.368826,0)">
			<path
				d="M 51.048188,2.1020419 V 31.136042 c 0,0 10.632034,-2.530053 11.049763,8.363162 0.02806,-1.178794 0.112237,-26.236162 0.112237,-26.236162 0.198663,-10.3806769 -11.162,-11.1610001 -11.162,-11.1610001"
				id="path1"
			/>
			<path
				d="M 55.995594,0.06204185 V 25.609042 c 0,0 5.808648,3.624596 6.077822,13.704088 0.01786,0.668686 0.115179,-21.896088 0.115179,-27.737088 10e-4,-10.3820001 -6.193,-11.51400015 -6.193,-11.51400015"
				opacity={"0.60"}
				id="path2"
			/>
			<path
				d="M 72.485381,5.8987119 C 64.515528,6.0504266 62.07796,12.942322 62.07796,12.942322 v 26.421536 c 0,0 5.359175,-15.010818 27.88713,4.327144 0,-7.007986 0.04181,-24.682691 0.04181,-30.524679 C 82.352287,7.5896785 76.660067,5.8192424 72.485381,5.8987119 Z M 67.477855,13.41877 h 4.939987 l 3.917256,10.314745 4.148818,-10.314745 h 4.994662 l 2.920253,19.299703 H 83.37844 L 81.969771,21.607277 h -0.05146 L 77.283859,32.718473 H 75.286637 L 70.854799,21.607277 h -0.04824 l -1.614501,11.111196 h -4.991445 z"
				id="path6"
			/>
		</g>
	)
}