import {createSlice, createAsyncThunk, createSelector} from "@reduxjs/toolkit"
import axios from "axios"
import { fetchArticleBreadcrumbs } from "./breadcrumbSlice"
import cloneDeep from "lodash/cloneDeep"
import { selectAllowedNoteIds, selectIsLoading } from "./noteRestrictionsSlice"
import {detachNoteFromAttachment} from "./noteAttachmentsSlice"
import {NOTE_ACTIONS} from "./noteActionSlice"
import {setLoadedChildNotesOrder} from "./noteOrderSlice"
//import {detachNoteFromAttachment} from "./noteAttachmentsSlice"

const defaultNoteObjectProperties = {
	order: -1,
	childCount: 0,
	noteChildren: {},
	showChildren: false,
	uniqueCount: 0,
	attachmentCount: 0
}

const noteArrayToFlatObject = (notes) => {
	return notes.reduce((acc, curr, i) => {
		return {
			...acc,
			[curr.id]: {
				...curr,
				noteChildren: {},
				showChildren: false,
				order: i
			}
		}
	}, {})
}

const closeAllChildren = (notes) => {
	const queue = []
	// queue all top level notes

	// Start with top-level nodes
	for (const key in notes) {
		queue.push({ node: notes[key] })
	}

	// Iterate over the queue
	while (queue.length > 0) {
		const { node } = queue.shift()
		// Update the path
		node.showChildren = false
		// Enqueue children notes
		for (const key in node.noteChildren) {
			queue.push({ node: node.noteChildren[key]})
		}
	}
}

const updateNestedPaths = (notes, newTopLevelPath) => {
	// Queue for breadth-first traversal
	const queue = []

	// Start with top-level nodes
	for (const key in notes) {
		queue.push({ node: notes[key], newPath: `${newTopLevelPath}.${key}` })
	}

	// Iterate over the queue
	while (queue.length > 0) {
		const { node, newPath } = queue.shift()
		// Update the path
		node.path = newPath

		// Enqueue children notes
		for (const key in node.noteChildren) {
			queue.push({ node: node.noteChildren[key], newPath: `${newPath}.${key}` })
		}
	}
}

export const getClosestParentNoteByPath = (notes, path, excludeTerminalNode = false) => {
	// Split the path to navigate through the nested structure
	const keys = path.split(".")
	// remove terminal note key
	if (excludeTerminalNode) {
		keys.pop()
	}
	// Reference to the current point in the notes object
	let current = notes
	let lastValidNote = undefined // Store the last valid note

	for (const key of keys) {
		if (current[key]) {
			// If the key exists, update lastValidNote and move to the next level
			lastValidNote = current[key]
			current = current[key].noteChildren || current[key]
		} else {
			// If the key doesn't exist, break the loop and return the last valid note
			break
		}
	}
	return lastValidNote // Return the last valid note found
}

export const getNoteByPath = (notes, path) => {
	// Split the path to navigate through the nested structure
	const keys = path.split(".")
	
	// Reference to the current point in the notes object
	let current = notes
	let targetNote = undefined
	keys.forEach((key, index) => {
		if (index === keys.length - 1) {
			// Last key, update the note here
			targetNote = current[key]
		} else {
			// Navigate to the next level
			current = current[key]?.noteChildren || current[key]
		}
	})
	return targetNote
}

// Helper function to remove a note by path
const removeNoteByPath = (notes, path) => {
	const keys = path.split(".")
	let parent = undefined
	let current = notes

	for (let i = 0; i < keys.length - 1; i++) {
		parent = current[keys[i]]	
		current = current[keys[i]]?.noteChildren || current[keys[i]]
	}

	const lastKey = keys[keys.length - 1]

	if(current[lastKey]){
		const removedNote = current[lastKey]
		delete current[lastKey] // Remove the note
		if (parent) {
			parent.childCount--
			if (parent.childCount === 0) {
				parent.showChildren = false
			}
		}
		return removedNote
	} else {
		return null
	}
}

// Helper function to add a note by path and open tree
const addNoteByPath = (notes, path, note, showChildren = true) => {
	const keys = path.split(".")
	let current = notes
	let parent = undefined
	for (let i = 0; i < keys.length - 1; i++) {
		parent = current[keys[i]]	
		current = current[keys[i]]?.noteChildren || current[keys[i]]
	}
	const lastKey = keys[keys.length - 1]
	current[lastKey] = note // Add the note
	if (parent) {
		parent.showChildren = showChildren ? showChildren : parent.showChildren
		parent.childCount++
	}
}

export const deleteArticleNoteRelationFromIcon = createAsyncThunk(
	"workspaceNotes/deleteArticleNoteRelationFromIcon",
	async (note, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return rejectWithValue("Failed to remove article from note. Invalid Workspace ID")
		const articleId = state.targetArticle
		if(!articleId) return rejectWithValue("Failed to remove article from note. Invalid article ID")
		if(`${articleId}`.includes("attachment")){
			const attachmentId = articleId.split("/")[1]
			const attachment = {
				id: attachmentId,
				noteId: note.id
			}
			await dispatch(detachNoteFromAttachment({note, attachment}))
			return {
				note,
				articleId
			}
		}

		try {
			// this route returns the note path (originally consumed by refresh relay)
			await axios.delete(`/api/workspace/${workspaceId}/note/${note.id}/article/${articleId}`)
			// reload breadcrumbs if articleMap[articleId] !== null
			if (state.breadcrumbSlice?.articleMap[articleId]) {
				dispatch(fetchArticleBreadcrumbs({article: {id: articleId}, note}))
			}
			return {
				note,
				articleId
			}
		} catch (error) {
			return rejectWithValue(error?.response?.message || `Failed to remove note ${note.id} from article using note icon`)
		}
	}
)

export const fetchTopLevelNotes = createAsyncThunk(
	"workspaceNotes/fetchTopLevelNotes",
	async (_, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return {}
		try {
			const {data: topLevelNotes} = await axios
				.get(`/api/workspace/${workspaceId}/note/0/children`)
			const flatNotes = noteArrayToFlatObject(topLevelNotes)
			return flatNotes
		} catch (error) {
			return rejectWithValue(error?.response?.message || "Error fetching children")
		}

	}
)

export const deleteNote = createAsyncThunk(
	"workspaceNotes/deleteNote",
	async (noteId, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		let isTopLevelEmpty = false
		if (!workspaceId) return {}
		try {
			const { data } = await axios.delete(`/api/workspace/${workspaceId}/note/${noteId}`)
			const path = `${data.path}`
			const relatedArticleIds = data.articles
			const unassignedArticles = data.unassignedArticles
			const parentPath = path.split(".").slice(0, -1).join(".")
			let originalParent
			// fetch unique count if parent path exists (not top level note)
			if (parentPath) {
				originalParent = getNoteByPath(state.workspaceNotes.notes, parentPath)
				dispatch(fetchUniqueCounts(originalParent))
			}
			const breadcrumbs = state.breadcrumbSlice.articleMap
			for (const [key] of Object.entries(breadcrumbs)) {
				if(relatedArticleIds.includes(+key)) {
					// update breadcrumb for article
					// passing in a fake article object that only includes id
					dispatch(fetchArticleBreadcrumbs({article: {id: +key}}))
				}
			}
			const isDeletedNoteRoot = path.split(".").length === 1
			if (isDeletedNoteRoot) {
				const topLevelCountAfterDeletion = Object.keys(state.workspaceNotes.notes).length - 1
				isTopLevelEmpty = topLevelCountAfterDeletion === 0
			}
			// return path to remove from tree, related articles to update breadcrumbs, and unassigned articles to add to the unassigned list
			return {path, relatedArticleIds, unassignedArticles, isTopLevelEmpty, partialDeletedNoteNfo: {noteId, parentNoteId: isDeletedNoteRoot ? "root" : originalParent.id}}
		} catch (error) {
			rejectWithValue(error?.response?.message || `Error Deleting Note ${noteId}`)
		}
	}
)

export const fetchNewTopLevelNotesById = createAsyncThunk(
	"workspaceNotes/fetchNewTopLevelNotesById",
	async (noteIds, { getState, rejectWithValue }) => {
		try {
			const state = getState()
			const workspaceId = state.workspace?.value?.id
			const currentUserId = state.user?.value?.id
			// Construct the base URL
			const baseUrl = `api/workspace/${workspaceId}/user/${currentUserId}/note`
			// Append the note IDs as query parameters
			const queryParams = noteIds.map(id => `noteIds=${id}`).join("&")
			const url = `${baseUrl}?${queryParams}`
			// Make the GET request
			const response = await axios.get(url)
			// Return the response data (or handle it as needed)
			return response.data
		} catch (error) {
			// Handle or throw the error
			console.error("Error fetching notes", error)
			rejectWithValue("Failed to fetch notes by id")
		}
	}
)

export const addNote = createAsyncThunk(
	"workspaceNotes/addNote",
	async ({note, content}, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return rejectWithValue("addNote thunk failed to find workspaceId")
		// check if adding sibling note
		const addingSiblingNote = state.noteAction?.value.action === NOTE_ACTIONS.addSibling
		// if adding sibling, fetch the target note's parent and use that as parent note :OR: if adding child / root note, set target note as parent note
		const parentNote = addingSiblingNote ? getClosestParentNoteByPath(state.workspaceNotes?.notes, note.path, true) : note
		const addingRootNote = state.noteAction?.value.action === NOTE_ACTIONS.addRoot
		try {
			// check if parent needs to open
			let incomingChildNotes = []
			if (!addingSiblingNote && !note.showChildren && note.childCount > Object.keys(note.noteChildren || {}).length) {
				const {data: children } = await axios.get(`/api/workspace/${workspaceId}/note/${note.id}/children`)
				incomingChildNotes = children
			}
			// create new note
			const {data: newNote} = await axios
				.post(`/api/workspace/${note.workspaceId}/note`, {
					parentId: addingRootNote ? null : parentNote.id,
					path: addingRootNote ? "" : parentNote.path,
					content: content,
					workspaceId: note.workspaceId,
				})
			// modify note order for positioning to ensure that siblings appear directly above the target note
			const newNoteObj = {...defaultNoteObjectProperties, ...newNote}
			if(addingSiblingNote) {
				newNoteObj.order = note.order
			} else {
				newNoteObj.order = 0
			}
			return {newNote: newNoteObj, parentNote, incomingChildNotes, invokingNote: note, addingSiblingNote}
		} catch (error) {
			return rejectWithValue(error?.response?.message || "Failed to create the note, please try again")
		}
	}
)

export const moveNote = createAsyncThunk(
	"workspaceNotes/moveNote",
	async ( {movingNote, receivingNoteId, receivingPath}, { getState, rejectWithValue, dispatch }) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return {}
		const invokingNote = state.targetNote?.value

		try {
			// Check if new parent note is closed and needs to be opened with additional children
			const newParentNote = getNoteByPath(state.workspaceNotes.notes, receivingPath)
			let incomingChildNotes = []
			if (newParentNote && !newParentNote.showChildren &&
				newParentNote.childCount > Object.keys(newParentNote.noteChildren || {}).length) {
				const {data: children } = await axios.get(`/api/workspace/${workspaceId}/note/${newParentNote.id || 0}/children`)
				incomingChildNotes = children
			}
			// Update note move on server
			const {data: newNote} = await axios.put(`/api/workspace/${workspaceId}/note/${movingNote.id}/location`, {
				receivingNoteId,
				movingNote,
			})
			// update unique counts for all parent notes in new location
			if (newParentNote) {
				// update receiving parent note hierarchy
				dispatch(fetchUniqueCounts(newParentNote))
			}
			// update original parent note hierarchy
			const parentPathArray = movingNote.path.split(".")
			//only create a new parentPath if the note isn't moving away from root, otherwise the parentPath value will be a blank string
			const parentPath = parentPathArray.length > 1 ? movingNote.path.split(".").slice(0, -1).join(".") : movingNote.path
			const originalParent = getNoteByPath(state.workspaceNotes.notes, parentPath)
			dispatch(fetchUniqueCounts(originalParent))
			return {newNote, originalNote: movingNote, incomingChildNotes, receivingPath, originalNoteLocation: {parentId: movingNote.parentId === null ? null : originalParent.id}, invokingNote}
		} catch(error) {
			rejectWithValue(error?.response?.message || "Error moving note")
		}
	}
)

export const fetchUniqueCounts = createAsyncThunk(
	"workspaceNotes/fetchUniqueCounts",
	async (note, {getState, rejectWithValue}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return rejectWithValue("Failed to fetch unique counts. Invalid WorkspaceId")
		try {
			const { data } = await axios.get(`/api/workspace/${workspaceId}/note/${note.id}/uniqueCount`)
			return data
		} catch (error) {
			return rejectWithValue(error?.response?.message || `Error fetching unique count for Note ${note.id}`)
		}
	}
)

export const fetchUniqueCountsForEachPath = createAsyncThunk(
	"workspaceNotes/fetchUniqueCountsForEachPath",
	async (paths, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return rejectWithValue("Failed to fetch unique counts. Invalid WorkspaceId")
		try {
			paths.forEach( path => {
				const targetNote = getClosestParentNoteByPath(state.workspaceNotes.notes, path)
				if(targetNote) {
					dispatch(fetchUniqueCounts(targetNote))
				}
			})
		} catch (error) {
			return rejectWithValue(error?.response?.message || `Error fetching unique count for some or all notes in path array ${paths}`)
		}
	}
)

export const openToPath = createAsyncThunk(
	"workspaceNotes/openToPath",
	async ({path, note, insertTarget = false, fromWebsocket = false}, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const workspaceId = state.workspace?.value?.id
			if (!workspaceId) return {}
			// Split the path to navigate through the nested structure
			const keys = `${path}`.split(".")

			// Reference to the current point in the notes object
			let allNotes =  cloneDeep(state.workspaceNotes.notes)
			let current = allNotes
			let lastValidNote = undefined
			let isParentInMemory = false
			// is incoming note parent in the current state and is not parent note
			const closestParent = getClosestParentNoteByPath(allNotes, note.path)

			if ((closestParent?.id === note.parentId && note.parentId) || !note.parentId) {
				isParentInMemory = true
			}

			if (!fromWebsocket || (fromWebsocket && isParentInMemory)) {
				for (const key of keys) {
					lastValidNote = current[key]

					if (!lastValidNote && key == note.id && insertTarget) {
						// check if incoming note is top level
						// if incoming note is not top level, than update parent note count
						if (!(keys.length === 1)) {
							const targetNoteParent = getNoteByPath(allNotes, note.path.split(".").slice(0, -1).join("."))
							targetNoteParent.childCount++
						} 

						lastValidNote = {
							...defaultNoteObjectProperties,
							...note
						}
					}
	
					// If closed and children not loaded, then fetch children and set open
					if ((Object.keys(lastValidNote.noteChildren || {}).length !== lastValidNote.childCount && key != note.id) && !fromWebsocket) {
						let {data: children} = await axios.get(`/api/workspace/${workspaceId}/note/${lastValidNote.id}/children`)
						const flatNotes = noteArrayToFlatObject(children)
						dispatch(setLoadedChildNotesOrder(flatNotes))
						current[key] = {
							...lastValidNote,
							noteChildren: flatNotes,
							showChildren: true
						}
					} else {
						current[key] = {
							...lastValidNote,
							showChildren: !fromWebsocket ? (key != note.id) : lastValidNote.showChildren
						}
					}
					current = current[key].noteChildren || current[key]
				}
			}
			dispatch(fetchUniqueCounts(note))
			return {allNotes, note, insertTarget, fromWebsocket}
		} catch (error) {
			console.error("error opening note", error)
			return rejectWithValue(error?.response?.message || `Error opening note tree to path ${path}`)
		}
	}
)


export const fetchChildren = createAsyncThunk(
	"workspaceNotes/fetchChildren",
	async ( note, { getState, dispatch, rejectWithValue }) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return {}
		// is showing, just close
		if (note.showChildren) {
			// close all children
			const clonedNote = cloneDeep(note)
			closeAllChildren(clonedNote.noteChildren)
			return {
				...clonedNote,
				showChildren: false
			}
		}
		try {
			// If closed and children not loaded, then fetch children and set open
			if (Object.keys(note.noteChildren || {}).length !== note.childCount) {
				const {data: children} = await axios.get(`/api/workspace/${workspaceId}/note/${note.id}/children`)
				const flatNotes = noteArrayToFlatObject(children)

				dispatch(setLoadedChildNotesOrder(flatNotes))
				return {
					...note,
					noteChildren: flatNotes,
					showChildren: true
				}
			} else {
				// else just open the note!
				return {
					...note,
					showChildren: true
				}
			}
		} catch (error) {
			console.error("error fetching child", error)
			return rejectWithValue({message: "Error fetching children", noteId: note.id})
		}


	}
)

export const workspaceNoteSlice = createSlice({
	name: "workspaceNotes",
	initialState: {
		notes: {},
		loading: false,
		submittingNote: {},
		error: null,
		attachError: null,
		addNoteError: null,
		loadingChildren: []
	},
	reducers: {
		updateNoteContent: (state, action) => {
			const newNote = action.payload
			const noteToUpdate = getNoteByPath(state.notes, newNote.path)
			if (noteToUpdate) {
				noteToUpdate.content = newNote.content
			}
		},
		moveNoteInTree: (state, action) => {
			// START HERE, WHAT HAPPENS WHEN ORIGINAL NOTE IS NOT IN MEMORY
			// What happens when receiving path is not in memory?
			const {newNote, originalNote, incomingChildNotes, receivingPath} = action.payload

			const receivingNote = getNoteByPath(state.notes, receivingPath)
			let originalNoteRemoved = removeNoteByPath(state.notes, originalNote.path)
			if (!originalNoteRemoved) {
				// update originalNoteRemoved if original note is not in redux
				originalNoteRemoved = newNote
			} else {
				// Update original note path
				originalNoteRemoved.path = newNote.path
			}
			// Update parent children if needed (only happens if parent was closed)
			if (incomingChildNotes.length > 0 && receivingNote) {
				const parentNote = getNoteByPath(state.notes, receivingPath)
				const flatNotes = noteArrayToFlatObject(incomingChildNotes)
				parentNote.noteChildren = flatNotes
			}
			// Update child paths, open and update child count
			updateNestedPaths(originalNoteRemoved.noteChildren, newNote.path)
			if (originalNoteRemoved) {
				// Step 2: Add the note to the new location
				addNoteByPath(state.notes, newNote.path, {
					...newNote,
					childCount: originalNote.childCount,
					noteChildren: originalNoteRemoved.noteChildren,
					showChildren: originalNoteRemoved.showChildren

				}, false)
			}
		},
		addNoteToTree: (state, action) => {
			const newNote = action.payload
			// if top level note then just add
			if (!newNote.parentId) {
				addNoteByPath(state.notes, newNote.path, {...newNote, ...defaultNoteObjectProperties})
			}
			// check if note is in path
			const closestNote = getClosestParentNoteByPath(state.notes, newNote.path)
			// if closestNote is parent then 
			if (newNote.parentId === closestNote.id) {
				// if parent is open, add note to parent
				if (closestNote.showChildren) {
					closestNote.noteChildren[newNote.id] = {...newNote, ...defaultNoteObjectProperties}
				} 
				closestNote.childCount = closestNote.childCount ? closestNote.childCount + 1 : 1
			}
		},
		deleteNoteFromTree: (state, action) => {
			removeNoteByPath(state.notes, action.payload?.path)
		},
		setSubmittingNote: (state, action) => {
			state.submittingNote = action.payload
		},
		clearAttachError: (state) => {
			state.attachError = null
		},
		clearAddNoteError: (state) => {
			state.addNoteError = null
			state.submittingNote = {}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNewTopLevelNotesById.fulfilled, (state, action) => {
				const incomingNotes = action.payload
				const notes = state.notes
				// Add new notes from incomingNotes to notes
				incomingNotes.forEach((note, i )=> {
					if (!Object.prototype.hasOwnProperty.call(notes, note.id.toString())) {
						notes[note.id] = {...note, order: i}
					}
				})
				// remove any note that is note in the incoming list
				let incomingIds = new Set(incomingNotes.map(note => note.id))
				// Iterate over the keys of noteEntries and delete entries not in incomingIds
				for (let id in notes) {
					if (!incomingIds.has(parseInt(id))) {
						delete notes[id]
					}
				}
			})
			.addCase(openToPath.fulfilled, (state, action) => {
				state.notes = action.payload.allNotes
			})
			.addCase(deleteNote.fulfilled, (state, action) => {
				const { path } = action.payload
				removeNoteByPath(state.notes, path)
			})
			.addCase(fetchUniqueCounts.fulfilled, (state, action) => {
				const listOfArticleNoteData = action.payload
				listOfArticleNoteData.forEach(note => {
					let targetNote
					const keys = note.path.split(".")
					keys.forEach( key => {
						if (!targetNote) {
							targetNote = state.notes[key]
						} else {
							targetNote = targetNote[key]
						}
						// target note might note be loaded in
						// ignore updating target note if not loaded in
						if (targetNote) {
							targetNote.attachmentNoteCount = note.attachmentNoteCount
							targetNote.articleNoteCount = note.articleNoteCount
							targetNote.uniqueCount = note.uniqueCount
							targetNote.attachmentCount = note.attachmentCount
							targetNote = targetNote.noteChildren
						}

					})
				})

			})
			.addCase(fetchUniqueCounts.rejected, (state, action) => {
				state.attachError = action.payload
			})
			.addCase(addNote.fulfilled, (state, action) => {
				const {newNote, parentNote, incomingChildNotes} = action.payload
				if (incomingChildNotes.length > 0) {
					const parentNoteState = getNoteByPath(state.notes, parentNote.path)
					const flatNotes = noteArrayToFlatObject(incomingChildNotes)
					parentNoteState.noteChildren = flatNotes
				}
				addNoteByPath(state.notes, newNote.path, newNote)
				state.submittingNote = {}
			})
			.addCase(addNote.rejected, (state, action) => {
				state.addNoteError = action.payload
			})
			.addCase(moveNote.fulfilled, (state, action) => {
				const {newNote, originalNote, incomingChildNotes, receivingPath} = action.payload
				// Locate and remove the original note
				const originalNoteRemoved = removeNoteByPath(state.notes, originalNote.path)
				// Update original note path
				originalNoteRemoved.path = newNote.path
				// Update parent children if needed (only happens if parent was closed)
				if (incomingChildNotes.length > 0) {
					const parentNote = getNoteByPath(state.notes, receivingPath)
					const flatNotes = noteArrayToFlatObject(incomingChildNotes)
					parentNote.noteChildren = flatNotes
				}
				// Update child paths, open and update child count
				updateNestedPaths(originalNoteRemoved.noteChildren, newNote.path)
				if (originalNoteRemoved) {
					// Step 2: Add the note to the new location
					addNoteByPath(state.notes, newNote.path, {
						...newNote,
						childCount: originalNote.childCount,
						noteChildren: originalNoteRemoved.noteChildren,
						showChildren: originalNoteRemoved.showChildren

					})
				}
			})
			.addCase(moveNote.rejected, (state, action) => {
				state.error = action.payload
			})
			.addCase(fetchTopLevelNotes.pending, (state) => {
				state.loading = true
				state.error = null
			})
			.addCase(fetchTopLevelNotes.fulfilled, (state, action) => {
				state.loading = false
				state.notes = action.payload
			})
			.addCase(fetchTopLevelNotes.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
			.addCase(fetchChildren.pending, (state, action) => {
				// Add the note ID to the loadingChildren array if it's not already there
				if (!state.loadingChildren.includes(action.meta.arg.id)) {
					state.loadingChildren.push(action.meta.arg.id)
				}
				state.childrenError = null
			})
			.addCase(fetchChildren.fulfilled, (state, action) => {
				// Update the note with its children
				const newNote = action.payload			
				const notePath = newNote.path
				// remove id from loadingChildren array
				state.loadingChildren = state.loadingChildren.filter(id => id !== newNote.id)
				// Split the path to navigate through the nested structure
				const keys = notePath.split(".")
				// Reference to the current point in the notes object
				let current = state.notes
				keys.forEach((key, index) => {
					if (index === keys.length - 1) {
						// Last key, update the note here
						current[key] = newNote
					} else {
						// Navigate to the next level
						current = current[key]?.noteChildren || current[key]
					}
				})
			})
			.addCase(fetchChildren.rejected, (state, action) => {
				// remove id from loadingChildren array
				const {message, noteId} = action.payload
				state.loadingChildren = state.loadingChildren.filter(id => id !== noteId)
				state.childrenError = message
			})
	}
})

export const { updateNoteContent, setSubmittingNote, clearAttachError, addNoteToTree, deleteNoteFromTree, moveNoteInTree, clearAddNoteError } = workspaceNoteSlice.actions

// Selector to get the notes object from the state
const selectNotes = (state) => state.workspaceNotes.notes

// Selector to count the top-level notes
export const selectTopLevelNoteCount = createSelector(
	[selectNotes],
	(notes) => Object.keys(notes).length
)

// Modified selector to return an array of allowed and sorted notes
export const selectTopLevelNotes = createSelector(
	[selectNotes, selectAllowedNoteIds, selectIsLoading],
	(notes, allowedNoteIds, isLoading) => {
		// short circuit if note restrictions are still loading
		if (isLoading) {
			return []
		}
		// sort filtered notes
		if (allowedNoteIds.length !== 0) {
			const filteredNotes = Object.values(notes).filter(note => allowedNoteIds.includes(note.id))
			return filteredNotes.sort((x, y) => x.order - y.order)
		}
		// Sort raw notes
		return Object.values(notes).sort((x, y) => x.order - y.order)
	}
)
// Selector for loading state
export const selectLoading = (state) => state.workspaceNotes.loading

// Selector for loading children
export const selectLoadingChildren = (state) => state.workspaceNotes.loadingChildren

// Selector for error state
export const selectError = (state) => state.workspaceNotes.error

// Selector for attach article error state
export const selectAttachError = (state) => state.workspaceNotes.attachError


// Selector for add note error state
export const selectAddNoteError = (state) => state.workspaceNotes.addNoteError

// Selector for submitting note
export const selectSubmittingNote = (state) => state.workspaceNotes.submittingNote



export default workspaceNoteSlice.reducer