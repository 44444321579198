import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { detachNoteFromAttachment } from "./noteAttachmentsSlice"

const initialValue = {
	related: [],
	attached: [],
}


export const setWorkingAttachmentRelation = createAsyncThunk(
	"workingArticleNoteRelation/setWorkingAttachmentRelation",
	async ({attachmentId},  {getState, rejectWithValue}) => {
		try {
			const state = getState()
			const workspaceId = state.workspace?.value?.id
			const userId = state.user?.value?.id

			// api/workspace/:workspaceId/user/:userId/attachment
			const url = `/api/workspace/${workspaceId}/user/${userId}/attachment/${attachmentId}/note-relations`
			const { data } = await axios.get(url)
			return data
		} catch (error) {
			return rejectWithValue("Something went wrong fetching attachment note relationships")
		}
	}
)

export const workingArticleNoteRelationSlice = createSlice({
	name: "workingArticleNoteRelation",
	initialState: {
		value: initialValue,
	},
	reducers: {
		setWorkingArticleNoteRelation: (state, action) => {
			state.value = action.payload
		},
		resetWorkingArticleNoteRelation: (state) => {
			state.value = initialValue
		}
	},
	extraReducers: (builder) => {
		builder.addCase(
			setWorkingAttachmentRelation.fulfilled,
			workingArticleNoteRelationSlice.caseReducers.setWorkingArticleNoteRelation
		)
		builder.addCase(detachNoteFromAttachment.fulfilled, )
	}
})

export const {
	setWorkingArticleNoteRelation,
	resetWorkingArticleNoteRelation
} = workingArticleNoteRelationSlice.actions

export const selectWorkingArticleNoteRelation = (state) =>
	state.workingArticleNoteRelation.value

export const setWorkingArticleNoteRelationAsync =
	(workspaceId, articleId) => async (dispatch) => {
		axios
			.get(`/api/workspace/${workspaceId}/article/${articleId}/note-relations`)
			.then(({ data }) => {
				dispatch(setWorkingArticleNoteRelation(data))
			})
	}

export default workingArticleNoteRelationSlice.reducer
