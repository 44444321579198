import {Box, Button, CircularProgress, Modal, TextField, Tooltip, Typography, useTheme} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {useSnackbar} from "notistack"
import {useState} from "react"
import axios from "axios"
import {AUTOMATED_SEARCH_PANEL_VIEW, setAutomatedSearchPanelView, setSelectedSearch, updateSearchCounts, upsertSingleSearch} from "../../../reducers/automatedSearchViewSlice"
import {Close, InfoTwoTone, Launch} from "@mui/icons-material"
import { selectSessionTimestamp } from "../../../reducers/sessionTimestampSlice"

export function AutomatedSearchForm({
	workspaceId,
	searchToUpdate,
	setSearchToUpdate,
}) {
	const theme = useTheme()
	const dispatch = useDispatch()
	const {enqueueSnackbar} = useSnackbar()
	const [searchIsUpdating, setSearchIsUpdating] = useState(false)
	const [query, setQuery] = useState((searchToUpdate || {}).query || "")
	const [title, setTitle] = useState((searchToUpdate || {}).title || "")
	const sessionTimestamp = useSelector(selectSessionTimestamp)
	const updateSearch = () => {
		axios
			.put(`/api/workspace/${workspaceId}/auto-search/${searchToUpdate.id}`, {
				query,
				title,
			})
			.then(({data}) => {
				dispatch(upsertSingleSearch(data))
				setSearchToUpdate(null)
				dispatch(updateSearchCounts({searchId: searchToUpdate.id}))
				enqueueSnackbar(
					"Your search has been updated.",
					{
						variant: "success",
						autoHideDuration: 3000,
					}
				)
				dispatch(
					setAutomatedSearchPanelView(AUTOMATED_SEARCH_PANEL_VIEW.SEARCH_LIST)
				)
			}).catch(() => {
				enqueueSnackbar(
					"Sorry that update to the search failed.",
					{
						variant: "error",
						autoHideDuration: 3000,
					}
				)
			}).finally(() => {
				setSearchIsUpdating(false)
			})
	}

	const createSearch = () => {
		setSearchIsUpdating(true)
		axios
			.post(`/api/workspace/${workspaceId}/auto-search`, {
				query,
				title,
				sessionTimestamp
			})
			.then(({data}) => {
				dispatch(setSelectedSearch(data.search))
				dispatch(upsertSingleSearch(data))
			})
			.finally(() => {
				dispatch(
					setAutomatedSearchPanelView(AUTOMATED_SEARCH_PANEL_VIEW.SEARCH_LIST)
				)
				setSearchIsUpdating(false)
			})
	}

	const handleSubmit = () => {
		if (query == "" || title == "") {
			enqueueSnackbar(
				"Please insert a search query AND a title.",
				{
					variant: "error",
					autoHideDuration: 3000,
				}
			)
		} else {
			if (searchToUpdate) {
				setSearchIsUpdating(true)
				updateSearch()
			} else {
				createSearch()
			}
		}
	}

	return (
		<Box
			sx={{
				height: "100vh",
				background: theme.palette.primary.light,
			}}
		>
			<Box
				sx={{
					margin: "0 8px",
					padding: "20px",
					borderRadius: "5px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						padding: "0 0 10px 0",
						gap: "5px",
						alignItems: "center",
						justifyContent: "end",
					}}
				>
					<a
						style={{ textDecoration: "none", color: "blue" }}
						href={"https://pubmed.ncbi.nlm.nih.gov/advanced/"}
						target={"_blank"}
						rel={"noreferrer"}
					>
						PubMed's Query Builder
						<Launch
							sx={{
								transform: "scale(0.9)",
								fontSize: "1rem",
							}}
						/>
					</a>
					<HowToMakeAutomatedSearch />
				</Box>
				<Box
					sx={{
						background: "white",
						borderRadius: "5px",
						padding: "20px 10px",
						display: "flex",
						flexDirection: "column",
						gap: "5px",
					}}
				>
					<TextField
						id="outlined-textarea"
						label="Paste or enter search term here"
						InputLabelProps={{ required: true }}
						multiline
						rows={3}
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						required
					/>

					<br />
					<TextField
						value={title}
						label="Make a simple title for this search"
						InputLabelProps={{ required: true }}
						onChange={(e) => setTitle(e.target.value)}
						required
					/>
					<br />
					<Box
						display={"flex"}
						justifyContent={"space-between"}>
						{searchIsUpdating ?
							<CircularProgress></CircularProgress> :
							<>
								<Button
									sx={{ width: "33%" }}
									onClick={() => handleSubmit()} variant={"contained"}>
									Submit
								</Button>

								<Button
									onClick={() => {
										dispatch(
											setAutomatedSearchPanelView(
												AUTOMATED_SEARCH_PANEL_VIEW.SEARCH_LIST
											)
										)
									}}
									variant={"outlined"}>
									Cancel
								</Button>
							</>
						}
					</Box>



				</Box>
			</Box>
		</Box>
	)
}

function HowToMakeAutomatedSearch() {
	const [open, setOpen] = useState(false)

	return (
		<>
			<Button
				color={"info"}
				variant={"contained"}
				size={"small"}
				onClick={() => setOpen(true)}
			>
				<span style={{ paddingRight: "5px" }}>How to make a search</span>
				<InfoTwoTone />
			</Button>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Box className={"modal-container"} sx={{ textAlign: "" }}>
					<ul style={{ listStyleType: "decimal" }}>
						<li>
							Navigate to{" "}
							<a
								href={"https://pubmed.ncbi.nlm.nih.gov/advanced/"}
								target={"_blank"}
								rel={"noreferrer"}
							>
								PubMed's Query Builder
							</a>
						</li>
						<li>Build a query you'd like to automate</li>
						<li>Copy the query and paste into the upper search term box.</li>
						<li>
							Enter a convenient title into the lower box. Multiple searches are
							displayed alphabetically.
						</li>
						<li>Press "Submit"</li>
					</ul>
					<Typography variant={"body1"}>
						You will receive alerts once per day if new results are found.
					</Typography>
					<ul style={{ listStyleType: "circle" }}>
						<li>All searches are updated automatically every 24 hours.</li>
						<li>
							Citations will remain in each automated search until imported or
							excluded.
						</li>
						<li>
							Importing or exporting a citation from one automated search will
							remove that citation from any other pending search.
						</li>
						<li>
							Citations already imported into the Workspace or previously
							excluded will not appear in an automated search.
						</li>
						<li>
							Citations imported or excluded will be removed from the list on
							next browser session or when list is refreshed.
						</li>
					</ul>
					<span
						style={{
							maxWidth: "25px",
							minWidth: "25px",
							position: "absolute",
							right: "5px",
							top: "5px",
						}}
					>
						<Tooltip title={"Close Info Pane"}>
							<Close
								onClick={() => setOpen(false)}
								sx={{
									cursor: "pointer",
									color: "black",
								}}
							/>
						</Tooltip>
					</span>
				</Box>
			</Modal>
		</>
	)
}
