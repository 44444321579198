import { useEffect, useState } from "react"
import axios from "axios"
import Loading from "../../../Loading"
import AvailableLicenses from "./AvailableLicenses"
import {Box, Typography} from "@mui/material"
import KeysInUse from "./KeysInUse"
import AdjustSeatsButtons from "./AdjustSeatsButtons"

export default function Licenses({enqueueSnackbar}) {

	const [loadingAllKeys, setLoadingAllKeys] = useState(true)
	const [activeKeys, setActiveKeys] = useState()
	const [inactiveKeys, setInactiveKeys] = useState()
	const [numOfKeys, setNumOfKeys] = useState()

	const sortKeys = (keysObject) => {
		const keys = keysObject.keys
		const activeKeys = keys.filter((key) => (key.used === true || key.status === "SENT"))
		setActiveKeys(activeKeys)
		const inactiveKeys = keys.filter((key) => key.used === false && key.status === "OPEN")
		setInactiveKeys(inactiveKeys)
	}

	const fetchKeys = () => {
		axios
			.get("/api/license/")
			.then(({ data }) => {
				sortKeys(data)
				setNumOfKeys(data.keys?.length || 0)
			})
			.catch(() => {
				enqueueSnackbar("Failed to load your owned keys", {
					variant: "error",
					autoHideDuration: 3000,
				})
			})
			.finally(() => {
				setLoadingAllKeys(false)
			})
	}

	useEffect(() => {
		fetchKeys()
	}, [])

	return loadingAllKeys == false ? (
		<>
			{ activeKeys.length > 0 && (
				<Box sx={{ mb: "1.3em" }}>
					<Typography variant={"tunedH2"}>
						Seats In Use
					</Typography>
					<Typography
						variant={"tunedSubtitle2"}
						paragraph
						sx={{
							padding: "1em 0",
							margin: 0,
							color: "RBOneFont.main"
						}}
					>
						You are currently using {activeKeys.length} out of {numOfKeys} subscription seats
					</Typography>
					{activeKeys &&
						<KeysInUse
							usedKeys={activeKeys}
							refreshLicenses={fetchKeys}
							enqueueSnackbar
						/>
					}
				</Box>
			) }
			<Box sx={{ mb: "0.5em" }}>
				<Typography variant={"tunedH2"}>
					Invite Users
				</Typography>
				{inactiveKeys ? 
					inactiveKeys.map((license, index) => (
						<AvailableLicenses
							key={license.UUID}
							index={index}
							license={license}
							enqueueSnackbar={enqueueSnackbar}
							total={inactiveKeys.length}
							refreshLicenses={fetchKeys}/>
					)): ""
				}
			</Box>
			<AdjustSeatsButtons
				refreshLicenses={fetchKeys}
				enqueueSnackbar={enqueueSnackbar}
				inactiveKeys={inactiveKeys}
			/>
		</>
	) : ( <Loading /> )
}