import { useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	selectWorkspaceExclusions,
	selectWorkspaceId,
	selectWorkspace,
	setWorkspaceThunk
} from "../../../reducers/workspaceSlice"
import RawArticle from "../../articles/RawArticle"
import { useSnackbar } from "notistack"
import PanelLoading from "../../PanelLoading"
import { fetchArticles, selectIsLoading, selectArticles, updateArticle } from "../../../reducers/notificationPanelSlice"
import NotificationListItem from "./NotificationListItem"
import ImportedArticle from "../../articles/ImportedArticle"
import { selectUnassignedArticleIds } from "../../../reducers/unassignedArticlesEventsSlice"
import { markNotificationsAsRead, selectArticleShareNotifications, selectWorkspaceInviteNotifications } from "../../../reducers/notificationsSlice"
import { checkExclusion } from "../../../utils/articleUtilities"
import axios from "axios"
import {resetWorkspaceSearches} from "../../../reducers/workspaceSearchSlice"
import { resetSearch as resetArticleSearch } from "../../../reducers/articleSearchSlice"
import { resetSearch as setAutomatedSearchArticles } from "../../../reducers/automatedSearchSlice"
import { resetSearch as resetNoteArticles } from "../../../reducers/noteArticlesSlice"
import { SocketContext } from "../../../context/socket.context"
import { selectUser } from "../../../reducers/userSlice"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box } from "@mui/material"
import {getImportedArticleCountForWorkspace} from "../../../reducers/freeTierTrackerslice"

export default function NotificationPanel() {

	// configurations
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()

	// local state
	const [targetArticle, setTargetArticle] = useState(null)
	const [confirmModalOpen, setConfirmModalOpen] = useState(false)
	const [shareWorkspaceId, setShareWorkspaceId] = useState(null)
	const [targetWorkspace, setTargetWorkspace] = useState(null)

	// global state
	const workspaceId = useSelector(selectWorkspaceId)
	const workspaceExcludedArticles = useSelector(selectWorkspaceExclusions)
	const articles = useSelector(selectArticles) 
	const isLoading = useSelector(selectIsLoading) 	
	const unassignedArticleIds = useSelector(selectUnassignedArticleIds)
	const fetchedArticleNotifications = useSelector(selectArticleShareNotifications)
	const workspaceInviteNotifications = useSelector(selectWorkspaceInviteNotifications)
	const currentWorkspace = useSelector(selectWorkspace)
	const user = useSelector(selectUser)
	const socket = useContext(SocketContext)

	// additional component logic and handlers
	const sharedData = fetchedArticleNotifications.concat(workspaceInviteNotifications).map(notification => ({
		userName: `${notification.sendingUser.firstName} ${notification.sendingUser.lastName}`,
		articles: notification.sharedArticles,
		date: new Date(notification.created_at),
		message: notification.message,
		notificationId: notification.id,
		isRead: notification.isRead,
		type: notification.type,
		sendingWorkspace: notification.sendingWorkspace,
		sharedWorkspaceId: notification.sendingWorkspace ? notification.sendingWorkspace.id : undefined
	}))

	const handleUpdateArticle = (article) => {
		dispatch(updateArticle(article))
	}

	const handleNotificationClick = async (data) => {
		if (data.type === "article_share") {
			setShareWorkspaceId(null)
			const fetchResult = await dispatch(fetchArticles({ workspaceId, sharedArticleArray: data.articles, enqueueSnackbar, sharedWorkspaceId: data.sharedWorkspaceId  }))
	
			if (fetchResult.payload) { // Check if the payload exists, indicating success.
				dispatch(markNotificationsAsRead(data.notificationId))
				if(fetchResult.payload.sharedWorkspaceId){
					setShareWorkspaceId(fetchResult.payload.sharedWorkspaceId)
				}
			}
		} 
		if (data.type === "workspace_invite") {
			dispatch(markNotificationsAsRead(data.notificationId))
			if (data.sendingWorkspace.id === currentWorkspace.id) {
				enqueueSnackbar(
					`You are already in the ${data.sendingWorkspace.title} workspace`,
					{
						variant: "info",
						autoHideDuration: 3000,
					}
				)
			} else {
				setTargetWorkspace(data)
				setConfirmModalOpen(true)
			}
		}

	}

	const handleCancel = () => {
		setConfirmModalOpen(false)
	}

	const handleAgree = async () => {
		if (targetWorkspace) {
			try {
				const url = `/api/workspace/${targetWorkspace.sharedWorkspaceId}/userWorkspace`
				const {data: userWorkspace} = await axios.get(url)
				const workspace = userWorkspace.workspace
				const {admin, read, write} = userWorkspace
				const permissions = {admin, read, write}
				socket.emit("LEAVE_WORKSPACE", user.sub, currentWorkspace.id)
				socket.emit("ENTER_WORKSPACE", user.sub, workspace.id)
				dispatch(resetArticleSearch())
				dispatch(setAutomatedSearchArticles())
				dispatch(resetNoteArticles())
				dispatch(resetWorkspaceSearches())
				dispatch(setWorkspaceThunk({
					...workspace,
					permissions,
					ownerIsFreeTier: userWorkspace.ownerIsFreeTier
				}))
				if(userWorkspace.ownerIsFreeTier) {
					dispatch(getImportedArticleCountForWorkspace())
				}
				localStorage.removeItem("recently-viewed-articles")
				setConfirmModalOpen(false)
			} catch(e) {
				enqueueSnackbar(
					"Something went wrong switching workspaces",
					{
						variant: "error",
						autoHideDuration: 3000,
					}
				)
			}
		} else {
			enqueueSnackbar(
				"Something went wrong selecting the workspace notification",
				{
					variant: "error",
					autoHideDuration: 3000,
				}
			)
		}
	}
	
	return (
		<>
			<Dialog
				open={confirmModalOpen}
				onClose={() => setConfirmModalOpen(false)}
			>
				<DialogTitle>
					Switch workspaces
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						You are about to switch the <Typography variant="accentedText">{targetWorkspace?.sendingWorkspace?.title}</Typography> workspace
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel}>Cancel</Button>
					<Button onClick={handleAgree}>Confirm</Button>
				</DialogActions>
			</Dialog>
			{isLoading && (
				<PanelLoading />
			)}
			{!isLoading && (
				<Box className="OVERFLOW-NOTI" sx={{overflowY: "scroll", height: "100%"}}>
					{
						!(articles.length > 0) && (
							<>
								{
									sharedData.map((data, index) => (
										<div key={data.userName + index}onClick={() => handleNotificationClick(data)}>
											<NotificationListItem sharedCitationObject={data} />
										</div>
									))
								}
							</>
						)
					}
					{
						(articles.length > 0) && (
							<div>
								<div>
									{
										articles.map((sharedArticle, index) => {
											const isDeleted = !!sharedArticle.deletedAt
											if (sharedArticle.articleId && !isDeleted) {
												return (
													<ImportedArticle
														key={sharedArticle.articleId}
														articleWorkspace={sharedArticle}
														targetArticle={targetArticle}
														setTargetArticle={setTargetArticle}
														isUnassigned={unassignedArticleIds.includes(sharedArticle.articleId)}
														index={index}
													/>
												)
											} else {
												const rawArticleObject = isDeleted ? sharedArticle.article : sharedArticle
												return(
													<RawArticle
														key={rawArticleObject.PMID || rawArticleObject.DOI || rawArticleObject.ISBN || rawArticleObject.ARXIVID || rawArticleObject.id}
														workspaceId={workspaceId}
														article={rawArticleObject}
														targetArticle={targetArticle}
														setTargetArticle={setTargetArticle}
														setArticleAsImported={handleUpdateArticle}
														isExcluded={checkExclusion(rawArticleObject.PMID, workspaceExcludedArticles)}
														index={index}
														isSharedFromOtherWorkspace={shareWorkspaceId}
														deletedAt={isDeleted}
													/>
												)
											}
										})
									}
								</div>
							</div>
						)
					}
				</Box>
			)}

		</>
	)
}