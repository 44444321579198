import { createSlice } from "@reduxjs/toolkit"

export const automatedSearchPanelNavSlice = createSlice({
	name: "automatedSearchPanelNav",
	initialState: {
		value: null,
	},
	reducers: {
		setAutomatedSearchPanelNav: (state, action) => {
			state.value = action.payload
		},
		resetAutomateSearchPanelNav: (state) => {
			state.value = null
		},
	},
})

export const { setAutomatedSearchPanelNav, resetAutomateSearchPanelNav } =
	automatedSearchPanelNavSlice.actions

export const selectAutomatedSearchPanelNav = (state) =>
	state.automatedSearchPanelNav.value

export default automatedSearchPanelNavSlice.reducer
