import {
	Box,
	Card,
	List,
	ListItem,
	Typography,
} from "@mui/material"

const SingleNoteBreadcrumb = ({deletedNote}) => {
	return (
		<List
			sx={{
				paddingLeft: "25px",
				paddingTop: "1px",
				paddingBottom: 0,
				pointerEvents: "none",
			}}
			dense
		>
			<ListItem
				sx={{
					display: "block",
					borderLeft: "1px solid #dfdfdfa1",
					padding: 0,
					pointerEvents: "auto",
				}}
			>
				<Box sx={{ display: "flex" }}>
					<Card
						variant={"note"}
						key={deletedNote.id}
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							paddingRight: "25px",
							minHeight: "30px",
							color: "RBOneFont.main",
							borderLeft: "5px solid",
							backgroundColor: "notes.selected",
							borderColor: "notes.selectedGreen",
						}}
					>
						<Typography sx={{ paddingLeft: "5px" }}>{deletedNote.content}</Typography>
					</Card>
				</Box>
			</ListItem>
		</List>

	)
}

export default SingleNoteBreadcrumb