import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Typography,
} from "@mui/material"
import Licenses from "./Licenses"
import {CurrentSubscriptionCard} from "../shared-components/CurrentSubscriptionCard"

export default function GiftedSubscriptions({enqueueSnackbar, userSubscription}) {
	return (
		<Box>
			<Card elevation={0}>
				<CardHeader
					title={
						<Typography variant={"tunedH1"}>Invite / Manage Users</Typography>
					}
					subheader={
						<Typography variant={"tunedSubtitle2"} sx={{paddingTop: "0.625em", color: "RBOneFont.main"}} paragraph>
							Pay for colleagues, collaborators, employees, or gift RefZoom to other by purchasing additional seats on your subscription. These seats will have the same level of access as your currently selected subscription.
						</Typography>
					}
					sx={{
						padding: 0
					}}
				/>
				<CardContent sx={{padding: 0}}>
					<CurrentSubscriptionCard userSubscription={userSubscription}/>
					<Licenses enqueueSnackbar={enqueueSnackbar}></Licenses>
				</CardContent>
			</Card>
		</Box>
	)
}