import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions, 
	Stack, 
	Button, 
	Box,
} from "@mui/material" 
import UndoDeleteBreadcrumb from "./UndoDeleteBreadcrumb"
import SingleNoteBreadcrumb from "./SingleNoteBreadcrumb"
import {useDispatch} from "react-redux"
import { setIsModalOpen, undoDeleteNote } from "../../../reducers/undoDeleteNoteSlice"

export default function UndoDeleteModal({ open, deletedNoteObject }) {
	const dispatch = useDispatch()
	const { noteTree, deletedNote } = deletedNoteObject


	const undoDeletedNote = (deletedNote) => {
		dispatch(undoDeleteNote(deletedNote))
	}



	return (
		<Dialog
			open={open}
			onClose={() => dispatch(setIsModalOpen(false))}
			sx={{ textAlign: "center"}}
		>
			<DialogTitle sx={{ padding: "24px 36px 16px" }}>
				The note that is going to be restored is shown below</DialogTitle>
			<DialogContent sx={{ padding: "0 36px 24px" }}>  

				<Box sx={{ position: "relative" }}>
					<Box
						sx={{
							padding: "12px",
							borderRadius: "5px",
							marginBottom: "24px",
						}}>
						{ (noteTree.length > 0) ? 
							<UndoDeleteBreadcrumb
								noteTree={noteTree[0]}
								deletedNote={deletedNote}
							/> :
							<SingleNoteBreadcrumb
								deletedNote={deletedNote}/>
						}
					</Box>
				</Box>

				<DialogActions 
					sx={{ 
						flexDirection: "row", 
						display: "flex",
						justifyContent: "center"
					}} 
					disableSpacing>
					<Stack width="75%" spacing={4} direction="row">

						<Button
							fullWidth
							variant={"outlined"}
							color="primary"
							onClick={() => dispatch(setIsModalOpen(false))}
						>
							Cancel
						</Button>

						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => {
								undoDeletedNote(deletedNote)
							}}>
						Restore Note
						</Button>

					</Stack>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}