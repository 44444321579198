import { useState } from "react"
import axios from "axios"
import Article from "./article/Article"
import { CircularProgress, IconButton, Tooltip, useTheme } from "@mui/material"
import { Close, Download, Undo } from "@mui/icons-material"
import { useSnackbar } from "notistack"
import ArticleSideBar from "./ArticleSideBar"
import { useDispatch, useSelector } from "react-redux"
import ExcludedBadge from "./ExcludedArticleBadge"
import { updateWorkspaceExclusions } from "../../reducers/workspaceSlice"
import {removeDeletedArticle} from "../../reducers/deletedArticlesSlice"
import {setWorkspaceOverLimit} from "../../reducers/freeTierTrackerslice"
import { pushImportedExcludedList, removeArticleFromImportedExcludedList } from "../../reducers/automatedSearchViewSlice"
import { clearState, selectTargetArticle } from "../../reducers/targetArticleSlice"

export default function AutoSearchArticle({
	workspaceId,
	searchId,
	article,
	setTargetArticle,
	setArticleAsImported,
	isExcluded,
	index,
	isDeleted
}) {
	useTheme()
	const { enqueueSnackbar } = useSnackbar(),
		dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const targetArticle = useSelector(selectTargetArticle)
	const importArticle = () => {
		setIsLoading(true)
		axios
			.post(`/api/workspace/${workspaceId}/article`, article)
			.then(({ data }) => {
				setArticleAsImported(data)
				if(isDeleted){
					dispatch(removeDeletedArticle(data.articleId))
				}
				dispatch(pushImportedExcludedList(article.PMID))
			})
			.catch(({response}) => {
				console.error("FAILED TO IMPORT: ", response)
				if(response.status === 516){
					dispatch(setWorkspaceOverLimit(true))
				} else {
					enqueueSnackbar(response.data, {
						variant: "error",
						autoHideDuration: 500,
					})
				}
			})
			.finally(() => setIsLoading(false))
	}

	const excludeArticle = () => {
		setIsLoading(true)
		axios
			.post(`/api/workspace/${workspaceId}/auto-search/${searchId}/exclusion`, {
				PMID: article.PMID,
			})
			.then(async () => {
				setIsLoading(false)
				dispatch(updateWorkspaceExclusions(workspaceId, "emit"))
				dispatch(pushImportedExcludedList(article.PMID))
				const id = article.id || article.DOI || article.PMID || article.PMCID
				if (id == targetArticle) {
					dispatch(clearState())
				}
			})
	}

	// do we need to decrement the importedExcludedCount for paging here??
	const includeArticle = () => {
		setIsLoading(true)
		axios
			.delete(
				`/api/workspace/${workspaceId}/auto-search/${searchId}/exclusion`,
				{
					data: {
						PMID: article.PMID,
					},
				}
			)
			.then(() => {
				setIsLoading(false)
				dispatch(updateWorkspaceExclusions(workspaceId, "emit"))
				dispatch(removeArticleFromImportedExcludedList(article.PMID))
			})
	}


	if (isExcluded || isDeleted) {
		return (
			<ExcludedBadge isDeleted={isDeleted}>
				<Article
					article={article}
					setTargetArticle={setTargetArticle}
					targetArticle={targetArticle}
					isExcluded={true}
					index={index}
				>
					<ArticleSideBar isExcluded={true}>
						{isLoading ? (
							<CircularProgress size={"20px"} />
						) : (
							<>
								{isExcluded && (
									<Tooltip
										title="Re-include in search results"
										placement={"left-end"}
									>
										<IconButton
											onClick={() => includeArticle()}
											variant={"grab-bar-excluded"}
											sx={{
												borderRightColor: "rgba(0,0,0, 0.5)",
												borderRightWidth: "2px",
												borderRightStyle: "solid",
												boxSizing: "border-box",
												color: "rgba(0,0,0, 0.5)"
											}}
										>
											<Undo />
										</IconButton>
									</Tooltip>
								)}
								<Tooltip
									title="Import Article into Workspace"
									placement={"left-end"}
								>
									<IconButton
										onClick={() => importArticle(article)}
										variant={"grab-bar-excluded"}
									>
										<Download />
									</IconButton>
								</Tooltip>
							</>
						)}
					</ArticleSideBar>
				</Article>
			</ExcludedBadge>
		)
	}

	return (
		<Article
			article={article}
			setTargetArticle={setTargetArticle}
			targetArticle={targetArticle}
			index={index}
		>
			<ArticleSideBar
				variant={"auto-search"}
			>
				{isLoading ? (
					<CircularProgress size={"20px"} />
				) : (
					<>
						<Tooltip title="Import Article into Workspace" placement={"left-end"}>
							<IconButton
								onClick={() => importArticle(article)}
								variant={"grab-bar-import"}
							>
								<Download />
							</IconButton>
						</Tooltip>
						<Tooltip title={"Exclude from future results"} placement={"left-end"}>
							<IconButton
								onClick={() => excludeArticle()}
								variant={"grab-bar-exclude"}
							>
								<Close />
							</IconButton>
						</Tooltip>
					</>
				)}
			</ArticleSideBar>
		</Article>
	)
}
