import {selectWorkspaceOverLimit, setWorkspaceOverLimit} from "../../reducers/freeTierTrackerslice"
import {useDispatch, useSelector} from "react-redux"
import {Button, Dialog, DialogContentText, IconButton, Typography} from "@mui/material"
import {Close} from "@mui/icons-material"
import infoIcon from "../../info-icon.png"
import {useNavigate} from "react-router-dom"

export default function LimitReachedModal() {
	const workplaceOverLimit = useSelector(selectWorkspaceOverLimit)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const handleCloseModal = () => {
		dispatch(setWorkspaceOverLimit(false))
	}
	const handleButtonClick = () => {
		navigate("/account-settings")
	}
	return (
		<Dialog
			open={workplaceOverLimit}
			onClose={handleCloseModal}
			PaperProps={{
				sx: {
					alignItems: "center",
					padding: "24px 32px",
					gap: "1rem",
					maxWidth: "26rem"
				}
			}}
		>
			<img
				src={infoIcon}
				alt={"info-icon"}
				style={{
					width: "2.875rem",
				}}
			/>
			<Typography
				sx={{
					fontWeight: 400,
					fontSize: "1.25rem",
					padding: "0",
					textAlign: "center"
				}}
			>
				You’ve reached your workspace citation limit.
			</Typography>
			<IconButton
				onClick={handleCloseModal}
				variant={"curved-square"}
				sx={{
					position: "absolute",
					top: 0,
					right: 0,
					padding: "24px"
				}}
			>
				<Close sx={{color: "#212121"}} fontSize={"large"} />
			</IconButton>
			<DialogContentText
				sx={{
					fontSize: "1rem",
					color: "#555555",
					lineHeight: "1.375rem"
				}}
			>
				Free accounts have a limit of 20 citations added to their workspaces. To continue adding citations to your notes, please upgrade to a paid subscription.
			</DialogContentText>
			<Button
				onClick={handleButtonClick}
				variant={"contained"}
				fullWidth={true}
			>
				Upgrade Now
			</Button>
		</Dialog>
	)
}