import { IconButton, Tooltip } from "@mui/material"
import { Undo } from "@mui/icons-material"
import { useEffect } from "react"
import UndoDeleteModal from "./UndoDeleteModal"
import { useSnackbar } from "notistack"
import { useDispatch, useSelector } from "react-redux"
import { clearFetchNoteError, clearSuccessMessage, clearUndoNoteError, fetchLastDeletedNote, selectDeletedNote, selectFetchUndoNoteError, selectIsModalOpen, selectSuccessMessage, selectUndoNoteError } from "../../../reducers/undoDeleteNoteSlice"

export function UndoDeleteMenu({ workspace }) {
	const isModalOpen = useSelector(selectIsModalOpen)
	const fetchUndoNoteError = useSelector(selectFetchUndoNoteError)
	const undoNoteError = useSelector(selectUndoNoteError)
	const successMessage = useSelector(selectSuccessMessage)

	const deletedNote = useSelector(selectDeletedNote)
	const dispatch = useDispatch()

	const { enqueueSnackbar } = useSnackbar()

	const handleClick = () => {
		dispatch(fetchLastDeletedNote())
	}

	useEffect(() => {
		let timer
		if(fetchUndoNoteError) {
			enqueueSnackbar(
				"There are no deleted notes to undo.", 
				{
					variant: "warning",
					autoHideDuration: 3000,
				}
			)
			timer = setTimeout(() => {
				dispatch(clearFetchNoteError())
			}, 3000)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [fetchUndoNoteError])

	useEffect(() => {
		let timer
		if (successMessage) {
			enqueueSnackbar(
				successMessage, 
				{
					variant: "success",
					autoHideDuration: 3000,
				}
			)
			timer = setTimeout(() => {
				dispatch(clearSuccessMessage())
			}, 3000)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [successMessage])

	useEffect(() => {
		let timer
		if(undoNoteError) {
			enqueueSnackbar(
				"There are no deleted notes to undo.", 
				{
					variant: "warning",
					autoHideDuration: 3000,
				}
			)
			timer = setTimeout(() => {
				dispatch(clearUndoNoteError())
			}, 3000)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [undoNoteError])

	return (
		<>
			<Tooltip title="Undo Last Delete">
				<IconButton
					onClick={handleClick}
					sx={{ p: "10px", flexGrow: 0 }}
					aria-label="collapse"
				>
					<Undo color={"action"} />
				</IconButton>
			</Tooltip>
		
			{ deletedNote && 
				<UndoDeleteModal open={isModalOpen} workspaceId={workspace.id} deletedNoteObject={deletedNote}></UndoDeleteModal>
			}
		</>
	)
}
