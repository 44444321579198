import {Button, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material"
import axios from "axios"
import {useEffect, useState} from "react"
import Loading from "../Loading"
import {formatDateWithYear} from "../../utils/dates"
import {useDispatch} from "react-redux"
import {setUser} from "../../reducers/userSlice"

export default function UpgradeWithKeyModal({close, keyId, enqueueSnackbar}) {
	const [isLoading, setIsLoading] = useState(true)
	const [licenseInfo, setLicenseInfo] = useState(null)
	const dispatch = useDispatch()

	const handleSubmit = () => {
		setIsLoading(true)
		axios.patch("/api/license/upgrade-user", {keyId})
			.then(({data}) => {
				dispatch(setUser(data))
				window.sessionStorage.setItem(keyId, "USED")
				enqueueSnackbar("Successfully accepted invite!", {variant: "success"})
			})
			.catch((e) => {
				console.error("FAILED TO UPGRADE USER SUBSCRIPTION: ", e)
				if(e.response.status === 513) {
					enqueueSnackbar(e.response.statusText, {variant: "error", autoHideDuration: 10000})
				} else {
					enqueueSnackbar("Failed to accept invite, please contact support.", {variant: "error"})
				}
			})
			.finally(() => {
				setIsLoading(false)
				close()
			})
	}

	const getKeyData = () => {
		if(window.sessionStorage.getItem(keyId) !== null) {
			close()
		} else {
			axios.get(`/api/license/${keyId}`)
				.then(({data}) => {
					setLicenseInfo(data)
					setIsLoading(false)
				})
				.catch((e) => {
					console.error("ERROR GETTING KEY DATA FOR ACCOUNT UPGRADE: ", e)
					enqueueSnackbar("Failed to fetch invite data, please contact support.", {variant: "error"})
					close()
				})
		}
	}

	useEffect(() => {
		getKeyData()
	}, [])

	return isLoading
		? ( <Loading /> )
		: (
			<>
				<DialogTitle>{licenseInfo.ownerUser.firstName} {licenseInfo.ownerUser.lastName} wants to give you a seat on their Subscription!</DialogTitle>
				<DialogContent>
					<DialogContent>
						<Typography variant={"tunedSubtitle2"} paragraph>
							{licenseInfo.ownerUser.firstName} {licenseInfo.ownerUser.lastName} has offered to give you a seat on their subscription! This will provide your account with paid access so long as {licenseInfo.ownerUser.firstName} {licenseInfo.ownerUser.lastName} maintains their subscription (which is currently set to last until {formatDateWithYear(new Date(licenseInfo.ownerUser.subscriptionValidThrough))})
						</Typography>
						{
							//TODO: figure out a way to make this next part conditional, but it's the same endpoint either way
						}
						<Typography variant={"tunedSubtitle2"} paragraph>
							If you are currently paying for your own subscription, accepting this invitation will pause your current subscription and provide you with a prorated refund on your next invoice.
						</Typography>
						<Typography variant={"tunedSubtitle2"} paragraph>
							After accepting, you are able to leave {licenseInfo.ownerUser.firstName} {licenseInfo.ownerUser.lastName}'s subscription at any time. We will alert {licenseInfo.ownerUser.firstName} {licenseInfo.ownerUser.lastName} so they can re-use the seat.
						</Typography>
					</DialogContent>
				</DialogContent>
				<DialogActions>
					<Button
						variant={"outlined"}
						color={"warning"}
						onClick={close}
					>Keep my account as-is</Button>
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={handleSubmit}
					>Accept {licenseInfo.ownerUser.firstName} {licenseInfo.ownerUser.lastName}'s invite</Button>
				</DialogActions>
			</>
		)
}