import {Check, Close} from "@mui/icons-material"
import {deleteArticleNoteRelationFromIcon} from "../../../reducers/workspaceNoteSlice"
import {useDispatch} from "react-redux"
import useHover from "../../../hooks/useOnHover"
import {
	//Fade,
	IconButton} from "@mui/material"
//import {TransitionGroup} from "react-transition-group"
import {useEffect, useState} from "react"

export default function NoteArticleRelationsIcon({note}) {
	const dispatch = useDispatch()
	const [hoverRef, isHovered] = useHover()
	const [transition, setTransition] = useState(false)

	const handleCheckIconClick = (e) => {
		console.log("handling checkmark icon click!", e, "also, what is note?", note)
		dispatch(deleteArticleNoteRelationFromIcon(note))
	}

	useEffect(() => {
		let timer
		if(isHovered && !transition){
			timer = setTimeout(() => {
				setTransition(true)
			}, 300)
		}

		if(!isHovered) {
			clearTimeout(timer)
			setTransition(false)
		}

		return () => {
			clearTimeout(timer)
		}
	}, [isHovered])

	return (
		<IconButton
			sx={{
				position: "absolute",
				right: "-0.4em",
				fontSize: "20px",
			}}
			ref={hoverRef}
		>
			{ transition ?
				<Close
					sx={{
						cursor: "pointer",
						color: "red",
					}}
					onClick={handleCheckIconClick}
				/>
				:
				<Check
					sx={{
						color: "green",
					}}
				/>
			}
		</IconButton>
	)
}