import { Button, TextField, Container } from "@mui/material"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import axios from "axios"
import { useSnackbar } from "notistack"

export default function OUDContact() {
	const [formData, setFormData] = useState({name: "", email: "", message: ""})
	const { enqueueSnackbar } = useSnackbar()

	const handleSend = () => {
		const messageToSend = {
			...formData
		}

		axios
			.post("/api/email/oud-contact", messageToSend)
			.then(() => {
				enqueueSnackbar("Message sent!", { variant: "info" })
			})
			.catch(error => {
				console.error(error)
				enqueueSnackbar("Something went wrong. Please try again.", { variant: "error" })
			})
			.finally(() => {
				setFormData({name: "", email: "", message: ""})
			})
	}
	return (
		<Container maxWidth="full"
			sx={{
				marginTop: 0,
				padding: "1rem",
				background: "#fff",
			}}
		>
			<Container maxWidth="xl">

				<Typography
					variant="h1"
					align="center"
					sx={{
						fontFamily: "Inter Tight",
						fontWeight: "700",
						fontSize: "2rem",
						lineHeight: "1.2",
						letterSpacing: "-1.5",
						marginLeft: "auto",
						marginRight: "auto",
						maxWidth: "75%",
					}}
				>
					Interested in learning more about our Opioid Use Disorder research database?
				</Typography>
				<Typography
					variant="h2"
					align="center"
					sx={{
						marginTop: "1rem",
						fontFamily: "Inter",
						fontSize: "1.25rem",
						lineHeight: "1.5",
						marginLeft: "auto",
						marginRight: "auto",
						marginBottom: "2rem",
						maxWidth: "75%",
					}}
				>
				Contact us below to let us know you're interested, and we'll be in touch.
				</Typography>
				<Container maxWidth="md">
					<form onSubmit={(e) => {
						e.preventDefault()
						handleSend()
					}}>
						<TextField
							fullWidth
							required
							variant="outlined"
							label="Your Name"
							value={formData.name}
							onChange={(e) => {
								setFormData(prevState => {
									return {
										...prevState,
										name: e.target.value
									}
								})
							}}
							sx={{ margin: "16px 0", background: "white" }}
						/>
						<TextField
							fullWidth
							required
							variant="outlined"
							label="Email Address"
							value={formData.email}
							type="email"
							onChange={(e) => {
								setFormData(prevState => {
									return {
										...prevState,
										email: e.target.value
									}
								})
							}}
							sx={{ margin: "0 0", background: "white" }}
						/>
						<TextField
							fullWidth
							multiline
							rows={5}
							variant="outlined"
							label="Why are you interested?"
							value={formData.message}
							onChange={(e) => {
								setFormData(prevState => {
									return {
										...prevState,
										message: e.target.value
									}
								})
							}}
							sx={{ margin: "16px 0", background: "white" }}
						/>
						<Button type='submit' variant="contained" sx={{ width: "100%" }}>Send</Button>
					</form>
				</Container>

			</Container>
		</Container>
	)

}
