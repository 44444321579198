export const defaultSocketMiddleware = (socket) => {
	return () => (next) => (action) => {
		if (action.type === "WS_CONNECT") {
			socket.connect()
		}

		if (action.type === "WS_DISCONNECT") {
			socket.disconnect()
		}

		if (action.type === "WS_EMIT") {
			const { event, payload } = action
			socket.emit(event, payload)
		}

		return next(action)
	}
}