import {
	Alert, AlertTitle,
	Box, Button,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material"
import FlagIcon from "@mui/icons-material/Flag"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	resetWorkingArticleNoteRelation,
	selectWorkingArticleNoteRelation,
	setWorkingArticleNoteRelationAsync,
} from "../../../reducers/workingArticleNoteRelationSlice"
import ArticleBreadcrumbs from "./ArticleBreadcrumbs"
import BreadCrumbMenu from "../BreadcrumbMenu"
import { useContextMenu } from "react-contexify"
import { selectWorkspaceId } from "../../../reducers/workspaceSlice"
import {ArticleSubmenu} from "./ArticleSubmenu"
import {selectSelectedArticles} from "../../../reducers/selectedArticlesSlice"
import {DownloadForOffline, PictureAsPdf} from "@mui/icons-material"
import {ArticleFileList} from "../fileUpload/ArticleFileList"
import ArticleCitation from "./ArticleCitation"
import ArticleLink from "./ArticleLinks"
import { addArticle } from "../../../reducers/recentlyReviewedSlice"
import { fetchArticleBreadcrumbs, selectArticleBreadcrumbs } from "../../../reducers/breadcrumbSlice"
import { selectTargetArticle, setTargetArticle, clearState } from "../../../reducers/targetArticleSlice"
import axios from "axios"

export default function Article({
	article,
	children,
	isDragFilesActive,
	isImported,
	isExcluded,
	articleWorkspace,
	isUnassigned,
	index,
	fullText,
	openFullText,
	files,
	setFullTextId,
	setArticleFiles,
	handleUploadButtonClick,
	fromAutoSearch
}) {
	const id = article.id || article.DOI || article.PMID || article.PMCID

	const theme = useTheme()
	const scrollRef = useRef(null)
	const { show, hideAll } = useContextMenu({
		id: "breadcrumb_menu",
	})

	const dispatch = useDispatch()
	const workspaceId = useSelector(selectWorkspaceId)
	const articleRelations = useSelector(selectWorkingArticleNoteRelation)
	const selectedArticles = useSelector(selectSelectedArticles)

	const selectedBreadcrumbs = useSelector(selectArticleBreadcrumbs)
	const breadcrumbs = selectedBreadcrumbs[article.id]

	const [copyTextMode, setCopyTextMode] = useState(false)
	const [isHovered, setIsHovered] = useState(false)

	const targetArticle = useSelector(selectTargetArticle)
	const setTargetObject = (id) => {
		dispatch(setTargetArticle(id))
	}

	const fullTextFile = files && files.length && fullText && files.find(file => file.id === fullText)

	const downloadNonPdfFullText = async () => {
		const {data: fileUrl} = await axios.get(`/api/workspace/${workspaceId}/article/${article.id}/attachment/${fullTextFile.id}`)
		axios
			.get(fileUrl, {
				// in App.js we set the Axios' default Headers to always include
				// JWT auth from auth0, and we need to remove those before
				// making an S3 req.
				transformRequest: (data, headers) => {
					delete headers.common["Authorization"]
					return data
				},
				responseType: "blob",
			})
			.then(({data}) => {
				const fakeHref = URL.createObjectURL(data)
				const fakeEl = document.createElement("a")
				fakeEl.setAttribute("download", fullTextFile.fileName)
				fakeEl.href = fakeHref
				fakeEl.setAttribute("target", "_blank")
				fakeEl.click()
				URL.revokeObjectURL(fakeHref)
			})
			.catch((err) => {
				console.error("DOWNLOADING FULL-TEXT FAILED: ", err)
			})
	}

	useEffect(() => {
		if (id === targetArticle) {
			console.log("SCROLL INTO VIEW")
			scrollRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			})
		}
	}, [id, targetArticle])

	useEffect(() => {
		if (targetArticle === article.id) {
			dispatch(fetchArticleBreadcrumbs({article}))
		}
	}, [targetArticle, article, workspaceId, dispatch])

	const addToRecentlyViewed = () => {
		if (!isImported) return

		let recentlyViewed = JSON.parse(
			localStorage.getItem("recently-viewed-articles") || "[]"
		)

		const i = recentlyViewed.findIndex((ra) => {
			return ra.articleId === id
		})

		if (i !== -1) {
			recentlyViewed.splice(i, 1)
		}

		recentlyViewed.unshift(articleWorkspace)
		localStorage.setItem(
			"recently-viewed-articles",
			JSON.stringify(recentlyViewed.slice(0, 20))
		)
		dispatch(addArticle(articleWorkspace))
	}


	const handleClick = () => {
		addToRecentlyViewed()
		if (id !== targetArticle) {
			setTargetObject(id)
			if (isImported && !isUnassigned) {
				dispatch(setWorkingArticleNoteRelationAsync(workspaceId, id))
			}
		} else {
			dispatch(clearState())
			if(isImported && !isUnassigned) {
				dispatch(resetWorkingArticleNoteRelation())
			}
		}
		scrollRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		})
	}

	const handleContextMenu = (e) => {
		show(e, { position: null })
	}

	const getYearFromPublicationDate = (pubDateString) => {
		const pubDate = new Date(pubDateString)
		return pubDate.toLocaleDateString("en-US", { year: "numeric" })
	}



	return (
		<Card
			ref={ scrollRef }
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			elevation={0}
			variant={isExcluded ? "excludedArticle" : "note"}
			sx={{
				display: "flex",
				borderRadius: "0px",
				marginBottom: "2px",
				boxSizing: "border-box",
				position: "relative",
				"&:hover": {
					boxShadow:
						id === targetArticle || isExcluded
							? "none"
							: "0 0px 8px rgba(0, 0, 0, 0.7)",
					backgroundColor:
						id === targetArticle && !isExcluded
							? "notes.background"
							: !isExcluded && "#f0f0f0"
				},
			}}
		>
			{ (isImported || id === targetArticle) &&
				<ArticleSubmenu
					fromAutoSearch={fromAutoSearch}
					isHovered={isHovered}
					isOpen={id === targetArticle}
					selectedArticles={selectedArticles}
					articleWorkspace={articleWorkspace}
					isImported={isImported}
					id={id}
					fullText={fullText}
					setFullTextId={setFullTextId}
					setArticleFiles={setArticleFiles}
				/>
			}
			<CardActions sx={{ padding: 0 }}>{children}</CardActions>
			<CardContent
				sx={{
					transition: "background-color 0.1s ease-in",
					...(isDragFilesActive && {
						backgroundColor: theme.palette.grey["200"],
						transition: "background-color 0.1s ease-out",
					}),
					padding: 0,
					"&:last-child": {
						padding: 0,
					},
				}}
			>
				<Box sx={{
					display: "flex",
					flexDirection: "row"
				}}>
					<Box sx={{
						paddingTop: "10px",
						paddingRight: "5px",
						flex: "1 0 auto",
						display: "flex",
						flexDirection: "column",
						alignItems: "end",
						minWidth: "24px"
					}} >
						<Typography
							variant={"articleIndex"}
							component={"div"}
							sx={{
								color: "RBOneFont.otherInfo",
								fontSize: "1rem",
								lineHeight: "1.4",
								fontWeight: 700,
								paddingLeft: "5px"
							}}
						>
							{index + 1}
						</Typography>
						{!!fullText && !!fullTextFile && (
							<Box>
								{fullTextFile.fileType === "pdf" ? (
									<Tooltip title={"Open PDF"}>
										<IconButton
											onClick={() => openFullText() }
											sx={{
												paddingTop: "3px",
												color: "dragBar.pdfIcon",
												"&:hover": {
													backgroundColor: "#eaeaea",
													color: "dragBar.pdfHover",
												},
											}}
										>
											<PictureAsPdf fontSize="small" />
										</IconButton>
									</Tooltip>
								) : (
									<Tooltip title={"Download File"}>
										<IconButton
											onClick={downloadNonPdfFullText}
											sx={{
												paddingTop: "3px",
												color: "dragBar.importROne",
												"&:hover": {
													backgroundColor: "#eaeaea",
													color: "dragBar.importROneHover",
												},
											}}
										>
											<DownloadForOffline fontSize="small" />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						)}
					</Box>
					{id !== targetArticle &&
						<Button
							onClick={handleClick}
							variant={"text"}
							color={"otherInfo"}
							sx={{
								position: "absolute",
								bottom: 0,
								right: 0,
								margin: "0 10px 2px 0",
								textTransform: "none",
								padding: "1px 50px",
								backgroundColor: "none",
								transition: "background-color 250ms ease-in",
								"&:hover": {
									backgroundColor: "primary.transparency20",
								}
							}}
						>
							Open Citation
						</Button>
					}
					<Stack spacing={0.5}>
						{id !== targetArticle ? (
							<Box
								sx={{
									width: "100%",
								}}
							>
								<ArticleCitation
									id={id}
									targetArticle={targetArticle}
									article={article}
									isUnassigned={isUnassigned}
									getYearFromPublicationDate={getYearFromPublicationDate}
								/>
							</Box>
						) : (
							<Box
								sx={{
									width: "100%",
								}}
							>
								<ArticleCitation
									id={id}
									targetArticle={targetArticle}
									article={article}
									isUnassigned={isUnassigned}
									getYearFromPublicationDate={getYearFromPublicationDate}
								/>
							</Box>
						)}
						<Box sx={{ fontSize: "small" }}>
							{article.PMID && (
								<ArticleLink
									link={`https://pubmed.ncbi.nlm.nih.gov/${article.PMID}`}
									label={"PMID"}
									value={article.PMID}
									selected={id === targetArticle}
								/>
							)}
							{article.DOI && (
								<ArticleLink
									link={`https://doi.org/${article.DOI}`}
									label={"DOI"}
									value={article.DOI}
									selected={id === targetArticle}
								/>
							)}
							{article.ISBN && (
								<ArticleLink
									link={`https://isbnsearch.org/isbn/${article.ISBN}`}
									label={"ISBN"}
									value={article.ISBN}
									selected={id === targetArticle}
								/>
							)}
							{article.ARXIVID && (
								<ArticleLink
									link={`https://arxiv.org/abs/${article.ARXIVID}`}
									label={"arXiv"}
									value={article.ARXIVID}
									selected={id === targetArticle}
								/>
							)}
						</Box>
						{id === targetArticle && (
							<Box>
								{
									(isUnassigned && id === targetArticle) && (
										<Alert
											sx={{
												backgroundColor: "unassignedArticle.main",
												margin: "8px 8px 0 8px",
												color: "RBOneFont.main",
												padding: "0 16px",
											}}
											icon={<FlagIcon sx={{color: "RBOneFont.main"}} />}
										>
											<AlertTitle sx={{marginBottom: "-2px"}}>
												<strong>Unattached</strong>
											</AlertTitle>
											<Typography
												variant={"subtitle2"}
											>
												Drag the green status indicator bar and drop it on a heading in the left panel that describes its content.
											</Typography>
										</Alert>
									)
								}
								{breadcrumbs && breadcrumbs.length > 0 && (
									<Box sx={{ position: "relative" }}>
										<Box
											sx={{
												padding: "8px",
												borderRadius: "5px",
												marginBottom: "10px",
											}}
										>
											{breadcrumbs.map((note) => (
												<ArticleBreadcrumbs
													key={note.id}
													note={note}
													articleRelations={articleRelations}
													workspaceId={workspaceId}
													articleId={article.id}
													copyTextMode={copyTextMode}
													handleContextMenu={handleContextMenu}
													hide={hideAll}
												/>
											))}
										</Box>
										<BreadCrumbMenu
											setCopyTextMode={setCopyTextMode}
											copyTextMode={copyTextMode}
											hide={hideAll}
										/>
									</Box>
								)}
								<br />
								<Typography sx={{ padding: "8px" }}>
									{article.abstract || "No abstract available"}
								</Typography>
								{isImported && (
									<ArticleFileList
										files={files}
										articleWorkspace={articleWorkspace}
										fullTextId={fullText}
										setArticleFiles={setArticleFiles}
										handleUploadButtonClick={handleUploadButtonClick}
									/>
								)}
							</Box>
						)}
					</Stack>
				</Box>
			</CardContent>
		</Card>
	)
}