import {Alert, AlertTitle} from "@mui/material"
import {useSelector} from "react-redux"
import {selectCurrentInvoiceUrl} from "../../../../reducers/stripeInvoicesSlice"

export default function OpenInvoiceAlert() {
	const openInvoiceUrl = useSelector(selectCurrentInvoiceUrl)
	return (
		<Alert severity={"warning"}>
			<AlertTitle>You currently have an open invoice</AlertTitle>
			Please resolve all open invoices before trying to change your subscription plan.<br />
			<a href={openInvoiceUrl}>Click here to view the open invoice.</a>
		</Alert>
	)
}