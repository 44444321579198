import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit"
import { workspaceSlice } from "./workspaceSlice"
import axios from "axios"
import {DYNAMIC_PANEL_TAB_INDEX_MAP, setDynamicView} from "./dynamicViewSlice"
import {breadcrumbDataToNoteIdsAsStrings} from "../utils/noteUtilities"
import { fetchNoteAttachments, fetchSingleNoteAttachments } from "./noteAttachmentsSlice"
import {deleteArticle} from "./deletedArticlesSlice"

export const fetchNoteCitationsAndSwitchPanels = createAsyncThunk(
	"noteArticleTabsV2/fetchNoteCitationsAndSwitchPanels",
	async (payload, {getState, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const {note, singleNote} = payload
		const page = 1
		let articles = []
		let count = 0
		// init tab
		const tab = state.noteArticleTabsV2.tabs[note.id]
		let queryString = singleNote ? `/api/workspace/${workspaceId}/note/${note.id}/singleNoteArticles` : `/api/workspace/${workspaceId}/note/${note.id}/article`
		const { data } = await axios.get(queryString, {
			params: { page }
		})
		articles = data.articles
		count = data.count
		// init link attachments
		if (singleNote) {
			dispatch(fetchSingleNoteAttachments({note}))
		} else {
			dispatch(fetchNoteAttachments({note}))
		}
		// update view
		dispatch(setDynamicView(DYNAMIC_PANEL_TAB_INDEX_MAP.NOTE_ARTICLE_LIST))
		return {
			note,
			articles,
			count,
			singleNote,
			tab
		}
	}
)

// TODO: this thunk is designed to consume the payload of a RefreshRelay in specific moments, but might not be the best path forward.
// TODO: it allows us to update any tabs that might be affected by changes from a RR, but just dumbly replaces the current article list
// TODO: with an updated one, which is roughly what we were doing before RB-526... detached notes just "poof" away from the tab
// TODO: I feel we handle note deletions in a really elegant way using this V2 slice, but attachments and detachments will require more thought
export const checkNotePathToUpdate = createAsyncThunk(
	"noteArticleTabsV2/checkNotesToUpdate",
	(payload, {getState, dispatch}) => {
		const {path} = payload
		let affectedIds
		if(!path.length) {
			affectedIds = breadcrumbDataToNoteIdsAsStrings(path)
		} else {
			affectedIds = []
			path.forEach(idPath => {
				const stringToArray = breadcrumbDataToNoteIdsAsStrings(idPath)
				affectedIds = affectedIds.concat(stringToArray)
			})
		}
		const { noteArticleTabsV2 } = getState()
		for(let id of affectedIds) {
			if(noteArticleTabsV2.tabInsertionOrder.includes(id)) {
				dispatch(fetchNoteArticlesSilently({noteId: id}))
			}
		}
	}
)
/**
 * fetches ArticleWorkspace entities for a note-tab that is currently open, but DOES NOT switch the active panel.
 * this is intended to be used on socketed actions to "quietly" update note-article tabs
 * @type {AsyncThunk<{noteId: string, articles: Array<ArticleWorkspace>}, void, AsyncThunkConfig>}
 */
export const fetchNoteArticlesSilently = createAsyncThunk(
	"noteArticleTabsV2/fetchNoteArticlesSilent",
	async (payload, {getState}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const {noteId} = payload //TODO: look at refresh relay and see what data we're given
		const res = await axios.get(`/api/workspace/${workspaceId}/note/${noteId}/article`)
		return { //TODO: do we need note info here?
			articles: res.data,
			noteId
		}
	}
)

// Make Async Note Article Page
// Fulfilled action should request next page of articles
export const incrementPageAndFetchArticles = createAsyncThunk(
	"noteArticleTabsV2/setArticlePageAsync",
	async ({tab, note, page, singleNote}, {getState}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const { data } = await axios.get(`/api/workspace/${workspaceId}/note/${note.id}/article`, {
			params: { page }
		})
		let nextArticles = data.articles
		let count = data.count

		return {
			page,
			tab,
			nextArticles,
			count,
			note,
			singleNote
		}
	}
)

export const noteArticleTabsV2 = createSlice({
	name: "noteArticleTabsV2",
	initialState: {
		currentIndex: -1,
		tabs: {},
		tabInsertionOrder: [],
		loading: false
	},
	reducers: {
		setCurrentTabIndex: (state, action) => {
			state.currentIndex = action.payload
		},
		setNoteArticlePage: (state, action) => {
			const {page, noteId} = action.payload
			state.tabs[noteId] = {
				...state.tabs[noteId],
				page,
			}
		},
		handleNoteArticle: (state, action) => {
			const {note, articles, singleNote, page, count: totalArticleCount, fromNoteList} = action.payload
			const noteId = note.id.toString()

			if(!state.tabs[noteId]) { //tab doesn't currently exist
				const title = note.content
				const count = articles.length
				const newTab = {
					page,
					hasMore: articles.length < totalArticleCount,
					title,
					articles,
					singleNote,
					meta: {note, count},
				}

				state.tabs[noteId] = newTab
				state.tabInsertionOrder.push(noteId)
			} else {
				let updatedArticles = []
				// if tab exists but is from noteList or tab change
				if (fromNoteList) {
					updatedArticles = articles
				} else {
					updatedArticles = state.tabs[noteId].articles.concat(articles)
				}
				state.tabs[noteId].articles = updatedArticles
				state.tabs[noteId].singleNote = singleNote
				state.tabs[noteId].hasMore = updatedArticles.length < totalArticleCount
			}

			state.currentIndex = `${note.id}`
			state.loading = false
		},
		updateNoteArticleAsImported: (state, action) => {
			const workspaceArticle = action.payload
			const stateCache = current(state)
			for(let tab of stateCache.tabInsertionOrder) {
				const affectedTab = stateCache.tabs[tab]
				const updatedTabArticleList = affectedTab.articles.map(article => {
					if (article.articleId === workspaceArticle.articleId) {
						return {
							...workspaceArticle
						}
					} else {
						return article
					}
				})
				state.tabs[tab].articles = updatedTabArticleList
			}

		},
		removeTab: (state, action) => {
			const deletingNoteIndex = state.tabInsertionOrder.findIndex(tabId => tabId === action.payload)
			const noteTabIsSelected = state.currentIndex === action.payload
			const isOnlyNote = state.tabInsertionOrder.length === 1

			delete state.tabs[action.payload] // delete object
			state.tabInsertionOrder.splice(deletingNoteIndex, 1) // delete index reference

			if(noteTabIsSelected && !isOnlyNote) { // move index if a new one is needed
				deletingNoteIndex > 0 ? state.currentIndex = state.tabInsertionOrder[deletingNoteIndex - 1] : state.currentIndex = state.tabInsertionOrder[0]
			} else if (noteTabIsSelected && isOnlyNote) { // if selected note was index 0, reset the slice
				state.currentIndex = -1
			}
		},
		clearTabs: (state) => {
			state.currentIndex = -1
			state.tabs = {}
			state.loading = false
			state.tabInsertionOrder = []
		},
		pushAttachedArticlesToNoteTab: (state, action) => {
			const {noteId, articleWorkspaces} = action.payload
			const stateCache = current(state)
			const affectedTab = stateCache.tabs[noteId]
			// this is needed when an article is deleted than reimported and attached to the same note
			articleWorkspaces.forEach(articleWorkspace => {
				if(affectedTab.articles.find(article => article.articleId === articleWorkspace.articleId)){
					return
				} else {
					state.tabs[noteId].articles.push(articleWorkspace)
				}
			})

		},
		// moveTab: (state, action) => {
		// 	const {movingTabIndex, targetTabIndex} = action.payload
		// 	const { tabs: tabsCache, currentIndex: currentIndexCache } = current(state.
		// 	const tabToMove = state.tabs.splice(movingTabIndex, 1)[0]
		// 	state.tabs.splice(targetTabIndex, 0, tabToMove)
		// 	//update currentIndex value if needed
		// 	if(current(state.tabs[state.currentIndex]).title !== tabsCache[currentIndexCache].title){
		// 		state.currentIndex = findTabIndexIfExists(state.tabs, tabsCache[currentIndexCache].type, tabsCache[currentIndexCache].meta.note.id)
		// 	}
		// 	//TODO: lmao...
		// }
	},
	extraReducers: (builder) => {
		builder.addCase(
			fetchNoteCitationsAndSwitchPanels.pending,
			(state) => { state.loading = true }
		)
		builder.addCase(
			fetchNoteCitationsAndSwitchPanels.rejected,
			(state) => { state.loading = false }
		)
		builder.addCase(
			fetchNoteCitationsAndSwitchPanels.fulfilled,
			(state, action) => { 
				const {
					note,
					articles,
					count,
					singleNote,
				} = action.payload
				// switch to tab


				const title = note.content
				const newTab = {
					page: 1,
					hasMore: articles.length < count,
					title,
					articles,
					singleNote,
					meta: {note, count},
				}
				state.currentIndex = note.id
				state.loading = false 
				state.tabs[note.id] = newTab
				// Check if note.id already exists in tabInsertionOrder before pushing
				if (!state.tabInsertionOrder.includes(note.id)) {
					state.tabInsertionOrder.push(note.id)
				}
			}
		)
		builder.addCase(
			fetchNoteArticlesSilently.fulfilled,
			(state, action) => {
				state.tabs[action.payload.noteId].articles = action.payload.articles
			}
		)
		builder.addCase( // If user creates or moves a new workspace - clear articleNoteTabs
			workspaceSlice.actions.setWorkspace,
			noteArticleTabsV2.caseReducers.clearTabs
		)
		builder.addCase(
			incrementPageAndFetchArticles.pending,
			(state) => { state.loading = true }
		)
		builder.addCase(
			incrementPageAndFetchArticles.rejected,
			(state) => { state.loading = false }
		)
		builder.addCase(
			incrementPageAndFetchArticles.fulfilled,
			(state, action) => {
				const {
					page,
					tab,
					nextArticles,
					count,
					note,
					singleNote
				} = action.payload

				// set new page state
				// concat nextArticles with tab articles
				// update count???
				let articles = [...tab.articles, ...nextArticles]
				state.tabs[note.id] = {
					...tab,
					page,
					articles,
					hasMore: articles.length < count,
					meta: {note, count},
					title: note.content,
					singleNote
				}
				state.loading = false
			}
		)
		builder.addCase(
			deleteArticle.fulfilled,
			(state, action) => {
				const {payload} = action
				const deletedArticleId = payload.articleId
				let affectedIds = []
				payload.path.forEach(idPath => {
					const stringToArray = breadcrumbDataToNoteIdsAsStrings(idPath)
					affectedIds = affectedIds.concat(stringToArray)
				})
				const stateCache = current(state)
				for(let tab of stateCache.tabInsertionOrder) {
					const affectedTab = stateCache.tabs[tab]
					const updatedTabArticleList = affectedTab.articles.map(article => {
						if (article.articleId === deletedArticleId) {
							return {
								...article,
								deletedAt: payload.deletedAt,
							}
						} else {
							return article
						}
					})
					state.tabs[tab].articles = updatedTabArticleList
				}
			}
		)
	}
})

export const {
	handleNoteArticle: handleNoteArticleV2,
	setCurrentTabIndex: setCurrentTabIndexV2,
	removeTab: removeTabV2,
	pushAttachedArticlesToNoteTab,
	updateNoteArticleAsImported,
	setNoteArticlePage
	// moveTab,
} = noteArticleTabsV2.actions

export const selectNoteArticleTabsListV2 = (state) => {
	if(state.noteArticleTabsV2.tabs){
		return Object.keys(state.noteArticleTabsV2.tabs).length ? state.noteArticleTabsV2.tabs : false
	} else {
		return false
	}
}

// Add page selector using conditionals like selectNoteArticleTabsArticles and selectNoteArticleSelectedTab
export const selectCurrentNoteArticleTab = (state) => state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex]
export const selectNoteArticleTabPage = (state) => state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex] && state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex].page
export const selectNoteArticleTabHasMore = (state) => state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex] && state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex].hasMore
export const selectNoteArticleTabsArticles = (state) => state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex] ? state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex].articles : []
export const selectNoteArticleSelectedTab = (state) => state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex] ? state.noteArticleTabsV2.tabs[state.noteArticleTabsV2.currentIndex] : {}
export const selectNoteArticleTabsCurrentIndexV2 = (state) => state.noteArticleTabsV2.currentIndex
export const selectNoteArticleTabsIsLoading = (state) => state.noteArticleTabsV2.loading
export const selectNoteArticleTabsInsertionOrder = (state) => state.noteArticleTabsV2.tabInsertionOrder

export default noteArticleTabsV2.reducer
