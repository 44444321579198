import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const updateSearchCounts = createAsyncThunk(
	"automatedSearchPanelView/updateSearchCounts",
	async ({searchId}, {getState}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const sessionStartTimestamp = state.sessionTimestamp.value
		const {data} = await axios.get(`/api/workspace/${workspaceId}/automatedSearch/${searchId}/count`, {
			params: { sessionStartTimestamp },
		})
		return {searchId, ...data}
	}
)

export const getAutomatedSearchList = createAsyncThunk(
	"automatedSearchPanelView/getSearches",
	async (params, {getState}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		const sessionStartTimestamp = state.sessionTimestamp.value
		const { data } = await axios.get(`/api/workspace/${workspaceId}/auto-search`, {
			params: { sessionStartTimestamp },
		})
		return data
	}
)

export const AUTOMATED_SEARCH_PANEL_VIEW = {
	ABOUT: "ABOUT",
	AUTOMATED_SEARCH_FORM: "AUTOMATED_SEARCH_FORM",
	SEARCH_LIST: "SEARCH_LIST",
	ARTICLE_LIST: "ARTICLE_LIST",
}

export const automatedSearchPanelViewSlice = createSlice({
	name: "automatedSearchPanelView",
	initialState: {
		value: "SEARCH_LIST",
		selectedSearch: null,
		searchListPosition: 0,
		automatedSearchList: [],
		countMap: {},
		isListLoading: false,
		importedExcludedList: [],
		offsetMap: {},
		pageMap: {},
		searchResultListPositionMap: {}
	},
	reducers: {
		setAutomatedSearchPanelView: (state, action) => {
			state.value = action.payload
		},
		setSelectedSearch: (state, action) => {
			state.selectedSearch = action.payload
		},
		setAutoSearchListPosition: (state, action) => {
			state.searchListPosition = action.payload
		},
		setPageMap: (state, action) => {
			const {selectedSearch, page} = action.payload
			state.pageMap[selectedSearch.id] = {page}
		},
		incrementPageCount: (state, action) => {
			const {selectedSearch} = action.payload
			const pageObject = state.pageMap[selectedSearch.id]
			if (pageObject?.page) {
				state.pageMap[selectedSearch.id] = { page: pageObject.page + 1}
			} else {
				state.pageMap[selectedSearch.id] = { page: 1 }
			}
		},
		initializePageCount: (state, action) => {
			const {selectedSearch} = action.payload
			const pageObject = state.pageMap[selectedSearch.id]
			if (pageObject?.page) {
				state.pageMap[selectedSearch.id] = {page: pageObject.page, fetchInitialPage: true}
			} else {
				state.pageMap[selectedSearch.id] = {page: 1, fetchInitialPage: false}
			}
		},
		setFetchInitialPage: (state, action) => {
			const {selectedSearch} = action.payload
			const pageObject = state.pageMap[selectedSearch.id]
			if (pageObject) {
				pageObject.fetchInitialPage = true
			}
			
			state.pageMap[selectedSearch.id] = pageObject
		},
		setAutoSearchResultListPosition: (state, action) => {
			const {searchId, position} = action.payload
			state.searchResultListPositionMap[searchId] = position
		},
		pushImportedExcludedList: (state, action) => {
			const pmid = action.payload
			// Check if the id is already in the list
			if (!state.importedExcludedList.includes(pmid)) {
				// If the id is not in the list, push it
				state.importedExcludedList.push(pmid)
			}
			state.offsetMap[state.selectedSearch?.id] = state.importedExcludedList.length
		},
		removeArticleFromImportedExcludedList: (state, action) => {
			const pmid = action.payload
			// Remove the id from the list
			state.importedExcludedList = state.importedExcludedList.filter(item => item !== pmid)
		},
		resetImportedExcludedList: (state) => {
			state.importedExcludedList = []
		},
		upsertSingleSearch: (state, action) => {
			const {search, counts} = action.payload
			const index = state.automatedSearchList.findIndex(oldSearch => oldSearch.id === search.id)
		
			if (index !== -1) {
				// Update existing search
				state.automatedSearchList[index] = search
			} else {
				// Insert new search
				state.automatedSearchList.push(search)
			}
			state.countMap = {
				...state.countMap,
				...counts
			}
		}

	},
	extraReducers: (builder) => {
		builder
			.addCase(
				updateSearchCounts.fulfilled,
				(state, action) => {
					const { counts } = action.payload
					state.countMap = {
						...state.countMap, 
						...counts
					}
				}
			)
			.addCase(
				updateSearchCounts.pending,
				(state, action) => {
					// set loading state for search id
					const {searchId} = action.meta.arg
					if (state.countMap[searchId]) {
						state.countMap[searchId].isLoading = true
					}
				}
			)
			.addCase(
				getAutomatedSearchList.pending,
				(state) => {
					state.isListLoading = true
				}
			)
			.addCase(
				getAutomatedSearchList.fulfilled,
				(state, action) => {
					const {searches, counts} = action.payload
					state.automatedSearchList = searches
					state.countMap = counts
					state.isListLoading = false
				}
			)
	}
})

export const { setFetchInitialPage, initializePageCount, incrementPageCount, setAutomatedSearchPanelView, setPageMap, setSelectedSearch, setAutoSearchListPosition, setAutoSearchResultListPosition, upsertSingleSearch, pushImportedExcludedList, resetImportedExcludedList, removeArticleFromImportedExcludedList } =
	automatedSearchPanelViewSlice.actions

export const selectAutoSearchResultListScrollPositionMap = createSelector(
	(state) => state.automatedSearchPanelView.searchResultListPositionMap,
	(state, searchId) => searchId,
	(searchResultListPositionMap, searchId) => searchResultListPositionMap[searchId]
)

export const selectAutoSearchPage = createSelector(
	(state) => state.automatedSearchPanelView.pageMap,
	(state, searchId) => searchId,
	(pageMap, searchId) => pageMap[searchId]
)

export const selectAutomatedSearchPanelView = (state) =>
	state.automatedSearchPanelView.value

export const selectAutomatedSearchSelectedSearch = (state) =>
	state.automatedSearchPanelView.selectedSearch

export const selectAutoSearchListScrollPosition = (state) => state.automatedSearchPanelView.searchListPosition

export const selectSearchCounts = (state) => state.automatedSearchPanelView.countMap

export const selectSearches = (state) => state.automatedSearchPanelView.automatedSearchList

export const selectIsLoading = (state) => state.automatedSearchPanelView.isListLoading

export const selectImportedExcludedCount = (state) => state.automatedSearchPanelView.importedExcludedList.length

export const selectAutoSearchResultPageMap= (state) => state.automatedSearchPanelView.pageMap


export default automatedSearchPanelViewSlice.reducer
