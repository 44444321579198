export const reorderNotes = (originalNotes, notePositions) => {
	if (!notePositions || notePositions.length === 0) {
		return originalNotes                                       // short circuit if notePositions is empty
	}
	//TODO: keep an eye on the console log above. I suspect there are race conditions that can be triggered by this utility FN
	// that will require dealing with situations where 'origionalNotes.length' !== notePositions.length
	// in that situation, we can use the note.order value to splice in at the correct location
	return notePositions.map(noteId => {
		const noteObjIndex = originalNotes.findIndex(note => noteId === note.id)
		return originalNotes[noteObjIndex]
	})
}

export const breadcrumbDataToNoteIdsAsStrings = (breadCrumbString) => breadCrumbString.toString().split(".")
export const breadcrumbDataToNoteIdsAsNumbers = (breadCrumbString) => breadCrumbString.toString().split(".").map(id => parseInt(id))
