import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { SocketContext, defaultSocketService } from "./context/socket.context"
import { Auth0ProviderWithHistory } from "./auth/auth0-provider-with-history"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import store from "./store"
import { ThemeProvider } from "@mui/material"
import theme from "./styles/theme"
import { SnackbarProvider } from "notistack"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<Auth0ProviderWithHistory>
				<SocketContext.Provider value={defaultSocketService}>
					<ThemeProvider theme={theme}>
						<SnackbarProvider maxSnack={3}>
							<App />
						</SnackbarProvider>
					</ThemeProvider>
				</SocketContext.Provider>
			</Auth0ProviderWithHistory>
		</BrowserRouter>
	</Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
