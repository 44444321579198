import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { deletedArticlesSlice } from "./deletedArticlesSlice"
import { cloneDeep } from "lodash"
import {setWorkspaceThunk} from "./workspaceSlice"

export const fetchArticles = createAsyncThunk(
	"notificationPanelView/fetchArticles",
	async (params) => {
		const { workspaceId, sharedArticleArray, enqueueSnackbar, sharedWorkspaceId } = params
		const response = await axios.get(`/api/workspace/${workspaceId}/article?search=${sharedArticleArray.join(",")}&shared=1`)
		const { count, articles } = response.data
		if (count === -1) {
			enqueueSnackbar("This search returned no results, check your query and try again", {
				variant: "error",
				autoHideDuration: 3000,
				disableWindowBlurListener: true,
				preventDuplicate: true,
			})
			return { articles: [], count, sharedWorkspaceId }
		} else {
			return { articles, count, sharedWorkspaceId }
		}
	}
)

const lookup = ({articleId}, listOfArticles) => {
	const index = listOfArticles.findIndex(
		(a) => (a.articleId === articleId)
	) 
	return index
}

export const notificationPanelViewSlice = createSlice({
	name: "notificationPanelView",
	initialState: {
		selectedData: [], isLoading: false, articles: [], count: 0 ,
	},
	reducers: {
		setNotificationPanelView: (state) => {
			return { ...state, selectedData: [], isLoading: false, articles: [], count: 0 }
		},
		clearNotificationPanelView: (state) => {
			state.selectedData = []
			state.isLoading = false
			state.articles = []
			state.count = 0
		},
		updateArticle: (state, action) => {
			const { DOI, PMID } = action.payload.article
			const articleWorkspace = action.payload
			const articleList = cloneDeep(state.articles)
			let index = articleList.findIndex(
				(a) => (a.DOI !== undefined && a.DOI === DOI) || (a.PMID !== undefined && a.PMID === PMID)
			)
			if (index === -1) {
				index = lookup(articleWorkspace,articleList)
			}
			if (index !== -1) {
				state.articles.splice(index, 1, articleWorkspace)
			} else {
				console.error("533 regular update Something went wrong when updating notification panel citations")
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchArticles.pending, (state) => {
				state.isLoading = true
			})
			.addCase(fetchArticles.fulfilled, (state, action) => {
				state.articles = action.payload.articles
				state.count = action.payload.count
				state.isLoading = false
			})
			.addCase(fetchArticles.rejected, (state) => {
				state.isLoading = false
			})
			.addCase(deletedArticlesSlice.actions.addDeletedArticle, (state, action) => {
				const articleList = cloneDeep(state.articles)
				const index = lookup(action.payload, articleList)
				const articleToUpdate = articleList[index]
				if (articleToUpdate) {
					articleToUpdate.deletedAt = action.payload.deletedAt
					articleList[index] = articleToUpdate
					state.articles = articleList
				}
			})
			.addCase(setWorkspaceThunk.pending, (state) => {
				notificationPanelViewSlice.caseReducers.clearNotificationPanelView(state)
			})
	},
})

// Selector to get articles
export const selectArticles = (state) => state.notificationPanelView.articles

// Selector to get the count
export const selectArticleCount = (state) => state.notificationPanelView.count

// Selector to check if the data is loading
export const selectIsLoading = (state) => state.notificationPanelView.isLoading

export const { setNotificationPanelView, updateArticle } = notificationPanelViewSlice.actions

export default notificationPanelViewSlice.reducer
