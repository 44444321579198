import io from "socket.io-client"
import { createContext } from "react"

const baseURL = window.origin.includes("localhost") ? "http://localhost:8080" : window.origin

export const defaultSocketService = io.connect(baseURL)
export const notificationsSocketService = io.connect(`${baseURL}/notification`)
export const articlesSocketService = io.connect(`${baseURL}/articles`)
export const notesSocketService = io.connect(`${baseURL}/notes`)
export const noteRestrictionsService = io.connect(`${baseURL}/noteRestrictions`)
export const noteAttachmentService = io.connect(`${baseURL}/noteAttachments`)

export const SocketContext = createContext(defaultSocketService)
