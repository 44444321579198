import {
	Box,
	Divider,
	IconButton,
	Tooltip,

} from "@mui/material"
import {
	NavigationTabWrapper,
	NavigationTab,
	NavigationTabs,
	NavigationWrapper,
} from "../panels/Navigation"
import SearchNote from "./SearchNote"
import NoteTreeV2 from "./note_tree/NoteTreeV2"
import { useDispatch, useSelector } from "react-redux"
import { useSnackbar } from "notistack"
import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../../context/socket.context"
import { selectUser } from "../../reducers/userSlice"
import {
	clearRefreshRelay,
	selectRefreshRelay,
	setRefreshRelay,
} from "../../reducers/refreshRelaySlice"
import { useContextMenu } from "react-contexify"
import { clearNoteAction } from "../../reducers/noteActionSlice"
import PanelLoading from "../PanelLoading"
import NoteMenu from "./note_tree/NoteMenu"
import { UnfoldLess } from "@mui/icons-material"
import { selectWorkspaceId, updateWorkspaceExclusions } from "../../reducers/workspaceSlice"
import { reorderNotes } from "../../utils/noteUtilities"
//import { selectPresentationNotes } from "../../reducers/notePresentationSlice"
import WorkspaceSearchBar from "./WorkSpaceSearchBar/WorkspaceSearchBar"
import {
	selectCurrentWorkspaceSearch, selectCurrentWorkspaceSearchIndex,
	selectCurrentWorkspaceSearchResults,
	selectIsSearching, selectSearches, updateSearches, 
	selectIsWorkspaceSearchLoading
} from "../../reducers/workspaceSearchSlice"
import FormattedToolTip from "../../utils/FormattedToolTip"
import { UndoDeleteMenu } from "./undodelete/UndoDeleteMenu"
import ImportedArticleCounter from "./ImportedArticleCounter"
import { fetchTopLevelNotes, selectTopLevelNotes, selectLoading } from "../../reducers/workspaceNoteSlice"
import {clearNotesOrder, selectNotesOrder} from "../../reducers/noteOrderSlice"
import { selectShowNoteActionLoading } from "../../reducers/noteActionSlice"
import PanelLoadingWithBackdrop from "../PanelLoadingWithBackdrop"

function WorkspacePanelNav({ workspace, currentSearches, currentSearchIndex }) {
	const dispatch = useDispatch()

	return (
		<NavigationWrapper>
			<NavigationTabWrapper>
				<Tooltip title="Collapse Notes">
					<IconButton
						onClick={() => {
							dispatch(clearNoteAction())
							dispatch(clearRefreshRelay())
							dispatch(clearNotesOrder())
							dispatch(fetchTopLevelNotes())
						}}
						sx={{ p: "10px", flexGrow: 0 }}
						aria-label="collapse"
					>
						<UnfoldLess color={"action"} />
					</IconButton>
				</Tooltip>
				<NavigationTabs value={0} aria-label="workspace-tabs">
					<Tooltip title={<FormattedToolTip
						title={workspace.title}
						description={workspace.description}
					/>}>
						<NavigationTab label={workspace.title} aria-label="workspace-tab" />
					</Tooltip>
				</NavigationTabs>
				<span style={{ flexGrow: 1 }} />
				<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
				<Tooltip title="Undo Last Delete">
					<UndoDeleteMenu workspace={workspace} />
				</Tooltip>
			</NavigationTabWrapper>
			<WorkspaceSearchBar
				workspace={workspace}
				currentSearches={currentSearches}
				currentSearchIndex={currentSearchIndex}
			/>
		</NavigationWrapper>
	)
}

export default function WorkspacePanel({ workspace }) {
	console.log("Attach Link - Workspace Panel")
	// HOOKS
	const
		dispatch = useDispatch(),
		socket = useContext(SocketContext),
		{ show, hideAll } = useContextMenu({ id: "note_menu" }),
		{ enqueueSnackbar } = useSnackbar()
	// REDUX SELECTORS
	const
		user = useSelector(selectUser),
		refreshRelay = useSelector(selectRefreshRelay),
		//notePositions = useSelector(selectPresentationNotes),
		isSearching = useSelector(selectIsSearching),
		searchResults = useSelector(selectCurrentWorkspaceSearchResults),
		search = useSelector(selectCurrentWorkspaceSearch),
		currentWorkspaceSearches = useSelector(selectSearches),
		currentWorkspaceSearchIndex = useSelector(selectCurrentWorkspaceSearchIndex)
	const topLevelNotes = useSelector(selectTopLevelNotes)
	const topLevelNoteOrder = useSelector(state => selectNotesOrder(state, "root"))
	const areNotesLoading = useSelector(selectLoading)
	const workspaceId = useSelector(selectWorkspaceId)
	const noteActionLoading = useSelector(selectShowNoteActionLoading)
	const isWorkspaceSearchLoading = useSelector(selectIsWorkspaceSearchLoading)
	const isPanelLoading = noteActionLoading || isWorkspaceSearchLoading
	
	// LOCAL STATE
	const [showingContext, setShowingContext] = useState(false)

	useEffect(() => {
		if (workspace) {
			dispatch(updateSearches(workspace.id))
		}
	}, [workspace, refreshRelay])

	useEffect(() => {
		if (workspace) {
			dispatch(fetchTopLevelNotes())
		}
	}, [workspaceId])

	useEffect(() => {
		console.log("Refresh Relay: ", refreshRelay)
	}, [refreshRelay])

	useEffect(() => {
		socket.on("USER_JOINED_WORKSPACE", (msg) => {
			enqueueSnackbar(msg, { variant: "info" })
		})

		socket.on("USER_LEFT_WORKSPACE", (msg) => {
			enqueueSnackbar(msg, { variant: "info" })
		})

		socket.on("WORKSPACE_UPDATED", (refreshRelay) => {
			dispatch(setRefreshRelay(refreshRelay))
		})

		socket.on("WORKSPACE_EXCLUSIONS_UPDATED", (msg) => {
			enqueueSnackbar(msg, { variant: "info" })
			dispatch(updateWorkspaceExclusions(workspace.id))
		})
	}, [socket, enqueueSnackbar, dispatch, user.sub])

	if (areNotesLoading) {
		return (
			<Box>
				<PanelLoading />
			</Box>
		)
	}

	return (
		<Box id="panel-container" sx={{ backgroundColor: "primary.rOneBG", position: "relative" }}>
			<WorkspacePanelNav
				workspace={workspace}
				currentSearches={currentWorkspaceSearches}
				currentSearchIndex={currentWorkspaceSearchIndex}
			/>
			<Box
				id="panel-scroll"
				sx={{
					padding: "8px",
					overflowY: "scroll",
					flex: "1 1 100%"
				}}
			>
				{isSearching === true
					? searchResults.map((note) => (
						<SearchNote
							key={note.id}
							note={note}
							search={search}
						/>
					))
					: reorderNotes(topLevelNotes, topLevelNoteOrder).map((note, i) => (
						<NoteTreeV2
							index={i}
							key={note?.id}
							workspaceId={workspace.id}
							note={note}
							hideAll={hideAll}
							show={show}
							showingContext={showingContext}
						/>
					))}
			</Box>
			{workspace.ownerIsFreeTier && (
				<ImportedArticleCounter />
			)}
			<NoteMenu
				permissions={workspace.permissions}
				hideAll={hideAll}
				setShowingContext={setShowingContext}
				showingContext={showingContext}
			/>
			{isPanelLoading && (
				<PanelLoadingWithBackdrop isPanelLoading={isPanelLoading} />
			)}
		</Box>
	)
}