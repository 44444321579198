/**
 * Quadratic easing function for smooth animations.
 * @param {number} t - The specified time when the animation will start.
 * @param {number} b - The specified starting position of the object on the x-axis.
 * @param {number} c - The specified change in value for the object.
 * @param {number} d - The specified duration of the whole process.
 *
 * The function divides the animation into two parts:
 * 1. An "easing in" part (when t < 1), where the pace of change starts slowly and then accelerates.
 * 2. An "easing out" part (when t >= 1), where the pace of change starts quickly and then decelerates.
 *
 * @returns {number} The new position of the object.
 */
const easeInOutQuad = (t, b, c, d) => {
	t /= d / 2
	if (t < 1) return (c / 2) * t * t + b
	t--
	return (-c / 2) * (t * (t - 2) - 1) + b
}

/**
 * Function to animate the scroll of a given element.
 * @param {number} currentTime - The current timestamp, generally passed in via requestAnimationFrame.
 * @param {Element} element - The DOM element to be scrolled.
 * @param {number} start - The initial scroll position.
 * @param {number} change - The change in scroll position.
 * @param {number} duration - The duration of the scroll animation in milliseconds.
 * @param {number} startTime - The timestamp when the animation started.
 *
 * The function calculates the new scroll position of the element at the current time and updates the element's scrollTop property.
 * If the animation duration has not been reached, it requests the next animation frame.
 *
 * @returns {void}
 */
const animateScroll = (currentTime, element, start, change, duration, startTime) => {
	const elapsedTime = currentTime - startTime
	const newScrollTop = easeInOutQuad(elapsedTime, start, change, duration)
	element.scrollTop = newScrollTop

	if (elapsedTime < duration) {
		window.requestAnimationFrame((currentTime) =>
			animateScroll(currentTime, element, start, change, duration, startTime)
		)
	}
}

/**
 * Smoothly scrolls an element to a target position over a specified duration.
 * @param {Element} element - The DOM element to be scrolled.
 * @param {number} target - The target scroll position.
 * @param {number} duration - The duration of the scroll animation in milliseconds.
 *
 * This function uses the `animateScroll` function to animate the scroll.
 *
 * @returns {void}
 */
export const smoothScrollTo = (element, target, duration) => {
	const start = element.scrollTop
	const change = target - start
	const startTime = performance.now()

	window.requestAnimationFrame((currentTime) =>
		animateScroll(currentTime, element, start, change, duration, startTime)
	)
}

/**
 * Creates a synthetic download Anchor element,
 * assigns the href as the url passed into the function,
 * and then synthetically clicks the link.
 *
 * @param {string} linkUrl - The URL to be clicked. Usually resolves to a downloadable asset.
 *
 * The intended use for this fn is to more easily download files to client's desktop
 * @returns {void}
 */
export const createAndClickDownloadLink = (linkUrl) => {
	const link = document.createElement("a")
	link.setAttribute("download", "")
	link.href = linkUrl
	link.click()
}