import { Box, Grid, Stack, Button, Typography} from "@mui/material"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
//import { useAuth0 } from "@auth0/auth0-react"
import YoutubeEmbed from "./YouTubeEmbed"


export default function Hero() {

	//const { loginWithRedirect } = useAuth0()

	return (
		<Grid container spacing={2}
			sx={{
				paddingBottom: "2rem",
				paddingRight: "2rem",
				alignItems: "center",
				marginTop: "4rem",
			}}
		>

			<Grid item md={6} display="flex-col" sx={{
				marginTop: "0 auto",
				marginBottom: "0 auto",
				height:"100%"
			}}>
				<Typography
					variant="h1"
					sx={{
						fontFamily: "Inter Tight",
						fontWeight:"800",
						fontSize: "3.25rem",
						letterSpacing: "-1.5",
						lineHeight: "1",
						color:"#263031",
						marginBottom: "1rem"
					}}
				>

					<Box>
						A Research Tool&nbsp;
					</Box>

					<Box sx={{color:"#02C1D4",fontSize:"4.5rem"}}>
						For Humans
					</Box>
				</Typography>

				<Typography
					variant="h2"
					sx={{
						fontFamily: "Inter",
						fontSize: "1.2rem",
						fontWeight: "400",
						lineHeight: "1.5",
						marginBottom: "1.5rem",
						paddingRight: "24px"
					}}
				>
					<Box display="inline">
						RefZoom enables researchers to&nbsp;
					</Box>
					<Box display="inline" sx={{color:"#02B3C5", fontWeight:"600"}}>
						find, automate, take notes, and organize&nbsp;
					</Box>
					<Box display="inline">
						citations with advanced citation management in a single platform.
					</Box>
				</Typography>

				<Stack direction="row" spacing={4} >

					<Button
						startIcon={<AccountCircleOutlinedIcon />}
						onClick={() =>
							document.getElementById("pricing").scrollIntoView({behavior: "smooth", block: "start"})
						}
						variant={"contained"}
						size="large"
						sx={{
							color: "white",
							borderRadius: "48px",
						}}
					>

					Sign Up

					</Button>

				</Stack>

			</Grid>

			<Grid item md={6}>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					sx={{ minHeight: "100%", minWidth:"100%"}}
				>
					<Grid item xs={12} sx={{ minWidth:"100%"}}>
						<YoutubeEmbed embedId="-N5p3jFdC_o"/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
