import { Box, CircularProgress } from "@mui/material"

export default function PanelLoading() {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "200px",
			}}
		>
			<CircularProgress />
		</Box>
	)
}


