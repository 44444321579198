import { Backdrop, CircularProgress } from "@mui/material"

export default function PanelLoadingWithBackdrop({ isPanelLoading }) {
	return (
		<Backdrop
			sx={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
			open={isPanelLoading}
		>
			<CircularProgress color="inherit" />
		</Backdrop>)
}