import { createSlice } from "@reduxjs/toolkit"

const initialValue = {
	isOpen: false,
	textBoxContent: null,
}

export const copyCitationsModalSlice = createSlice({
	name: "copyCitationsModal",
	initialState: {
		value: initialValue,
	},
	reducers: {
		openModal: (state) => {
			state.value.isOpen = true
		},
		closeModal: (state) => {
			state.value.isOpen = false
		},
		clearModalAndClose: (state) => {
			state.value = initialValue
		},
		setTextBoxContent: (state, payload) => {
			state.value.textBoxContent = payload
		},
	},
})
export const { openModal, closeModal, clearModalAndClose, setTextBoxContent } =
	copyCitationsModalSlice.actions

export const modalIsOpen = (state) => state.copyCitationsModal.value.isOpen

export const copyCitationsModal = (state) => state.copyCitationsModal.value

export default copyCitationsModalSlice.reducer
