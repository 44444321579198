import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {workspaceSlice} from "./workspaceSlice"
import axios from "axios"
import { fetchUniqueCountsForEachPath } from "./workspaceNoteSlice"
import { getImportedArticleCountForWorkspace } from "./freeTierTrackerslice"
import { removeArticleFromImportedExcludedList } from "./automatedSearchViewSlice"

export const deleteArticle = createAsyncThunk(
	"deletedArticles/deleteArticle",
	async ({articleId, fromAutoSearch = false, articlePMID}, {getState, rejectWithValue, dispatch}) => {
		const state = getState()
		const workspaceId = state.workspace?.value?.id
		if (!workspaceId) return rejectWithValue("Failed to fetch unique counts. Invalid WorkspaceId")
		try {
			const { data } = await axios.delete(`/api/workspace/${workspaceId}/article/${articleId}`)
			dispatch(fetchUniqueCountsForEachPath(data.path))
			dispatch(getImportedArticleCountForWorkspace())
			if (fromAutoSearch) {
				dispatch(removeArticleFromImportedExcludedList(articlePMID))
			}
			return data
		} catch (error) {
			rejectWithValue(`Failed to delete article with ID ${articleId}`)
		}
	}
)

export const deletedArticlesSlice = createSlice({
	name: "deletedArticles",
	initialState: {
		currentWorkspaceId: -1,
		deletedArticles: {},
		deleteArticleError: null
	},
	reducers: {
		addDeletedArticle: (state, action) => {
			const {currentWorkspaceId} = state
			state.deletedArticles[currentWorkspaceId].push(action.payload)
		},
		removeDeletedArticle: (state, action) => {
			const {payload: articleId} = action
			const workspaceId = state.currentWorkspaceId
			const articleIndex = state.deletedArticles[workspaceId].findIndex(deletedArticle => deletedArticle.articleId === articleId)
			if(articleIndex >= 0) {
				state.deletedArticles[workspaceId].splice(articleIndex, 1)
			}
		},
		clearDeleteArticleError: (state) => {
			state.deleteArticleError = null
		}

	},
	extraReducers: (builder) => {
		builder.addCase(deleteArticle.rejected, (state) => {
			state.deleteArticleError = "Failed to delete this article from all notes in workspace"
		})
		builder.addCase(workspaceSlice.actions.setWorkspace, (state, action) => {
			const workspaceId = action.payload.id
			state.currentWorkspaceId = workspaceId
			if(state.deletedArticles[workspaceId] === undefined){
				state.deletedArticles[workspaceId] = []
			}
		})
		builder.addCase(deleteArticle.fulfilled, deletedArticlesSlice.caseReducers.addDeletedArticle)
	}
})

export const {
	addDeletedArticle,
	removeDeletedArticle,
	clearDeleteArticleError
} = deletedArticlesSlice.actions

export const selectWorkspaceDeletedArticles = (state) => state.deletedArticles.deletedArticles[state.deletedArticles.currentWorkspaceId]
export const selectDeleteArticleError = (state) => state.deletedArticles.deleteArticleError

export default deletedArticlesSlice.reducer