import {
	Box,
	Card, CardContent, CardHeader,
	Typography
} from "@mui/material"
import {BillingHistoryTable} from "./BillingHistoryTable"

export function BillingHistory() {

	return (
		<Box>
			<Card elevation={0}>
				<CardHeader
					title={
						<Typography variant={"tunedH1"}>Billing History</Typography>
					}
					subheader={
						<Typography variant={"tunedSubtitle2"} sx={{paddingTop: "0.625em", color: "RBOneFont.main"}} paragraph>
							See your full billing history, download previous invoices, or check your upcoming invoice.
						</Typography>
					}
					sx={{
						padding: 0
					}}
				/>
				<CardContent sx={{padding: 0}}>
					<BillingHistoryTable />
				</CardContent>
			</Card>
		</Box>
	)
}