import {useDispatch, useSelector} from "react-redux"
import {selectWorkspaceExclusions} from "../../../reducers/workspaceSlice"
import {selectUnassignedArticles} from "../../../reducers/unassignedArticlesEventsSlice"
import {selectWorkspaceDeletedArticles} from "../../../reducers/deletedArticlesSlice"
import {selectSessionTimestamp} from "../../../reducers/sessionTimestampSlice"
import {useEffect, useState} from "react"
import axios from "axios"
import {
	setDeletedArticlesAsDeleted,
	setExcludedArticlesAsExcluded,
	setUnassignedArticlesAsImported
} from "../../../utils/automatedSearchUtilities"
import {articleDeletedDuringSession, checkExclusion, updateArticleAsImported} from "../../../utils/articleUtilities"
import PanelLoading from "../../PanelLoading"
import PanelInfiniteScroll from "../PanelInfiniteScroll"
import AutoSearchArticle from "../../articles/AutoSearchArticle"
import ImportedArticle from "../../articles/ImportedArticle"
import { incrementPageCount, selectAutoSearchPage, selectAutoSearchResultListScrollPositionMap, selectImportedExcludedCount, setAutoSearchResultListPosition } from "../../../reducers/automatedSearchViewSlice"
import EmptyResults from "../EmptyResults"
import { debounce } from "lodash"

export function AutomatedSearchArticles({ workspaceId, selectedSearch }) {
	const dispatch = useDispatch()
	const workspaceExcludedArticles = useSelector(selectWorkspaceExclusions)
	const unassignedArticles = useSelector(selectUnassignedArticles)
	const deletedArticles = useSelector(selectWorkspaceDeletedArticles)
	const sessionStartTimestamp = useSelector(selectSessionTimestamp)
	const importedExcludedCount = useSelector(selectImportedExcludedCount)
	const scrollPosition = useSelector((state) => selectAutoSearchResultListScrollPositionMap(state, selectedSearch.id))
	const pageObject = useSelector(state => selectAutoSearchPage(state, selectedSearch.id))
	const [targetArticle, setTargetArticle] = useState(null)
	const [searchParams, setSearchParams] = useState({
		page: 1,
		articles: [],
		count: 0,
		isLoading: true,
		hasMore: true
	})

	useEffect(() => {
		const scrollList = document.querySelector(".js-scroll-list")
		const handleScroll = debounce(() => {
			if (scrollList) {
				const position = scrollList.scrollTop
				dispatch(setAutoSearchResultListPosition({searchId: selectedSearch.id, position}))
			}
		}, 500)

		if (scrollList) {
			scrollList.addEventListener("scroll", handleScroll)
		}

		return () => {
			if (scrollList) {
				scrollList.removeEventListener("scroll", handleScroll)
			}
		}
	}, [dispatch, searchParams.isLoading])

	// update the view scroll on component load
	useEffect(() => {
		const scrollList = document.querySelector(".js-scroll-list")
		if (scrollList && !searchParams.isLoading) {
			scrollList.scrollTo(0, scrollPosition)
		}
	}, [searchParams.isLoading])

	useEffect(() => {
		fetchResults(pageObject.page)
	}, [pageObject.page])

	useEffect(() => {
		setExcludedArticlesAsExcluded(searchParams, setSearchParams)
	}, [workspaceExcludedArticles])

	useEffect(() => {
		setUnassignedArticlesAsImported(unassignedArticles, setSearchParams)
	}, [unassignedArticles])

	useEffect(() => {
		setDeletedArticlesAsDeleted(deletedArticles, setSearchParams)
	}, [deletedArticles])

	const paginate = () => {
		if (searchParams.hasMore) {
			dispatch(incrementPageCount({selectedSearch}))
		}
	}

	const fetchResults = async (page) => {
		try {
			let initialPageSizeMulti = 1

			if (pageObject?.fetchInitialPage) {
				initialPageSizeMulti = pageObject.page
			}

			const { data } = await axios.get(`/api/workspace/${workspaceId}/automatedSearch/${selectedSearch.id}/results?page=${page}`, {
				params: {sessionStartTimestamp, importedExcludedCount, pageMultiplier: initialPageSizeMulti}
			})

			setSearchParams(prevState => {
				let updateArticles = searchParams.articles.concat(data.articles)
				let hasMore = updateArticles.length < data.total

				return {
					...prevState,
					articles: updateArticles,
					hasMore: hasMore,
					isLoading: false
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	const setArticleAsImported = updateArticleAsImported(setSearchParams)

	if (searchParams.isLoading && !searchParams?.articles?.length) {
		return <PanelLoading />
	}

	if (!searchParams.isLoading && searchParams?.articles?.length == 0) {
		return <EmptyResults 
			title={`No Results in Automated Search for ${selectedSearch.title}`}
			body={"Currently, this automated search has no results. Automated searches are updated at 2 AM and 2 PM Eastern Time. Please check back later for new results."}
		/>
	}

	return (
		<>
			<PanelInfiniteScroll
				dataLength={searchParams.articles.length}
				next={paginate}
				hasMore={searchParams.hasMore}
				loader={<PanelLoading />}
				className="js-scroll-list"
			>
				{ searchParams.articles
					.filter(article => {
						if(article?.articleId){
							const articleWasDeletedDuringSession = articleDeletedDuringSession(sessionStartTimestamp, article.deletedAt)
							if(article.deletedAt === null || articleWasDeletedDuringSession === true){
								return article
							} //else - article was deleted before session and shouldn't be rendered
						} else {
							return article
						}
					})
					.map((article, index) => {
						if(article.articleId){
							const articleWasDeletedDuringSession = articleDeletedDuringSession(sessionStartTimestamp, article.deletedAt)
							if(articleWasDeletedDuringSession){
								return (
									<AutoSearchArticle
										key={article.article.DOI || article.article.PMID || article.article.PMCID}
										workspaceId={workspaceId}
										article={article.article}
										setTargetArticle={setTargetArticle}
										targetArticle={targetArticle}
										searchId={selectedSearch.id}
										setArticleAsImported={setArticleAsImported}
										isExcluded={false}
										isDeleted={true}
										index={index}
									/>

								)
							} else {
								return (
									<ImportedArticle
										fromAutoSearch={true}
										key={article.articleId}
										articleWorkspace={article}
										setTargetArticle={setTargetArticle}
										targetArticle={targetArticle}
										isUnassigned={unassignedArticles.map(({articleId}) => articleId).includes(article.articleId)}
										index={index}
									/>
								)
							}
						} else {
							return (
								<AutoSearchArticle
									key={article.DOI || article.PMID || article.PMCID || article.id}
									workspaceId={workspaceId}
									article={article}
									setTargetArticle={setTargetArticle}
									targetArticle={targetArticle}
									searchId={selectedSearch.id}
									setArticleAsImported={setArticleAsImported}
									isExcluded={checkExclusion(article.PMID, workspaceExcludedArticles)}
									isDeleted={false}
									index={index}
								/>
							)
						}
					})
				}
			</PanelInfiniteScroll>
		</>
	)
}