import {
	Box,
	Card,
	List,
	ListItem,
	TextField,
} from "@mui/material"
import { useState, useRef, useEffect } from "react"
import {useDispatch, useSelector} from "react-redux"
import {clearNoteAction,
	NOTE_ACTIONS,
	selectNoteAction} from "../../../reducers/noteActionSlice"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import { addNote, clearAddNoteError, selectAddNoteError, selectSubmittingNote, setSubmittingNote } from "../../../reducers/workspaceNoteSlice"
import { useSnackbar } from "notistack"

export default function AddNote({ note }) {
	const dispatch = useDispatch()
	const ref = useRef()
	const noteAction = useSelector(selectNoteAction)
	const hasSubmittingNote = useSelector(selectSubmittingNote)
	const [content, setContent] = useState("")
	const addNoteError = useSelector(selectAddNoteError)
	const { enqueueSnackbar } = useSnackbar()

	useOnClickOutside(ref, () => handleSubmit())

	const handleSubmit = () => {
		const noteContentsValueChanged = content && content.length > 0
		const noteContentIsAllWhitespace = /^\s+$/.test(content)

		if (noteContentsValueChanged && !noteContentIsAllWhitespace && !addNoteError) {
			dispatch(setSubmittingNote(note))
			dispatch(addNote({note, content}))
		} else {
			dispatch(clearNoteAction())
		}
	}

	useEffect(() => {
		let timer
		if(addNoteError) {
			enqueueSnackbar(addNoteError, { variant: "info" })
			timer = setTimeout(() => {
				dispatch(clearAddNoteError())
			}, 3000)
		}
		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [addNoteError, dispatch])

	const handleChange = (e) => {
		setContent(e.target.value)
	}

	const keyPress = (e) => {
		if (e.key === "Enter" || e.keyCode === 13) {
			handleSubmit(e)
		}

		if(e.key === "Escape" || e.keyCode === 27){
			dispatch(clearNoteAction())
		}
	}

	const handleClick = (e) => {
		e.stopPropagation()
		e.preventDefault()
	}
	return (
		<List
			sx={{
				paddingLeft: [NOTE_ACTIONS.addChild, NOTE_ACTIONS.addRoot].includes(noteAction.action) ? "25px" : "0px",
				paddingTop: [NOTE_ACTIONS.addChild, NOTE_ACTIONS.addRoot].includes(noteAction.action) ? "2px" : "0px",
				paddingBottom: noteAction.action === NOTE_ACTIONS.addChild ? "0px" : "2px",
				paddingRight: noteAction.action === NOTE_ACTIONS.addRoot ? "15px" : "0px",
				borderLeft: noteAction.action === NOTE_ACTIONS.addChild ? "1px solid white" : "none",
			}}
			onClick={e => handleClick(e)}
			id={"add-note-item"}
		>
			<ListItem
				sx={{
					display: "block",
					padding: 0,
				}}
			>
				{ noteAction.action !== NOTE_ACTIONS.addSibling && (
					<Box
						sx={{
							maxWidth: "25px",
							minWidth: "25px",
							opacity: "0.4",
							alignSelf: noteAction.action === "add-root" ? "center" : "start",
						}}
					>
					</Box>
				)}
				<Card
					elevation={0}
					sx={{ width: "100%", padding: "5px" }}
					variant={"note"}
					ref={ref}
				>
					<TextField
						InputProps={{
							sx: { padding: "0px" },
							inputProps: {
								sx: { padding: "5px 10px" },
							},
						}}
						fullWidth
						autoFocus
						placeholder="Enter a descriptive note"
						multiline
						onKeyDown={keyPress}
						onChange={handleChange}
						disabled={hasSubmittingNote?.id === note.id}
						sx={{backgroundColor: "white"}}
					/>
				</Card>
				{/*</Box>*/}
			</ListItem>
		</List>
	)
}
