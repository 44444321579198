import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit"
import axios from "axios"

// Async thunk for fetching notifications
export const fetchUserNotifications = createAsyncThunk(
	"notifications/fetchUserNotifications",
	async (userId, thunkAPI) => {
		try {
			const response = await axios.get(`/api/notifications/${userId}`)
			return response.data
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data)
		}
	}
)

export const markNotificationsAsRead = createAsyncThunk(
	"notifications/markAsRead",
	async (notificationId, { rejectWithValue }) => {
		try {
			await axios.patch(`/api/notifications/${notificationId}/read`)
			return notificationId
		} catch (error) {
			return rejectWithValue(error.response.data)
		}
	}
)

const notificationsSlice = createSlice({
	name: "notifications",
	initialState: {
		items: [],
		status: "idle",
		error: null
	},
	reducers: {
		receivedNewNotification: (state, action) => {
			// push the new notification to the items array!
			state.items.push(action.payload)
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserNotifications.pending, (state) => {
				state.status = "loading"
			})
			.addCase(fetchUserNotifications.fulfilled, (state, action) => {
				state.status = "succeeded"
				state.items = action.payload
			})
			.addCase(fetchUserNotifications.rejected, (state, action) => {
				state.status = "failed"
				state.error = action.payload || "Failed to fetch notifications"
			})
			.addCase(markNotificationsAsRead.fulfilled, (state, action) => {
				const notificationIndex = state.items.findIndex(notification => notification.id === action.payload)
				if (notificationIndex >= 0) {
					state.items[notificationIndex].isRead = true
				}
			})
	}
})

// Selectors
export const selectAllNotifications = state => state.notifications.items
export const selectStatus = state => state.notifications.status
export const selectError = state => state.notifications.error

// Selector to get all notifications from the state
const selectNotifications = (state) => state.notifications.items

// Memoized selector to filter article share notifications
export const selectArticleShareNotifications = createSelector(
	[selectNotifications], 
	(notifications) => notifications.filter(notification => notification.type === "article_share")
)

// Memoized selector to filter workspace invite notifications
export const selectWorkspaceInviteNotifications = createSelector(
	[selectNotifications], 
	(notifications) => notifications.filter(notification => notification.type === "workspace_invite")
)

// Memoized selector to filter unread notifications
export const selectUnreadNotifications = createSelector(
	[selectNotifications], 
	(notifications) => notifications.filter(notification => !notification.isRead)
)
export const { receivedNewNotification } = notificationsSlice.actions

// Reducer export
export default notificationsSlice.reducer
