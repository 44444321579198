import { createSlice, current } from "@reduxjs/toolkit"
import { workspaceSlice } from "./workspaceSlice"

export const TAB_TYPES = {
	SEARCH: "SEARCH",
	NOTE: "NOTE",
	LOADING: "LOADING",
}

/**
 * NOTE META
 * noteId
 *
 * SEARCH META
 * query
 * */

const initialValue = {
	search: {
		query: "",
		count: 0,
	},
	currentIndex: -1,
	tabs: [],
}

const findTabIndexIfExists = (tabs, tabType, tabTarget) => {
	let foundIndex = -1
	if (tabType === TAB_TYPES.NOTE) {
		foundIndex = tabs.findIndex(
			({ meta }) => meta.note && meta.note.id === tabTarget
		)
	} else if (tabType === TAB_TYPES.SEARCH) {
		foundIndex = tabs.findIndex(
			({ meta }) => meta.query && meta.query === tabTarget
		)
	}

	return foundIndex === -1 ? null : foundIndex
}

export const noteArticleTabs = createSlice({
	name: "noteArticleTabs",
	initialState: {
		value: initialValue,
	},
	reducers: {
		setCurrentTabIndex: (state, action) => {
			state.value.currentIndex = action.payload
		},
		setMetaCount: (state, action) => {
			const { index, count } = action.payload
			if (state.value.tabs[index]) {
				state.value.tabs[index].meta.count = count
			}
		},
		handleNoteArticleSearch: (state, action) => {
			const existingTabIndex = findTabIndexIfExists(
				current(state.value.tabs),
				TAB_TYPES.SEARCH,
				action.payload
			)

			if (existingTabIndex !== null) {
				state.value.currentIndex = existingTabIndex
			} else {
				state.value.tabs.push({
					title: action.payload,
					type: TAB_TYPES.SEARCH,
					meta: {
						query: action.payload,
					},
				})
			}
		},
		handleNoteArticle: (state, action) => {
			const existingTabIndex = findTabIndexIfExists(
				current(state.value.tabs),
				TAB_TYPES.NOTE,
				action.payload.id
			)

			if (existingTabIndex !== null) {
				state.value.currentIndex = existingTabIndex
			} else {
				state.value.tabs.push({
					title: action.payload.content,
					type: TAB_TYPES.NOTE,
					meta: {
						note: action.payload,
					},
				})
			}
		},
		removeTab: (state, action) => {
			const deletingIndex = action.payload

			if (
				state.value.currentIndex >= deletingIndex &&
				state.value.currentIndex > 0
			) {
				state.value.currentIndex = state.value.currentIndex - 1
			}

			state.value.tabs.splice(deletingIndex, 1)
		},
		clearTabs: (state) => {
			state.value.search = {},
			state.value.currentIndex = 0,
			state.value.tabs = []
		},
		moveTab: (state, action) => {
			const {movingTabIndex, targetTabIndex} = action.payload
			const { tabs: tabsCache, currentIndex: currentIndexCache } = current(state.value)
			const tabToMove = state.value.tabs.splice(movingTabIndex, 1)[0]
			state.value.tabs.splice(targetTabIndex, 0, tabToMove)
			//update currentIndex value if needed
			if(current(state.value.tabs[state.value.currentIndex]).title !== tabsCache[currentIndexCache].title){
				state.value.currentIndex = findTabIndexIfExists(state.value.tabs, tabsCache[currentIndexCache].type, tabsCache[currentIndexCache].meta.note.id)
			}
		}
	},
	extraReducers: (builder) => {
		// If user creates or moves a new workspace - clear articleNoteTabs
		builder.addCase(
			workspaceSlice.actions.setWorkspace,
			noteArticleTabs.caseReducers.clearTabs
		)
	}
})

export const {
	handleNoteArticle,
	setCurrentTabIndex,
	handleNoteArticleSearch,
	setMetaCount,
	removeTab,
	clearTabs,
	moveTab,
} = noteArticleTabs.actions

export const selectNoteArticleTabs = (state) => state.noteArticleTabs.value
export const selectNoteArticleTabsList = (state) => state.noteArticleTabs.value.tabs.length ? state.noteArticleTabs.value.tabs : false
export const selectNoteArticleTabsCurrentIndex = (state) => state.noteArticleTabs.value.currentIndex



export default noteArticleTabs.reducer
