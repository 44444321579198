import {
	Box,
	Container,
} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {useEffect, useState} from "react"
import {useSnackbar} from "notistack"
import AccountSettingsNav from "../components/account_settings/AccountSettingsNav"
import AccountSettingsDrawer from "../components/account_settings/AccountSettingsDrawerMenu"
import Loading from "../components/Loading"
import {useDispatch, useSelector} from "react-redux"
import {
	clearUserCustomerDataError, clearUserCustomerDataSuccess,
	getProductsFromStripe,
	selectCustomerData,
	selectLoadingCustomerData,
	selectSubscriptionData,
	selectUserCustomerDataError, selectUserCustomerDataSuccessMessage,
} from "../reducers/stripeDetailsSlice"
import {selectLoadingUser} from "../reducers/userSlice"
import {
	accountSettingsComponents,
	selectOpenAccountSettingsComponent
} from "../reducers/accountSettingsNavSlice"
export const TOOLBAR_HEIGHT = "2.5em"

export default function AccountSettings(){
	const {enqueueSnackbar} = useSnackbar()
	const dispatch = useDispatch()

	const loadingUserData = useSelector(selectLoadingUser)
	const loadingStripeData = useSelector(selectLoadingCustomerData)
	const userSubscriptionData = useSelector(selectSubscriptionData)
	const userCustomerData = useSelector(selectCustomerData)
	const userCustomerDataError = useSelector(selectUserCustomerDataError)
	const userModifyDataSuccess = useSelector(selectUserCustomerDataSuccessMessage)
	const openComponentKey = useSelector(selectOpenAccountSettingsComponent)
	const openComponent = accountSettingsComponents[openComponentKey]

	const [drawerOpen, setDrawerOpen] = useState(true)
	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen)
	}

	useEffect(() => {
		if(loadingUserData === false) {
			dispatch(getProductsFromStripe())
		}
	}, [loadingUserData])

	useEffect(() => {
		if(userCustomerDataError !== null){
			enqueueSnackbar(userCustomerDataError, {
				variant: "error",
				autoHideDuration: 3000,
				onClose: () => dispatch(clearUserCustomerDataError()),
			})
		}
	}, [userCustomerDataError])

	useEffect(() => {
		if(userModifyDataSuccess !== null) {
			enqueueSnackbar(userModifyDataSuccess, {
				variant: "success",
				autoHideDuration: 3000,
				onClose: () => dispatch(clearUserCustomerDataSuccess()),
			})
		}
	}, [userModifyDataSuccess])

	return loadingUserData
		? ( <Loading /> )
		: (
			<Box>
				<AccountSettingsNav
					title={"Account Settings"}
					toolbarHeight={TOOLBAR_HEIGHT}
					toggleDrawer={toggleDrawer}
				/>
				<Grid container rowSpacing={1} sx={{paddingTop: TOOLBAR_HEIGHT}}>
					<Grid xs={drawerOpen ? 3 : 0}>
						<AccountSettingsDrawer
							drawerOpen={drawerOpen}
							toolbarHeight={TOOLBAR_HEIGHT}
						/>
					</Grid>
					<Grid xs={drawerOpen ? 8 : 12}>
						<Container sx={{width:"100%", height: "100%", paddingTop: "20px"}}>
							{
								(loadingStripeData || userSubscriptionData === null) ? <Loading /> :
									(<openComponent.component
										userSubscription={userSubscriptionData}
										userCustomer={userCustomerData}
										enqueueSnackbar={enqueueSnackbar}
									/>)
							}
						</Container>
					</Grid>
				</Grid>
			</Box>
		)
}