import { Box, Button, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {
	clearManageWorkspaceView,
	confirmWorkspaceTransfer,
	selectTargetUser
} from "../../../reducers/manageWorkspaceSlice"
import { selectWorkspace } from "../../../reducers/workspaceSlice"
import { useState } from "react"

export default function TransferOwnership() {

	const dispatch = useDispatch()
	const targetUser = useSelector(selectTargetUser)
	const workspace = useSelector(selectWorkspace)
	const firstName = targetUser?.user?.firstName
	const lastName = targetUser?.user?.lastName
	const email = targetUser?.user?.email
	const [workspaceTitleInput, setWorkspaceConfirmationText] = useState("")
	const [confirmationError, setConfirmationError] = useState(false)
	
	const handleConfirmationOnChange = (e) => {
		setConfirmationError(workspaceTitleInput !== workspaceTitleInput)
		setWorkspaceConfirmationText(e.target.value)
	}

	return (
		<Box sx={{ display: "flex", gap: "2rem", flexDirection: "column", justifyContent: "flex-end", width: "100%" }}>
			<Typography id="modal-modal-title" variant="h4">
				Transfer Workspace Ownership
			</Typography>
			<Typography id="modal-modal-title" variant="body">
				You are attempting to transfer ownership to <Typography component="span" fontWeight="bold">{`${firstName} ${lastName} (${email})`}</Typography>.
				Type <Typography component="span" fontWeight="bold">{`${workspace.title}`}</Typography> and press confirm to complete the transfer of ownership and upgrade selected user to an admin role.
			</Typography>
			<TextField
				autoFocus
				variant="outlined"
				value={workspaceTitleInput}
				onChange={(e) => handleConfirmationOnChange(e)}
				error={confirmationError}
				fullWidth
			/>
			<Box sx={{ display: "flex", justifyContent:"space-evenly" }}>
				<Button
					onClick={() => {
						console.log("submit")
						dispatch(confirmWorkspaceTransfer({
							userId: targetUser?.user?.id,
							workspaceId: workspace.id
						}))
					}}
					variant={"contained"}
					color={"primary"}
					disabled={workspace.title !== workspaceTitleInput}
				>
					Confirm
				</Button>
				<Button
					color={"error"}
					onClick={() => {
						dispatch(clearManageWorkspaceView())
					}
					}
				>
					Cancel
				</Button>
			</Box>
		</Box>
	)
}