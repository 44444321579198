import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
// ### PortalLegacy ### \\
//import { useDispatch } from "react-redux"
//import { setView } from "../reducers/dynamicViewSlice"
// ### PortalLegacy ### \\
import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import {
	ContactSupport,
	Launch,
	Logout,
	ManageAccounts, SettingsApplicationsOutlined,
	// ### PortalLegacy ### \\
	//Share,
	// ### PortalLegacy ### \\
} from "@mui/icons-material"

export function DashboardMenu({
	setOpenWorkspaceManager,
	setOpenSupportModal,
	setOpenWorkspacePermissions
}) {
	const { logout } = useAuth0()
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	// ### PortalLegacy ### \\
	//const dispatch = useDispatch()
	// ### PortalLegacy ### \\

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const switchWorkspace = () => {
		setOpenWorkspaceManager(true)
		handleClose()
	}

	const contactSupport = () => {
		setOpenSupportModal(true)
		handleClose()
	}

	const handleWorkspacePermissions = () => {
		setOpenWorkspacePermissions(true)
		handleClose()
	}

	// ### PortalLegacy ### \\
	// const changeView = (view) => {
	// 	dispatch(setView(view))
	// 	handleClose()
	// }
	// ### PortalLegacy ### \\

	return (
		<>
			<Tooltip title="Dashboard Menu">
				<IconButton
					sx={{ p: "10px", flexGrow: 0 }}
					color="default"
					aria-controls={open ? "dashboard-positioned-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					<MenuIcon />
				</IconButton>
			</Tooltip>
			<Menu
				id="dashboard-positioned-menu"
				aria-labelledby="dashboard-positioned-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				{
					setOpenWorkspaceManager &&
						(
							<div>
								<MenuItem onClick={switchWorkspace}>
									<Launch fontSize={"small"} sx={{ marginRight: "5px" }} /> Switch
									workspace
								</MenuItem>
								<MenuItem onClick={handleWorkspacePermissions}>
									<SettingsApplicationsOutlined fontSize={"small"} sx={{ marginRight: "5px" }}/>
									Manage this workspace
								</MenuItem>
								<Divider />
							</div>
						)
				}
				{/*
				// ### PortalLegacy ### \\
				<MenuItem onClick={() => changeView("my-portals")}>
				<Share fontSize={"small"} sx={{ marginRight: "5px" }} /> My portals
				</MenuItem>
				// ### PortalLegacy ### \\
				*/}
				<MenuItem onClick={() => navigate("/account-settings")}>
					<ManageAccounts fontSize={"small"} sx={{ marginRight: "5px" }} />{" "}
					Account settings
				</MenuItem>
				<MenuItem onClick={contactSupport}>
					<ContactSupport fontSize={"small"} sx={{ marginRight: "5px" }} />
					Support or Contact
				</MenuItem>
				<Divider />
				<MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
					<Logout fontSize={"small"} sx={{ marginRight: "5px" }} /> Logout
				</MenuItem>
			</Menu>
		</>
	)
}
