import { Card, CardContent, CardHeader, Typography} from "@mui/material"
import {useSelector} from "react-redux"
import {
	selectDefaultPaymentMethod,
	selectOwnsSubscription,
	selectUserNeedsPaymentDetails,
	selectUserSubscriptionType
} from "../../../../reducers/stripeDetailsSlice"

export function PaymentDetailsCard() {
	const ownsSubscription = useSelector(selectOwnsSubscription)
	const needsPaymentDetails = useSelector(selectUserNeedsPaymentDetails)
	//what we've replaced: state.cardDetails = action.payload.paymentMethod.data[0]
	const currentCardDetails = useSelector(selectDefaultPaymentMethod)
	const currentSubscriptionType = useSelector(selectUserSubscriptionType)

	const setPaymentDetailsText = () => {
		if(needsPaymentDetails) {
			return "Payment method needed"
		}
		if(currentCardDetails) {
			return `${currentCardDetails.card.brand.toUpperCase()} ending in ${currentCardDetails.card.last4} set to expire on ${currentCardDetails.card.exp_month}/${currentCardDetails.card.exp_year}`
		}
		if(!ownsSubscription && currentSubscriptionType === "KeyUser") {
			return "You are currently using a subscription seat."
		}
	}

	return (
		<Card
			sx={{
				margin: "1em 0",
				border: "1px solid rgb(207, 207, 207)",
				display: "flex",
				justifyContent: "space-between"
			}}
			elevation={0}
		>
			<CardContent sx={{padding: "0 1em"}}>
				<CardHeader
					title={"Current Payment Method"}
					titleTypographyProps={{
						variant: "tunedSubtitle3Bold",
					}}
					sx={{ padding: "1em 1em 0.25em 0em" }}
				/>
				<Typography
					variant={"tunedSubtitle3"}
					paragraph
					sx={{ color: "RBOneFont.main", }}
				>
					{setPaymentDetailsText()}
				</Typography>
			</CardContent>
		</Card>
	)
}