import { 
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogContentText, 
	DialogActions, 
	Stack, 
	Button 
} from "@mui/material" 
import axios from "axios"

export default function RevokeKeyModal({ open, setIsOpen, keyToModify, refreshLicenses, enqueueSnackbar, keyAction, handleModalClose }) {
	const revokeLicenseKey = () => {
		axios
			.put(`/api/license/${keyToModify.id}/reset`)
			.then(() => {
				refreshLicenses()
			})
			.catch((error) => {
				enqueueSnackbar(error.response.data.msg, {
					variant: "error",
					autoHideDuration: 3000,
				})
			})
	}

	const deleteLicenseKey = () => {
		axios
			.delete(`/api/license/${keyToModify.id}`)
			.then(() => {
				axios
					.get("/api/license/")
				refreshLicenses()
			})
			.catch((error) => {
				enqueueSnackbar(error.response.data.msg, {
					variant: "error",
					autoHideDuration: 3000,
				})
			})
	}

	return (
		<Dialog
			open={open}
			onClose={() => handleModalClose}
			sx={{ textAlign: "center" }}
		>
			<DialogTitle>Do you want to {keyAction == "delete" ? "delete" : "revoke"} this subscription?</DialogTitle>
			{/*<DialogTitle>diagnostic: key is - {keyToModify.UUID}</DialogTitle>*/}
			<DialogContent>
				{keyAction == "delete" ? 
					<DialogContentText variant="body1" paragraph gutterBottom>
					Deleting this seat will revoke the associated user's access AND
					will delete the cost of the seat from your next invoice.
					</DialogContentText> : 
					<DialogContentText variant="body1" paragraph gutterBottom>
					Revoking this seat will revoke the associated user's access, but will keep the seat on your subscription so that you can invite a different user.
					The total for your next bill will not change.
					</DialogContentText> 
				} 
					
				<DialogActions sx={{ flexDirection: "column" }} disableSpacing>
					<Stack spacing={2}>

						{keyAction == "delete" ? 
							<Button
								fullWidth
								variant="contained"
								color="warning"
								onClick={() => {
									deleteLicenseKey()
									setIsOpen(false)
								}}
							>
							Delete this seat from my subscription and remove the cost from my bill.
							</Button> : 
							<Button
								fullWidth
								variant="contained"
								color="warning"
								onClick={() => {
									revokeLicenseKey()
									setIsOpen(false)
								}}>
							Revoke this user's access to your subscription.
							</Button>
						}
						<Button
							fullWidth
							variant={"outlined"}
							color="primary"
							onClick={() => setIsOpen(false)}
						>
							Cancel
						</Button>
					</Stack>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}
