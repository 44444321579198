import {FormControl, IconButton, OutlinedInput, Tooltip, Typography} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import {Close} from "@mui/icons-material"

export function WorkspaceSearchInputForm({showSearchInput, setShowSearchInput, handleSubmit, handleChange, query, canAddSearch}) {
	const handleClickSearchButton = () => {
		if(canAddSearch){
			setShowSearchInput(true)
		}
	}

	return (
		<FormControl
			as={"form"}
			fullWidth={showSearchInput}
			variant="outlined"
			onSubmit={handleSubmit}
			sx={{
				flexDirection: "row",
				alignItems: "center",
				position: showSearchInput ? "absolute" : "relative",
				zIndex: 100,
				backgroundColor: "white",
				alignSelf: "center",
				height: "40px",
			}}
		>
			{
				showSearchInput ?
					(
						<OutlinedInput
							value={query}
							sx={{
								height: "30px",
								width: "100%",
								
							}}
							inputProps={{
								style: {
									padding: "0px 8px 0px 8px"
								}
							}}
							placeholder={"Search notes"}
							onChange={handleChange}
							autoFocus
							endAdornment={
								<IconButton
									aria-label="search notes"
									type={"submit"}
									edge="end"
								>
									<SearchIcon />
								</IconButton>
							}
							startAdornment={
								<IconButton
									aria-label="close search notes"
									edge={"start"}
									onClick={() => setShowSearchInput(false)}
								>
									<Close />
								</IconButton>
							}
						/>
					)
					:
					(
						<Tooltip
							title={canAddSearch ?
								"Search your workspace notes for all occurrences of this term"
								:
								(
									<Typography variant={"h5"}>
										Three searches max. Delete one please.
									</Typography>
								)
							}
							enterDelay={0}
							enterNextDelay={0}
						>
							<span>
								<OutlinedInput
									value={""}
									onClick={handleClickSearchButton}
									onFocus={handleClickSearchButton}
									onChange={handleClickSearchButton}
									placeholder={"Search Workspace"}
									endAdornment={
										<IconButton
											aria-label="search notes"
											edge="end"
											onClick={handleClickSearchButton}
											disabled={!canAddSearch}
										>
											<SearchIcon />
										</IconButton>
									}
									autoFocus={false}
									sx={{
										height: "30px",
										boxSizing: "border-box"
									}}
									inputProps={{
										style: {
											padding: "0px 8px 0px 8px"
										}
									}}
									disabled={!canAddSearch}
								/>
							</span>
						</Tooltip>
					)
			}
		</FormControl>
	)
}