import { Box, Card, CardContent, Grid, Rating, Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
const testimonials = [
	{
		rating: () => <Rating name="Review" value={5} readOnly />,
		body: "RefBin makes my research more productive by keeping my literature up to date and organized like never before!",
		author: {
			name: "Stephanie Pero, Ph.D.",
			title: "",
		}
	},
	{
		rating: () => <Rating name="Review" value={5} readOnly />,
		body: "As an NIH-funded physician scientist, I have found RefBin to be a critical and essential tool for my research program. RefBin allows me to stay up-to-date in my specific research niche, despite a busy schedule. It also provides an intuitive, rapid mechanism for organizing and retrieving articles which I have reviewed.",
		author: {
			name: "Kalev Freeman, M.D.",
			title: "CEO",
		},
	},
	{
		rating: () => <Rating name="Review" value={5} readOnly />,
		body: "To login every day and find the newest science tailored to my needs and without duplication is invaluable, and keeps me at the leading edge of fast-paced of biomedical research.",
		author: {
			name: "Nathan R. Tykocki, Ph.D.",
			title: "",
		},
	}
]
export default function Testimonials() {
	return (
		<div>
			<Stack
				direction="row"
				justifyContent="space-evenly"
				alignItems="flex-start"
				spacing={4}
				sx={{
					mb: 18,
				}}
			>
				<Grid container spacing={4}>
					{testimonials.map((testimonial) => (
						<Grid key={testimonial.author.title} item md={4}>
							<Card elevation="3" grow="true">
								<CardContent>
									<Box component="div" sx=
										{{
											mb: 1.5,
										}}
									>
										{testimonial.rating()}
									</Box>
									<Typography variant="p" component="div" sx={{ fontFamily:"Inter", fontSize:" 1rem", lineHeight:"1.5", fontWeight: "400", marginBottom:".5rem",}}>
										{testimonial.body}
									</Typography>
									<Typography variant="body2" component="div" sx={{fontFamily:"Inter", fontSize:" 1rem", fontWeight: "700",}}>
										{testimonial.author.name}
									</Typography>
									<Typography variant="body2" component="div" sx={{fontFamily:"Inter", fontSize:" 1rem", fontWeight: "700",}}>
										{testimonial.author.title}
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					))}

				</Grid>
			</Stack>
		</div>
	)
}
