import {
	Box,
	Card,
	List,
	ListItem,
	Typography,
} from "@mui/material"

// This is a recursive component
export default function UndoDeleteBreadcrumb({ noteTree, deletedNote }) {

	return (
		<List
			sx={{
				paddingLeft: "25px",
				paddingTop: "1px",
				paddingBottom: 0,
				pointerEvents: "none",
			}}
			dense
		>
			<ListItem
				sx={{
					display: "block",
					borderLeft: "1px solid #dfdfdfa1",
					padding: 0,
					pointerEvents: "auto",
				}}
			>
				<Box sx={{ display: "flex" }}>
					<Card
						variant={"note"}
						key={noteTree.id}
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							paddingRight: "25px",
							minHeight: "30px",
							color: "RBOneFont.main",
							borderLeft: "5px solid",
							borderColor: "notes.dark",
							backgroundColor: "notes.crumbsBackground",
							...(noteTree.id == deletedNote.id) && {
								backgroundColor: "notes.selected",
								borderColor: "notes.selectedGreen",
							}
						}}
					>
						<Typography sx={{ paddingLeft: "5px" }}>{noteTree.content}</Typography>
					</Card>
				</Box>
				{ noteTree.children && 
					noteTree.children.map((child) => (
						<UndoDeleteBreadcrumb
							key={child.id}
							noteTree={child}
							deletedNote={deletedNote}
						/>
					))
				}
			</ListItem>
		</List>
	)
}