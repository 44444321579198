import { useState, useRef } from "react"
import {
	Button,
	Stack,
	Dialog, 
	DialogTitle, 
	DialogContent, 
	DialogActions,
	List,
	ListItem,
	Typography,
	TextField,
	Box
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import axios from "axios"
import { useSnackbar } from "notistack"
import { selectSelectedArticles, clearSelectedArticles } from "../../reducers/selectedArticlesSlice"
import { useDispatch, useSelector } from "react-redux"
import { selectUser } from "../../reducers/userSlice"
import {selectWorkspaceId} from "../../reducers/workspaceSlice"
import { debounce } from "lodash"

export default function ShareArticleModal({setIsArticleShareModalOpen, isArticleShareModalOpen}) {
	const [searchResults, setSearchResults] = useState([])
	const [userSelection, setUserSelection] = useState([])
	const [searchString, setSearchString] = useState("")
	const [message, setMessage] = useState("")
	const { enqueueSnackbar } = useSnackbar()
	const modalRef = useRef(null)
	const sendingUser = useSelector(selectUser)
	const selectedArticles = useSelector(selectSelectedArticles)
	const dispatch = useDispatch()
	const articleCount = Object.keys(selectedArticles).length
	const workspaceId = useSelector(selectWorkspaceId)


	const addUserToList = (user) => {
		setUserSelection([...userSelection, user])
		setSearchResults([])
		setSearchString("")
	}

	const removeUserfromList = (selectedUser) => {
		const filteredResults = userSelection.filter(user => user.id !== selectedUser.id)
		setUserSelection([...filteredResults])
	}

	const clearData = () => {
		setUserSelection([])
		setMessage("")
		setSearchResults([])
		setSearchString("")
	}

	const sendCitation = () => {
		// Construct the batch payload
		const notificationsData = userSelection.map(user => ({
			message: message,
			type: "article_share", 
			userId: user.id,
			sendingUserId: sendingUser.id,
			sharedArticles: Object.keys(selectedArticles),
			sendingWorkspaceId: workspaceId
		}))
	
		// Send the batch request
		axios.post("/api/notifications/batch", notificationsData)
			// eslint-disable-next-line no-unused-vars
			.then(response => {
				enqueueSnackbar(
					"Successfully shared the articles.",
					{
						variant: "success",
						autoHideDuration: 3000
					}
				)
				clearData()
				setIsArticleShareModalOpen(false)
				dispatch(clearSelectedArticles()) // Clear the selected articles from the state
			})
			.catch(error => {
				console.error("Error while sharing articles: ", error)
				enqueueSnackbar(
					"Error while sharing. Please try again.",
					{
						variant: "error",
						autoHideDuration: 3000
					}
				)
			})
	}
	

	const queryUserByLastName = (query) => {
		if (query.length >= 3) {
			axios.get(`/api/user?search=${query}`)
				.then(({ data }) => {
					// Assuming userSelection is available in your component's context
					const filteredResults = data.filter(serverUserData =>
						!userSelection.some(user => user.id === serverUserData.id)
					)
					setSearchResults(filteredResults)
				})
				.catch((error) => {
					console.error("SEARCHING FOR MEMBERS TO SHARE CITATION: \n", error)
					enqueueSnackbar("Failed while searching. Please try again.", { variant: "error" })
				})
		}
	}

	// debounce the user search
	const debouncedSearch = debounce(queryUserByLastName, 300)

	return(
		<Dialog
			open={isArticleShareModalOpen}
			onClose={() => setIsArticleShareModalOpen(false)}
			sx={{ textAlign: "center"}}
			ref={modalRef}
		>
			<DialogTitle sx={{ padding: "24px 36px 16px" }}>
				Add recipients - Sharing {articleCount} articles
			</DialogTitle>
			<DialogContent sx={{ padding: "0 36px 24px" }}>  
				<DialogActions 
					sx={{ 
						flexDirection: "row", 
						display: "flex",
						justifyContent: "center"
					}} 
					disableSpacing>
					<Stack width="100%" spacing={4} direction="column">
						<TextField
							sx={{minWidth: "512px"}}
							onKeyDown={(e) => e.stopPropagation()}
							fullWidth
							label="Search Users"
							variant="standard"
							value={searchString}
							onChange={(e) => {
								setSearchString(e.target.value)
								debouncedSearch(e.target.value)
							}}
						/>
						{
							searchResults.length > 0 && (
								<List sx={{ maxHeight: "150px", overflow: "scroll" }}>
									{searchResults.map((user) => (
										<ListItem
											onClick={() => addUserToList(user)}
											key={user.id}
											sx={{
												display: "flex",
												border: "1px solid black",
												borderRadius: "5px",
												justifyContent: "center"
											}}
										>
											<Typography>
												{user.firstName} {user.lastName} - {user.email}
											</Typography>
										</ListItem>
									))}
								</List>
							)
						}
						{
							userSelection.length > 0 && (
								<List sx={{display: "flex", flexFlow: "row wrap", gap: "4px",}}>
									{
										userSelection.map((user) => (
											<ListItem
												sx={{
													display: "inline-flex",
													width: "auto",
													border: "1px solid black",
													borderRadius: "5px",
													justifyContent: "start"
												}}
												key={user.id + "userselection"}>
												<Box onClick={() => removeUserfromList(user)}>
													<CloseIcon  />
												</Box>
												<Typography>
													{user.firstName} {user.lastName}
												</Typography>
											</ListItem>
										))
									}
								</List>
							)
						}
						<TextField
							onKeyDown={(e) => e.stopPropagation()}
							fullWidth
							label="Message"
							variant="standard"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
						<Stack spacing={4} direction="row">
							<Button
								fullWidth
								variant={"outlined"}
								color="primary"
								onClick={() => {
									clearData()
									setIsArticleShareModalOpen(false)
								}}
							>
								Cancel
							</Button>

							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => {
									sendCitation()
								}}>
								Share
							</Button>
						</Stack>
					</Stack>

				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}